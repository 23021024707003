import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ThankyouImage from "../../../assets/Images/send-message.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { CommentKitty } from "../../../store/thunk/Kitty/KittyDetailsThunk";
import { useTranslation } from "react-i18next";

export default function ResponsiveDialog() {
  const { token } = useParams();
  const contributeInfo = useAppSelector(
    (state) => state.newUserSlice?.contributeResponse
  );
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [fullName, setFullName] = React.useState({
    first_name: contributeInfo?.user?.first_name,
    name: contributeInfo?.user?.name,
    message: "",
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
    setFullName({
      first_name: contributeInfo.user.first_name,
      name: contributeInfo.user.name,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setFullName({
      first_name: contributeInfo.user.first_name,
      name: contributeInfo.user.name,
    });
  };

  return (
    <div>
      <Box
        sx={{ minHeight: "200px", width: "100%", cursor: "pointer" }}
        onClick={handleClickOpen}
      >
        <img
          src={ThankyouImage}
          alt="ThankyouImage"
          style={{ width: "100%" }}
        />
        <Typography
          variant="h4"
          sx={{
            fontSize: " 14px",
            mt: 1,
            fontWeight: "600",
            marginBottom: "8px",
            textAlign: "center",
          }}
        >
          {t("afterContribution.9")}
        </Typography>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            background: "#592091",
            color: "white",
            marginBottom: "16px",
          }}
        >
          {t("MessageAfterContribute.0")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {" "}
            {t("MessageAfterContribute.1")}
          </DialogContentText>
          <Box
            sx={{ display: "flex", flexDirection: "column", width: "400px" }}
          >
            <TextField
              multiline
              rows={4}
              size="small"
              value={fullName.message}
              onChange={(e) => {
                setFullName({
                  ...fullName,
                  message: e.target.value,
                });
              }}
              id="outlined-basic-first_name"
              label={t("spendKittyWriteMessageLable")}
              variant="outlined"
              sx={{
                margin: " 10px 0px 0px",
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            padding: " 0px 24px 20px",
            justifyContent: "left",
          }}
        >
          <Button autoFocus onClick={handleClose} variant="contained">
            {t("cancelLable")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              handleClose();
              dispatch(
                CommentKitty({
                  data: {
                    comment: fullName.message,
                    user: contributeInfo?.user?.id,
                    kitty: contributeInfo?.kitty?.id,
                  },
                  token: token,
                })
              );
            }}
            autoFocus
          >
            {t("sendLabel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
