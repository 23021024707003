import { Grid, Paper, Box } from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import Image1 from "../../assets/Images/inner-pages/Tontine.jpg";
import Image3 from "../../assets/Images/inner-pages/Kitty.jpg";
import Image4 from "../../assets/Images/inner-pages/principal.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/store";

export default function CardBlock() {
  const { t } = useTranslation();
  const userCheck = useAppSelector((state) => state.UserProfile);

  const items = [
    {
      name: t("Dasboard.2"),
      imgPath: Image1,
      title: t("Dasboard.3"),
      route: "/tontine-admin",
    },
    {
      name: t("Dasboard.6"),
      imgPath: Image3,
      title: t("Dasboard.7"),
      route: userCheck.details.is_superuser ? "/my-kitty" : "/kitty-listing",
    },
    {
      name: t("Dasboard.8"),
      imgPath: Image4,
      title: t("Dasboard.9"),
      route: "/how-it-works",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Grid
        container
        sx={{
          flexwrap: " nowrap",
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "84%",
        }}
      >
        {items.map((item) => (
          <Grid item md={4} xs={12} sx={{ p: 1, mb: { md: "0px", xs: 2 } }}>
            <Paper sx={{ background: "#e6e6e6", position: "relative" }}>
              <img
                src={item.imgPath}
                alt=""
                style={{ width: "100%" }}
                loading="lazy"
              />
              <Box className="description" sx={{}}>
                {item.name}
              </Box>
              <Link to={item.route}>
                <Button
                  size="small"
                  sx={{
                    background: " #91bcff",
                    color: "#1c1c1c",
                    "&:hover": {
                      background: " #91bcff",
                      color: "#1c1c1c",
                    },
                  }}
                >
                  {item.title}
                </Button>
              </Link>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
