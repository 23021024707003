import * as React from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import KittySwapCreater from "./KittySwapCreater";

export default function Admin() {
  return (
    <>
      <Header />
      <KittySwapCreater />
      <Footer />
    </>
  );
}
