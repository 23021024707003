import { Navigate } from "react-router-dom";
import { useAppSelector } from "./store/store";

function PublicRoute(props) {
  const { component } = props;
  const userAuthData = useAppSelector((state) => state.userSlice);
  const userCheck = useAppSelector((state) => state.UserProfile);
  return userAuthData?.token &&
    userCheck?.details?.country &&
    userCheck?.details?.is_superuser ? (
    <Navigate to="/admin-dashboard" />
  ) : userAuthData?.token &&
    userCheck?.details?.country &&
    !userCheck?.details?.is_superuser ? (
    <Navigate to="/admin" />
  ) : userAuthData?.token &&
    !userCheck?.details?.country &&
    !userCheck?.details?.is_superuser ? (
    <Navigate to="/sign-up" />
  ) : (
    component
  );
}

export default PublicRoute;
