import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { listofSubscribedTontine } from "../../../store/thunk/Tontine/MytontineThunk";
import { Box, Typography, Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CustomMPagination from "../../../components/common/CustomMPagination";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#592091ab",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function MyTontineTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = React.useState(0);
  const [pageCountShow, SetPageCountShow] = React.useState(1);
  const [recordLimit, SetRecordLimit] = React.useState(10);

  React.useEffect(() => {
    dispatch(
      listofSubscribedTontine({
        limit: Number(recordLimit),
        offset: Number(pageCount),
      })
    );
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [recordLimit, pageCount]);

  const myTontine = useAppSelector((state) => state.MyTontine);

  const detailPage = (id) => {
    navigate(`/tontine/${id}`, {
      state: { backRedirectUrl: "/tontine-admin" },
    });
  };

  return (
    <>
      <Box sx={{ margin: "10px 0" }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={myTontine?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Paper
          sx={{
            p: 2,
            backgroundColor: "#59209124",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="h5"
              sx={{
                textTransform: "uppercase",
                fontWeight: " 600",
                fontSize: "16px",
              }}
            >
              {t("tontineTab1")}
            </Typography>
          </Box>
        </Paper>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                {t("tontineTableColumn.0")}
              </StyledTableCell>
              <StyledTableCell>{t("tontineTableColumn.1")}</StyledTableCell>
              <StyledTableCell align="center">
                {t("tontineTableColumn.2")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("tontineTableColumn.3")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("OrganizedByLable")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("FutureReliablityLable")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("NumberParticipantsLable")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("StartDateLable")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("tontineFrequencyFieldLabel")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myTontine?.SubscribedTontine?.results?.length ? (
              myTontine?.SubscribedTontine?.results?.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    <img
                      src={row.tontine.image}
                      alt="Tontine"
                      height={60}
                      width={85}
                      onClick={() => detailPage(row.id)}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    onClick={() => detailPage(row.tontine.id)}
                  >
                    <Box sx={{ width: "160px" }}>{row.tontine.name}</Box>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.tontine.id)}
                  >
                    {t(row.tontine.status)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.tontine.id)}
                  >
                    {row.tontine.amount}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.tontine.id)}
                  >
                    {`${row.user.first_name} ${row.user.name}`}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.tontine.id)}
                  >
                    {`${row.tontine.future_reliability}%`}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.tontine.id)}
                  >
                    {`${row.tontine.participant_length}`}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.tontine.id)}
                  >
                    {row.tontine?.creation_date !== null
                      ? moment(row.tontine?.creation_date).format(
                          localStorage.getItem("language") == "en"
                            ? "YYYY-MM-DD"
                            : "DD-MM-YYYY"
                        )
                      : t("NotstartedLable")}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.tontine.id)}
                  >
                    {t(row.tontine.frequency)}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow key="1">
                <StyledTableCell
                  scope="row"
                  colSpan={10}
                  sx={{
                    color: "#525453cf",
                    backgroundColor: "#f1efeb",
                    textAlign: "center",
                  }}
                >
                  {t("norecordLbl")}
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ mt: 1 }}>
        <CustomMPagination
          pageCount={pageCount}
          SetPageCount={SetPageCount}
          recordLimit={recordLimit}
          pageCountShow={pageCountShow}
          SetRecordLimit={SetRecordLimit}
          numRecords={myTontine?.SubscribedTontine?.num_records}
          numCount={myTontine?.SubscribedTontine?.count}
        />
      </Box>
    </>
  );
}
