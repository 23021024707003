import React, { useRef, useState } from "react";
import PublishedWithChangesRoundedIcon from "@mui/icons-material/PublishedWithChangesRounded";
import "./accordion.scss";
import { Box } from "@mui/material";

function AccordionComponent(props) {
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState("0px");

  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  }

  return (
    <Box className="accordion__section">
      <Box
        className={`accordion ${active ? "active" : ""}`}
        onClick={toggleAccordion}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PublishedWithChangesRoundedIcon sx={{ color: "#592091" }} />
          <p className="accordion__title">{props.title}</p>
        </Box>
        <Box>
          <span style={{ marginLeft: "20px" }}>{active ? "-" : "+"}</span>
        </Box>
      </Box>
      <Box
        ref={content}
        style={{ maxHeight: `${height}`, textAlign: "justify" }}
        className="accordion__content"
      >
        <Box
          className="accordion__text"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </Box>
    </Box>
  );
}

export default AccordionComponent;
