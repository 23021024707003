import React from "react";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Stack,
  Card,
  CardContent,
  Button,
  Box,
  Alert,
  FormHelperText,
} from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import RulesImage from "../../../../assets/Images/upload-rules-id.svg";

const fileType = ["image/png", "image/jpeg", "image/gif", "application/pdf"];

function DocumentUploadSection({
  title,
  description,
  goBackFunc,
  onSelectFile,
  docError,
  setDocError,
  handleChange,
  acceptTerms,
  setAcceptTerms,
}) {
  const { t } = useTranslation();

  const [fileList, setFileList] = React.useState([]);
  const [error, setError] = React.useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    setDocError(false);
    setAcceptTerms(false);
  }, []);

  const handleFileUpload = (e) => {
    let approveFlag = 1;
    if (!e.target.files) {
      return;
    }
    setError("");
    Array.from(e.target.files).forEach((file) => {
      if (!fileType.includes(file.type)) {
        setError(t("imageValidationPdf"));
        approveFlag = 0;
        return;
      }

      // File Size Validation
      const fileSize = file.size;
      let fileSizeMb = 0;
      let fileSizeKb = 0;

      if (fileSize > 0) {
        fileSizeMb = (file.size / 1024 / 1024).toFixed(2);
        fileSizeKb = (file.size / 1024).toFixed(2);
      }

      if (fileSizeKb < 32) {
        setError(t("imageLessSizeValidation"));
        approveFlag = 0;
        return;
      }

      if (fileSizeMb > 5) {
        setError(t("imageSizeValidation"));
        approveFlag = 0;
        return;
      }
    });
    setDocError(false);
    if (approveFlag) {
      const oldFileList = Array.from(fileList);
      const newFileList = [...oldFileList, ...Array.from(e.target.files)];
      setFileList(newFileList);
      onSelectFile(newFileList);
    }
  };

  const handleFileRemove = (index) => {
    const newFileList = Array.from(fileList);
    newFileList.splice(index, 1);
    setFileList(newFileList);
    onSelectFile(newFileList);
  };

  const documentFiles = fileList ? [...fileList] : [];

  return (
    <Grid
      container
      spacing={1}
      sx={{
        display: { md: "flex", xs: "block" },
        justifyContent: " center",
        textAlign: "center",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{ display: { md: "flex", xs: "block" }, justifyContent: " center" }}
      >
        <Typography
          variant="subtitle2"
          component={"span"}
          gutterBottom
          sx={{ pl: "8px", fontWeight: "700" }}
        >
          {t("uploadDocLabel")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: { md: "flex", xs: "block" }, justifyContent: " center" }}
      >
        {error && (
          <Alert severity="error" sx={{ mb: "10px" }}>
            {error}
          </Alert>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: { md: "flex", xs: "block" }, justifyContent: " center" }}
      >
        <Typography variant="caption" gutterBottom sx={{ ml: "8px" }}>
          {t("quickDocLabel")}:
        </Typography>
      </Grid>
      <Grid item xs={7} sx={{ textAlign: "center", margin: "10px auto", p: 0 }}>
        <img src={RulesImage} style={{ width: "50%" }} />
      </Grid>
      <Grid item xs={7} sx={{ maxWidth: " 100% " }}>
        {documentFiles.map((file, i) => (
          <Box key={i} sx={{ display: "flex", justifyContent: "center" }}>
            <TextSnippetIcon />
            <Tooltip title={file.name}>
              <span className="document-preview-list-name">{file.name}</span>
            </Tooltip>
            <Tooltip title={t("removeLable")}>
              <CancelIcon
                sx={{ cursor: "pointer", color: "#ccc" }}
                onClick={() => handleFileRemove(i)}
              />
            </Tooltip>
          </Box>
        ))}
      </Grid>
      <Grid item xs={7}>
        <Button
          variant="text"
          component="label"
          sx={{
            width: "100%",
            "&:hover": {
              background: "transparent",
            },
          }}
        >
          <Card
            sx={{
              mt: 2,
              mr: 1,
              border: "1px solid #e6e6e6",
              cursor: "pointer",
              margin: "20px auto 0px",
              maxWidth: "200px",
              "&:hover": {
                borderColor: "#592091",
              },
              width: "100%",
              textAlign: "center",
            }}
          >
            <CardContent
              height="90"
              sx={{
                display: "grid",
                alignItems: "center",
                "&:hover": {
                  borderColor: "#592091",
                },
              }}
            >
              <Typography
                component={Stack}
                direction="column"
                alignItems="center"
              >
                <AddCircleRoundedIcon />
              </Typography>
              <input
                accept="image/jpeg, image/png, application/pdf"
                type="file"
                onChange={handleFileUpload}
                hidden
                multiple
              />
              {t("addLabel")}
            </CardContent>
          </Card>
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: { md: "flex", xs: "block" },
          justifyContent: " center",
          margin: "10px auto",
        }}
      >
        {docError && (
          <FormHelperText id="component-error-text" error>
            {t("documentValidation")}
          </FormHelperText>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: { md: "flex", xs: "block" }, justifyContent: " center" }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptTerms}
              name="confirm_document"
              onChange={handleChange}
            />
          }
          label={
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ color: "inherit" }}
            >
              {t("confirmDocLabel")}
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={goBackFunc}
          sx={{
            color: "#868484",
            fontWeight: "600",
            justifyContent: "left",
            margin: "0 auto 20px",
          }}
        >
          {"< "}
          {t("goBackLable")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default DocumentUploadSection;
