import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  success: "",
  list: {},
  filterList: {},
  id:"",
  invitedKitty:{},
};

const listofKittySlice = createSlice({
  name: "listofKittySlice",
  initialState,
  reducers: {
    setListofKitty: (state, action) => ({
      ...state,
      list: action.payload,
    }),
    setListofInvitedKitty: (state, action) => ({
      ...state,
      invitedKitty: action.payload,
    }),
    setFilterListofKitty: (state, action) => ({
      ...state,
      filterList: action.payload,
    }),
    setIdofKitty: (state, action) => ({
      ...state,
      id: action.payload,
    }),
    setKittyLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setSuccess: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    setError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
  },
});

export const { setListofKitty, setKittyLoader, setSuccess, setError, setIdofKitty, setFilterListofKitty, setListofInvitedKitty } = listofKittySlice.actions;

export default listofKittySlice.reducer;