import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  Box,
  Backdrop,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/store";
import { approveRejectDocument } from "../../../../store/thunk/Tontine/MytontineThunk";
import { LoadingButton } from "@mui/lab";

function YourID(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const documentLoader = useAppSelector((state) => state?.newUserSlice);
  const loaderUser = useAppSelector((state) => state?.MyTontine?.loading);
  const userDocuments = useAppSelector(
    (state) => state?.MyTontine?.userDocuments
  );
  const [IBAN, setIBAN] = React.useState("");

  useEffect(() => {
    setIBAN(userDocuments[0]?.iban || "");
  }, [userDocuments]);

  const handleSubmit = () => {
    dispatch(
      approveRejectDocument({
        documentId: userDocuments[0]?.id,
        request: { iban: IBAN },
        limit: 10,
        offSet: 0,
        callbackPayout: () => {
          props?.callback();
        },
      })
    );
  };
  return (
    <Grid
      container
      spacing={1}
      sx={{ display: { md: "flex", xs: "block" }, justifyContent: " center" }}
    >
      {/* Document Upload Success */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderUser}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <>
        <Grid item md={8} xs={12}>
          <Box
            sx={{
              maxWidth: "550px !important",
              fontSize: "18px",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                textAlign: "center",
                fontWeight: " 600",
                color: "#592091",
              }}
            >
              {t("KittyPayoutPage.26")}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <TextField
                value={IBAN}
                id="IBAN number"
                label={t("IBANNumberLable")}
                fullWidth
                variant="outlined"
                sx={{ width: "100%" }}
                placeholder="FR00 1111 2222 3333 4444 5555 666"
                onChange={(e) => {
                  setIBAN(e.target.value);
                }}
              />
              <LoadingButton
                onClick={() => handleSubmit()}
                loading={documentLoader.loading}
                disabled={IBAN === ""}
                sx={{
                  background: "#592091",
                  color: "#fff",
                  display: "block",
                  marginLeft: "10px",
                  padding: "8px 20px",
                  fontWeight: "600",
                  letterSpacing: " 2px",
                  border: "1px solid #592091",
                  textAlign: "center",
                  maxWidth: "200px",
                  width: "100%",
                  "&:hover": {
                    color: "#592091",
                  },
                  "&:disabled": {
                    color: "#fff",
                    background: "#876ba3",
                  },
                }}
                // disabled={!acceptTerms}
              >
                {t("submitLabel")}
              </LoadingButton>
            </Box>
          </Box>
        </Grid>
      </>
    </Grid>
  );
}

export default YourID;
