import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newKittyCreate: "",
  newKittyData: { category_name: "", id: "", user:"" },
  RedirectUrl:"",
  activeTabIndex:0,
  triggerFunction:false,
};

const NewKittyCreateSlice = createSlice({
  name: "setNewKittyCreate",
  initialState,
  reducers: {
    setNewKittyCreate: (state, action) => ({
      ...state,
      newKittyCreate: action.payload,
    }),
    setNewKittyData: (state, action) => ({
      ...state,
      newKittyData: action.payload,
    }),
    setRedirectData: (state, action) => ({
      ...state,
      RedirectUrl: action.payload,
    }),
    TabIndexData: (state, action) => ({
      ...state,
      activeTabIndex: action.payload,
    }),
    setTriggerFunction: (state, action) => ({
      ...state,
      triggerFunction: action.payload,
    }),
  },
});

export const { setNewKittyCreate, setNewKittyData, setRedirectData, TabIndexData, setTriggerFunction } = NewKittyCreateSlice.actions;

export default NewKittyCreateSlice.reducer;
