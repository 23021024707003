import * as React from "react";
import Countdown from "react-countdown";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { addContributeWithUserData } from "../../store/thunk/newUserThunk";
import { useAppDispatch } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { CheckPaymentIntent } from "../../store/thunk/Payments/CheckOutThunk";
import { useTranslation } from "react-i18next";
import i18Next from "../../i18Next";

export default function CircularIndeterminate() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const payment_id = urlParams.get("payment_intent");
  const { t } = useTranslation();

  React.useEffect(() => {
    const language = localStorage.getItem("language") || "fr";
    if (language) localStorage.setItem("language", language);
    i18Next.changeLanguage(
      localStorage.getItem("language") ? localStorage.getItem("language") : "fr"
    );
  }, []);

  const callContribute = () => {
    let request = JSON.parse(localStorage.getItem("kittyContributeData"));
    dispatch(
      addContributeWithUserData({
        ...request,
        payment_intent_id: payment_id,
        callback: (id) => {
          window.location.href = `/after-contribution/${id.id}/${
            id?.token
              ? id?.token
              : localStorage.getItem("UserInfo")
              ? localStorage.getItem("UserInfo")
              : localStorage.getItem("guestUserToken")
          }`;
        },
      })
    );
  };
  React.useEffect(() => {
    const timeInterval = setInterval(() => {
      dispatch(
        CheckPaymentIntent({
          payment_id: payment_id,
          callback: () => callContribute(),
          callbackTimeFunction: () => {
            window.clearInterval(timeInterval);
          },
        })
      );
    }, 2000);
  }, []);

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
              {t("paymentPage.3")}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "18px", fontWeight: "600", mr: 1 }}>
              {t("paymentPage.4")}
            </Typography>
            <Box
              sx={{
                display: "inline-block",
                fontSize: "18px",
                fontWeight: "600",
                backgroundColor: "#592091",
                borderRadius: "5px",
                border: "1px #592091 solid",
                padding: "7px 20px",
                color: "white",
                "&:hover": {
                  backgroundColor: "#7d0cee",
                },
              }}
            >
              <Button onClick={() => navigate("/")}>
                {t("paymentPage.8")}
              </Button>
            </Box>
          </Box>
        </Box>
      );
    } else {
      // Render a countdown
      return (
        <span>{`${t("paymentPage.5")} ${seconds} ${t("paymentPage.6")}`}</span>
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        placeContent: "center",
        minHeight: "480px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        <Box>{t("paymentPage.2")}.....</Box>
        <Box>
          <CircularProgress sx={{ padding: "30px" }} />
        </Box>
        <Box>
          <Countdown date={Date.now() + 30000} renderer={renderer} />
        </Box>
      </Box>
    </Box>
  );
}
