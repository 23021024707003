import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../store/store";
import {
  CreateCommissionTontine,
  UpdateCommissionTontine,
} from "../../../../../store/thunk/Payments/CheckOutThunk";

export default function AddUpdateTontineComponent(props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(true);
  const [formError, setFormError] = React.useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [form, setForm] = React.useState({
    range_start: "",
    range_end: "",
    commissions: "",
  });

  React.useEffect(() => {
    setForm({
      ...form,
      range_start: props?.data?.range_start,
      range_end: props?.data?.range_end,
      commissions: props?.data?.commissions,
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const handleClose = () => {
    setOpen(false);
    props.callback();
  };

  const validateForm = (data) => {
    let error = [];

    if (!data.range_start) {
      error.push("range_start");
    }
    if (!data.range_end) {
      error.push("range_end");
    }
    if (!data.commissions) {
      error.push("commissions");
    }

    setFormError(error);

    return error;
  };

  const createNewCommission = () => {
    let error = validateForm(form);
    if (error.length == 0) {
      dispatch(
        CreateCommissionTontine({
          request: form,
          callback: () => handleClose(),
          limit: 10,
          offSet: 0,
        })
      );
    }
  };

  const UpdateCommission = () => {
    let error = validateForm(form);
    if (error.length == 0) {
      dispatch(
        UpdateCommissionTontine({
          request: form,
          callback: () => handleClose(),
          id: props?.data?.id,
          limit: props.limit,
          offSet: props.offSet,
        })
      );
    }
  };

  const handleChangeInput = (text, event) => {
    if (
      event.target.value > 9999999 ||
      (text === "commissions" && event.target.value > 100)
    ) {
      return;
    } else {
      const index = formError.indexOf(text);
      if (index !== -1) {
        formError.splice(index, 1);
      }

      if (
        text === "range_start" ||
        text === "range_end" ||
        text === "commissions"
      ) {
        // Regex pattern to match up to two decimal places
        const regex = /^(\d*\.?\d{0,2}).*$/;
        const newValue = event.target.value.replace(regex, "$1");

        setForm({
          ...form,
          [text]: newValue,
        });
      } else {
        setForm({
          ...form,
          [text]: event.target.value,
        });
      }
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {props?.data === null
            ? t("commissionAddDialogTitle")
            : t("commissionEditDialogTitle")}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ p: 2 }}>
            <TextField
              margin="dense"
              size="small"
              type="number"
              id="range_start"
              label={t("commissionStartRangeFieldLbl")}
              variant="outlined"
              fullWidth
              value={form.range_start}
              onChange={(e) => {
                handleChangeInput("range_start", e);
              }}
              onKeyPress={preventMinus}
              error={formError.includes("range_start")}
              helperText={
                formError.includes("range_start") ? t("fieldRequiredLbl") : ""
              }
            />
            <TextField
              margin="dense"
              size="small"
              type="number"
              id="range_end"
              label={t("commissionEndRangeFieldLbl")}
              variant="outlined"
              fullWidth
              value={form.range_end}
              onChange={(e) => {
                handleChangeInput("range_end", e);
              }}
              onKeyPress={preventMinus}
              error={formError.includes("range_end")}
              helperText={
                formError.includes("range_end") ? t("fieldRequiredLbl") : ""
              }
            />
            <TextField
              margin="dense"
              size="small"
              type="number"
              id="commissions"
              label={t("commissionPercentageFieldLbl")}
              variant="outlined"
              fullWidth
              value={form.commissions}
              onChange={(e) => {
                handleChangeInput("commissions", e);
              }}
              onKeyPress={preventMinus}
              error={formError.includes("commissions")}
              helperText={
                formError.includes("commissions") ? t("fieldRequiredLbl") : ""
              }
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            p: { md: "20px", xs: "10px" },
            justifyContent: "space-between",
          }}
        >
          <Button
            autoFocus
            onClick={handleClose}
            sx={{
              fontSize: "12px",
              background: "#fff",
              color: "#592091",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
          >
            {t("cancelLable")}
          </Button>
          <>
            {props?.data === null ? (
              <Button
                onClick={() => createNewCommission()}
                sx={{
                  background: "#592091",
                  color: "#fff",
                  display: "block",
                  padding: "8px 20px",
                  fontWeight: "600",
                  letterSpacing: " 2px",
                  border: "1px solid #592091",
                  "&:hover": {
                    color: "#592091",
                    background: "#fff",
                  },
                }}
              >
                {t("createButtonLbl")}
              </Button>
            ) : (
              <Button
                onClick={() => UpdateCommission()}
                sx={{
                  background: "#592091",
                  color: "#fff",
                  display: "block",
                  padding: "8px 20px",
                  fontWeight: "600",
                  letterSpacing: " 2px",
                  border: "1px solid #592091",
                  "&:hover": {
                    color: "#592091",
                    background: "#fff",
                  },
                }}
              >
                {t("updateLable")}
              </Button>
            )}
          </>
        </DialogActions>
      </Dialog>
    </div>
  );
}
