import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setUserData,
  setLoginLoader,
  setSuccess,
  setError,
  ValidationUpdate,
  setFBUserData,
  SetNewUserCheck,
  setAdminadminUserLogList,
  setAdminUserPasswordLogList,
} from "../reducer/User/SliceUser";
import {
  setUserDetails,
  setUserDetailsSuccess,
} from "../reducer/User/UserProfileSlice";
import {
  setSuccessAlert,
  setErrorAlert,
  setPendingAlert,
  setCommonLoader,
} from "../reducer/AlertSlice";
import axios from "axios";
import {
  UserProfileDetails,
  UserProfileDetailsFacebook,
  UserProfileGoogleDetails,
} from "./UserProfileThunk";
import { toast } from "react-toastify";
import { DeleteData } from "../reducer/User/TokenExpireSlice";
import { TabIndexData } from "../reducer/KittySlice/CreateKittySlice";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Authorization"] =
  localStorage.getItem("UserInfo") !== ""
    ? localStorage.getItem("UserInfo")
      ? `Token ${localStorage.getItem("UserInfo")}`
      : ""
    : "";

export const loginUser = createAsyncThunk(
  "loginUser",
  async (_request, { dispatch }) => {
    dispatch(setLoginLoader(true));
    dispatch(setCommonLoader(true));
    axios
      .post("api/v1/accounts/api-token-auth/", _request.request)
      .then((response) => {
        localStorage.setItem("UserInfo", response.data.token);
        localStorage.setItem("is_superuser", response.data.user.is_superuser);
        dispatch(setUserDetails(response.data.user));
        dispatch(setSuccess(true));
        dispatch(setLoginLoader(false));
        dispatch(setCommonLoader(false));
        dispatch(UserProfileDetails({}));
        _request.callback(response.data.user.is_superuser);
        dispatch(setSuccessAlert(_request.t("LoginSuccessfully")));
        dispatch(setUserData(response.data));
        axios.defaults.headers["Authorization"] =
          "Token " + response.data.token;
      })
      .catch((err) => {
        dispatch(setSuccess(false));
        dispatch(setLoginLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
          dispatch(setErrorAlert(err?.response?.data?.detail));
        }
        if (err && err?.response?.data && err?.response?.data?.message) {
          if (
            err?.response?.data?.login_attempts &&
            Number(err?.response?.data?.login_attempts[0]) < 3
          ) {
            dispatch(
              setErrorAlert(
                `${err?.response?.data?.message[0]} You Have ${
                  3 - Number(err?.response?.data?.login_attempts[0])
                } attempts left`
              )
            );
          } else {
            dispatch(setErrorAlert(`${err?.response?.data?.message}`));
          }
        } else {
          dispatch(setSuccess(false));
          dispatch(setErrorAlert(_request.t("LoginFailAlert")));
        }
        dispatch(setLoginLoader(false));
        dispatch(setCommonLoader(false));
        dispatch(setError(err));
      });
  }
);

export const loginUserForKitty = createAsyncThunk(
  "loginUserForKitty",
  async (_request, { dispatch }) => {
    dispatch(setCommonLoader(true));
    axios
      .post("api/v1/accounts/api-token-auth/", _request.request)
      .then((response) => {
        localStorage.setItem("UserInfo", response.data.token);
        localStorage.setItem("is_superuser", response.data.user.is_superuser);
        dispatch(setCommonLoader(false));
        dispatch(setSuccess(true));
        dispatch(setLoginLoader(false));
        dispatch(setUserDetails(response.data.user));
        dispatch(setSuccessAlert("Login Successfully"));
        dispatch(setUserData(response.data));
        dispatch(UserProfileDetails());
        _request.callback({
          token: response.data.token,
          id: response.data.user.id,
        });
        axios.defaults.headers["Authorization"] =
          "Token " + response.data.token;
      })
      .catch((err) => {
        dispatch(setSuccess(false));
        dispatch(setCommonLoader(false));
        if (Array.isArray(err.response.data.message)) {
          dispatch(setErrorAlert(err.response.data.message[0]));
        } else {
          dispatch(setErrorAlert(err.response.data.message));
        }
        dispatch(setLoginLoader(false));
        dispatch(setError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const loginUserGoogle = createAsyncThunk(
  "loginUserForKitty",
  async (_request, { dispatch }) => {
    dispatch(setCommonLoader(true));
    axios
      .post("api/v1/accounts/api-token-auth/", _request.code)
      .then((response) => {
        localStorage.setItem("UserInfo", response.data.token);
        localStorage.setItem("UserId", JSON.stringify(response.data.user));
        localStorage.setItem("is_superuser", response.data.user.is_superuser);
        dispatch(setSuccess(true));
        dispatch(setUserDetails(response.data.user));
        dispatch(setLoginLoader(false));
        dispatch(setCommonLoader(false));
        dispatch(setSuccessAlert("Login Successfully"));
        dispatch(setUserData(response.data.token));
        dispatch(
          UserProfileGoogleDetails({
            token: response?.data?.token,
            id: response?.data?.user.id,
          })
        );
        if (!response?.data?.user.first_name) {
          dispatch(SetNewUserCheck(true));
          _request.callback({
            token: _request.token,
            id: _request.id,
          });
        } else {
          _request.callback2({
            token: _request.token,
            id: _request.id,
          });
        }
        axios.defaults.headers["Authorization"] =
          "Token " + response.data.token;
      })
      .catch((err) => {
        dispatch(setSuccess(false));
        dispatch(setErrorAlert("Wrong Credentials, Fill correct details"));
        dispatch(setLoginLoader(false));
        dispatch(setCommonLoader(false));
        dispatch(setError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const UserFacebookCode = createAsyncThunk(
  "UserFacebookCode",
  async (_request, { dispatch }) => {
    dispatch(setLoginLoader(true));
    axios
      .get(
        `api/v1/accounts/users/get-fb-access-token/?code=${_request.code}&redirect_uri=${window.location.origin}${window.location.pathname}`
      )
      .then((response) => {
        dispatch(setPendingAlert(_request.t("WaitForResponseLable")));
        dispatch(setLoginLoader(false));
        if (response.data === "") {
          toast.dismiss();
          dispatch(setSuccess(false));
          dispatch(setErrorAlert("Something went wrong"));
        }
        if (response.data.user_details.email) {
          dispatch(
            UserFacebookLogin({
              code: {
                email: response.data.user_details.email,
                username: response.data.user_details.email,
                first_name: response.data.user_details.name,
                token: response.data.token_details.access_token,
                social_account: 2,
                is_verified: true,
              },
              _request,
            })
          );
        } else {
          toast.dismiss();
          dispatch(setSuccess(false));
          dispatch(setErrorAlert(_request.t("FacebookEmailIdErrorLable")));
          if (_request.callback3) {
            _request.callback3();
          }
        }
      })
      .catch((err) => {
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const UserFacebookLogin = createAsyncThunk(
  "UserFacebookLogin",
  async (_request, { dispatch }) => {
    dispatch(setLoginLoader(true));
    dispatch(setCommonLoader(true));
    axios
      .post("api/v1/accounts/api-token-auth/", _request.code)

      .then((response) => {
        toast.dismiss();
        dispatch(setLoginLoader(false));
        dispatch(setCommonLoader(false));
        dispatch(TabIndexData(2));
        dispatch(setFBUserData(response.data.user));
        localStorage.setItem("UserInfo", response.data.token);
        localStorage.setItem("UserId", JSON.stringify(response.data.user));
        dispatch(setUserData(response.data.token));
        dispatch(setSuccess(true));
        dispatch(UserProfileDetails());
        dispatch(setUserDetails(response.data.user));
        dispatch(setSuccessAlert("Login Successfully"));
        dispatch(
          UserProfileDetailsFacebook({
            token: response?.data?.token,
            id: response?.data?.user.id,
          })
        );
        axios.defaults.headers["Authorization"] =
          "Token " + response.data.token;
        if (response?.data?.user.city === null) {
          _request._request.callback({ token: response?.data?.token });
          dispatch(setUserDetailsSuccess(true));
          dispatch(SetNewUserCheck(true));
        } else {
          _request._request.callback2({ token: response?.data?.token });
        }
      })
      .catch((err) => {
        toast.dismiss();
        dispatch(setLoginLoader(false));
        dispatch(setCommonLoader(false));
        dispatch(setSuccess(false));
        dispatch(setErrorAlert(`${err?.response?.data?.message}`));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const activateEmail = createAsyncThunk(
  "activateEmail",
  async (_request, { dispatch }) => {
    axios
      .get(
        `api/v1/accounts/activate_account/${_request.request.uid}/${_request.request.token}/`
      )
      .then((response) => {
        dispatch(ValidationUpdate(response));
        dispatch(setSuccessAlert("User Verified"));
        _request.callback();
      })
      .catch((err) => {
        dispatch(ValidationUpdate(err));
        dispatch(setErrorAlert("Something went wrong"));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofUserLog = createAsyncThunk(
  "getListofUserLog",
  async (_request, { dispatch }) => {
    dispatch(setLoginLoader(true));

    axios
      .get(
        `api/v1/accounts/user-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminadminUserLogList(response.data));
        dispatch(setLoginLoader(false));
      })
      .catch((err) => {
        dispatch(setLoginLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofUserPasswordLog = createAsyncThunk(
  "getListofUserPasswordLog",
  async (_request, { dispatch }) => {
    dispatch(setLoginLoader(true));

    axios
      .get(
        `api/v1/accounts/reset-password-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminUserPasswordLogList(response.data));
        dispatch(setLoginLoader(false));
      })
      .catch((err) => {
        dispatch(setLoginLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
