import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { Button } from "@mui/material";
import ParticpantList from "./ParticipantRearrange";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { SwapParticipantRankings } from "../../../store/thunk/Tontine/MytontineThunk";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ParticipantRearrangeLayout() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const [newArray, setNewArray] = useState([]);
  const tontineDetails = useAppSelector(
    (state) => state.MyTontine?.singleTontine
  );

  const handleChange = (array) => {
    setNewArray(array);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#f4f4f4",
          mb: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            flexDirection: "row",
            fontSize: { md: "20px", xs: "16px" },
            fontWeight: "700",
            flex: "1 1 1",
          }}
        >
          <Box
            sx={{
              width: { md: "150px", xs: "120px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 10px",
            }}
          >
            {t("CurrentRankingLable")}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: { md: "300px" },
              }}
            >
              {t("ParticipantNameLable")}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "right",
            m: 3,
            mr: -2.5,
            fontSize: { md: "20px", xs: "16px" },
            fontWeight: "700",
          }}
        >
          {t("SavedRankingLable")}
        </Box>
      </Box>
      {tontineDetails?.tontine_participants_id?.length > 0 ? (
        <ParticpantList handleChange={handleChange} />
      ) : null}
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Button
          onClick={() => {
            dispatch(
              SwapParticipantRankings({ swap_array: newArray, tontine_id: id })
            );
          }}
          sx={{
            mt: 2,
            background: " #91bcff",
            color: " #000",
            padding: " 10px 36px",
            fontWeight: "600",
            border: " 2px solid #91bcff",
          }}
        >
          {t("SaveChangesLable")}
        </Button>
      </Box>
    </Box>
  );
}

export default ParticipantRearrangeLayout;
