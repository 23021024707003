import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Box, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function RejectDialogComponent({
  reasondata,
  dataFunc,
  callBackFunc,
  handleClose,
}) {
  const [formError, setFormError] = React.useState([]);
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (reasondata === "") {
      setFormError(["reason"]);
    } else {
      callBackFunc();
    }
  };

  return (
    <>
      <Dialog
        open
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        PaperProps={{ sx: { m: { md: "30px", xs: "10px" } } }}
      >
        <DialogTitle
          sx={{
            textTransform: "uppercase",
            borderBottom: "1px solid #ddd",
            background:
              "linear-gradient(to left, #592091, #4b0082, #91bcff, #1064e7, #808080, #4b0082, #0161f7)",
            WebkitBackgroundClip: "text",
          }}
        >
          {t("reasonLabel")}
        </DialogTitle>
        <DialogContent sx={{ padding: { md: "0 24px", xs: "0 10px" } }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box sx={{ padding: "1px 4px" }}>
                <TextField
                  margin="dense"
                  size="small"
                  id="reason"
                  value={reasondata}
                  onChange={(e) => {
                    dataFunc(e.target.value);
                    setFormError([]);
                  }}
                  label={t("reasonFieldLabel")}
                  type="text"
                  variant="outlined"
                  sx={{ display: "flex" }}
                  error={formError.includes("reason")}
                  helperText={
                    formError.includes("reason") ? t("fieldRequiredLabel") : ""
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            p: { md: "20px", xs: "10px" },
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{
              fontSize: "12px",
              background: "#fff",
              color: "#592091",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={() => {
              handleClose();
            }}
          >
            {t("cancelLable")}
          </Button>
          <Button
            sx={{
              background: "#592091",
              color: "#fff",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={() => {
              handleSubmit();
            }}
          >
            {t("insertLable")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
