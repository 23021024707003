import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  success: false,
  email:{}
};

const forgetPasswordSlice = createSlice({
  name: 'forgetPasswordSlice',
  initialState,
  reducers: {
    setUserData: (state, action) => ({
      ...state,
      email: action.payload,
    })
  }
});

export const { setforgetPasswordData } = forgetPasswordSlice.actions;

export default forgetPasswordSlice.reducer;
