import { Box, Chip, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { tontineCycleDetails } from "../../../../store/thunk/Tontine/MytontineThunk";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import moment from "moment";

function CycleDetails(props) {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const list = useAppSelector((state) => state.MyTontine?.cycleOfTontine);

  useEffect(() => {
    dispatch(tontineCycleDetails(id));
  }, []);
  return (
    <>
      <Box
        sx={{
          height: { md: "360px", xs: "360px" },
          overflow: "auto",
          pr: { md: 0, xs: "10px" },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#d5c7e3",
            p: 2,
            pt: "1px",
            m: { md: 2, xs: "16px 0px" },
          }}
        >
          {list?.results?.length > 0 ? (
            list?.results?.map((item) => {
              return (
                <>
                  <Box
                    sx={
                      item?.cycle_complete
                        ? {
                            margin: { md: "16px 8px", xs: "12px 0px" },
                            borderRadius: "16px",
                            padding: { md: "15px", xs: "15px" },
                            fontFamily: "inherit",
                            color: "#3c763d",
                            backgroundColor: "#dff0d8",
                            border: "2px solid #3c763d",
                          }
                        : item.is_active && !item?.cycle_complete
                        ? {
                            margin: { md: "16px 8px", xs: "12px 0px" },
                            borderRadius: "16px",
                            padding: { md: "15px", xs: "15px" },
                            fontFamily: "inherit",
                            color: "#8a6d3b",
                            backgroundColor: "#fcf8e3",
                            border: "2px solid #8a6d3b",
                          }
                        : {
                            margin: { md: "16px 8px", xs: "12px 0px" },
                            borderRadius: "16px",
                            padding: { md: "15px", xs: "15px" },
                            fontFamily: "inherit",
                            color: "#31708f",
                            backgroundColor: "#d9edf7",
                            border: "2px solid #31708f",
                          }
                    }
                  >
                    <Box
                      sx={{
                        p: "unset",
                        textAlign: { md: "right", xs: "center" },
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: {
                          sm: "column",
                          xs: "column",
                          md: "row",
                        },
                        m: { md: 0, sm: 0, xs: "5px 0" },
                      }}
                    >
                      <Box className="participant-get-paid">
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "600", mr: 1 }}
                        >
                          {t("CycleDetailsTab.13")}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "600" }}
                        >{`${item?.payout_detail?.user_name || ""}`}</Typography>
                        {item?.payout_detail?.payout ? (
                          <Tooltip
                            title={t("payoutPayoutDoneBtnLbl")}
                            placement="top"
                          >
                            <TaskAltRoundedIcon sx={{ m: "3px" }} />
                          </Tooltip>
                        ) : null}
                      </Box>
                      <Chip
                        sx={
                          item?.cycle_complete
                            ? {
                                color: "#dff0d8",
                                backgroundColor: "#3c763d",
                                m: { md: 0, sm: 0, xs: "5px 0" },
                              }
                            : item.is_active && !item?.cycle_complete
                            ? {
                                color: "#fcf8e3",
                                backgroundColor: "#8a6d3b",
                                m: { md: 0, sm: 0, xs: "5px 0" },
                              }
                            : {
                                color: "#d9edf7",
                                backgroundColor: "#31708f",
                                m: { md: 0, sm: 0, xs: "5px 0" },
                              }
                        }
                        label={
                          item?.cycle_complete
                            ? t("CycleDetailsTab.8")
                            : item.is_active && !item?.cycle_complete
                            ? t("CycleDetailsTab.9")
                            : t("CycleDetailsTab.10")
                        }
                        variant="outlined"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: { md: "flex", xs: "flex" },
                        flexWrap: "nowrap",
                        flexDirection: { md: "row", sm: "row", xs: "column" },
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          pl: 0,
                          width: "10%",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "600",
                            fontSize: { md: "16px", xs: "14px" },
                          }}
                        >
                          # {item.cycle_number}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "90%",
                        }}
                      >
                        <Box
                          // className="cycledetails-table-box"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: {
                              md: "center",
                              xs: "left",
                            },
                            flexDirection: {
                              md: "row",
                              xs: "column",
                              sm: "row",
                            },
                          }}
                        >
                          <Box className="cycledetails-table-box-one">
                            <Box
                              sx={{
                                m: "10px 8px",
                                fontWeight: " 600",
                                fontSize: "13px",
                                textTransform: "uppercase",
                                display: "flex",
                                flexDirection: {
                                  md: "column",
                                  sm: "column",
                                  xs: "row",
                                },
                              }}
                            >
                              <Box sx={{ mr: 1 }}>{t("StartDateLable")}: </Box>
                              <Box>
                                {moment(item.cycle_start_date).format(
                                  localStorage.getItem("language") == "en"
                                    ? "YYYY-MM-DD"
                                    : "DD-MM-YYYY"
                                )}
                              </Box>
                            </Box>
                          </Box>
                          <Box className="cycledetails-table-box-one">
                            <Box
                              sx={{
                                m: "10px 8px",
                                fontWeight: " 600",
                                fontSize: "13px",
                                textTransform: "uppercase",
                                display: "flex",
                                flexDirection: {
                                  md: "column",
                                  sm: "column",
                                  xs: "row",
                                },
                              }}
                            >
                              <Box sx={{ mr: 1 }}>
                                {t("CycleDetailsTab.11")}{" "}
                              </Box>
                              <Box>
                                {moment(item.payment_date).format(
                                  localStorage.getItem("language") == "en"
                                    ? "YYYY-MM-DD"
                                    : "DD-MM-YYYY"
                                )}
                              </Box>
                            </Box>
                          </Box>
                          <Box className="cycledetails-table-box-one">
                            <Box
                              sx={{
                                m: "10px 8px",
                                fontWeight: " 600",
                                fontSize: "13px",
                                textTransform: "uppercase",
                                display: "flex",
                                flexDirection: {
                                  md: "column",
                                  sm: "column",
                                  xs: "row",
                                },
                              }}
                            >
                              <Box sx={{ mr: 1 }}>
                                {t("CycleDetailsTab.12")}{" "}
                              </Box>
                              <Box>
                                {" "}
                                {moment(item.cycle_end_date).format(
                                  localStorage.getItem("language") == "en"
                                    ? "YYYY-MM-DD"
                                    : "DD-MM-YYYY"
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              );
            })
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  placeContent: "center",
                  padding: "4rem 1rem",
                }}
              >
                <Typography variant="h5">
                  {t("TontineStartedValidationLable")}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default CycleDetails;
