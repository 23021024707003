import React from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Box,
  Backdrop,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useDispatch } from "react-redux";
import UpdateIcon from "@mui/icons-material/Update";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";
import Viewer from "react-viewer";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PDFViewerDialogComponent from "../../VerifyDocument/PDFViewerDialog";
import DocumentSection from "./DocumentSection";
import DocumentUploadSection from "./DocumentUploadSection";
import {
  uploadUserDocument,
  DeleteTontineDocument,
} from "../../../../store/thunk/newUserThunk";
import { useAppSelector } from "../../../../store/store";
import { CheckUserDocumentVerifyStatus } from "../../../../store/thunk/Tontine/MytontineThunk";
import { LoadingButton } from "@mui/lab";

function YourID() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const profileDetails = useAppSelector((state) => state?.UserProfile?.details);
  const documentLoader = useAppSelector((state) => state?.newUserSlice);
  const loaderUser = useAppSelector((state) => state?.MyTontine?.loading);
  const userDocuments = useAppSelector(
    (state) => state?.MyTontine?.userDocuments
  );
  const [showDocumentUploadOptions, setShowDocumentUploadOptions] =
    React.useState(true);
  const [showDocumentUpload, setShowDocumentUpload] = React.useState(false);
  const [showDocumentSuccess, setShowDocumentSuccess] = React.useState(false);
  const [showDocumentApprovePending, setShowDocumentApprovePending] =
    React.useState(false);
  const [showDocumentAccept, setShowDocumentAccept] = React.useState(false);
  const [showDocumentDecline, setShowDocumentDecline] = React.useState(false);
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const [docError, setDocError] = React.useState(false);
  const [formState, setFormState] = React.useState({});
  const [showImageViewer, setShowImageViewer] = React.useState(false);
  const [showPdfViewer, setShowPdfViewer] = React.useState(false);
  const [activeImageIndex, setActiveImageIndex] = React.useState(null);
  const [imageList, setImageList] = React.useState([]);
  const [pdfPath, setPdfPath] = React.useState("");
  const [currentDocumentId, setCurrentDocumentId] = React.useState(0);

  React.useEffect(() => {
    if (userDocuments?.length > 0 && userDocuments[0]?.status === "ACCEPTED") {
      // Show Verify Section
      setShowDocumentUploadOptions(false);
      setShowDocumentSuccess(true);
      setShowDocumentApprovePending(false);
      setShowDocumentAccept(true);
    } else if (
      userDocuments?.length > 0 &&
      userDocuments[0]?.status === "PENDING"
    ) {
      // Show Pending Verify Section
      setShowDocumentUploadOptions(false);
      setShowDocumentSuccess(true);
      setShowDocumentApprovePending(true);
      setShowDocumentAccept(false);
    } else if (
      userDocuments?.length > 0 &&
      userDocuments[0]?.status === "REJECTED"
    ) {
      // Show Rejected Section
      setShowDocumentUploadOptions(false);
      setShowDocumentSuccess(true);
      setShowDocumentApprovePending(false);
      setShowDocumentAccept(false);
      setShowDocumentDecline(true);
    } else {
      // Show Upload Section
      setShowDocumentUploadOptions(true);
      setShowDocumentSuccess(false);
      setShowDocumentApprovePending(false);
      setShowDocumentAccept(false);
    }

    if (userDocuments?.length > 0 && userDocuments[0]) {
      setCurrentDocumentId(userDocuments[0]?.id);
    }
  }, [userDocuments]);

  React.useEffect(() => {
    dispatch(CheckUserDocumentVerifyStatus({}));
  }, []);

  const setDocumentUpload = () => {
    setShowDocumentUpload(true);
    setShowDocumentUploadOptions(false);
  };

  const handleChange = (event) => {
    setAcceptTerms(event.target.checked);
  };

  const removeDocumentUpload = () => {
    setShowDocumentUpload(false);
    setShowDocumentUploadOptions(true);
  };

  const handleSelectFile = (file) => {
    setFormState((formState) => ({
      ...formState,
      image: file,
    }));
  };

  const handleSubmit = () => {
    let request = {
      ...formState,
    };

    setDocError(false);

    if (
      Object.keys(formState)?.length == 0 ||
      (Object.keys(formState)?.length && formState?.image?.length == 0)
    ) {
      setDocError(true);
      return;
    }

    dispatch(
      uploadUserDocument({
        request: request,
        user: profileDetails?.id,
        callback: () => {
          removeDocumentUpload();
          setShowDocumentUploadOptions(false);
          setShowDocumentSuccess(true);
          setShowDocumentApprovePending(true);

          // Delete Document
          dispatch(
            DeleteTontineDocument({
              id: currentDocumentId,
            })
          );
        },
      })
    );
  };

  const getFileName = (imagePath) => {
    const filePath = imagePath?.substring(imagePath.lastIndexOf("/") + 1);
    const filename = filePath?.split("?")[0];
    return filename;
  };

  const getImageCount = (imageUrl) => {
    const imageObj = imageList.filter(function (entry) {
      return entry.src === imageUrl;
    });

    return imageObj?.length || 0;
  };

  const showUploadOptions = () => {
    setShowDocumentUploadOptions(true);
    setShowDocumentSuccess(false);
    setShowDocumentApprovePending(false);
    setShowDocumentAccept(false);
    setShowDocumentDecline(false);
  };

  const hideImageViewer = () => {
    setShowImageViewer(false);
  };

  const viewImageViewer = (imagePath) => {
    const imageIndex = imageList.findIndex((x) => x.src === imagePath);
    setActiveImageIndex(imageIndex);
    setShowImageViewer(true);
  };

  const hidePdfDocumentViewer = (event, reason) => {
    if (reason && (reason == "backdropClick" || reason == "escapeKeyDown")) {
      return;
    }
    setShowPdfViewer(false);
    setPdfPath("");
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{ display: { md: "flex", xs: "block" }, justifyContent: " center" }}
    >
      {/* Document Upload Success */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderUser}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {showDocumentSuccess && (
        <>
          {showDocumentApprovePending && (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  component={"span"}
                  gutterBottom
                  sx={{
                    pl: "8px",
                    fontWeight: "700",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  {t("documentThank")}
                </Typography>
                <Typography
                  variant="subtitle2"
                  component={"span"}
                  gutterBottom
                  sx={{
                    pl: "8px",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  {t("documentThankConfirm")}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                sx={{
                  justifyContent: "center",
                  mb: "100px ",
                }}
              >
                <Paper sx={{ p: "10px" }}>
                  <Typography
                    variant="subtitle1"
                    component={"span"}
                    gutterBottom
                    sx={{
                      pl: "8px",
                      fontWeight: "700",
                      display: "block",
                      textAlign: "left",
                    }}
                  >
                    {t("documentStatus")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component={"span"}
                    gutterBottom
                    sx={{
                      pl: "8px",
                      display: "block",
                      textAlign: "left",
                    }}
                  >
                    {`${t("documentAddedOnLable")}: ${moment(
                      userDocuments[0]?.created_at
                    ).format(
                      localStorage.getItem("language") == "en"
                        ? "YYYY-MM-DD"
                        : "DD-MM-YYYY"
                    )}`}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component={"span"}
                    gutterBottom
                    sx={{
                      pl: "8px",
                      fontWeight: "700",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left",
                      color: "#592091",
                    }}
                  >
                    <UpdateIcon sx={{ mr: "5px", fontSize: "20px" }} />
                    {t("documentPendingStatusLable")}
                  </Typography>
                </Paper>
              </Grid>
            </>
          )}
          {showDocumentAccept && (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  component={"span"}
                  gutterBottom
                  sx={{
                    pl: "8px",
                    fontWeight: "700",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  {t("documentVerifyLable")}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                sx={{
                  justifyContent: "center",
                  mb: "100px ",
                }}
              >
                <Paper sx={{ p: "10px" }}>
                  <Typography
                    variant="subtitle1"
                    component={"span"}
                    gutterBottom
                    sx={{
                      pl: "8px",
                      fontWeight: "700",
                      display: "block",
                      textAlign: "left",
                    }}
                  >
                    {t("documentStatus")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component={"span"}
                    gutterBottom
                    sx={{
                      pl: "8px",
                      display: "block",
                      textAlign: "left",
                    }}
                  >
                    {`${t("documentAddedOnLable")}: ${moment(
                      userDocuments[0]?.created_at
                    ).format(
                      localStorage.getItem("language") == "en"
                        ? "YYYY-MM-DD"
                        : "DD-MM-YYYY"
                    )}`}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component={"span"}
                    gutterBottom
                    sx={{
                      pl: "8px",
                      fontWeight: "700",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left",
                      color: "#592091",
                    }}
                  >
                    <DoneIcon
                      sx={{ mr: "5px", fontSize: "20px", fontWeight: "bold" }}
                    />
                    {t("documentApprovedStatus")}
                  </Typography>

                  {userDocuments[0]?.image_for_tontine_document?.map(
                    (documentData) => {
                      const fileExt = getFileName(documentData?.image)
                        .split(".")
                        .pop();

                      if (!getImageCount(documentData?.image)) {
                        if (fileExt !== "pdf") {
                          setImageList([
                            ...imageList,
                            {
                              src: documentData?.image,
                              alt: getFileName(documentData?.image),
                            },
                          ]);
                        }
                      }

                      return fileExt === "pdf" ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            pl: "8px",
                            alignItems: "center",
                          }}
                          key={documentData?.id}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <InsertDriveFileIcon
                              sx={{ fontSize: "20px", mr: "5px" }}
                            />
                            <span
                              onClick={() => {
                                setPdfPath(documentData?.image);
                                setShowPdfViewer(true);
                              }}
                              style={{
                                cursor: "pointer",
                                fontSize: "13px",
                              }}
                            >
                              {getFileName(documentData?.image)}
                            </span>
                          </Box>
                          <Tooltip title={t("viewLable")} placement="top">
                            <VisibilityIcon
                              sx={{
                                pl: "5px",
                                fill: "#592091",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setPdfPath(documentData?.image);
                                setShowPdfViewer(true);
                              }}
                            />
                          </Tooltip>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            pl: "8px",
                            alignItems: "center",
                          }}
                          key={documentData?.id}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <InsertDriveFileIcon
                              sx={{ fontSize: "20px", mr: "5px" }}
                            />
                            <span
                              className=""
                              style={{
                                cursor: "pointer",
                                fontSize: "13px",
                              }}
                              onClick={() =>
                                viewImageViewer(documentData?.image)
                              }
                            >
                              {getFileName(documentData?.image)}
                            </span>
                          </Box>
                          <Tooltip title={t("viewLable")} placement="top">
                            <VisibilityIcon
                              sx={{
                                pl: "5px",
                                fill: "#592091",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                viewImageViewer(documentData?.image)
                              }
                            />
                          </Tooltip>
                        </Box>
                      );
                    }
                  )}
                </Paper>
              </Grid>
            </>
          )}
          {showDocumentDecline && (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  component={"span"}
                  gutterBottom
                  sx={{
                    pl: "8px",
                    fontWeight: "700",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  {t("documentRejectLable")}
                </Typography>
                <Typography
                  variant="subtitle2"
                  component={"span"}
                  gutterBottom
                  sx={{
                    pl: "8px",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  {t("documentIncompleteLable")}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                sx={{
                  justifyContent: "center",
                  mb: "100px ",
                }}
              >
                <Paper sx={{ p: "10px" }}>
                  <Typography
                    variant="subtitle1"
                    component={"span"}
                    gutterBottom
                    sx={{
                      pl: "8px",
                      fontWeight: "700",
                      display: "block",
                      textAlign: "left",
                    }}
                  >
                    {t("documentStatus")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component={"span"}
                    gutterBottom
                    sx={{
                      pl: "8px",
                      display: "block",
                      textAlign: "left",
                    }}
                  >
                    {t("documentAddedOnLable")} :{" "}
                    {userDocuments?.length
                      ? moment(userDocuments[0].created_at).format(
                          localStorage.getItem("language") == "en"
                            ? "YYYY-MM-DD"
                            : "DD-MM-YYYY"
                        )
                      : ""}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component={"span"}
                    gutterBottom
                    sx={{
                      pl: "8px",
                      display: "block",
                      textAlign: "left",
                    }}
                  >
                    {`${t("reasonLable")}: ${userDocuments[0]?.reason}`}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component={"span"}
                    gutterBottom
                    sx={{
                      pl: "8px",
                      fontWeight: "700",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left",
                      color: "#592091",
                    }}
                  >
                    <CloseIcon
                      sx={{ mr: "5px", fontSize: "20px", fontWeight: "bold" }}
                    />
                    {t("documentRejectedStatus")}
                  </Typography>
                </Paper>
                <Box
                  sx={{ display: "flex", justifyContent: "center", mt: "10px" }}
                >
                  <Button
                    onClick={showUploadOptions}
                    sx={{
                      background: "#592091",
                      color: "#fff",
                      display: "block",
                      marginTop: "10px",
                      padding: "8px 20px",
                      fontWeight: "600",
                      letterSpacing: " 2px",
                      border: "1px solid #592091",
                      "&:hover": {
                        color: "#592091",
                      },
                      "&:disabled": {
                        color: "#fff",
                        background: "#876ba3",
                      },
                    }}
                  >
                    {t("Add New Document")}
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </>
      )}
      {showDocumentUploadOptions && (
        <>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              component={"span"}
              gutterBottom
              sx={{
                pl: "8px",
                fontWeight: "700",
                display: "block",
                textAlign: "center",
              }}
            >
              {t("chooseDocLabel")}:
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              justifyContent: "center",
              mb: "100px ",
            }}
          >
            <DocumentSection
              title={t("documentOption1")}
              description=""
              documenttype="passport"
              documentFunc={setDocumentUpload}
            />
          </Grid>
        </>
      )}
      {showDocumentUpload && (
        <>
          <DocumentUploadSection
            goBackFunc={removeDocumentUpload}
            onSelectFile={handleSelectFile}
            docError={docError}
            setDocError={setDocError}
            handleChange={handleChange}
            acceptTerms={acceptTerms}
            setAcceptTerms={setAcceptTerms}
          />
        </>
      )}
      {showDocumentUpload && (
        <Grid
          item
          xs={12}
          sx={{
            display: { md: "flex", xs: "block" },
            justifyContent: " center",
          }}
        >
          <LoadingButton
            onClick={() => handleSubmit()}
            loading={documentLoader.loading}
            sx={{
              background: "#592091",
              color: "#fff",
              display: "block",
              marginTop: "10px",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
              },
              "&:disabled": {
                color: "#fff",
                background: "#876ba3",
              },
            }}
            disabled={!acceptTerms}
          >
            {t("submitLabel")}
          </LoadingButton>
        </Grid>
      )}
      {showImageViewer && (
        <Viewer
          visible={showImageViewer}
          onClose={hideImageViewer}
          showTotal={false}
          noNavbar={true}
          noImgDetails={true}
          attribute={false}
          rotatable={true}
          changeable={false}
          disableKeyboardSupport={true}
          scalable={false}
          images={imageList}
          activeIndex={activeImageIndex}
        />
      )}
      {showPdfViewer && (
        <PDFViewerDialogComponent
          dialogStatus={showPdfViewer}
          handleClose={hidePdfDocumentViewer}
          pdfSrc={pdfPath}
        />
      )}
    </Grid>
  );
}

export default YourID;
