import { Box } from "@mui/material";
import "../../layout/comingSoon/comingSoon.scss";

export default function ComingSoon() {
  return (
    <>
      <Box className="coming-soon-page">
        <Box className="landing-page"></Box>
      </Box>
    </>
  );
}
