import * as React from "react";
import "./Rates.scss";
import Box from "@mui/material/Box";
import CatchingPokemonIcon from "@mui/icons-material/CatchingPokemon";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import WorkIcon from "@mui/icons-material/Work";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import PaidIcon from "@mui/icons-material/Paid";
import { Button, Container, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SimpleRates() {
  const { t } = useTranslation();

  return (
    <>
      <Box
        className="containerFee"
        id="simpleRates"
        sx={{ borderBottom: "2px solid #e6e6e6" }}
      >
        <Container sx={{ p: { md: "50px 0px", xs: "30px 20px" } }}>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={12} md={6} sx={{ p: { md: "0px 20px", xs: "0" } }}>
              <List
                sx={{ bgcolor: "background.paper", p: 0 }}
                className="optionList"
              >
                <Grid item>
                  <ListItem
                    sx={{
                      p: { md: "8px 16px !important", xs: "8px !important" },
                      color: "#592091",
                    }}
                  >
                    <Grid item xs={2}>
                      <ListItemAvatar>
                        <WorkIcon
                          sx={{ fontSize: 40 }}
                          style={{ transform: "rotate(350deg)" }}
                        />
                      </ListItemAvatar>
                    </Grid>
                    <Grid item xs={6}>
                      <h4>{t("simpleRates.0")}</h4>
                    </Grid>
                    <Grid item xs={4}>
                      <p
                        style={{ backgroundColor: "#592091", width: "100%" }}
                        className="offer"
                      >
                        {t("simpleRates.1")}
                      </p>
                    </Grid>
                  </ListItem>
                </Grid>
                <Divider />
                <Grid item>
                  <ListItem
                    sx={{
                      p: { md: "8px 16px !important", xs: "8px !important" },
                      color: "#00b8b1",
                    }}
                  >
                    <Grid item xs={2}>
                      <ListItemAvatar>
                        <VolunteerActivismIcon sx={{ fontSize: 40 }} />
                      </ListItemAvatar>
                    </Grid>
                    <Grid item xs={6}>
                      <h4>{t("simpleRates.2")}</h4>
                    </Grid>
                    <Grid item xs={4}>
                      <p
                        style={{ backgroundColor: "#00b8b1", width: "100%" }}
                        className="offer"
                      >
                        {t("simpleRates.1")}
                      </p>
                    </Grid>
                  </ListItem>
                </Grid>
                <Divider />
                <Grid item>
                  <ListItem
                    sx={{
                      p: { md: "8px 16px !important", xs: "8px !important" },
                      color: "#7aa6eb",
                    }}
                  >
                    <Grid item xs={2}>
                      <ListItemAvatar>
                        <PaidIcon sx={{ fontSize: 40 }} />
                      </ListItemAvatar>
                    </Grid>
                    <Grid item xs={6}>
                      <h4>{t("simpleRates.3")}</h4>
                    </Grid>
                    <Grid item xs={4}>
                      <p
                        style={{ backgroundColor: "#7aa6eb", width: "100%" }}
                        className="offer"
                      >
                        2.8%-5.5%
                      </p>
                    </Grid>
                  </ListItem>
                </Grid>
              </List>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                mt: { md: "0", xs: "20px" },
                p: { md: "0px 20px", xs: "0" },
              }}
            >
              <Typography variant="span" className="subtitle">
                {" "}
                {t("simpleRates.4")}
              </Typography>
              <Typography variant="h5" className="title">
                {t("simpleRates.5")}
              </Typography>
              <Typography variant="h6"> {t("simpleRates.6")}</Typography>
              <Link to="/inner-lowest-fees" style={{ color: "#fff" }}>
                <Button variant="contained">{t("simpleRates.7")}</Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          width: " 100%",
          color: "#e6e6e6",
          position: "relative",
        }}
      >
        <CatchingPokemonIcon
          className="divider"
          sx={{
            margin: "0px",
            position: "absolute",
            left: "50%",
            top: "-12px",
            background: " white",
            borderRadius: "50%",
          }}
        />
      </Box>
    </>
  );
}
