import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography, Paper, Grid } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { makeStyles } from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { UpdateKittyContributePayout } from "../../../../../store/thunk/Kitty/KittyDetailsThunk";
import moment from "moment";
import RejectDialogComponent from "../../../VerifyDocument/RejectDialog";
import {
  UpdateTontinePayoutStatus,
  getListofTontinePayoutList,
} from "../../../../../store/thunk/Tontine/MytontineThunk";
import CustomMPagination from "../../../../common/CustomMPagination";

const useStyles = makeStyles((theme) => ({
  summaryRoot: {
    flexDirection: "row-reverse",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

export default function AdminKittyContributeComponent() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = useState(0);
  const [pageCountShow, SetPageCountShow] = useState(0);
  const [recordLimit, SetRecordLimit] = useState(10);
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [requestData, setRequestData] = useState({
    request: {},
    documentId: 0,
  });

  const adminTontineList = useAppSelector(
    (state) => state.MyTontine.TontinePayoutListing
  );

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    requestList();
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, recordLimit]);

  const handleCloseReasonDialog = () => {
    setShowRejectDialog(false);
  };

  const requestList = () => {
    dispatch(
      getListofTontinePayoutList({
        limit: recordLimit,
        offSet: pageCount,
      })
    );
  };

  const saveRejectData = () => {
    setRequestData({
      request: {
        ...requestData.request,
        reason: rejectReason,
      },
      documentId: requestData.documentId,
    });

    dispatch(
      UpdateKittyContributePayout({
        request: { withdrawal_status: "FAILED", reason: rejectReason },
        id: requestData?.documentId,
        callback: () => requestList(),
      })
    );
    setShowRejectDialog(false);
  };

  const FailPayoutApproval = (data, status) => {
    if (status === "ACCEPTED") {
      setConfirmMessage(t("acceptConfirmLabel"));
    } else if (status === "REJECTED") {
      setConfirmMessage(t("rejectConfirmLabel"));
    }
    setShowRejectDialog(true);

    let request = {
      status,
    };

    if (status === "ACCEPTED") {
      request.is_verified = true;
    }

    setRequestData({
      request,
      documentId: data?.id,
    });
  };

  return (
    <>
      <Box className="kittyTable" sx={{ minHeight: "90vh" }}>
        <Box sx={{ borderRadius: 0, padding: { md: 2, xs: 1 } }}>
          <Box sx={{ minHeight: "72vh" }}>
            <Paper
              sx={{
                width: { md: "100%", xs: "auto" },
                padding: 2,
                marginBottom: "10px",
                background: "rgba(89, 32, 145, 0.1411764706)",
              }}
            >
              <Box
                className="tableTitle"
                style={{
                  textTransform: "uppercase",
                  // marginTop: "10px",
                }}
              >
                {t("contributerPayoutListLable")}
              </Box>
            </Paper>
            <Accordion
              expanded={false}
              sx={{
                backgroundColor: "#2a2828",
                color: "#fff",
                marginBottom: "10px",
                borderRadius: "4px",
              }}
            >
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={classes.summaryRoot}
              >
                <Typography
                  sx={{ marginLeft: "33px", width: "25%", flexShrink: 0 }}
                >
                  {t("payoutTontineNameLbl")}
                </Typography>
                <Typography sx={{ width: "20%", flexShrink: 0 }}>
                  {t("payoutRequestUserNameLbl")}
                </Typography>
                <Typography sx={{ width: "20%", flexShrink: 0 }}>
                  {t("payoutRequestDateLbl")}
                </Typography>
                <Typography sx={{ width: "20%", flexShrink: 0 }}>
                  {t("payoutRequestAmountLbl")}
                </Typography>
                <Typography sx={{ width: "20%", flexShrink: 0 }}>
                  {t("ParticipantRankingLable")}
                </Typography>
              </AccordionSummary>
            </Accordion>
            {adminTontineList?.results?.map((item) => (
              <Accordion
                expanded={expanded === `panel${item?.id}`}
                onChange={handleChange(`panel${item?.id}`)}
                key={item?.id}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${item?.id}bh-content`}
                  id={`panel${item?.id}bh-header`}
                  className={classes.summaryRoot}
                >
                  <Typography
                    sx={{ marginLeft: "10px", width: "25%", flexShrink: 0 }}
                  >
                    {item?.tontine?.name}
                  </Typography>
                  <Grid container>
                    <Grid item xs={3}>
                      {" "}
                      <Typography
                        sx={{
                          color: "text.secondary",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        {`${item?.user.first_name} ${item?.user.name}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        sx={{
                          color: "text.secondary",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        {moment(item?.created_at).format(
                          localStorage.getItem("language") == "en"
                            ? "YYYY-MM-DD"
                            : "DD-MM-YYYY"
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        display: "flex",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                        }}
                      >
                        € {item?.payout_amount}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        display: "flex",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                        }}
                      >
                        {item?.tontineparticipants?.ranking}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Typography>{`${t("payoutRequestIBANFieldLbl")} : ${
                      item?.iban
                    }`}</Typography>
                  </Box>
                  <Box>
                    <Typography>{`Actual amount : €${item?.actual_amount}`}</Typography>
                  </Box>
                  <Box>
                    <Typography>{`${t("payoutPlatformCommissionFieldLbl")} : €${
                      item?.payout_commission
                    }`}</Typography>
                  </Box>
                  {item?.length > 0 ? (
                    <>
                      <Box>
                        <Typography>{`${t("payoutBeneficiaryIBANFieldLbl")}: ${
                          item?.beneficiary_details[0]?.beneficiary_iban
                        }`}</Typography>
                      </Box>
                      <Box>
                        <Typography>{`${t(
                          "payoutBeneficiaryEmailFieldLbl"
                        )} : ${
                          item?.beneficiary_details[0]?.beneficiary_email
                        }`}</Typography>
                      </Box>
                      <Box>
                        <Typography>{`${t("payoutBeneficiaryNameFieldLbl")} : ${
                          item?.beneficiary_details[0]?.beneficiary_name
                        }`}</Typography>
                      </Box>
                    </>
                  ) : null}
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      onClick={() => {
                        dispatch(
                          UpdateTontinePayoutStatus({
                            request: {
                              is_paid: true,
                              payout_date: moment().format(
                                "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
                              ),
                            },
                            id: item?.id,
                            NextAPICall: {
                              id: item?.tontineparticipants?.id,
                              payout_status: "PAID",
                            },
                          })
                        );
                      }}
                      sx={{
                        background: "#592091",
                        color: "#fff",
                        display: "block",
                        padding: "8px 20px",
                        fontWeight: "600",
                        letterSpacing: " 2px",
                        marginTop: "15px",
                        border: "1px solid #592091",
                        "&:hover": {
                          color: "#592091",
                          background: "#fff",
                        },
                      }}
                    >
                      {t("payoutPayoutDoneBtnLbl")}
                    </Button>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
          <Box sx={{ mt: 2 }}>
            <CustomMPagination
              pageCount={pageCount}
              SetPageCount={SetPageCount}
              recordLimit={recordLimit}
              pageCountShow={pageCountShow}
              SetRecordLimit={SetRecordLimit}
              numRecords={adminTontineList?.num_records}
              numCount={adminTontineList?.count}
            />
          </Box>
        </Box>
      </Box>

      {showRejectDialog && (
        <RejectDialogComponent
          reasondata={rejectReason}
          dataFunc={setRejectReason}
          callBackFunc={saveRejectData}
          handleClose={handleCloseReasonDialog}
        />
      )}
    </>
  );
}
