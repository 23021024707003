import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useAppDispatch } from "../../../../store/store";
import { ListOfAllUsers } from "../../../../store/thunk/Admin/UserListThunk";
import SearchBar from "../../../common/SearchBar";
import { useTranslation } from "react-i18next";

function SearchFilterAdminUserManagement(props) {
  const dispatch = useAppDispatch();
  const [filter, setFilter] = React.useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const TempSearch = useRef("");
  useEffect(() => {
    TempSearch.current = searchTerm;
  }, [searchTerm]);
  const handleChange = (event) => {
    setFilter(event.target.value);
    props.data?.SetPageCount(0);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.data.setSearchTerm(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, props.data.pageCount, props.data.recordLimit]);

  useEffect(() => {
    props.data.setFilter(filter);
  }, [filter, props.data.pageCount, props.data.recordLimit]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        mt: { md: 1, xs: 0 },
      }}
    >
      <Box sx={{ display: { md: "flex", xs: "block" } }}>
        <Box
          sx={{
            marginBottom: { lg: "0", xs: "10px" },
            mr: "10px",
            width: { md: "auto", xs: "100%" },
          }}
        >
          <SearchBar
            searchVal={searchTerm}
            setSearchFunc={setSearchTerm}
            change={{ setPage: () => props.data?.SetPageCount(0) }}
          />
        </Box>
      </Box>
      <Box sx={{ minWidth: 120, mr: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {t("FilterLable")}
          </InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filter}
            label={t("FilterLable")}
            onChange={handleChange}
            sx={{ backgroundColor: "#fff" }}
          >
            <MenuItem value={t("UserFilterDropdownLable.0")}>
              {t("UserFilterDropdownLable.0")}
            </MenuItem>
            <MenuItem value="verified">
              {t("UserFilterDropdownLable.1")}
            </MenuItem>
            <MenuItem value="guest">{t("UserFilterDropdownLable.2")}</MenuItem>
            <MenuItem value="super">{t("UserFilterDropdownLable.3")}</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}

export default SearchFilterAdminUserManagement;
