import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setListofKitty,
  setKittyLoader,
  setIdofKitty,
  setFilterListofKitty,
  setListofInvitedKitty,
  //   setSuccess,
  //   setError,
} from "../../reducer/KittySlice/listofKittySlice";
import axios from "axios";
import { DeleteData } from "../../reducer/User/TokenExpireSlice";
import { setCommonLoader } from "../../reducer/AlertSlice";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Authorization"] = localStorage.getItem("UserInfo")
  ? `Token ${localStorage.getItem("UserInfo")}`
  : "";

export const listofKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setKittyLoader(true));
    dispatch(setCommonLoader(true));

    let offSet = "";
    let search = "";
    let category = "";
    if (_request.offSet) {
      offSet = `&offset=${_request.offSet}`;
    }

    if (_request.search) {
      search = `&search=${_request.search}`;
    }
    if (_request.category) {
      category = `&category=${_request.category}`;
    }

    axios
      .get(
        `api/v1/kitty/kitty/?limit=${_request.limit}${offSet}${search}${category}`
      )
      .then((response) => {
        dispatch(setKittyLoader(false));
        dispatch(setCommonLoader(false));
        // dispatch(setSuccess(true));
        dispatch(setListofKitty(response.data));
      })
      .catch((err) => {
        dispatch(setKittyLoader(false));
        dispatch(setCommonLoader(false));
        // dispatch(setSuccess(false));
        // dispatch(setError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const filterlistofKitty = createAsyncThunk(
  "filterlistofKitty",
  async (_request, { dispatch }) => {
    dispatch(setKittyLoader(true));
    dispatch(setCommonLoader(true));

    let search = "";
    let category = "";
    if (_request.search) {
      search = `&search=${_request.search}`;
    }
    if (_request.category) {
      category = `&category=${_request.category}`;
    }
    axios
      .get(
        `api/v1/kitty/kitty-sort-details/?limit=${_request.limit}${search}${category}`
      )
      .then((response) => {
        dispatch(setKittyLoader(false));
        dispatch(setCommonLoader(false));
        // dispatch(setSuccess(true));
        dispatch(setFilterListofKitty(response.data));
      })
      .catch((err) => {
        dispatch(setKittyLoader(false));
        dispatch(setCommonLoader(false));
        // dispatch(setSuccess(false));
        // dispatch(setError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const listofInvitedKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setKittyLoader(true));
    dispatch(setCommonLoader(true));

    let search = "";
    if (_request.search && _request.search !== "") {
      search = `&search=${_request.search}`;
    }

    axios
      .get(
        `api/v1/kitty/get-invited-kitty-email-list/?limit=${_request.limit}&offset=${_request.offSet}${search}`
      )
      .then((response) => {
        dispatch(setKittyLoader(false));
        dispatch(setCommonLoader(false));
        // dispatch(setSuccess(true));
        dispatch(setListofInvitedKitty(response.data));
      })
      .catch((err) => {
        dispatch(setKittyLoader(false));
        // dispatch(setSuccess(false));
        // dispatch(setError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const listofUserKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setKittyLoader(true));
    dispatch(setCommonLoader(true));

    let search = "";
    let category = "";
    let status = "";

    if (_request.search && _request.search !== "") {
      search = `&search=${_request.search}`;
    }

    if (_request.category && _request.category !== "") {
      if (_request.category === "All" || _request.category === "Toute") {
        category = "";
      } else {
        category = `&category=${_request.category}`;
      }
    }

    if (_request.status && _request.status !== "") {
      if (_request.status === "All" || _request.status === "Toute") {
        status = "";
      } else {
        status = `&status=${_request.status}`;
      }
    }

    axios
      .get(
        `api/v1/kitty/kitty-user-based/?limit=${_request.limit}&offset=${_request.offSet}${search}${category}${status}`
      )
      .then((response) => {
        dispatch(setKittyLoader(false));
        dispatch(setCommonLoader(false));
        // dispatch(setSuccess(true));
        dispatch(setListofKitty(response.data));
      })
      .catch((err) => {
        dispatch(setKittyLoader(false));
        // dispatch(setSuccess(false));
        // dispatch(setError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const singleKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setKittyLoader(true));
    dispatch(setCommonLoader(true));
    axios
      .get(`api/v1/kitty/kitty/${_request}/`)
      .then((response) => {
        dispatch(setKittyLoader(false));
        dispatch(setCommonLoader(false));
        dispatch(setIdofKitty(response));
      })
      .catch((err) => {
        dispatch(setKittyLoader(false));
        dispatch(setCommonLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const listofKittyForAdmin = createAsyncThunk(
  "listofKittyForAdmin",
  async (_request, { dispatch }) => {
    dispatch(setKittyLoader(true));
    dispatch(setCommonLoader(true));

    let search = "";
    let category = "";
    let status = "";

    if (_request.search && _request.search !== "") {
      search = `&search=${_request.search}`;
    }

    if (_request.category && _request.category !== "") {
      if (_request.category === "All" || _request.category === "Toute") {
        category = "";
      } else {
        category = `&category=${_request.category}`;
      }
    }

    if (_request.status && _request.status !== "") {
      if (_request.status === "All" || _request.status === "Toute") {
        status = "";
      } else {
        status = `&status=${_request.status}`;
      }
    }

    axios
      .get(
        `api/v1/kitty/kitty/?limit=${_request.limit}&offset=${_request.offSet}${search}${category}${status}`
      )
      .then((response) => {
        dispatch(setKittyLoader(false));
        dispatch(setCommonLoader(false));
        dispatch(setListofKitty(response.data));
        if (_request.callback()) {
          _request.callback();
        }
      })
      .catch((err) => {
        dispatch(setKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
