import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { FormControl, FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";
import FileUploader from "../../common/imageUpload";
import RichTextEditor from "react-rte";
import { useNavigate } from "react-router-dom";
import { setNewKittyTempData } from "../../../store/reducer/KittySlice/InsertNewKittySlice";
import { setTriggerFunction } from "../../../store/reducer/KittySlice/CreateKittySlice";

export default function KittyUpdate() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const categoryData = useAppSelector((state) => state.listofKittyCatSlice);
  const kittyName = useAppSelector((state) => state.CreateKitty);
  const [formError, setFormError] = React.useState([]);
  const session = useAppSelector((state) => state.UserProfile);
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createValueFromString("", "html")
  );
  /**
   * Handle editor's change.
   * @param {import('react-rte').EditorValue} value Value from the editor.
   */
  const [open, setOpen] = React.useState(false);
  const [kittyData, setKittyData] = React.useState({
    name: "",
    object_of_kitty: "",
    amount: "",
    category: "",
    image: "",
  });

  useEffect(() => {
    setFormError([]);
  }, []);

  useEffect(() => {
    if (kittyName.triggerFunction) {
      handleSubmit();
      let error = validateForm(kittyData);
      if (error.length == 0) {
        dispatch(setTriggerFunction(false));
      }
    }
  }, [kittyName]);

  useEffect(() => {
    if (kittyName.newKittyCreate) {
      setKittyData({
        ...kittyData,
        name:
          !typeof kittyName?.newKittyData?.name == "object"
            ? kittyName?.newKittyData?.name
            : kittyName?.newKittyData?.name?.newKittyCreate?.kittyName ||
              kittyName?.newKittyCreate?.kittyName,
        category: kittyName.newKittyData.category || kittyName.newKittyData.id,
        category_name:
          kittyName.newKittyData.category_name ||
          kittyName.newKittyData.category_name,
      });
    } else if (session.token !== "") {
      let temp = JSON.parse(localStorage.getItem("kittyData"));
      setKittyData({
        ...kittyData,
        name: temp?.newKittyCreate.kittyName,
        category: temp?.newKittyData.category || temp?.newKittyData.id,
        category_name: temp?.newKittyData.category_name,
      });
    }
  }, []);

  function onChangeTextEditor(value) {
    setEditorValue(value);
    setKittyData({
      ...kittyData,
      object_of_kitty: value.toString("html"),
    });

    const index = formError.indexOf("object_of_kitty");
    if (index !== -1) {
      formError.splice(index, 1);
    }
  }

  const clearData = {
    name: "",
    object_of_kitty: "",
    amount: "",
    category: "",
    image: "",
  };

  const validateForm = (data) => {
    let error = [];

    if (data.name === "") {
      error.push("name");
    }
    if (data.object_of_kitty === "" || data.object_of_kitty === "<p><br></p>") {
      error.push("object_of_kitty");
    }
    if (data.category === "") {
      error.push("category");
    }

    if (data.amount !== "" && data.amount <= 0) {
      error.push("amount");
    }

    setFormError(error);

    return error;
  };

  const theme = createTheme({
    TextField: {
      outlined: {
        padding: "10px 14px",
      },
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updatekittyData = (text, event) => {
    if (text == "amount") {
      if (event.target.value >= 0 && event.target.value <= 999999999) {
        setKittyData({
          ...kittyData,
          [text]: event.target.value,
        });
      }
    } else {
      setKittyData({
        ...kittyData,
        [text]: event.target.value,
      });
    }

    const index = formError.indexOf(text);
    if (index !== -1) {
      formError.splice(index, 1);
    }
  };

  const updatekittyCategory = (newValue) => {
    setKittyData({
      ...kittyData,
      category: newValue.id,
      category_name: newValue.category_name,
    });
  };

  const handleSelectFile = (file) => {
    setKittyData((kittyData) => ({
      ...kittyData,
      image: file,
    }));
  };

  const handleSubmitCallback = () => {
    setKittyData(clearData);
  };

  const handleSubmit = () => {
    let error = validateForm(kittyData);
    let UserCheck = JSON.parse(localStorage.getItem("UserId"));
    if (error.length == 0) {
      let request = {
        ...kittyData,
        token: localStorage.getItem("UserInfo"),
        user:
          session?.details?.id ||
          localStorage.getItem("UserId").id ||
          UserCheck.id,
        callback: handleSubmitCallback,
      };
      dispatch(setNewKittyTempData({ ...request, navigate: navigate }));
      handleClose();
    } else {
      dispatch(setTriggerFunction(false));
    }
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Grid container>
          <Grid item sx={{ padding: "1px 4px", mt: 1 }} xs={12} md={3}>
            <FileUploader onSelectFile={handleSelectFile} />
            {formError.includes("image") && (
              <FormHelperText id="component-error-text" error>
                {t("kittyFieldValidation.0")}
              </FormHelperText>
            )}
          </Grid>
          <Grid
            item
            sx={{ padding: { md: "1px 4px 1px 20px", xs: 0 } }}
            xs={12}
            md={9}
          >
            <Box sx={{ padding: "1px 4px" }}>
              <TextField
                autoFocus
                size="small"
                margin="dense"
                id="name"
                value={kittyData.name}
                onChange={(e) => {
                  updatekittyData("name", e);
                }}
                label={t("kittyFieldLable.2")}
                type="text"
                fullWidth
                variant="outlined"
                sx={{ background: "#fff" }}
                error={formError.includes("name")}
              />
              {formError.includes("name") && (
                <FormHelperText
                  id="component-error-text"
                  error
                  sx={{ marginLeft: "10px" }}
                >
                  {t("kittyFieldValidation.1")}
                </FormHelperText>
              )}
            </Box>
            <Box item sx={{ padding: "9px 4px 1px 4px", mt: 1 }}>
              <FormControl sx={{ width: " 100%" }}>
                <Autocomplete
                  id="country-select-demo"
                  error={formError.includes("category")}
                  size="small"
                  sx={{ width: "100%", background: "#fff" }}
                  options={categoryData.list.results}
                  autoHighlight
                  getOptionLabel={(option) =>
                    t("category." + option.category_name).replace(
                      "category.",
                      ""
                    )
                  }
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {t("category." + option.category_name).replace(
                        "category.",
                        ""
                      )}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("kittyFieldLable.3")}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                  value={
                    categoryData.category_name
                      ? categoryData.category_name
                      : {
                          category: kittyData.category,
                          category_name: kittyData.category_name,
                        }
                  }
                  onChange={(e, newValue) => {
                    updatekittyCategory(newValue);
                  }}
                />
                {formError.includes("category") && (
                  <FormHelperText id="component-error-text" error>
                    {t("kittyFieldValidation.2")}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box item sx={{ padding: "1px 4px", mt: 1 }}>
              <TextField
                margin="dense"
                size="small"
                value={kittyData.amount}
                onChange={(e) => {
                  updatekittyData("amount", e);
                }}
                onKeyPress={preventMinus}
                step=".01"
                inputProps={{
                  autoComplete: "off",
                  min: 1,
                  max: 2000000000,
                }}
                id="amount"
                label={t("kittyFieldLable.4")}
                type="number"
                fullWidth
                variant="outlined"
                error={formError.includes("amount")}
                sx={{ background: "#fff" }}
              />
              {formError.includes("amount") && (
                <FormHelperText
                  id="component-error-text"
                  error
                  sx={{ marginLeft: "10px" }}
                >
                  {t("kittyFieldValidation.4")}
                </FormHelperText>
              )}
            </Box>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <RichTextEditor
                onChange={onChangeTextEditor}
                value={editorValue}
                sx={{ width: "100%", m: "0 !important" }}
                placeholder={t("kittyFieldLable.5")}
                editorClassName="richtext-editor-input"
              />
              <FormHelperText
                sx={{
                  marginBottom: "10px",
                  marginLeft: "10px",
                  color: "red",
                }}
              >
                {formError.includes("object_of_kitty")
                  ? t("kittyFieldValidation.3")
                  : ""}
              </FormHelperText>
            </Box>
            <Box style={{ fontSize: "13px" }} />
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
