import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { createTheme } from "react-data-table-component";
import moment from "moment";
import DataTable from "../../../../../../components/common/DataTableBase";
import { useAppDispatch, useAppSelector } from "../../../../../../store/store";
import { listofTontineLog } from "../../../../../../store/thunk/Tontine/MytontineThunk";
import CustomMPagination from "../../../../../common/CustomMPagination";

export default function AdminLogComponent() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = useState(0);
  const [pageCountShow, SetPageCountShow] = useState(0);
  const [recordLimit, SetRecordLimit] = useState(10);
  const adminTontineLogData = useAppSelector((state) => state.MyTontine);

  const columns = [
    {
      name: t("tontineNameTitle"),
      selector: (row) => row.tontine_name,
    },
    {
      name: t("tontineStatusTitle"),
      selector: (row) => row.tontine_status,
    },
    {
      name: t("tontineCreatedDateTitle"),
      selector: (row) =>
        moment(row.tontine_created).format(
          localStorage.getItem("language") == "en" ? "YYYY-MM-DD" : "DD-MM-YYYY"
        ),
    },
    {
      name: t("tontineCreatedByTitle"),
      selector: (row) => row.created_by || "-",
    },
    {
      name: t("tontineActionLabel"),
      selector: (row) => row.action,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    dispatch(listofTontineLog({ limit: recordLimit, offSet: pageCount }));
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, recordLimit]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "#ccc",
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "#fff ",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        textAlign: "center",
        fontSize: "17px",
        display: "grid",
        placeContent: "center",
        backgroundColor: "#2a2828",
        color: "#fff",
        maxWidth: "40px",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        padding: "8px",
        fontSize: "14px",
        display: "grid",
        placeContent: "center",
      },
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );

  return (
    <>
      <Box className="kittyTable">
        <Box sx={{ borderRadius: 0, padding: { md: 2, xs: 1 } }}>
          <DataTable
            title={
              <>
                <Box
                  className="tableTitle"
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {t("tontineLogTitle")}
                </Box>
              </>
            }
            columns={columns}
            data={adminTontineLogData?.adminTontineLogList?.results}
            customStyles={customStyles}
            theme="solarized"
            noDataComponent={<Box sx={{ p: 3 }}>{t("norecordLbl")}</Box>}
            className="rdt_TableHeader"
            progressPending={adminTontineLogData.loading}
          />
          <CustomMPagination
            pageCount={pageCount}
            SetPageCount={SetPageCount}
            recordLimit={recordLimit}
            pageCountShow={pageCountShow}
            SetRecordLimit={SetRecordLimit}
            numRecords={adminTontineLogData?.adminTontineLogList?.num_records}
            numCount={adminTontineLogData?.adminTontineLogList?.count}
          />
        </Box>
      </Box>
    </>
  );
}
