import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Box, Tooltip } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../store/store";
import { unblockUserProfile } from "../../../../../store/thunk/Admin/UserListThunk";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteAlertBBox(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const unblockUser = () => {
    dispatch(
      unblockUserProfile({
        user_id: props.data.id,
        limit: props.limit,
        offSet: props.offSet,
        request: {
          is_active: true,
          login_attempts: 0,
        },
      })
    );
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: " center",
          justifyContent: "center",
          fontWeight: " 600",
          fontSize: "18px",
        }}
        onClick={handleClickOpen}
      >
        <Tooltip title={t("unblockLabel")}>
          <LockOpenIcon
            sx={{
              color: "#000000",
              mr: 1,
            }}
          />
        </Tooltip>
      </Box>

      <Dialog
        sx={{ padding: { md: "0 24px", xs: "0 10px" } }}
        open={open}
        fullWidth
        maxWidth="xs"
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            fontWeight: "600",
            background: "#592091",
            color: "#fff",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          {t("confirmationLabel")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("unblockUserConfirmMsg")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              background: "#fff",
              color: "#592091",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={handleClose}
          >
            {t("noLabel")}
          </Button>
          <Button
            sx={{
              background: "#592091",
              color: "#fff",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: "2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={() => {
              handleClose();
              unblockUser();
            }}
          >
            {t("yesLabel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
