import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  success: "",
  KittyCatData: {},
};

const updateKittyCatSlice = createSlice({
  name: "updateKittyCatSlice",
  initialState,
  reducers: {
    setUpdatedKittyCat: (state, action) => ({
      ...state,
      KittyData: action.payload,
    }),
    setKittyCatUpdateLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setSuccess: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    setKittyCatUpdateError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
  },
});

export const {
  setUpdatedKittyCat,
  setKittyCatUpdateLoader,
  setSuccess,
  setKittyCatUpdateError,
} = updateKittyCatSlice.actions;

export default updateKittyCatSlice.reducer;
