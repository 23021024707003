import * as React from "react";
import Button from "@mui/material/Button";
import YourID from "../../../components/User/MyAccount/Tabs/YourID";
import YourAccountDetails from "../../../components/User/MyAccount/Tabs/YourAccountDetails";
import CardAddTab from "../../../components/User/MyAccount/Tabs/CardDetails/MyCardDetails";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import ParticipantDocuements from "../../../layout/tontinePage/tontineLanding/StartTontine/ParticipantDocuements";
import {
  CheckUserDocumentStatus,
  SingleTontine,
  emailReminderToParticipants,
  tontineCycleDetails,
  updateMyTontine,
} from "../../../store/thunk/Tontine/MytontineThunk";
import { useNavigate, useParams } from "react-router-dom";
import TontineStartDetails from "../../../layout/tontinePage/tontineLanding/StartTontine/TontineStartDetails";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MyCardDetails } from "../../../store/thunk/Payments/CheckOutThunk";
import moment from "moment";

export default function StartTonine(props) {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [participantDocStatus, setParticipantDocStatus] = React.useState([]);
  const [formError, setFormError] = React.useState([]);
  const userDocuments = useAppSelector((state) => state?.MyTontine);
  const [dates, setDates] = React.useState({});
  const tontineDetails = useAppSelector(
    (state) => state.MyTontine.singleTontine
  );
  const ListOfCards = useAppSelector(
    (state) => state?.UserProfile?.MyCardDetails
  );

  const loginUserDetails = useAppSelector(
    (state) => state?.UserProfile?.details
  );

  const getCardDetails = () => {
    dispatch(MyCardDetails(loginUserDetails?.stripe_customer_id));
    dispatch(SingleTontine(id));
  };

  React.useEffect(() => {
    if (loginUserDetails?.stripe_customer_id) {
      dispatch(MyCardDetails(loginUserDetails?.stripe_customer_id));
    }
  }, [loginUserDetails]);

  React.useEffect(() => {
    if (tontineDetails?.tontine_participants_id) {
      setParticipantDocStatus(() => {
        let temp = tontineDetails?.tontine_participants_id?.filter(
          (item) =>
            item?.document[0]?.is_verified === false ||
            item?.document?.length < 1
        );
        let temp2 = tontineDetails?.tontine_participants_id?.filter(
          (item) => item?.document[0]?.iban === null
        );

        let temp3 = tontineDetails?.tontine_participants_id?.filter(
          (item) => !item?.document[0]?.card_status
        );

        let response = [...temp, ...temp2, ...temp3];
        return [...new Set(response)];
      });
    }
  }, [props.data]);

  const refreshTontineDetails = () => {
    dispatch(SingleTontine(id));
    getCardDetails();
    dispatch(CheckUserDocumentStatus({ navigate: navigate }));
  };

  const handleReminder = () => {
    dispatch(
      emailReminderToParticipants({
        id,
        callback: () => {
          setOpen(false);
        },
      })
    );
  };

  const validateForm = (data) => {
    let error = [];
    if (
      dates.creation_date === "" ||
      dates.creation_date === null ||
      moment(dates.creation_date) < moment()
    ) {
      error.push("creation_date");
    }
    setFormError(error);
    return error;
  };

  return (
    <div>
      {tontineDetails?.tontine_participants_id?.length < 2 ? (
        <Box>
          <Typography variant="p" sx={{ p: "20px !important" }}>
            {`${t("startTontine.0")} ${tontineDetails?.name} ${t(
              "startTontine.1"
            )}. Invite your family & friends from invite tab`}
          </Typography>
        </Box>
      ) : !userDocuments?.userDocuments[0]?.is_verified &&
        localStorage.getItem("is_superuser") == "false" ? (
        <YourID />
      ) : userDocuments?.userDocuments[0]?.iban === null &&
        localStorage.getItem("is_superuser") == "false" ? (
        <YourAccountDetails callback={refreshTontineDetails} />
      ) : ListOfCards?.data?.length < 1 &&
        localStorage.getItem("is_superuser") == "false" ? (
        <CardAddTab callback={getCardDetails} />
      ) : !participantDocStatus?.length < 1 ? (
        <ParticipantDocuements
          data={tontineDetails?.tontine_participants_id}
          pendingUser={participantDocStatus}
        />
      ) : (
        <TontineStartDetails
          formError={formError}
          data={{
            data: tontineDetails,
            dateChange: (data) => {
              setDates({ id, ...data });
            },
          }}
        />
      )}

      {tontineDetails?.tontine_participants_id?.length <
      2 ? null : !userDocuments?.userDocuments[0]?.is_verified &&
        tontineDetails?.tontine_participants_id?.length < 2 ? null : ListOfCards
          ?.data?.length < 1 ? null : !participantDocStatus?.length < 1 ? (
        <Button
          onClick={handleReminder}
          autoFocus
          sx={{
            border: "1px solid #592091",
            color: "#fff",
            background: "#592091",
            "&:hover": {
              color: "#592091",
              background: "#fff",
            },
          }}
        >
          {t("SendEmailparticipantsLable")}
        </Button>
      ) : (
        <Button
          onClick={() => {
            let error = validateForm(dates);
            if (error.length === 0) {
              dispatch(
                updateMyTontine({
                  ...dates,
                  callback: () => {
                    dispatch(tontineCycleDetails(id));
                    setOpen(false);
                  },
                })
              );
            }
          }}
          autoFocus
          sx={{
            border: "1px solid #592091",
            color: "#fff",
            background: "#592091",
            "&:hover": {
              color: "#592091",
              background: "#fff",
            },
          }}
        >
          {t("TontineLandingPage.1")}
        </Button>
      )}
    </div>
  );
}
