import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setNewKittyCat,
  setNewKittyCatSuccess,
  setNewKittyCatError,
} from "../../reducer/KittyCategorySlice/InsertNewKittyCatSlice";
import axios from "axios";
import { setSuccessAlert, setErrorAlert } from "../../reducer/AlertSlice";
import { DeleteData } from "../../reducer/User/TokenExpireSlice";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "multipart/form-data";
axios.defaults.headers["Authorization"] = localStorage.getItem("UserInfo")
  ? `Token ${localStorage.getItem("UserInfo")}`
  : "";

export const insertNewKittyCat = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    let form_data = new FormData();
    form_data.append("category_name", _request.category_name);
    form_data.append("category_type", _request.category_type);
    form_data.append("description", _request.description);
    if (_request.image) {
      form_data.append("image", _request.image);
    }

    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    axios
      .post("api/v1/kitty/kitty-category/", form_data)
      .then((response) => {
        dispatch(setNewKittyCatSuccess(true));
        dispatch(setNewKittyCat(response));
        dispatch(setSuccessAlert("Created successfully"));
        if (_request.callback) {
          _request.callback();
        }
      })
      .catch((err) => {
        dispatch(setNewKittyCatError(err));
        if (err?.response?.data?.category_name) {
          dispatch(setErrorAlert(err?.response?.data?.category_name[0]));
        } else {
          dispatch(setErrorAlert("something wrong try again"));
        }
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
