import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/store";
import "./CreateKitty.scss";
import { useNavigate } from "react-router-dom";
import { listofKittyCat } from "../../store/thunk/KittyCategory/listofKittyCatThunk";
import KittyCategoryCard from "../KittyCategory/KittyCategoryCard";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import {
  TabIndexData,
  setNewKittyCreate,
  setNewKittyData,
} from "../../store/reducer/KittySlice/CreateKittySlice";
import {
  setKittyTempData,
  setNewKittyTempData,
} from "../../store/reducer/KittySlice/InsertNewKittySlice";

function CreateKitty(props) {
  const navigate = useNavigate();
  const kittyName = useAppSelector((state) => state.CreateKitty);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const data = useAppSelector((state) => state.listofKittyCatSlice);
  useEffect(() => {
    dispatch(listofKittyCat({}));
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box style={{ backgroundColor: "#f1efeb", minHeight: " 100vh" }}>
      <Box className="CreateKittyName">
        <Box
          sx={{
            position: "absolute",
            right: " 0",
            top: "0",
          }}
        >
          <Button
            onClick={() => {
              navigate("/");
              dispatch(TabIndexData(0));
              dispatch(setNewKittyTempData({}));
              dispatch(setKittyTempData({}));
              dispatch(
                setNewKittyData({ name: "", category: "", category_name: "" })
              );
              window.localStorage.removeItem("kittyData");
              window.localStorage.removeItem("UserId");
            }}
            sx={{ float: "right", padding: "20px 10px" }}
          >
            <CancelTwoToneIcon />
          </Button>
        </Box>
        <Box sx={{ textAlign: "center", marginBottom: { xs: "10px" }, p: 1 }}>
          <Typography
            variant="p"
            sx={{
              fontSize: { md: "20px", xs: "10px" },
              mt: "10px",
              textTransform: "uppercase",
              fontWeight: "600",
            }}
          >
            {t("createKittyRaise")}:
          </Typography>
          <Typography
            className="top-subheading"
            variant="h3"
            sx={{
              mt: 2,
              fontWeight: "600",
              background:
                " linear-gradient(to left, #592091, #4b0082, #91bcff, #1064e7, #808080, #4b0082, #0161f7)",
              WebkitBackgroundClip: "text",
              color: " transparent",
            }}
          >
            {kittyName.newKittyCreate.kittyName}
          </Typography>
        </Box>
      </Box>
      <Box style={{ paddingBottom: "30px" }}>
        <Container>
          <KittyCategoryCard data={data.list} />
        </Container>
      </Box>
    </Box>
  );
}

export default CreateKitty;
