import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setUserDetails,
  setUserDetailsSuccess,
  setUserDetailsError,
} from "../reducer/User/UserProfileSlice";

import axios from "axios";
import { DeleteData } from "../reducer/User/TokenExpireSlice";
import { setListofUser, setUserLoader } from "../reducer/User/UserListSlice";
import { setCommonLoader } from "../reducer/AlertSlice";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";

export const UserProfileDetails = createAsyncThunk(
  "UserProfileDetails",
  async (_request, { dispatch }) => {
    axios
      .get("api/v1/accounts/me/", {
        headers: {
          Authorization: localStorage.getItem("UserInfo")
            ? `Token ${localStorage.getItem("UserInfo")}`
            : "",
        },
      })
      .then((response) => {
        dispatch(setCommonLoader(false));
        dispatch(setUserDetailsSuccess(true));
        dispatch(setUserDetails(response.data));
        localStorage.setItem("is_superuser", response.data.is_superuser);
        if (!response.data.first_name) {
          if (_request?.callback) {
            _request.callback(_request.token);
          }
        } else {
          if (_request?.callback2) {
            _request.callback2(_request.token);
          }
        }
        if (_request.request()) {
          _request.request();
        }
      })
      .catch((err) => {
        dispatch(setUserDetailsError(err));
        if (err?.response?.data?.detail === "Invalid token") {
          if (_request.callback) {
            _request.callback();
          }

          dispatch(DeleteData());
        }
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const UserProfileGoogleDetails = createAsyncThunk(
  "UserProfileGoogleDetails",
  async (_request, { dispatch }) => {
    axios
      .get("api/v1/accounts/me/", {
        headers: {
          Authorization: localStorage.getItem("UserInfo")
            ? `Token ${localStorage.getItem("UserInfo")}`
            : "",
        },
      })
      .then((response) => {
        // dispatch(setUserDetailsSuccess(true));
        dispatch(setUserDetails(response?.data));
        dispatch(setCommonLoader(false));
        localStorage.setItem("is_superuser", response.data.user.is_superuser);
      })
      .catch((err) => {
        dispatch(setUserDetailsError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const UserProfileDetailsFacebook = createAsyncThunk(
  "UserProfileDetailsFacebook",
  async (_request, { dispatch }) => {
    axios
      .get("api/v1/accounts/me/", {
        headers: {
          Authorization: localStorage.getItem("UserInfo")
            ? `Token ${localStorage.getItem("UserInfo")}`
            : _request,
        },
      })
      .then((response) => {
        dispatch(setUserDetailsSuccess(true));
        dispatch(setCommonLoader(false));
        dispatch(setUserDetails(response.data));
        localStorage.setItem("is_superuser", response.data.user.is_superuser);
      })
      .catch((err) => {
        dispatch(setUserDetailsError(err));
        dispatch(setUserDetailsSuccess(true));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const listofUsers = createAsyncThunk(
  "listofUsers",
  async (_request, { dispatch }) => {
    axios
      .post(`/api/v1/accounts/check-user-account/`, _request)
      .then((response) => {
        dispatch(setUserLoader(true));
        // dispatch(setSuccess(true));
        dispatch(setCommonLoader(false));
        dispatch(setListofUser(response));
      })
      .catch((err) => {
        dispatch(setUserLoader(false));
        // dispatch(setSuccess(false));
        // dispatch(setError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
