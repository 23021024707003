import * as React from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch } from "../../store/store";
import { deleteKitty } from "../../store/thunk/Kitty/DeleteKittyListThunk";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import DeleteDialogBox from "./KittyDeleteDialog";
import { Box } from "@mui/material";
import { listofUserKitty } from "../../store/thunk/Kitty/listofKittyThunk";

export default function DeleteAlertBBox(props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteUpdateKitty = () => {
    dispatch(
      deleteKitty({
        id: props.data.id,
        callback: () => {
          dispatch(
            listofUserKitty({
              limit: props.recordLimit,
              offSet: props.pageCount,
            })
          );
        },
      })
    );
  };

  return (
    <>
      <Tooltip title={t("deleteLable")}>
        <Button
          onClick={handleClickOpen}
          sx={{ textDecoration: "none", color: "#fff" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: " center",
              justifyContent: "center",
              fontWeight: " 600",
              fontSize: "18px",
            }}
          >
            <DeleteIcon sx={{ color: "#fff ", mr: 1 }} />
            {t("deleteLable")}
          </Box>
        </Button>
      </Tooltip>
      <DeleteDialogBox
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        deleteUpdateKitty={deleteUpdateKitty}
        open={open}
        data={props.data}
      />
    </>
  );
}
