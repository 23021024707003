import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import ResponsiveDrawer from "../../topbar";
import AdminContributePayout from "./ContributePayout";
import { useNavigate } from "react-router-dom";

export default function AdminContributePayoutContent() {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (localStorage.getItem("is_superuser") == "false") {
      navigate("/admin");
    }
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ResponsiveDrawer pageComponent={<AdminContributePayout />} />
    </Box>
  );
}
