import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { Button } from "@mui/material";
import { useAppSelector } from "../../../../store/store";
import { useTranslation } from "react-i18next";

export default function BasicTable(props) {
  const { t } = useTranslation();
  const tontineDetails = useAppSelector(
    (state) => state.MyTontine.singleTontine
  );
  return (
    <>
      {tontineDetails?.tontine_participants_id ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t("ParticipantsDocTab.0")}</TableCell>
                <TableCell align="center">
                  {t("ParticipantsDocTab.1")}
                </TableCell>
                <TableCell align="right">{t("ParticipantsDocTab.2")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tontineDetails?.tontine_participants_id?.map((row) => (
                <TableRow
                  key={row.user?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {`${row.user?.first_name} ${row.user?.name}`}
                  </TableCell>
                  <TableCell align="center">
                    {moment(row.created_at).format(
                      localStorage.getItem("language") == "en"
                        ? "YYYY-MM-DD"
                        : "DD-MM-YYYY"
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {row?.document?.length < 1 ? (
                      <Button
                        sx={{
                          backgroundColor: "#fc0b03",
                          color: "#fff",
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "#fc0b03",
                          },
                        }}
                      >
                        {t("ParticipantsDocTab.3")}
                      </Button>
                    ) : !row?.document[0]?.card_status &&
                      row?.document[0]?.iban === null ? (
                      <Button
                        sx={{
                          backgroundColor: "#fc0b03",
                          color: "#fff",
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "#fc0b03",
                          },
                        }}
                      >
                        {t("ParticipantsDocTab.4")}
                      </Button>
                    ) : !row?.document[0]?.card_status ? (
                      <Button
                        sx={{
                          backgroundColor: "#fc0b03",
                          color: "#fff",
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "#fc0b03",
                          },
                        }}
                      >
                        {t("ParticipantsDocTab.5")}
                      </Button>
                    ) : row?.document[0]?.iban === null ? (
                      <Button
                        sx={{
                          backgroundColor: "#fc0b03",
                          color: "#fff",
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "#fc0b03",
                          },
                        }}
                      >
                        {t("ParticipantsDocTab.6")}
                      </Button>
                    ) : row?.document[0]?.is_verified ? (
                      <Button
                        sx={{
                          backgroundColor: "#03fc62",
                          color: "#fff",
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "#03fc62",
                          },
                        }}
                      >
                        {t("DocumentVerifiedLable")}
                      </Button>
                    ) : (
                      <Button
                        sx={{
                          backgroundColor: "#fc0b03",
                          color: "#fff",
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "#fc0b03",
                          },
                        }}
                      >
                        {t("ParticipantsDocTab.3")}
                      </Button>
                    )}
                  </TableCell>
                  {/* <TableCell align="right">{row.carbs}</TableCell> */}
                  {/* <TableCell align="right">{row.protein}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
}
