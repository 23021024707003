import * as React from "react";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import TontineImage from "../../../assets/Images/inner-pages/fistbump-over-desk.jpg";
import { useTranslation } from "react-i18next";
import "../style.scss";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { listofUserTontine } from "../../../store/thunk/Tontine/MytontineThunk";
import "../../../assets/scss/common.scss";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
// import SignInPopUp from "../../../components/SignIn/SignInPopup";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const SignInPopUp = React.lazy(() =>
  import("../../../components/SignIn/SignInPopup")
);

export default function TontineLanding() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.userSlice);
  const [recordLimit, SetRecordLimit] = React.useState(10);
  React.useEffect(() => {
    dispatch(listofUserTontine({ limit: recordLimit, offset: 0 }));
  }, []);

  React.useEffect(() => {
    dispatch(listofUserTontine({ limit: recordLimit, offset: 0 }));
  }, [recordLimit]);

  React.useEffect(() => {
    dispatch(listofUserTontine({ limit: recordLimit, offset: 0 }));
  }, [user]);

  const myTontine = useAppSelector((state) => state.MyTontine);
  const navigateToDetailPage = (id) => {
    navigate(`/tontine/${id}`, {
      state: { backRedirectUrl: "/tontine" },
    });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={myTontine?.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ pt: 8, minHeight: "450px" }}>
        <React.Suspense
          fallback={
            <div sx={{ minHeight: "540px", background: "#f1efeb" }}></div>
          }
        >
          {localStorage.getItem("UserInfo") ? null : <SignInPopUp />}
        </React.Suspense>
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              m: { md: "20px 15px 10px", xs: "10px 0px 20px 0px" },
            }}
          >
            <Typography
              className="top-subheading"
              variant="p"
              sx={{ display: "block", fontSize: "30px" }}
            >
              {t("tontineTab2")}
            </Typography>
          </Box>
          <Grid container sx={{ display: { md: "flex", xs: "block" } }}>
            {myTontine?.myTontine?.results?.length > 0 ? (
              myTontine?.myTontine?.results?.map((item) => {
                return (
                  <Grid item md={12} key={item.id}>
                    <Box
                      onClick={() => navigateToDetailPage(item.id)}
                      sx={{ cursor: "pointer" }}
                    >
                      <Paper
                        sx={{
                          p: 2,
                          border: "1px solid #ddd",
                          m: { md: 2, xs: "20px 0px" },
                        }}
                      >
                        <Grid
                          container
                          sx={{ display: { md: "flex", xs: "block" } }}
                        >
                          <Grid
                            item
                            md={3}
                            sx={{
                              paddingRight: { md: "20px", xs: "0" },
                              borderRight: { md: "1px solid #ddd", xs: "none" },
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={item.image}
                              alt={TontineImage}
                              loading="lazy"
                              style={{
                                height: "170px",
                                width: "250px",
                                objectFit: "cover",
                                aspectRatio: "3/2",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            sx={{ p: { md: "0 16px", xs: "10px 0px 20px" } }}
                          >
                            <Typography
                              variant="h5"
                              sx={{ fontWeight: "600", color: "#555", mb: 1 }}
                            >
                              {item.name}
                            </Typography>
                            <Box
                              variant="body1"
                              className="TontineDescription"
                              sx={{
                                color: "#555",
                                display: "-webkit-box",
                                WebkitLineClamp: "4",
                                WebkitBoxOrient: " vertical",
                                overflow: "hidden",
                                textOverflow: " ellipsis",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: item?.description,
                              }}
                            ></Box>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            sx={{
                              paddingLeft: { md: "20px", xs: "0" },
                              borderLeft: { md: "1px solid #ddd", xs: "none" },
                            }}
                          >
                            <Box
                              sx={{
                                textAlign: "center",
                                background: "#9a5ec0",
                                marginBottom: "10px",
                                padding: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "3px",
                                color: "#fff",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: " center",
                                  justifyContent: "center",
                                  marginBottom: "6px",
                                  fontWeight: " 600",
                                  fontSize: "18px",
                                }}
                              >
                                <EuroSymbolIcon
                                  sx={{
                                    background: "#fff ",
                                    borderRadius: " 50%",
                                    color: "#aba0a0",
                                    padding: " 4px",
                                    fontSize: "13px",
                                    mr: 1,
                                  }}
                                />
                                {item?.amount}
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                textAlign: "center",
                                background: "#dc70b5bd",
                                marginBottom: "10px",
                                padding: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "3px",
                                color: "#fff",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: " center",
                                  justifyContent: "center",
                                  marginBottom: "6px",
                                  fontWeight: " 600",
                                  fontSize: "18px",
                                }}
                              >
                                <LoopOutlinedIcon
                                  sx={{
                                    background: "#fff ",
                                    borderRadius: " 50%",
                                    color: "#aba0a0",
                                    padding: " 4px",
                                    fontSize: "13px",
                                    mr: 1,
                                  }}
                                />
                                {item?.frequency}
                              </Box>
                            </Box>
                            <Button
                              variant="contained"
                              size="large"
                              sx={{
                                background: "#91bcff",
                                margin: "0 auto",
                                display: "flex",
                                width: "100%",
                                padding: " 10px 10px",
                                "&:hover": {
                                  background: "#808080",
                                },
                              }}
                            >
                              <AccountCircleIcon
                                sx={{ color: "#fff", mr: 1 }}
                              />
                              {t("TontineLandingPage.0")}:{" "}
                              {item?.tontine_participants_id?.length}
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  </Grid>
                );
              })
            ) : (
              <>
                <Grid item md={12}>
                  <Grid container>
                    <Grid item md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          minHeight: "80px",
                          margin: "16px",
                          borderRadius: "10px",
                        }}
                      >
                        <Box>{t("norecordLbl")}</Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {myTontine?.myTontine?.results?.length > 0 &&
            myTontine?.myTontine?.count > recordLimit ? (
              <Grid item md={12}>
                <Box
                  onClick={() => SetRecordLimit(recordLimit + 10)}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Paper
                    sx={{
                      p: 2,
                      border: "1px solid #ddd",
                      m: { md: 2, xs: "20px 0px" },
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box>
                        <KeyboardDoubleArrowDownIcon />
                      </Box>
                      <Box>{t("ShowMoreLable")}</Box>
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
