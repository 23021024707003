import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  success: "",
  list: {},
};

const listofKittyCatSlice = createSlice({
  name: "listofKittyCatSlice",
  initialState,
  reducers: {
    setListofKittyCat: (state, action) => ({
      ...state,
      list: action.payload,
    }),
    setKittyCatLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setSuccess: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    setError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
  },
});

export const { setListofKittyCat, setKittyCatLoader, setSuccess, setError } = listofKittyCatSlice.actions;

export default listofKittyCatSlice.reducer;