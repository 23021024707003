import { Box, Container, Paper, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import VerticalTabs from "./VerticalTabs";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/store";

import { SingleTontine } from "../../store/thunk/Tontine/MytontineThunk";

export default function Settings() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const tontineDetails = useAppSelector(
    (state) => state.MyTontine.singleTontine
  );
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(SingleTontine(id));
  }, []);

  // useEffect(() => {
  //   // Redirect to tontineDetails?ing page is visited setting page other users
  //   if (
  //     Object.keys(profileDetails).length > 0 &&
  //     Object.keys(tontineDetails?).length > 0 &&
  //     profileDetails?.id !== tontineDetails??.user?.id
  //   ) {
  //     dispatch(setErrorAlert("You don't have access for this tontine setting"));
  //     navigate("/kitty-tontineDetails?ing");
  //   }
  // }, [tontineDetails?]);

  return (
    <Box className="howItWorkBlock" sx={{ pt: 10, pb: 5 }}>
      <Container>
        <Typography variant="h5" className="tontine-title">
          {t("tonetineDetailHeading")}
        </Typography>
        <Paper
          sx={{ p: "10px 10px", mb: 2, display: "flex", flexDirection: "rows" }}
        >
          <img
            src={tontineDetails?.image}
            height="100px"
            width="100px"
            style={{ objectFit: "cover" }}
            alt={t("kittyFieldLable.0")}
          />
          <Box sx={{ m: "4px", display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "17px",
                fontWeight: " 600",
                padding: "10px",
              }}
            >
              {tontineDetails?.name}
            </Typography>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                color: "#868484",
                fontWeight: "600",
                justifyContent: "left",
              }}
            >
              {"< "}
              {t("back")}
            </Button>
          </Box>
        </Paper>
        <Paper>
          <VerticalTabs />
        </Paper>
      </Container>
    </Box>
  );
}
