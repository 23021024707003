import React from "react";
import Footer from "../components/common/Footer"
import Header from "../components/common/Header";
import HomePage from "./homePage";

function HomePageComponent() {
  return (
    <>
      <Header />
      <HomePage />
      <Footer />
    </>
  );
}
export default HomePageComponent;
