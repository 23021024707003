import * as React from "react";
// import "./ActivationLink.scss";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  SingleTontine,
  TontineLinkCheck,
  participantReplyTontine,
  participantTontine,
} from "../../../store/thunk/Tontine/MytontineThunk";
import { CircularProgressbar } from "react-circular-progressbar";
// import Signin from "../../../components/SignIn/SignInPopup";

const Signin = React.lazy(() =>
  import("../../../components/SignIn/SignInPopup")
);

export default function ActivationLink() {
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const uid = urlParams.get("uid");
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = React.useState(false);
  const { t } = useTranslation();
  const tontine = useAppSelector((state) => state.MyTontine);
  const userData = useAppSelector((state) => state.UserProfile);
  let flag = React.useRef(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(SingleTontine(id));
  }, []);

  React.useEffect(() => {
    dispatch(SingleTontine(id));
    dispatch(
      TontineLinkCheck({
        email: userData?.details?.email,
        uid,
        tontine: id,
      })
    );
  }, [userData]);

  const handleReject = () => {
    let request = {
      id: Number(tontine.tontineLink.id),
      status: "REJECTED",
      is_verify: true,
      navigate: navigate,
    };
    dispatch(participantReplyTontine(request));
    flag.current = false;
  };

  const handleRequest = () => {
    setDisableBtn(true);
    let request = {
      navigate: navigate,
      tontine: Number(id),
      // status: "accepted",
      // is_verify: true,
      user: userData?.details?.id,
      callback: () => {
        setTimeout(() => {
          setDisableBtn(false);
        }, 1000);
      },
    };
    let status = {
      id: Number(tontine.tontineLink.id),
      status: "ACCEPTED",
      is_verify: true,
    };
    dispatch(participantTontine(request));
    dispatch(participantReplyTontine(status));
  };
  return (
    <Box>
      {localStorage.getItem("UserInfo") ? (
        tontine.tontineLink.link === true ? (
          window.location.pathname.includes("accept") ? (
            <Box
              fullWidth
              sx={{
                background: "rgb(89, 32, 145)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                sx={{
                  zIndex: 1,
                  background: "#f1efeb",
                  padding: "10px",
                  margin: "40px 10px",
                  marginTop: "100px",
                  maxWidth: " 400px",
                  width: " 100%",
                }}
                className="ForgetPasswordForm"
              >
                {tontine.singleTontine?.id == id ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        align="left"
                        width={"100%"}
                        sx={{
                          textAlign: "center",
                          marginBottom: "20px",
                          fontWeight: "600",
                        }}
                      >
                        {`${t("ParticipantJoinPage.0")} ${
                          tontine.singleTontine.name
                        } tontine`}
                      </Typography>
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Box sx={{ display: "grid", placeContent: "center" }}>
                            <img
                              src={tontine.singleTontine.image}
                              alt="Tontine"
                              height={150}
                              loading="lazy"
                              style={{ maxWidth: "250px", aspectRatio: "1/1" }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="p"
                                sx={{
                                  background:
                                    "linear-gradient(to left, #592091, #4b0082, #91bcff, #1064e7, #808080, #4b0082, #0161f7)",
                                  WebkitBackgroundClip: "text",
                                  color: "transparent",
                                  fontSize: "18px",
                                  fontWeight: "700",
                                  pb: 1,
                                }}
                              >
                                {t("FutureReliablityLable")}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                width: { md: "120px", xs: "100px" },
                              }}
                            >
                              <CircularProgressbar
                                value={
                                  tontine?.singleTontine?.future_reliability
                                }
                                text={`${tontine?.singleTontine?.future_reliability}%`}
                                className="circular-progress-bar"
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>

                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                          fontSize: "16px",
                          m: "10px 0px",
                        }}
                      >
                        {`${t("ParticipantJoinPage.1")} ${
                          tontine.singleTontine.name
                        } ${t("ParticipantJoinPage.2")}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{
                            mt: 1,
                            width: "100%",
                            height: "50px",
                            fontWeight: "600",
                          }}
                          onClick={handleRequest}
                          disabled={disableBtn}
                        >
                          {t("ParticipantJoinPage.3")}
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{
                            mt: 1,
                            width: "100%",
                            height: "50px",
                            backgroundColor: "#fff",
                            border: "1px solid blue",
                            color: "#592091",
                            "&:hover": {
                              backgroundColor: "#fff",
                              color: "#592091",
                            },
                          }}
                          onClick={() => navigate("/admin")}
                          disabled={disableBtn}
                        >
                          {t("cancelLable")}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
              </Paper>
            </Box>
          ) : (
            <>
              <Box
                fullWidth
                sx={{
                  background: "rgb(89, 32, 145)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Paper
                  sx={{
                    zIndex: 1,
                    background: "#f1efeb",
                    padding: 4,
                    margin: 5,
                    mt: "100px",
                    width: "400px",
                  }}
                  className="ForgetPasswordForm"
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        align="left"
                        width={"100%"}
                        sx={{
                          textAlign: "center",
                          marginBottom: "20px",
                          fontWeight: "600",
                        }}
                      >
                        {`You reject the invited to join ${tontine.singleTontine.name} tontine`}
                      </Typography>
                      <Box sx={{ display: "grid", placeContent: "center" }}>
                        <img
                          src={tontine.singleTontine.image}
                          alt="Tontine"
                          height={150}
                          loading="lazy"
                          style={{ maxWidth: "250px" }}
                        />
                      </Box>
                      <Box>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{
                            mt: 1,
                            width: "100%",
                            height: "50px",
                          }}
                          onClick={handleReject}
                          disabled={disableBtn}
                        >
                          {t("ConfirmLable")}
                        </Button>
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                          fontSize: "16px",
                          m: "10px 0px",
                        }}
                      >
                        {`${t("RejectParticipantPage")} ${
                          tontine.singleTontine.name
                        }.`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </>
          )
        ) : tontine.tontineLink.link === false ? (
          <Box
            sx={{
              pt: "5rem",
              pb: "1rem",
              display: "grid",
              placeContent: "center",
            }}
          >
            <Paper
              sx={{
                width: "40rem",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#f1efeb",
              }}
            >
              <Typography
                variant="h3"
                sx={{ textAlign: "center", padding: "2rem 1rem" }}
              >
                {t("TontineNotInvitedLable")}
              </Typography>
            </Paper>
          </Box>
        ) : (
          <Box
            sx={{
              pt: "5rem",
              pb: "1rem",
              display: "grid",
              placeContent: "center",
            }}
          >
            <Paper
              sx={{
                width: "40rem",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#f1efeb",
              }}
            >
              <Typography
                variant="h3"
                sx={{ textAlign: "center", padding: "2rem 1rem" }}
              >
                {`...${t("LoadingLable")}`}
              </Typography>
            </Paper>
          </Box>
        )
      ) : (
        <React.Suspense
          fallback={
            <div sx={{ minHeight: "540px", background: "#f1efeb" }}></div>
          }
        >
          <Signin />
        </React.Suspense>
      )}
    </Box>
  );
}
