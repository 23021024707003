import { Box, Button, Paper, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/store";
import { useDispatch } from "react-redux";
import {
  ListOfTontineInvites,
  emailInviteTontine,
} from "../../../store/thunk/Tontine/MytontineThunk";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#7837b9",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Invitations(id) {
  const dispatch = useDispatch();
  const recordLimit = 10;
  const [pageCount, SetPageCount] = useState(0);
  const list = useAppSelector((state) => state.MyTontine.singleTontine);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(
      ListOfTontineInvites({
        id: id.id.id,
        limit: recordLimit,
        offSet: pageCount,
      })
    );
  }, [pageCount]);

  const { t } = useTranslation();

  const tontineInvites = useAppSelector((state) => state.MyTontine);

  return (
    <Box>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ fontSize: "17px", fontWeight: "650" }}>
                  {t("createEmailLable")}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontSize: "17px", fontWeight: "650" }}
                >
                  {t("dateLabel")}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontSize: "17px", fontWeight: "650" }}
                >
                  {t("timeLabel")}
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{ fontSize: "17px", fontWeight: "650" }}
                >
                  {t("ResponseLable")}
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{ fontSize: "17px", fontWeight: "650" }}
                >
                  {t("ResendInviteLable")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tontineInvites?.ListofInvites?.results
                ? tontineInvites?.ListofInvites?.results?.map((row) => {
                    return (
                      <StyledTableRow key={row.receiver_email}>
                        <StyledTableCell component="th" scope="row">
                          {row.receiver_email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {moment(row.created_at).format(
                            localStorage.getItem("language") == "en"
                              ? "YYYY-MM-DD"
                              : "DD-MM-YYYY"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {moment(row.created_at).format("hh:mm A")}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {" "}
                          <Typography variant="p">{row.status}</Typography>{" "}
                          {/* <AccessTimeIcon /> */}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Button
                            sx={{
                              background: "#592091",
                              color: "#fff",
                              padding: "8px 10px",
                              fontWeight: "600",
                              border: "1px solid #592091",
                              textAlign: "center",
                              maxWidth: "200px",
                              width: "100%",
                              "&:hover": {
                                color: "#592091",
                              },
                              "&:disabled": {
                                color: "#fff",
                                background: "#876ba3",
                              },
                            }}
                            onClick={() => {
                              dispatch(
                                emailInviteTontine({
                                  request: {
                                    emails: row.receiver_email,
                                    link: `${process.env.REACT_APP_REDIRECT_URL}/tontine/participantpage/`,
                                    tontine_name: list?.name,
                                    tontine: list?.id,
                                  },
                                  callback: () => {},
                                })
                              );
                            }}
                          >
                            {t("ResendInviteLable")}
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className="pagination">
          <Tooltip title={t("previousLabel")}>
            <Button
              onClick={() => SetPageCount(pageCount - 10)}
              disabled={pageCount < 1 ? true : false}
            >
              <ArrowBackIosNewIcon />
            </Button>
          </Tooltip>
          <Typography variant="h7" padding={2.5}>
            {pageCount / 10 + 1}
          </Typography>
          <Tooltip title={t("nextLabel")}>
            <Button
              onClick={() => SetPageCount(pageCount + 10)}
              disabled={
                tontineInvites?.ListofInvites?.count <= pageCount + 10
                  ? true
                  : false
              }
            >
              <ArrowForwardIosIcon />
            </Button>
          </Tooltip>
        </Box>
      </Paper>
    </Box>
  );
}

export default Invitations;
