import { useEffect, lazy, Suspense } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import "./assets/scss/common.scss";
import HomePageComponent from "./layout/homePageComponent";
import MoneyPotsInnerPageComonent from "./layout/MoneyPotsInnerPageComonent";
import SignIn from "./layout/signInPageComponent";
// import SignUp from "./layout/signUpPageComponent";
import Forget from "../src/components/common/ForgetPassword/ResquestForPassword/index";
import NewPassword from "../src/components/common/ForgetPassword/NewPassword/index";
import Admin from "../src/components/admin/index";
import KittyTemplete from "../src/components/admin/KittyTemplete";
import KittyTable from "./components/Kitty/index";
import KittyCatTable from "./components/KittyCategory/index";
import HowItWorks from "./components/howItWorkBlock/index";
import CreateKitty from "./components/Kitty/CreateKitty";
// import KittyPage from "./layout/OurFeeComponent";
import AccountActivation from "../src/components/common/ActivationLink";
import KittyContributePayment from "./components/Kitty/KittyContributeComponent";
import { useAppDispatch, useAppSelector } from "./store/store";
import { UserProfileDetails } from "./store/thunk/UserProfileThunk";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setErrorAlert, setSuccessAlert } from "./store/reducer/AlertSlice";
import { Helmet } from "react-helmet";
import Update from "./components/Kitty/ourFee/Update";
import Settings from "./components/settings/index";
import TontineSettings from "./components/tontineSettings/index";
import MyAccount from "./components/User/MyAccount";
import VerifyDocument from "./components/User/VerifyDocument";
import InnerLowestFeesComponent from "./layout/InnerLowestFeesComponent";
import InnerServicePageComponent from "./layout/InnerServicePageComponent";
import VerifyEmail from "./components/SignUp/VerifyEmail/index";
import PrivateRoute from "./PrivateRoute";
import SuperAdminRoute from "./SuperAdminRoute";
import PublicRoute from "./PublicRoute";
import TontinePageComponent from "./layout/TontinePageComponent";
import TontineAdminComponent from "./layout/TontineAdminComponent";
// import ContributionComponent from "./layout/ContributionComponent";
import ParticipantPage from "./layout/tontinePage/tontineParticipants/index";
import StripePayments from "./layout/tontinePage/TontineStepper/index";
import AfterContributionComponent from "./layout/AfterContributionComponent";
// import AdminDashboardComponent from "./layout/AdminDashboardComponent";
import AdminKittyComponent from "./components/User/adminDashboard/Kitty/KittyList";
import AdminKittySettings from "./components/User/adminDashboard/Kitty/KittyList/AdminKittySettings";
import AdminKittyCategory from "./components/User/adminDashboard/Kitty/KittyCategory";
import AdminKittyContribute from "./components/User/adminDashboard/Kitty/KittyContribute";
import AdminTontineListComponent from "./components/User/adminDashboard/Tontine/TontineList";
import AdminEditTontine from "./components/User/adminDashboard/Tontine/TontineList/AdminEditTontine";
import AdminTontineUserListComponent from "./components/User/adminDashboard/Tontine/TontineUserList";
import AdminUserManagementList from "./components/User/adminDashboard/UserManagement";
import AdminUserManagementSettings from "./layout/AdminUserManagementSettings";
import AdminUserManagementDocument from "./components/User/VerifyDocument/AdminIndex";
import AdminUserTontineLogsList from "./components/User/adminDashboard/Logs/Tontine/TontineLogs";
import TontineCycleLogs from "./components/User/adminDashboard/Logs/Tontine/TontineCycleLogs";
import TontineCycleUserLogs from "./components/User/adminDashboard/Logs/Tontine/TontineCycleUserLogs";
import TontineSubscriptionLogs from "./components/User/adminDashboard/Logs/Tontine/TontineSubscriptionLogs";
import TontinePayoutLogs from "./components/User/adminDashboard/Logs/Tontine/TontinePayoutLogs";
import AdminKittyLogsList from "./components/User/adminDashboard/Logs/Kitty/KittyLogs";
import AdminKittyCategoryLogsList from "./components/User/adminDashboard/Logs/Kitty/KittyCategoryLogs";
import AdminKittyLikeLogsList from "./components/User/adminDashboard/Logs/Kitty/KittyLikeLogs";
import AdminKittyContributeLogsList from "./components/User/adminDashboard/Logs/Kitty/KittyContributeLogs";
import AdminKittyCommentLogsList from "./components/User/adminDashboard/Logs/Kitty/KittyCommentLogs";
import AdminKittyMessageLogsList from "./components/User/adminDashboard/Logs/Kitty/KittyMessageLogs";
import AdminKittySettingLogsList from "./components/User/adminDashboard/Logs/Kitty/KittySettingLogs";
import AdminKittyUpdateLogsList from "./components/User/adminDashboard/Logs/Kitty/KittyUpdateLogs";
import AdminKittyPayoutLogsList from "./components/User/adminDashboard/Logs/Kitty/KittyPayoutLogs";
import AdminKittyInviteLogsList from "./components/User/adminDashboard/Logs/Kitty/KittyInviteLogs";
import AdminKittyPaymentIntentLogsList from "./components/User/adminDashboard/Logs/Kitty/KittyPaymentIntentLogs";
import AdminUserLogsList from "./components/User/adminDashboard/Logs/User/UserLogs";
import AdminResetPasswordLogsList from "./components/User/adminDashboard/Logs/User/ResetPassword";
import TontineBeforeLoginComponent from "./layout/tontineBeforeLoginComponent";
import AdminUserTontineParticipateLogsList from "./components/User/adminDashboard/Logs/Tontine/TontineParticipateLogs";
import AdminUserTontineDocumentLogsList from "./components/User/adminDashboard/Logs/Tontine/TontineDocumentLogs";
import AdminUserTontineInviteLogsList from "./components/User/adminDashboard/Logs/Tontine/TontineInviteLogs";
import LegalImprint from "./components/common/Footer/LegalLayout";
import TermsAndConditions from "./components/common/Footer/TermsAndConditionsLayout";
import ContactUs from "./components/common/Footer/ContactUsLayout";
import AboutUs from "./components/common/Footer/AboutUsLayout";
// import PrivacyPolicy from "./components/common/Footer/PrivacyPolicyLayout";
import AdminBlockedUsersList from "./components/User/adminDashboard/UserManagement/BlockedUsers";
import KittySwapCreater from "./components/Kitty/KittySwapCreater/index";
import StripPayments from "./components/Payments/CheckOut";
import StripPaymentsSuccess from "./components/Payments/StripPayments";
import AdminMyAccount from "./components/User/adminDashboard/MyAccount";
import LoadingTransaction from "./components/Payments/LoadingPage";
import FAQorHelp from "./components/common/Footer/FAQ/index";
import AdminContributePayout from "./components/User/adminDashboard/ContributePayout/ContributePayout";
import AdminKittyCommission from "./components/User/adminDashboard/ContributePayout/KittyCommision/index";
import AdminTontineCommission from "./components/User/adminDashboard/TontinePayout/TontineCommision";
import AdminTontinePayout from "./components/User/adminDashboard/Tontine/TontinePayoutList";
import AdminTontineHoldTontine from "./components/User/adminDashboard/Tontine/TontineOnHold";
import ComingSoonComponent from "./layout/ComingSoonComponent";
import MyCardDetails from "./components/User/MyAccount/Tabs/CardDetails/MyCardDetails";

const KittyPage = lazy(() => import("./layout/OurFeeComponent"));
const SignUp = lazy(() => import("./layout/signUpPageComponent"));
const AdminDashboardComponent = lazy(() =>
  import("./layout/AdminDashboardComponent")
);
const PrivacyPolicy = lazy(() =>
  import("./components/common/Footer/PrivacyPolicyLayout")
);
const ContributionComponent = lazy(() =>
  import("./layout/ContributionComponent")
);

function App() {
  const session = useAppSelector((state) => state);
  const alert = useAppSelector((state) => state.AlertSlice);
  const dispatch = useAppDispatch();

  const validateStorage = (e) => {
    if (e.key === "is_superuser" && e.oldValue !== e.newValue) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener("storage", validateStorage);

    return () => {
      window.removeEventListener("storage", validateStorage);
    };
  }, []);

  const showToastMessage = (alert) => {
    switch (alert.status) {
      case true:
        toast.success(alert.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(setSuccessAlert({ status: "", message: "" }));
        break;
      case false:
        toast.error(alert.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(setErrorAlert({ status: "", message: "" }));
        break;
      case "pending":
        toast.loading(alert.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(setErrorAlert({ status: "", message: "" }));
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    if (session.userSlice.token) {
      dispatch(UserProfileDetails({}));
    }
  }, []);

  useEffect(() => {
    showToastMessage(alert);
    // eslint-disable-next-line
  }, [
    session.NewKittySlice,
    session.userSlice,
    session.newUserSlice,
    session.DetailsOfKitty,
    session.updateKittySlice,
    session.deleteKittySlice,
    session.deleteKittyCatSlice,
    session.NewKittyCatSlice,
    session.updateKittyCatSlice,
    session.forgetPasswordSlice,
    session.UserProfile,
    session.MyTontine,
    session.CheckOut,
  ]);

  return (
    <>
      <ToastContainer autoClose={3000} />
      <Router>
        <Helmet>
          <meta
            property="og:url"
            content={process.env.REACT_APP_REDIRECT_URL}
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Likelemba" />
          <meta
            name="description"
            content="Use Kitty & Tontine services for contribute money"
          />
          <meta
            property="og:description"
            content="Use Kitty & Tontine services for contribute money"
          />
          <meta name="keywords" content="kitty, tontine, contribution" />
        </Helmet>
        <Suspense
          fallback={
            <div sx={{ minHeight: "540px", background: "#f1efeb" }}></div>
          }
        >
          <Routes>
            <Route path="/coming-soon" element={<ComingSoonComponent />} />
            <Route path="/" element={<HomePageComponent />} />
            {/* <Route path="/money-pots" element={<MoneyPotsComponent />} /> */}
            <Route
              path="/money-pots-innerPage/:allkitty"
              element={<MoneyPotsInnerPageComonent />}
            />
            <Route path="/verify-user-account" element={<VerifyEmail />} />
            <Route
              path="/inner-lowest-fees"
              element={<InnerLowestFeesComponent />}
            />
            <Route
              path="/inner-service-page"
              element={<InnerServicePageComponent />}
            />

            <Route
              path="/sign-in/"
              element={<PublicRoute component={<SignIn />} />}
            />
            {/* <Route
            path="/sign-up/"
            element={<SocialUserRoute component={<SignUp />} />}
          /> */}
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/forget-password" element={<Forget />} />
            <Route path="/reset-password" element={<NewPassword />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route
              path="/admin"
              element={<PrivateRoute component={<Admin />} />}
            />
            <Route path="/my-kitty" element={<KittyTemplete />} />
            <Route
              path="/kitty-listing"
              element={<PrivateRoute component={<KittyTable />} />}
            />
            <Route
              path="/kitty-cat-listing"
              element={<PrivateRoute component={<KittyCatTable />} />}
            />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path={"/kittycreation"} element={<CreateKitty />} />
            <Route path={"/kittypage/:id/"} element={<KittyPage />} />
            <Route
              path={"/kittyupdate"}
              element={<Update showCancel={true} />}
            />
            <Route
              path={"/kitty-settings/:index/:id"}
              element={<PrivateRoute component={<Settings />} />}
            />
            <Route
              path={"/tontine_settings/:index/:id"}
              element={<PrivateRoute component={<TontineSettings />} />}
            />
            <Route
              path={"/account/:id"}
              element={<PrivateRoute component={<MyAccount />} />}
            />

            <Route
              path={"/contribute-payment/:id"}
              element={<KittyContributePayment />}
            />
            <Route path={"/legal-mentions"} element={<LegalImprint />} />
            <Route
              path={"/terms-and-conditions"}
              element={<TermsAndConditions />}
            />
            <Route path={"/PrivacyPolicy"} element={<PrivacyPolicy />} />
            <Route path={"/FAQ"} element={<FAQorHelp />} />
            <Route path="*" element={<HomePageComponent />} />
            <Route path="/tontine-admin" element={<TontineAdminComponent />} />
            <Route path="/tontine" element={<TontinePageComponent />} />
            <Route
              path="/how-to-create-tontine"
              element={<TontineBeforeLoginComponent />}
            />
            <Route
              path="/activation-account/:email"
              element={<AccountActivation />}
            />
            <Route path="/tontine/:id" element={<ContributionComponent />} />
            <Route path="/checkout/" element={<StripPayments />} />
            <Route
              path="/checkout/:emailId"
              element={<StripPaymentsSuccess />}
            />
            <Route path="/checkout/success" element={<LoadingTransaction />} />
            <Route
              path="/tontine/participantpage/accept/"
              element={<ParticipantPage />}
            />
            <Route
              path="/tontine/participantpage/reject/"
              element={<ParticipantPage />}
            />
            <Route path="/payments/" element={<StripePayments />} />
            <Route
              path={"/verify-documents"}
              element={<PrivateRoute component={<VerifyDocument />} />}
            />
            <Route
              path="/after-contribution/:id/:token"
              element={<AfterContributionComponent />}
            />
            <Route
              path="/kitty-owner-change/accept"
              element={<KittySwapCreater />}
            />
            <Route
              path="/kitty-owner-change/reject"
              element={<KittySwapCreater />}
            />
            <Route
              path="/admin-dashboard"
              element={
                <SuperAdminRoute component={<AdminDashboardComponent />} />
              }
            />
            <Route
              path="/admin-kitty"
              element={<SuperAdminRoute component={<AdminKittyComponent />} />}
            />
            <Route
              path={"/admin-kitty-settings/:index/:id"}
              element={<SuperAdminRoute component={<AdminKittySettings />} />}
            />
            <Route
              path="/admin-kitty-categories"
              element={<SuperAdminRoute component={<AdminKittyCategory />} />}
            />
            <Route
              path="/admin-kitty-commission"
              element={<SuperAdminRoute component={<AdminKittyCommission />} />}
            />
            <Route
              path="/admin-tontine-list"
              element={
                <SuperAdminRoute component={<AdminTontineListComponent />} />
              }
            />
            <Route
              path={"/admin-tontine-edit/:index/:id"}
              element={<SuperAdminRoute component={<AdminEditTontine />} />}
            />
            <Route
              path="/admin-tontine-assign-user"
              element={
                <SuperAdminRoute
                  component={<AdminTontineUserListComponent />}
                />
              }
            />
            <Route
              path="/admin-user-management"
              element={
                <SuperAdminRoute component={<AdminUserManagementList />} />
              }
            />
            <Route
              path="/admin-blocked-users"
              element={
                <SuperAdminRoute component={<AdminBlockedUsersList />} />
              }
            />
            <Route
              path="/admin-user-settings-management/:id"
              element={
                <SuperAdminRoute component={<AdminUserManagementSettings />} />
              }
            />
            <Route
              path="/admin-document-management"
              element={
                <SuperAdminRoute component={<AdminUserManagementDocument />} />
              }
            />
            <Route
              path="/admin-kitty-logs"
              element={<SuperAdminRoute component={<AdminKittyLogsList />} />}
            />
            <Route
              path="/admin-kitty-category-logs"
              element={
                <SuperAdminRoute component={<AdminKittyCategoryLogsList />} />
              }
            />
            <Route
              path="/admin-kitty-like-logs"
              element={
                <SuperAdminRoute component={<AdminKittyLikeLogsList />} />
              }
            />
            <Route
              path="/admin-kitty-contribute-logs"
              element={
                <SuperAdminRoute component={<AdminKittyContributeLogsList />} />
              }
            />
            <Route
              path="/admin-kitty-comment-logs"
              element={
                <SuperAdminRoute component={<AdminKittyCommentLogsList />} />
              }
            />
            <Route
              path="/admin-kitty-message-logs"
              element={
                <SuperAdminRoute component={<AdminKittyMessageLogsList />} />
              }
            />
            <Route
              path="/admin-kitty-setting-logs"
              element={
                <SuperAdminRoute component={<AdminKittySettingLogsList />} />
              }
            />
            <Route
              path="/admin-kitty-update-logs"
              element={
                <SuperAdminRoute component={<AdminKittyUpdateLogsList />} />
              }
            />
            <Route
              path="/admin-kitty-payout-logs"
              element={
                <SuperAdminRoute component={<AdminKittyPayoutLogsList />} />
              }
            />
            <Route
              path="/admin-kitty-invite-logs"
              element={
                <SuperAdminRoute component={<AdminKittyInviteLogsList />} />
              }
            />
            <Route
              path="/admin-payment-intents-logs"
              element={
                <SuperAdminRoute
                  component={<AdminKittyPaymentIntentLogsList />}
                />
              }
            />
            <Route
              path="/admin-user-logs"
              element={<SuperAdminRoute component={<AdminUserLogsList />} />}
            />
            <Route
              path="/admin-reset-password-logs"
              element={
                <SuperAdminRoute component={<AdminResetPasswordLogsList />} />
              }
            />
            <Route
              path="/admin-tontine-logs"
              element={
                <SuperAdminRoute component={<AdminUserTontineLogsList />} />
              }
            />
            <Route
              path="/admin-tontine-cycle"
              element={<SuperAdminRoute component={<TontineCycleLogs />} />}
            />
            <Route
              path="/admin-tontine-cycle-user-logs"
              element={<SuperAdminRoute component={<TontineCycleUserLogs />} />}
            />
            <Route
              path="/admin-tontine-subscription-logs"
              element={
                <SuperAdminRoute component={<TontineSubscriptionLogs />} />
              }
            />
            <Route
              path="/admin-tontine-payout-logs"
              element={<SuperAdminRoute component={<TontinePayoutLogs />} />}
            />
            <Route
              path="/admin-tontine-participant"
              element={
                <SuperAdminRoute
                  component={<AdminUserTontineParticipateLogsList />}
                />
              }
            />
            <Route
              path="/admin-tontine-document"
              element={
                <SuperAdminRoute
                  component={<AdminUserTontineDocumentLogsList />}
                />
              }
            />
            <Route
              path="/admin-tontine-invite"
              element={
                <SuperAdminRoute
                  component={<AdminUserTontineInviteLogsList />}
                />
              }
            />
            <Route
              path="/admin-profile"
              element={<SuperAdminRoute component={<AdminMyAccount />} />}
            />
            <Route
              path="/admin-kitty-contribution"
              element={<SuperAdminRoute component={<AdminKittyContribute />} />}
            />
            <Route
              path="/admin-contribution-payout"
              element={
                <SuperAdminRoute component={<AdminContributePayout />} />
              }
            />
            <Route path={"/user-card-details"} element={<MyCardDetails />} />
            <Route
              path="/admin-tontine-commission"
              element={
                <SuperAdminRoute component={<AdminTontineCommission />} />
              }
            />
            <Route
              path="/admin-tontine-payout"
              element={<SuperAdminRoute component={<AdminTontinePayout />} />}
            />
            <Route
              path="/admin-paused-tontine"
              element={
                <SuperAdminRoute component={<AdminTontineHoldTontine />} />
              }
            />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
