import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  Tooltip,
  Paper,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Accordion from "@mui/material/Accordion";
import { makeStyles } from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import {
  UpdateKittyContributePayout,
  getListofKittyContributePayoutList,
} from "../../../../../store/thunk/Kitty/KittyDetailsThunk";
import moment from "moment";
import RejectDialogComponent from "../../../VerifyDocument/RejectDialog";
import CustomMPagination from "../../../../common/CustomMPagination";

const useStyles = makeStyles((theme) => ({
  summaryRoot: {
    flexDirection: "row-reverse",
    "& .MuiButtonBase-root": {
      marginRight: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

export default function AdminKittyContributeComponent() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = useState(0);
  const [pageCountShow, SetPageCountShow] = useState(0);
  const [recordLimit, SetRecordLimit] = useState(10);
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [requestData, setRequestData] = useState({
    request: {},
    documentId: 0,
  });
  const adminKittyContributePayoutData = useAppSelector(
    (state) => state.DetailsOfKitty
  );

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    requestList();
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, recordLimit]);

  const handleCloseReasonDialog = () => {
    setShowRejectDialog(false);
  };

  const requestList = () => {
    dispatch(
      getListofKittyContributePayoutList({
        limit: recordLimit,
        offSet: pageCount,
      })
    );
  };

  const saveRejectData = () => {
    setRequestData({
      request: {
        ...requestData.request,
        reason: rejectReason,
      },
      documentId: requestData.documentId,
    });

    dispatch(
      UpdateKittyContributePayout({
        request: {
          withdrawal_status: "FAILED",
          reason: rejectReason,
          payout_date: moment().format("YYYY-MM-DD HH:mm:ss.SSSSSSZ"),
        },
        id: requestData?.documentId,
        callback: () => requestList(),
      })
    );
    setShowRejectDialog(false);
  };

  const FailPayoutApproval = (data, status) => {
    if (status === "ACCEPTED") {
      setConfirmMessage(t("acceptConfirmLabel"));
    } else if (status === "REJECTED") {
      setConfirmMessage(t("rejectConfirmLabel"));
    }
    setShowRejectDialog(true);

    let request = {
      status,
    };

    if (status === "ACCEPTED") {
      request.is_verified = true;
    }

    setRequestData({
      request,
      documentId: data?.id,
    });
  };

  return (
    <>
      <Box className="kittyTable" sx={{ minHeight: "90vh" }}>
        <Box sx={{ borderRadius: 0, padding: { md: 2, xs: 1 } }}>
          <Box sx={{ minHeight: "72vh" }}>
            <Paper
              sx={{
                width: { md: "100%", xs: "auto" },
                padding: 2,
                marginBottom: "10px",
                background: "rgba(89, 32, 145, 0.1411764706)",
              }}
            >
              <Box
                className="tableTitle"
                style={{
                  textTransform: "uppercase",
                  // marginTop: "10px",
                }}
              >
                {t("contributerPayoutListLable")}
              </Box>
            </Paper>
            <Accordion
              expanded={false}
              sx={{
                backgroundColor: "#2a2828",
                color: "#fff",
                marginBottom: "10px",
                borderRadius: "4px",
              }}
            >
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={classes.summaryRoot}
              >
                <Typography
                  sx={{ marginLeft: "33px", width: "25%", flexShrink: 0 }}
                >
                  {t("payoutKittyNameLbl")}
                </Typography>
                <Typography sx={{ width: "25%", flexShrink: 0 }}>
                  {t("payoutRequestUserNameLbl")}
                </Typography>
                <Typography sx={{ width: "25%", flexShrink: 0 }}>
                  {t("payoutRequestDateLbl")}
                </Typography>
                <Typography sx={{ width: "25%", flexShrink: 0 }}>
                  {t("payoutRequestAmountLbl")}
                </Typography>
              </AccordionSummary>
            </Accordion>
            {adminKittyContributePayoutData?.adminKittyContributePayoutList?.results?.map(
              (item) => (
                <Accordion
                  expanded={expanded === `panel${item?.id}`}
                  onChange={handleChange(`panel${item?.id}`)}
                  key={item?.id}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${item?.id}bh-content`}
                    id={`panel${item?.id}bh-header`}
                    className={classes.summaryRoot}
                  >
                    <Typography
                      sx={{ marginLeft: "10px", width: "25%", flexShrink: 0 }}
                    >
                      {item?.kitty.name}
                    </Typography>
                    <Grid container>
                      <Grid item xs={4}>
                        {" "}
                        <Typography sx={{ color: "text.secondary" }}>
                          {`${item?.user.first_name} ${item?.user.name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography sx={{ color: "text.secondary" }}>
                          {moment(item?.created_at).format(
                            localStorage.getItem("language") == "en"
                              ? "YYYY-MM-DD"
                              : "DD-MM-YYYY"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography sx={{ color: "text.secondary" }}>
                          € {item?.payout_amount}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      <Typography>{`${t("payoutRequestIBANFieldLbl")} : ${
                        item?.iban
                      }`}</Typography>
                    </Box>
                    <Box>
                      <Typography>{`${t("payoutStatusFieldLbl")} : ${
                        item?.withdrawal_status
                      }`}</Typography>
                    </Box>
                    <Box>
                      <Typography>{`${t("ActualAmountLable")} : €${
                        item?.actual_amount
                      }`}</Typography>
                    </Box>
                    <Box>
                      <Typography>{`${t(
                        "payoutPlatformCommissionFieldLbl"
                      )} : €${item?.payout_commissions}`}</Typography>
                    </Box>
                    {item?.beneficiary_details.length > 0 ? (
                      <>
                        <Box>
                          <Typography>{`${t(
                            "payoutBeneficiaryIBANFieldLbl"
                          )}: ${
                            item?.beneficiary_details[0]?.beneficiary_iban
                          }`}</Typography>
                        </Box>
                        <Box>
                          <Typography>{`${t(
                            "payoutBeneficiaryEmailFieldLbl"
                          )} : ${
                            item?.beneficiary_details[0]?.beneficiary_email
                          }`}</Typography>
                        </Box>
                        <Box>
                          <Typography>{`${t(
                            "payoutBeneficiaryNameFieldLbl"
                          )} : ${
                            item?.beneficiary_details[0]?.beneficiary_name
                          }`}</Typography>
                        </Box>
                      </>
                    ) : null}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        onClick={() => {
                          FailPayoutApproval(item, "FAILED");
                        }}
                        sx={{
                          background: "#ed2b2b",
                          color: "#fff",
                          display: "block",
                          padding: "8px 20px",
                          fontWeight: "600",
                          letterSpacing: " 2px",
                          marginTop: "15px",
                          mr: 1,
                          border: "1px solid #592091",
                          "&:hover": {
                            color: "#592091",
                            background: "#fff",
                          },
                        }}
                      >
                        {t("payoutFailedBtnLbl")}
                      </Button>
                      <Button
                        onClick={() => {
                          dispatch(
                            UpdateKittyContributePayout({
                              request: {
                                withdrawal_status: "SUCCESS",
                                payout_date: moment().format(
                                  "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
                                ),
                              },
                              id: item?.id,
                              callback: () => requestList(),
                            })
                          );
                        }}
                        sx={{
                          background: "#592091",
                          color: "#fff",
                          display: "block",
                          padding: "8px 20px",
                          fontWeight: "600",
                          letterSpacing: " 2px",
                          marginTop: "15px",
                          border: "1px solid #592091",
                          "&:hover": {
                            color: "#592091",
                            background: "#fff",
                          },
                        }}
                      >
                        {t("payoutPayoutDoneBtnLbl")}
                      </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )
            )}
          </Box>
          <CustomMPagination
            pageCount={pageCount}
            SetPageCount={SetPageCount}
            recordLimit={recordLimit}
            pageCountShow={pageCountShow}
            SetRecordLimit={SetRecordLimit}
            numRecords={
              adminKittyContributePayoutData?.adminKittyContributePayoutList
                ?.num_records
            }
            numCount={
              adminKittyContributePayoutData?.adminKittyContributePayoutList
                ?.count
            }
          />
        </Box>
      </Box>

      {showRejectDialog && (
        <RejectDialogComponent
          reasondata={rejectReason}
          dataFunc={setRejectReason}
          callBackFunc={saveRejectData}
          handleClose={handleCloseReasonDialog}
        />
      )}
    </>
  );
}
