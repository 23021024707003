import React from "react";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import ContributePayment from "./ContributePayment";

function KittyContributeComponent() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <ContributePayment />
      <Footer />
    </>
  );
}
export default KittyContributeComponent;
