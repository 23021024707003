import * as React from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Verify from "./VerifyEmail";

export default function Admin() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <Header />
            <Verify/>
            <Footer />
        </>
    );
}
