import React, { useState } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import "./MyCardDetails.scss";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { InsertNewUserCardDetails } from "../../../../../store/thunk/Payments/CheckOutThunk";
import { useTranslation } from "react-i18next";

const CreditCard = (props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [number, SetNumber] = useState("");
  const [name, SetName] = useState("");
  const [month, SetMonth] = useState("");
  const [year, SetYear] = useState("");
  const [cvc, SetCvc] = useState("");
  const [focus, SetFocus] = useState("");

  const profileDetails = useAppSelector((state) => state?.UserProfile?.details);

  const clearData = () => {
    SetNumber("");
    SetName("");
    SetMonth("");
    SetYear("");
    SetCvc("");
    SetFocus("");
  };

  const handleSubmit = () => {
    dispatch(
      InsertNewUserCardDetails({
        request: {
          number,
          exp_month: month,
          exp_year: year,
          cvc,
          customer_id: profileDetails?.stripe_customer_id,
        },
        callback: () => {
          clearData();
          if (props.callback) {
            props.callback();
          }
        },
      })
    );
  };

  const currentYear = new Date().getFullYear();
  return (
    <Box className="my-card-details-payment"
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container sx={{ display:{ md:"grid",xs:""}, placeContent: "center" }}>
        <Box
          clasName="rccs__card rccs__card--unknown"
          sx={{
            margin: "12px 0",
            padding: "0 8px",
          }}
        >
          <Cards
            number={number}
            name={name}
            expiry={`${month}${year}`}
            cvc={cvc}
            focused={focus}
          />
        </Box>
        <Grid
          item
          xs={12}
          sx={{
            margin: "12px 0",
            padding: "0 8px",
          }}
        >
          <TextField
            id="Card_Number"
            label={t("cardNumberLabel")}
            variant="outlined"
            size="small"
            fullWidth
            value={number}
            name="number"
            placeholder="0000 0000 0000 0000"
            onChange={(e) => {
              SetNumber(e.target.value);
            }}
            onFocus={(e) => SetFocus(e.target.name)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            margin: "12px 0",
            padding: "0 8px",
          }}
        >
          <TextField
            id="Card_holder_name"
            label={t("CardHolderNameLable")}
            variant="outlined"
            name="name"
            size="small"
            fullWidth
            value={name}
            placeholder="John Doe"
            onChange={(e) => {
              SetName(e.target.value);
            }}
            onFocus={(e) => SetFocus(e.target.name)}
          />
        </Grid>
        <Grid container>
          <Grid
            item
            xs={5.5}
            sx={{
              margin: "12px 0",
              padding: "0 8px",
            }}
          >
            <TextField
              id="expiry_month"
              label={t("ExpiryMonthLable")}
              variant="outlined"
              name="expiry"
              size="small"
              fullWidth
              value={month}
              placeholder="01"
              onChange={(e) => {
                if (e.target.value.length < 3 && e.target.value < 13) {
                  SetMonth(e.target.value);
                }
              }}
              onFocus={(e) => SetFocus(e.target.name)}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid
            item
            xs={5.5}
            sx={{
              margin: "12px 0",
              padding: "0 8px",
            }}
          >
            <TextField
              id="expiry_year"
              label={t("ExpiryYearLable")}
              variant="outlined"
              value={year}
              name="expiry"
              size="small"
              fullWidth
              placeholder={currentYear}
              onChange={(e) => {
                if (e.target.value.length < 5) {
                  SetYear(e.target.value);
                }
              }}
              onFocus={(e) => SetFocus(e.target.name)}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            margin: "12px 0",
            padding: "0 8px",
          }}
        >
          <TextField
            id="card_cvv"
            label="CVC/CVV"
            variant="outlined"
            name="cvc"
            size="small"
            fullWidth
            value={cvc}
            onChange={(e) => {
              SetCvc(e.target.value);
            }}
            onFocus={(e) => SetFocus(e.target.name)}
          />
        </Grid>
        <Grid item xs={12} className="save-a-card-grid">
          <Button  className="save-a-card-btn" onClick={handleSubmit}>{t("saveLabel")}</Button>
        </Grid>{" "}
      </Grid>
    </Box>
  );
};
export default CreditCard;

