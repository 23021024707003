import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { PersonIcon } from "@radix-ui/react-icons";
import { Box, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  ContributeWithUserData,
  updateContributeUser,
} from "../../../store/thunk/newUserThunk";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ResponsiveDialog() {
  const { id } = useParams();
  const { token } = useParams();
  const { t } = useTranslation();
  const contributeInfo = useAppSelector(
    (state) => state.newUserSlice?.contributeResponse
  );
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [fullName, setFullName] = React.useState({
    first_name: contributeInfo?.user?.first_name,
    name: contributeInfo?.user?.name,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
    setFullName({
      first_name: contributeInfo.user.first_name,
      name: contributeInfo.user.name,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setFullName({
      first_name: contributeInfo.user.first_name,
      name: contributeInfo.user.name,
    });
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        sx={{
          width: "100%",
          background: "#d1d1d1",
          color: "gray",
          fontWeight: "600",
          "&:hover": {
            background: "#d1d1d1",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PersonIcon sx={{ mr: 1 }} />
          {t("afterContribution.11")}
        </Box>
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Change the name"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Change the name used for your contribution.
          </DialogContentText>
          <Box>
            <TextField
              size="small"
              value={fullName.first_name}
              onChange={(e) => {
                setFullName({
                  ...fullName,
                  first_name: e.target.value,
                });
              }}
              id="outlined-basic-first_name"
              label={t("createFirstNameLable")}
              variant="outlined"
              sx={{ m: 1 }}
            />
            <TextField
              size="small"
              value={fullName.name}
              onChange={(e) => {
                setFullName({
                  ...fullName,
                  name: e.target.value,
                });
              }}
              id="outlined-basic-name"
              label={t("myAccountLastNameLable")}
              variant="outlined"
              sx={{ m: 1 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              dispatch(
                updateContributeUser({
                  id: contributeInfo?.user?.id,
                  data: fullName,
                  token: token,
                  callback: () => {
                    dispatch(ContributeWithUserData(id));
                  },
                })
              );
            }}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
