import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Paper,
  Box,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Viewer from "react-viewer";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  getUserDocuments,
  approveRejectDocument,
} from "../../../store/thunk/Tontine/MytontineThunk";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { createTheme } from "react-data-table-component";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ConfirmDialogComponent from "../../../components/common/ConfirmDialog";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import RejectDialogComponent from "./RejectDialog";
import { countries } from "../../SignUp/Country";
import PDFViewerDialogComponent from "./PDFViewerDialog";
import "./verifydocuments.scss";
import CustomMPagination from "../../common/CustomMPagination";

function VerifyDocuments() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pageCount, SetPageCount] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [showImageViewer, setShowImageViewer] = React.useState(false);
  const [showPdfViewer, setShowPdfViewer] = React.useState(false);
  const [activeImageIndex, setActiveImageIndex] = React.useState(null);
  const [imageList, setImageList] = useState([]);
  const [pdfPath, setPdfPath] = useState("");
  const [pageCountShow, SetPageCountShow] = useState(0);
  const [recordLimit, SetRecordLimit] = useState(10);
  const [requestData, setRequestData] = useState({
    request: {},
    documentId: 0,
  });

  const userDocumentsList = useAppSelector(
    (state) => state?.MyTontine?.userDocumentsList
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    dispatch(getUserDocuments({ limit: recordLimit, offSet: pageCount }));
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, recordLimit]);

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );
  const redirectPage = () => {
    window.location.pathname.includes("admin-document")
      ? navigate("/admin-user-management")
      : navigate("/admin");
  };

  const getFileName = (imagePath) => {
    const filePath = imagePath?.substring(imagePath.lastIndexOf("/") + 1);
    const filename = filePath?.split("?")[0];
    return filename;
  };

  const getCountryName = (countryCode) => {
    const countryObj = countries.filter(function (entry) {
      return entry.code === countryCode;
    });

    const countryName = countryObj[0] ? countryObj[0]?.label : "";
    return countryName;
  };

  const getImageCount = (imageUrl) => {
    const imageObj = imageList.filter(function (entry) {
      return entry.src === imageUrl;
    });

    return imageObj.length || 0;
  };

  const confirmDocumentApproval = (data, status) => {
    if (status === "ACCEPTED") {
      setConfirmMessage(t("acceptConfirmLabel"));
    } else if (status === "REJECTED") {
      setConfirmMessage(t("rejectConfirmLabel"));
    }

    setShowConfirmDialog(true);

    let request = {
      status,
    };

    if (status === "ACCEPTED") {
      request.is_verified = true;
    }

    setRequestData({
      request,
      documentId: data?.id,
    });
  };

  const acceptRejectDocument = () => {
    dispatch(
      approveRejectDocument({
        request: requestData.request,
        documentId: requestData.documentId,
        limit: recordLimit,
        offSet: pageCount,
        callbackPayout: () => {
          dispatch(getUserDocuments({ limit: recordLimit, offSet: pageCount }));
          setRequestData({
            request: {},
            documentId: 0,
          });
        },
      })
    );
  };

  const saveRejectData = () => {
    setRequestData({
      request: {
        ...requestData.request,
        reason: rejectReason,
      },
      documentId: requestData.documentId,
    });

    dispatch(
      approveRejectDocument({
        request: {
          ...requestData.request,
          reason: rejectReason,
        },
        documentId: requestData.documentId,
        limit: recordLimit,
        offSet: pageCount,
        callbackPayout: () => {
          dispatch(getUserDocuments({ limit: recordLimit, offSet: pageCount }));
          setRequestData({
            request: {},
            documentId: 0,
          });
        },
      })
    );
    setShowRejectDialog(false);
  };

  const confirmSuccessCallback = () => {
    if (requestData?.request?.status === "REJECTED") {
      setShowRejectDialog(true);
    } else {
      acceptRejectDocument();
    }
    setShowConfirmDialog(false);
  };

  const confirmCancelCallback = () => {
    setShowConfirmDialog(false);
    setRequestData({
      request: {},
      documentId: 0,
    });
  };

  const handleCloseReasonDialog = () => {
    setShowRejectDialog(false);
  };

  const hideImageViewer = () => {
    setShowImageViewer(false);
  };

  const viewImageViewer = (imagePath) => {
    const imageIndex = imageList.findIndex((x) => x.src === imagePath);
    setActiveImageIndex(imageIndex);
    setShowImageViewer(true);
  };

  const hidePdfDocumentViewer = (event, reason) => {
    if (reason && (reason == "backdropClick" || reason == "escapeKeyDown")) {
      return;
    }
    setShowPdfViewer(false);
    setPdfPath("");
  };

  return (
    <>
      <Box className="kittyTable">
        <Box sx={{ borderRadius: 0 }}>
          <Grid
            container
            sx={{ display: { md: "flex", xs: "block" }, textAlign: "left" }}
          >
            <Paper
              sx={{
                width: { md: "100%", xs: "auto" },
                padding: 2,
                margin: { md: "16px 16px 0px 16px", xs: "10px" },
                background: "rgba(89, 32, 145, 0.1411764706)",
              }}
            >
              <Box
                className="tableTitle"
                style={{
                  textTransform: "uppercase",
                  // marginTop: "10px",
                }}
              >
                {t("documentListinSection.0")}
              </Box>
            </Paper>
            {userDocumentsList?.results?.map((item) => (
              <Grid item md={12} key={item.id}>
                <Paper
                  sx={{
                    p: 2,
                    border: "1px solid #ddd",
                    margin: { md: "16px 16px 0px 16px", xs: "10px" },
                  }}
                >
                  <Grid container sx={{ display: { md: "flex", xs: "block" } }}>
                    <Grid item md={4} sx={{ p: { md: "0 16px", xs: 0 } }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignContent: "center",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: "600", color: "#555", mb: 1 }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#555 !important",
                          mb: "8px !important",
                          fontSize: "15px !important",
                          fontWeight: "600 !important",
                        }}
                      >
                        {t("nameLabel")}:{" "}
                        <span style={{ fontSize: "15px", fontWeight: "100" }}>
                          {`${item?.user?.first_name || ""} ${
                            item?.user?.name || ""
                          }`}
                        </span>
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#555 !important",
                          mb: "8px !important",
                          fontSize: "15px !important",
                          fontWeight: "600 !important",
                        }}
                      >
                        {t("emailLabel")} :{" "}
                        <span style={{ fontSize: "15px", fontWeight: "100" }}>
                          {`${item?.user?.email || ""}`}
                        </span>
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#555 !important",
                          mb: "8px !important",
                          fontSize: "15px !important",
                          fontWeight: "600 !important",
                        }}
                      >
                        {t("addressLabel")} :{" "}
                        <span style={{ fontSize: "15px", fontWeight: "100" }}>
                          {`${item?.user?.address || ""}`}
                        </span>
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#555 !important",
                          mb: "8px !important",
                          fontSize: "15px !important",
                          fontWeight: "600 !important",
                        }}
                      >
                        {t("cityLabel")} :{" "}
                        <span style={{ fontSize: "15px", fontWeight: "100" }}>
                          {`${item?.user?.city || ""}`}
                        </span>
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#555 !important",
                          mb: "8px !important",
                          fontSize: "15px !important",
                          fontWeight: "600 !important",
                        }}
                      >
                        {t("countryLabel")} :{" "}
                        <span style={{ fontSize: "15px", fontWeight: "100" }}>
                          {getCountryName(item?.user?.country)}
                        </span>
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#555 !important",
                          mb: "8px !important",
                          fontSize: "15px !important",
                          fontWeight: "600 !important",
                        }}
                      >
                        {t("postalCodeLabel")} :{" "}
                        <span style={{ fontSize: "15px", fontWeight: "100" }}>
                          {`${item?.user?.postal_code || ""}`}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sx={{
                        paddingLeft: { md: "20px", xs: "0px" },
                      }}
                    >
                      <Box>
                        {item?.image_for_tontine_document?.map(
                          (documentData) => {
                            const fileExt = getFileName(documentData?.image)
                              .split(".")
                              .pop();

                            if (!getImageCount(documentData?.image)) {
                              if (fileExt !== "pdf") {
                                setImageList([
                                  ...imageList,
                                  {
                                    src: documentData?.image,
                                    alt: getFileName(documentData?.image),
                                  },
                                ]);
                              }
                            }

                            return fileExt === "pdf" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "left",
                                  pl: { md: "8px", xs: 0 },
                                  alignItems: "center",
                                }}
                                key={documentData?.id}
                              >
                                <InsertDriveFileIcon
                                  sx={{
                                    fontSize: "20px !important",
                                    mr: "5px !important",
                                  }}
                                />
                                <span
                                  onClick={() => {
                                    setPdfPath(documentData?.image);
                                    setShowPdfViewer(true);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {getFileName(documentData?.image)}
                                </span>
                                <Tooltip title={t("viewLable")} placement="top">
                                  <VisibilityIcon
                                    sx={{
                                      pl: { md: "5px", xs: 0 },
                                      fill: "#592091",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setPdfPath(documentData?.image);
                                      setShowPdfViewer(true);
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "left",
                                  pl: { md: "8px", xs: 0 },
                                  alignItems: "center",
                                }}
                                key={documentData?.id}
                              >
                                <InsertDriveFileIcon
                                  sx={{
                                    fontSize: "20px !important",
                                    mr: "5px !important",
                                  }}
                                />
                                <Typography
                                  variant="body"
                                  className=""
                                  sx={{
                                    cursor: "pointer",
                                    wordBreak: "break-all",
                                    fontSize: { md: "16px", xs: "13px" },
                                  }}
                                  onClick={() =>
                                    viewImageViewer(documentData?.image)
                                  }
                                >
                                  {getFileName(documentData?.image)}
                                </Typography>
                                <Tooltip title={t("viewLable")} placement="top">
                                  <VisibilityIcon
                                    sx={{
                                      pl: { md: "5px", xs: 0 },
                                      fill: "#592091",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      viewImageViewer(documentData?.image)
                                    }
                                  />
                                </Tooltip>
                              </Box>
                            );
                          }
                        )}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      sx={{
                        paddingLeft: { md: "20px", xs: 0 },
                        borderLeft: { md: "1px solid #ddd", xs: "0px" },
                        pt: { md: 0, xs: "20px" },
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: "center",
                          background: "#008000",
                          marginBottom: "10px",
                          padding: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "3px",
                          color: "#fff",
                          "&:hover": {
                            background: "#0b990b",
                          },
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          confirmDocumentApproval(item, "ACCEPTED")
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: " center",
                            justifyContent: "center",
                            // marginBottom: "6px",
                            fontWeight: " 600",
                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                        >
                          <DoneIcon sx={{ color: "#fff ", mr: 1 }} />
                          {t("documentApproveLabel")}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          textAlign: "center",
                          background: "#f5564a",
                          marginBottom: "10px",
                          padding: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "3px",
                          color: "#fff",
                          cursor: "pointer",
                          "&:hover": {
                            background: "#f56b61",
                          },
                        }}
                        onClick={() =>
                          confirmDocumentApproval(item, "REJECTED")
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: " center",
                            justifyContent: "center",
                            // marginBottom: "6px",
                            fontWeight: " 600",
                            fontSize: "18px",
                          }}
                        >
                          <CloseIcon sx={{ color: "#fff ", mr: 1 }} />
                          {t("documentRejecteLabel")}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ m: "15px 10px" }}>
            <CustomMPagination
              pageCount={pageCount}
              SetPageCount={SetPageCount}
              recordLimit={recordLimit}
              pageCountShow={pageCountShow}
              SetRecordLimit={SetRecordLimit}
              numRecords={userDocumentsList?.num_records}
              numCount={userDocumentsList?.count}
            />
          </Box>
        </Box>
        {showConfirmDialog && (
          <ConfirmDialogComponent
            confirmSuccessFunc={confirmSuccessCallback}
            confirmCancelFunc={confirmCancelCallback}
            title={t("confirmationLabel")}
            contentText={confirmMessage}
            dialogStatus={showConfirmDialog}
          />
        )}

        {showRejectDialog && (
          <RejectDialogComponent
            reasondata={rejectReason}
            dataFunc={setRejectReason}
            callBackFunc={saveRejectData}
            handleClose={handleCloseReasonDialog}
          />
        )}
        {showImageViewer && (
          <Viewer
            visible={showImageViewer}
            onClose={hideImageViewer}
            showTotal={false}
            noNavbar={true}
            noImgDetails={true}
            attribute={true}
            rotatable={true}
            changeable={false}
            disableKeyboardSupport={true}
            scalable={false}
            images={imageList}
            activeIndex={activeImageIndex}
          />
        )}
        {showPdfViewer && (
          <PDFViewerDialogComponent
            dialogStatus={showPdfViewer}
            handleClose={hidePdfDocumentViewer}
            pdfSrc={pdfPath}
          />
        )}
      </Box>
    </>
  );
}

export default VerifyDocuments;
