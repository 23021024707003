import * as React from "react";
import { Box, Container, Paper, Typography, Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import OtherImage from "../../../assets/Images/OtherOther.webp";
import VerticalTabs from "./VerticalTabs";
import ProfileImagePopup from "./ProfileImage";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../store/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  CheckUserDocumentStatus,
  CheckUserDocumentVerifyStatus,
} from "../../../store/thunk/Tontine/MytontineThunk";

export default function Settings(props) {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showProfileImagePopup, setShowProfileImagePopup] = useState(false);

  const [profileDetails, setProfileDetails] = useState({});
  const loginUserDetails = useAppSelector(
    (state) => state?.UserProfile?.details
  );
  const tempUserDetails = useAppSelector(
    (state) => state?.UserProfile?.tempDetails
  );
  const documentStatus = useAppSelector((state) => state.MyTontine);

  useEffect(() => {
    setProfileDetails(
      id == "my" || (props.calledFor && props.calledFor === "admin_profile")
        ? loginUserDetails
        : tempUserDetails
    );
  }, [tempUserDetails, loginUserDetails]);

  const profileImagePopup = () => {
    setShowProfileImagePopup(!showProfileImagePopup);
    dispatch(CheckUserDocumentVerifyStatus({}));
  };

  React.useEffect(() => {
    dispatch(CheckUserDocumentStatus({}));
  }, []);

  return (
    <Box
      className="howItWorkBlock"
      sx={{
        pt: props.calledFor && props.calledFor === "admin_profile" ? 1 : 10,
        pb: 5,
      }}
    >
      <Container>
        <Typography variant="h5" className="tontine-title">
          {t("myAccountLable")}
        </Typography>
        <Box>
          <Paper
            sx={{
              p: "10px 10px",
              mb: 2,
              display: { md: "flex", xs: "block" },
              flexDirection: "rows",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box>
                <img
                  src={profileDetails ? profileDetails?.avatar : OtherImage}
                  height="100px"
                  width="100px"
                  style={{
                    objectFit: "cover",
                    border: "1px solid #592091",
                    borderRadius: "50%",
                  }}
                  alt="Profile"
                />
                <Tooltip title={t("myAccountProfilePicLable")}>
                  <EditIcon
                    sx={{ cursor: "pointer", color: "#592091" }}
                    onClick={profileImagePopup}
                  />
                </Tooltip>
              </Box>
              <Box sx={{ m: "4px", display: "flex", flexDirection: "row" }}>
                <Typography variant="h5"></Typography>
                {props.calledFor &&
                props.calledFor === "admin_profile" ? null : (
                  <Link
                    to={
                      window.location.pathname.includes("admin-user")
                        ? "/admin-user-management"
                        : localStorage.getItem("is_superuser") === "true"
                        ? "/admin-dashboard"
                        : "/admin"
                    }
                  >
                    <Button>
                      {"< "}
                      {t("dashboardLbl")}
                    </Button>
                  </Link>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { md: 320, xs: "100%" },
                  p: { md: 2, xs: "8px 8px 8px 0px" },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#592091",
                    fontWeight: "600",
                    mb: 1,
                    fontSize: { md: "20px", xs: "16px" },
                  }}
                >
                  {t("myAccountComplete")}
                </Typography>
                <Typography variant="body2">
                  {t("myAccountCompleteDescription")}
                </Typography>
              </Box>
              <Box sx={{ width: { md: 100, xs: 150 } }}>
                <CircularProgressbar
                  value={profileDetails?.reliability}
                  text={`${profileDetails?.reliability}%`}
                  className="circular-progress-bar"
                />
              </Box>
            </Box>
          </Paper>
        </Box>
        <Paper>
          <VerticalTabs
            calledFor={
              props.calledFor && props.calledFor === "admin_profile"
                ? "admin_profile"
                : ""
            }
          />
        </Paper>
      </Container>
      {showProfileImagePopup && (
        <ProfileImagePopup
          open={showProfileImagePopup}
          setOpen={setShowProfileImagePopup}
        />
      )}
    </Box>
  );
}
