import * as React from "react";
import Box from "@mui/material/Box";
import CatchingPokemonIcon from "@mui/icons-material/CatchingPokemon";
import Grid from "@mui/material/Grid";
import { Button, Container } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CheckIcon from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ServiceImage from "../../../assets/Images/service.png";
import { Link } from "react-router-dom";
import "./Service.scss";

export default function Service() {
  const { t } = useTranslation();

  return (
    <>
      <Box
        className="containerFee"
        id="simpleRates"
        sx={{ borderBottom: "2px solid #e6e6e6" }}
      >
        <Container sx={{ p: { md: "50px 0px", xs: "30px 20px" } }}>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={12} md={4} sx={{ p: { md: "0px 20px", xs: "0" } }}>
              <Typography variant="span" className="subtitle">
                {t("serviceHomePage.0")}:
              </Typography>
              <Typography variant="h5" className="title">
                {t("serviceHomePage.1")}
              </Typography>
              <List>
                <ListItem disablePadding sx={{ mb: "10px" }}>
                  <ListItemIcon>
                    <CheckIcon
                      sx={{
                        color: "white",
                        background: " #059ff5",
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        padding: "3px",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: "#555" }}
                    primary={t("serviceHomePage.2")}
                  />
                </ListItem>
                <ListItem disablePadding sx={{ mb: "10px" }}>
                  <ListItemIcon>
                    <CheckIcon
                      sx={{
                        color: "white",
                        background: " #059ff5",
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        padding: "3px",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: "#555" }}
                    primary={t("serviceHomePage.3")}
                  />
                </ListItem>
                <ListItem disablePadding sx={{ mb: "10px" }}>
                  <ListItemIcon>
                    <CheckIcon
                      sx={{
                        color: "white",
                        background: " #059ff5",
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        padding: "3px",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: "#555" }}
                    primary={t("serviceHomePage.4")}
                  />
                </ListItem>
              </List>
              <Button variant="contained">
                <Link to="/inner-service-page" style={{ color: "#fff" }}>
                  {t("serviceHomePage.5")}
                </Link>
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                mt: { md: "0px", xs: "20px" },
                p: { md: "0px 20px", xs: "0" },
              }}
            >
              <Box sx={{ position: "relative" }}>
                <img
                  src={ServiceImage}
                  alt={ServiceImage}
                  style={{ width: "100%" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          width: " 100%",
          color: "#e6e6e6",
          position: "relative",
        }}
      >
        <CatchingPokemonIcon
          className="divider"
          sx={{
            margin: "0px",
            position: "absolute",
            left: "50%",
            top: "-12px",
            background: " white",
            borderRadius: "50%",
          }}
        />
      </Box>
    </>
  );
}
