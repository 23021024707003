import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  error: "",
  success: "",
  KittyData:{},
};

const updateKittySlice = createSlice({
  name: "updateKittySlice",
  initialState,
  reducers: {
    setUpdatedKitty: (state, action) => ({
      ...state,
      KittyData: action.payload,
    }),
    setLoginLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setSuccess: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    setError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
  },
});

export const { setUpdatedKitty, setLoginLoader, setSuccess, setError } = updateKittySlice.actions;

export default updateKittySlice.reducer;
