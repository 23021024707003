import {
  Grid,
  Button,
  Stack,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import OtherImage from "../../../assets/Images/OtherOther.webp";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useState, useEffect } from "react";
import AlertDialogSlide from "./Dialogue";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useParams } from "react-router-dom";
import {
  SingleTontine,
  updateMyTontine,
} from "../../../store/thunk/Tontine/MytontineThunk";

const fileType = ["image/png", "image/jpeg", "image/gif"];

export default function AddImage() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const tontineDetails = useAppSelector((state) => state.MyTontine);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(tontineDetails?.singleTontine?.image);
  const [newUploadedFile, setNewUploadedFile] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(SingleTontine(id));
  }, []);

  // setFile(URL.createObjectURL(event.target.files[0]),
  const handleChange = (event) => {
    setError("");
    if (!fileType.includes(event.target.files[0].type)) {
      setError(t("imageValidation"));
      return;
    }

    const fileSize = event.target.files[0].size;
    let fileSizeMb = 0;

    if (fileSize > 0) {
      fileSizeMb = (event.target.files[0].size / 1024 / 1024).toFixed(2);
    }

    if (fileSizeMb > 5) {
      setError(t("imageSizeValidation"));
      return;
    }
    setFile(URL.createObjectURL(event.target.files[0]), setOpen(false));
    setNewUploadedFile(event.target.files[0]);
  };

  const handleSaveImage = () => {
    if (newUploadedFile) {
      dispatch(updateMyTontine({ id, image: newUploadedFile }));
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <img
            style={{
              width: "100%",
              height: "25rem",
              objectFit: "contain",
              display: "block",
              maxWidth: "100%",
              right: 0,
            }}
            src={file ? file : OtherImage}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Card
            onClick={handleClickOpen}
            sx={{
              mt: 2,
              mr: 1,
              border: "4px solid #e6e6e6",
              cursor: "pointer",
              "&:hover": {
                borderColor: "#592091",
              },
            }}
          >
            <CardContent
              height="90"
              sx={{
                display: "grid",
                alignItems: "center",
                "&:hover": {
                  borderColor: "#592091",
                },
              }}
            >
              <Typography
                component={Stack}
                direction="column"
                alignItems="center"
              >
                <AddCircleRoundedIcon />
                {t("addLabel")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div style={{ display: "grid", placeContent: "center" }}>
        <Button
          sx={{
            margin: "20px",
            backgroundColor: "#592091",
            borderRadius: "20px",
            color: "#fff",
            p: "8px 15px",
            fontSize: { md: "17px", xs: "10px" },
            "&:hover": {
              backgroundColor: "#a4badb",
              textDecoration: "none",
              color: "#000",
            },
          }}
          variant="contained"
          onClick={() => handleSaveImage()}
        >
          {t("saveLabel")}
        </Button>
        <AlertDialogSlide
          open={open}
          setOpen={setOpen}
          handleChange={handleChange}
          imgError={error}
        />
      </div>
    </>
  );
}
