import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Grid, Container, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import "../MoneyPotsInnerPage/style.scss";
import CategoriesBlock from "./categories";
import SearchBlock from "./search";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { filterlistofKitty } from "../../../../store/thunk/Kitty/listofKittyThunk";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function MediCard() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [recordLimit, setRecordLimit] = React.useState(18);
  const [category, setCategory] = React.useState([]);
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    dispatch(
      filterlistofKitty({
        limit: recordLimit,
        category: category,
        search: search,
      })
    );
  }, [category, search, recordLimit]);

  const data = useAppSelector((state) => state.listofKittySlice);

  return (
    <Box sx={{ background: "#f1efeb" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={data?.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ p: { md: "0 16px", xs: 0 } }}>
        <Box
          sx={{
            pt: { md: 2, xs: 0 },
            display: { md: "flex", xs: "block" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <CategoriesBlock
              recordLimit={recordLimit}
              category={category}
              setCategory={setCategory}
            />
          </Box>
          <Box>
            <SearchBlock
              recordLimit={recordLimit}
              search={search}
              setSearch={setSearch}
            />
          </Box>
        </Box>
        <Grid container sx={{ p: "10px 0px" }}>
          {data?.filterList?.results?.length
            ? data?.filterList?.results?.map((data) => {
                return (
                  <Grid item xs={6} md={6} lg={4}>
                    <Card
                      className="money-pots-block"
                      sx={{
                        margin: "10px",
                        backgroundColor: "#eeebeb",
                        borderRadius: { md: "40px", xs: "10px" },
                        background: "#5920912e",
                      }}
                    >
                      <Link to={`/kittypage/${data.id}`}>
                        <CardMedia
                          sx={{
                            height: { md: "350px", xs: "200px" },
                            overflow: "hidden",
                          }}
                        >
                          <LazyLoadImage
                            alt={data.category.category_name}
                            effect="blur"
                            src={data.image}
                            width={"100%"}
                            height={"100%"}
                            objectFit={"cover"}
                          />
                          {/* <img
                            src={data.image}
                            alt={data.category.category_name}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              height: "100%",
                            }}
                          /> */}
                        </CardMedia>
                        <CardContent
                          sx={{ p: " 0 !important" }}
                          className="money-pots-content"
                        >
                          <Box className="money-pots-card-action">
                            <svg
                              className="card__arc"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path />
                            </svg>
                            <Typography
                              className="money-pots-title"
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{
                                fontSize: "18px",
                                fontWeight: "600",
                                textTransform: "capitalize",
                                background:
                                  "linear-gradient(to left, #592091, #4b0082, #91bcff, #1064e7, #808080, #4b0082, #0161f7)",
                                WebkitBackgroundClip: "text",
                                color: "transparent",
                                mb: 0,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              {data.name}
                            </Typography>
                          </Box>
                          <Box className="money-pots-card-title">
                            <Typography
                              className="money-pots-card-content"
                              variant="h6"
                              color="text.secondary"
                              sx={{
                                mb: 1,
                                // overflow: "hidden",
                                // textOverflow: "ellipsis",
                                // display: "-webkit-box",
                                // WebkitLineClamp: "2",
                                // WebkitBoxOrient: "vertical",
                                fontSize: "14px",
                                marginTop: "10px",
                                minHeight: " 45px",
                              }}
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: data.object_of_kitty,
                                }}
                              ></p>
                            </Typography>
                          </Box>
                        </CardContent>
                      </Link>
                    </Card>
                  </Grid>
                );
              })
            : null}

          {!data?.filterList?.results?.length && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                paddingTop: "10px",
                minHeight: "200px",
              }}
            >
              <Box>{t("norecordLbl")}</Box>
            </Box>
          )}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              setRecordLimit(recordLimit + 18);
            }}
            disabled={data?.filterList?.count < recordLimit}
            sx={{
              m: 2,
              width: "100%",
              background: "#592091",
              color: "#fff",
              fontWeight: "600",
              "&:hover": {
                background: "#c196ec",
              },
              "&:disabled": {
                background: "#d1d1d1",
              },
            }}
          >
            {t("ShowMoreLable")}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
