import { Box, Grid, Container, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./moneyPots.scss";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useEffect } from "react";
import { listofKittyCat } from "../../../store/thunk/KittyCategory/listofKittyCatThunk";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

document.documentElement.style.setProperty("--animate-duration", "2s");
export default function MoneyPots() {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.listofKittyCatSlice);
  useEffect(() => {
    dispatch(listofKittyCat({ limit: 1000 }));
  }, []);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const subPage = (id) => {
    navigate(`/money-pots-innerPage/${id}`);
  };

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 8,
    margin: 1,
    rows: 1,
    nav: true,
    initialSlide: 0,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 300,
        settings: "unslick",
      },
    ],
  };
  return (
    <>
      <Box
        id="OwlSlider"
        component="section"
        className="container"
        sx={{
          position: "relative",
          overflow: "hidden",
          background: "#f1efeb",
        }}
      >
        <Container
          className="slider-box"
          sx={{
            zIndex: "9",
            position: "relative",
            p: { md: "50px 0px", xs: "30px 0px" },
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              marginBottom: { md: "50px", xs: "30px" },
              p: 1,
            }}
          >
            <Typography className="top-subheading" variant="p">
              {t("moneyPotsPage.0")}
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: { md: "30px", xs: "20px" },
                mt: "10px",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("moneyPotsPage.1")}
            </Typography>
          </Box>
          <Slider {...settings2} sx={{ position: "absolute" }}>
            {data.list.results
              ?.filter((data) => data.category_type !== "GROUP")
              .reverse()
              .map((data) => (
                <Grid
                  className="Home-slider-grid"
                  sx={{
                    position: "relative",
                    padding: "10px",
                  }}
                >
                  <Grid item xs={12} md={6} onClick={() => subPage(data?.id)}>
                    <Box className="slider-images">
                    <LazyLoadImage alt={data?.category_name} effect="blur" src={data?.image} width="100%"/>

                      {/* <img
                        src={data?.image}
                        alt={data?.category_name}
                        style={{ width: "100%" }}
                        loading="lazy"
                      /> */}
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "13px",
                          p: "5px 3px",
                          textAlign: "center",
                          background: "#7d0cee",
                          color: "#fff",
                        }}
                      >
                        {t("category." + data?.category_name).replace(
                        "category.",
                        ""
                      )}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              ))}
          </Slider>
        </Container>
      </Box>
    </>
  );
}
