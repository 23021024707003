import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  success: false,
  password:'',
  userCheck:{},
};

const newPasswordSlice = createSlice({
  name: 'newPasswordSlice',
  initialState,
  reducers: {
    setNewPasswordData: (state, action) => ({
      ...state,
      password: action.payload,
      success:true
    }),
    CheckUserPasswordData: (state, action) => ({
      ...state,
      userCheck: action.payload,
    }),
  }
});

export const { setNewPasswordData, CheckUserPasswordData } = newPasswordSlice.actions;

export default newPasswordSlice.reducer;
