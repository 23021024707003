import * as React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/store";
import { Avatar, Badge, MenuItem, MenuList, Typography } from "@mui/material";
import MenuItemUnstyled, {
  menuItemUnstyledClasses,
} from "@mui/base/MenuItemUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import GridViewIcon from "@mui/icons-material/GridView";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};`
);

const StyledMenuItem = styled(MenuItemUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &.${menuItemUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${menuItemUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const TriggerButton = styled("button")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.25rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  border-radius: 12px;
  padding: 6px 16px;
  line-height: 1.5;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &:focus {
    border-color: ${blue[400]};
  }
  `
);

const Popper = styled(PopperUnstyled)`
  z-index: 999999999999;
`;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function UserDrawer() {
  const ref = React.useRef();
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = React.useState({});
  const [showMenu, setShowMenu] = React.useState(false);
  const res = useAppSelector((state) => state.UserProfile);
  React.useEffect(() => {
    res.success ? setUserDetails(res.details) : setUserDetails({});
  }, [res]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);
  const buttonRef = React.useRef(null);
  const menuActions = React.useRef(null);
  const preventReopen = React.useRef(false);

  React.useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showMenu && ref.current && !ref.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showMenu]);

  const handleButtonClick = (event) => {
    if (preventReopen.current) {
      event.preventDefault();
      preventReopen.current = false;
      return;
    }

    if (isOpen) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleButtonMouseDown = () => {
    if (isOpen) {
      // Prevents the menu from reopening right after closing
      // when clicking the button.
      preventReopen.current = true;
    }
  };

  const handleButtonKeyDown = (event) => {
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      if (event.key === "ArrowUp") {
        menuActions.current?.highlightLastItem();
      }
    }
  };

  const close = () => {
    setAnchorEl(null);
    buttonRef.current.focus();
  };

  const createHandleMenuClick = (menuItem) => {
    return () => {
      close();
    };
  };

  const logoutUser = () => {
    localStorage.removeItem("UserInfo");
    localStorage.removeItem("UserId");
    localStorage.removeItem("is_superuser");
    window.location.href = "/";
  };
  const handleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div ref={ref}>
      <MenuList className="dropdown" sx={{ p: 0 }}>
        <MenuItem className="dropdown__item">
          <TriggerButton
            onClick={handleMenu}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "rows",
              padding: "0px",
              cursor: "pointer",
            }}
            type="button"
            // onClick={handleButtonClick}
            onKeyDown={handleButtonKeyDown}
            onMouseDown={handleButtonMouseDown}
            ref={buttonRef}
            aria-controls={isOpen ? "simple-menu" : undefined}
            aria-expanded={isOpen || undefined}
            aria-haspopup="menu"
          >
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar
                alt={userDetails.first_name}
                sx={{ width: 36, height: 36 }}
                src={userDetails.avatar}
              />
            </StyledBadge>
            <Typography
              variant="h6"
              sx={{
                paddingLeft: 1,
                fontSize: "14px !important",
                marginTop: 1,
                color: "#fff !important",
              }}
            >
              {userDetails.first_name}
            </Typography>
          </TriggerButton>
          <MenuList
            className={showMenu ? "dropdown__sublist" : "hide"}
            actions={menuActions}
            open={isOpen}
            onClose={close}
            anchorEl={anchorEl}
            slots={{ root: Popper, listbox: StyledListbox }}
            slotProps={{ listbox: { id: "simple-menu" } }}
          >
            <MenuItem onClick={createHandleMenuClick("Profile")}>
              <Link
                to={
                  localStorage.getItem("is_superuser") == "true"
                    ? "/admin-dashboard"
                    : "/admin"
                }
                style={{ textDecoration: "none" }}
              >
                <GridViewIcon sx={{ mr: 1 }} /> {t("dashboardLbl")}
              </Link>
            </MenuItem>
            <MenuItem onClick={createHandleMenuClick("My account")}>
              <Link to="/account/my" style={{ textDecoration: "none" }}>
                <AccountBalanceWalletIcon sx={{ mr: 1 }} /> {t("myAccountLbl")}
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => {
                createHandleMenuClick("Log out");
                logoutUser();
              }}
            >
              <Link
                to="/logout"
                style={{
                  textDecoration: "none",
                }}
              >
                <LockOpenIcon sx={{ mr: 1 }} /> {t("logoutLbl")}
              </Link>
            </MenuItem>
          </MenuList>
        </MenuItem>
      </MenuList>
    </div>
  );
}
