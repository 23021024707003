import React from "react";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import SignIn from "../components/SignIn/index";
import { Box } from "@mui/material";

function LowestFeesComponent() {
  return (
    <>
      <Header />
      <Box sx={{ background: "#592091" }} className="min-height-div">
        <SignIn />
      </Box>
      <Footer />
    </>
  );
}
export default LowestFeesComponent;
