/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../../../common/DataTableBase";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { listofKittyCat } from "../../../../../store/thunk/KittyCategory/listofKittyCatThunk";
import { Button, Box } from "@mui/material";
import DeleteAlertBBox from "./AdminKittyCatDelete";
import UpdateKittyCat from "../../../../KittyCategory/KittyCatUpdate";
import KittyCatUpdate from "../../../../KittyCategory/KittyCatInsertNew";
import { createTheme } from "react-data-table-component";
import CustomMPagination from "../../../../common/CustomMPagination";

function MyComponent() {
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = useState(0);
  const [recordLimit, SetRecordLimit] = useState(10);
  const [pageCountShow, SetPageCountShow] = useState(0);
  const { t } = useTranslation();
  const data = useAppSelector((state) => state.listofKittyCatSlice);
  const [search, setSearch] = useState(
    localStorage.getItem("SuperAdminKittyCategorySearch")
  );

  const columns = [
    {
      name: t("kittyCatListingTableLable.0"),
      selector: useCallback(
        (row) => <img width={50} height={50} src={row.image} alt="" />,
        []
      ),
      // width: "4rem",
    },
    {
      name: t("kittyCatListingTableLable.1"),
      selector: (row) => row.category_name,
    },
    {
      name: t("kittyCatListingTableLable.2"),
      selector: (row) => row.description,
    },
    {
      name: t("kittyCatListingTableLable.3"),
      selector: (row) => row.created_at.slice(0, 10),
    },
    {
      name: t("kittyCatListingTableLable.4"),
      selector: (row) => row.updated_at.slice(0, 10),
    },
    {
      name: t("kittyCatListingTableLable.5"),
      width: "6rem",
      cell: useCallback(
        (row) => (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "rows",
              width: "1rem",
            }}
          >
            <Box style={{ width: "2rem", display: "flex" }}>
              <Button>
                <UpdateKittyCat
                  data={row}
                  SetPageCount={SetPageCount}
                  refreshSearch={refreshSearch}
                  from="admin"
                />
              </Button>
            </Box>
            <Box
              style={{
                width: "2rem",
                display: "flex",
                margin: "10px",
                lineHeight: 0,
                minWidth: "64px",
                padding: 0,
              }}
            >
              <Button>
                <DeleteAlertBBox
                  data={row}
                  from="admin"
                  deleteCallback={refreshSearch}
                />
              </Button>
            </Box>
          </Box>
        ),
        []
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    if (Number(pageCount) >= data.list?.count) {
      SetPageCount(Number(pageCount) - Number(recordLimit));
    }
    dispatch(
      listofKittyCat({
        limit: Number(recordLimit),
        offSet: Number(pageCount),
        search,
      })
    );
    SetPageCountShow(
      (Number(pageCount) + Number(recordLimit)) / Number(recordLimit)
    );
  }, [pageCount, recordLimit, search]);

  const refreshSearch = () => {
    dispatch(
      listofKittyCat({
        limit: Number(recordLimit),
        offSet: Number(pageCount),
        search: localStorage.getItem("SuperAdminKittyCategorySearch"),
      })
    );
  };

  // useEffect(() => {
  //   dispatch(
  //     listofKittyCat({
  //       limit: recordLimit,
  //       offSet: 0,
  //       search,
  //       callback: () => {
  //         if (search !== "") {
  //           SetPageCount(0);
  //         }
  //       },
  //     })
  //   );
  // }, [search]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "#ccc",
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "#fff ",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        textAlign: "center",
        fontSize: "17px",
        display: "grid",
        placeContent: "center",
        backgroundColor: "#2a2828",
        color: "#fff",
        maxWidth: "40px",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        padding: "8px",
        fontSize: "14px",
        display: "grid",
        placeContent: "center",
      },
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );

  return (
    <Box className="kittyTable">
      <Box sx={{ padding: { md: 2, xs: 1 } }}>
        <DataTable
          title={
            <>
              <Box
                className="tableTitle"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                {t("kittyCategoryListLabel")}
              </Box>
            </>
          }
          columns={columns}
          data={data.list.results}
          customStyles={customStyles}
          theme="solarized"
          progressPending={data.loading}
          actions={
            <KittyCatUpdate
              search={search}
              setSearch={setSearch}
              SetPageCount={SetPageCount}
              refreshSearch={refreshSearch}
            />
          }
          noDataComponent={<Box sx={{ p: 3 }}>{t("norecordLbl")}</Box>}
          className="rdt_TableHeader"
        />
      </Box>
      <CustomMPagination
        pageCount={pageCount}
        SetPageCount={SetPageCount}
        recordLimit={recordLimit}
        pageCountShow={pageCountShow}
        SetRecordLimit={SetRecordLimit}
        numRecords={data?.list?.num_records}
        numCount={data?.list?.count}
      />
    </Box>
  );
}

export default MyComponent;
