import * as React from "react";
import PropTypes from "prop-types";
// import Box from 'react-swipeable-views';
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Container, Tooltip } from "@mui/material";
import DocumentSection from "./YourID";
import YourAccountDetails from "./YourAccountDetails";
import { useAppSelector } from "../../../../store/store";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { md: 2, xs: 1 } }}>
          <Typography sx={{ width: " 100%" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function TontineTab() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const documentStatus = useAppSelector(
    (state) => state?.MyTontine?.userDocuments[0]
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <Container>
      <Paper className="kittyTable" sx={{ border: "1px solid #ddd", mb: 5 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            background: "#ddd",
            color: "#000",
            "& .MuiTab-root": {
              height: "70px",
              fontSize: { md: "20px", xs: "13px" },
              fontWeight: "600",
            },
            "& .MuiTab-root.Mui-selected": {
              background: "rgb(145, 188, 255)",
              color: "#fff",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#91bcff",
            },
          }}
        >
          <Tab label={t("YourDocumentLable")} {...a11yProps(0)} />
          <Tab label={t("YourIBANDetailsLable")} {...a11yProps(1)} />
        </Tabs>
        <Box
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <DocumentSection />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {documentStatus ? (
              <YourAccountDetails callback={() => {}} />
            ) : (
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                    placeContent: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      mb: 1,
                      textAlign: "center",
                      color: "#592091",
                      display: " table",
                      margin: " 0 auto",
                      fontWeight: " 600",
                      lineHeight: "26px",
                      fontSize: { md: "27px", xs: "18px" },
                      padding: { md: "20px", xs: "10px" },
                      width: "100%",
                    }}
                    variant="h5"
                  >
                    {t("IBAN&CARDAddValidation")}
                  </Typography>
                </Box>
              </>
            )}
          </TabPanel>
        </Box>
      </Paper>
    </Container>
  );
}
