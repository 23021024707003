import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import { createTheme } from "react-data-table-component";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import DataDialog from "../DescriptionContentDialog";
import DataTable from "../../../../../../components/common/DataTableBase";
import { useAppDispatch, useAppSelector } from "../../../../../../store/store";
import { getListofKittyPayoutLog } from "../../../../../../store/thunk/Kitty/KittyDetailsThunk";
import CustomMPagination from "../../../../../common/CustomMPagination";

export default function AdminKittySettingLogComponent() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = useState(0);
  const [pageCountShow, SetPageCountShow] = useState(0);
  const [recordLimit, SetRecordLimit] = useState(10);
  const adminKittyPayoutLogData = useAppSelector(
    (state) => state.DetailsOfKitty
  );
  const [openContentDialog, setOpenContentDialog] = React.useState(false);
  const [contentData, setContentData] = React.useState(null);

  const handleClickOpen = () => {
    setOpenContentDialog(true);
  };
  const handleClose = () => {
    setOpenContentDialog(false);
    setContentData(null);
  };

  const columns = [
    {
      name: t("kittyNameListLabel"),
      selector: (row) => row.kitty_name,
    },
    {
      name: t("collectedAmountListLabel"),
      selector: (row) => (
        <NumericFormat
          value={Number(row.actual_amount)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"€"}
        />
      ),
    },
    {
      name: t("PayoutAmountListLabel"),
      selector: (row) => (
        <NumericFormat
          value={Number(row.payout_amount)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"€"}
        />
      ),
    },
    {
      name: t("withdrawalStatusListLabel"),
      cell: useCallback(
        (row) => (
          <Chip
            size="small"
            label={row.withdrawal_status}
            sx={{
              background:
                row.withdrawal_status === "FAILED"
                  ? "#ff0000"
                  : row.withdrawal_status === "PENDING"
                  ? "#ffa500"
                  : "#008000",
              color: "#fff",
            }}
          />
        ),
        []
      ),
    },
    {
      name: t("commissionListLabel"),
      selector: (row) => (
        <NumericFormat
          value={Number(row.payout_commissions)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"€"}
        />
      ),
    },
    {
      name: t("createdOnLabel"),
      selector: (row) =>
        moment(row.kitty_created).format(
          localStorage.getItem("language") == "en" ? "YYYY-MM-DD" : "DD-MM-YYYY"
        ),
    },
    {
      name: t("kittyPayoutLogRequestedByTitle"),
      minWidth: "250px",
      selector: (row) => row.payout_to || "-",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    dispatch(
      getListofKittyPayoutLog({ limit: recordLimit, offSet: pageCount })
    );
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, recordLimit]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "#ccc",
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "#fff ",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        textAlign: "center",
        fontSize: "17px",
        display: "grid",
        placeContent: "center",
        backgroundColor: "#2a2828",
        color: "#fff",
        maxWidth: "40px",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        padding: "8px",
        fontSize: "14px",
        display: "grid",
        placeContent: "center",
      },
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );

  return (
    <>
      <Box className="kittyTable">
        <Box sx={{ borderRadius: 0, padding: { md: 2, xs: 1 } }}>
          <DataTable
            title={
              <>
                <Box
                  className="tableTitle"
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {t("kittyPayoutLogListLabel")}
                </Box>
              </>
            }
            columns={columns}
            data={adminKittyPayoutLogData?.adminKittyPayoutLogList?.results}
            customStyles={customStyles}
            theme="solarized"
            noDataComponent={<Box sx={{ p: 3 }}>{t("norecordLbl")}</Box>}
            className="rdt_TableHeader"
            progressPending={adminKittyPayoutLogData.loading}
          />
          <CustomMPagination
            pageCount={pageCount}
            SetPageCount={SetPageCount}
            recordLimit={recordLimit}
            pageCountShow={pageCountShow}
            SetRecordLimit={SetRecordLimit}
            numRecords={
              adminKittyPayoutLogData?.adminKittyPayoutLogList?.num_records
            }
            numCount={adminKittyPayoutLogData?.adminKittyPayoutLogList?.count}
          />
        </Box>
        {openContentDialog && (
          <DataDialog
            openFunc={handleClickOpen}
            closeFunc={handleClose}
            dialogContent={contentData}
          />
        )}
      </Box>
    </>
  );
}
