import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { updateUserProfileImage } from "../../../store/thunk/newUserThunk";
import FileUploader from "../../common/imageUpload";
import { useAppSelector, useAppDispatch } from "../../../store/store";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProfileImage({ open, setOpen }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const profileDetails = useAppSelector((state) => state?.UserProfile?.details);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [formState, setFormState] = React.useState({
    values: {},
  });

  React.useEffect(() => {
    setDisableSubmit(false);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectFile = (file) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        image: file,
      },
    }));
  };

  const handleSubmit = () => {
    let request = {
      ...formState,
    };

    dispatch(
      updateUserProfileImage({
        request: request.values,
        user_id: profileDetails?.id,
        callback: () => {
          handleClose();
          setDisableSubmit(false);
        },
      })
    );
  };

  return (
    <div>
      <Dialog
        PaperProps={{ sx: { m: { md: "30px", xs: "10px" } } }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            fontWeight: "600",
            background: "#592091",
            color: "#fff",
            textAlign: "center",
          }}
        >
          {t("myAccountProfilePicHeadLable")}
          <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <FileUploader onSelectFile={handleSelectFile} imagewidth="50%" />
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ p: "20px", justifyContent: "space-between" }}>
          <Button
            onClick={handleClose}
            sx={{
              fontSize: "12px",
              background: "#fff",
              color: "#592091",
              display: "block",
              marginTop: "10px",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
          >
            {t("cancelLable")}
          </Button>
          <Button
            sx={{
              background: "#592091",
              color: "#fff",
              display: "block",
              marginTop: "10px",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={handleSubmit}
            disabled={disableSubmit}
          >
            {t("updateLable")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
