import * as React from "react";
import Header from "../Header/index";
import Footer from "../Footer/index";
import TermsAndConditions from "./TermsAndConditions";

export default function Admin() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.URL]);
  return (
    <>
      <Header />
      <TermsAndConditions />
      <Footer />
    </>
  );
}
