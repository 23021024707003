import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  error: "",
  success: false,
  KittyID:"",
};

const deleteKittySlice = createSlice({
  name: "deleteKittySlice",
  initialState,
  reducers: {
    setDeleteKitty: (state, action) => ({
      ...state,
      KittyID: action.payload,
    }),
    setKittyDeleteLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setKitttyDeleteSuccess: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    setKittyDeleteError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
  },
});

export const { setDeleteKitty, setKittyDeleteLoader, setKitttyDeleteSuccess, setKittyDeleteError } = deleteKittySlice.actions;

export default deleteKittySlice.reducer;
