import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { combineReducers } from "redux";

import userSlice from "./reducer/User/SliceUser";
import newUserSlice from "./reducer/User/NewUserSlice";
import UserProfileSlice from "./reducer/User/UserProfileSlice";
import UserListSlice from "./reducer/User/UserListSlice";
import forgetPasswordSlice from "./reducer/Password/ForgetPasswordSlice";
import newPasswordSlice from "./reducer/Password/NewPasswordSlice";
import listofKittySlice from "./reducer/KittySlice/listofKittySlice";
import UpdateKittyListSlice from "./reducer/KittySlice/UpdateKittyListSlice";
import DeleteKittyListSlice from "./reducer/KittySlice/DeleteKittyListSlice";
import InsertNewKittySlice from "./reducer/KittySlice/InsertNewKittySlice";
import listofKittyCatSlice from "./reducer/KittyCategorySlice/listofKittySliceCat";
import UpdateKittyCatListSlice from "./reducer/KittyCategorySlice/UpdateKittyCatListSlice";
import DeleteKittyCatListSlice from "./reducer/KittyCategorySlice/DeleteKittyCatListSlice";
import InsertNewKittyCatSlice from "./reducer/KittyCategorySlice/InsertNewKittyCatSlice";
import likeofKittySlice from "./reducer/KittySlice/LikeOfKitty/LikeOfKittySlice";
import contributeKittySlice from "./reducer/KittySlice/Kittycontribute/ListOfKittycontributeSlice";
import KittyDetailsSlice from "./reducer/KittySlice/KittyDetailsSlice";
import CreateKittySlice from "../store/reducer/KittySlice/CreateKittySlice";
import TokenExpire from "../store/reducer/User/TokenExpireSlice";
import AlertSlice from "./reducer/AlertSlice";
import MyTontineSlice from "./reducer/Tontine/MyTontineSlice";
import CheckOutSlice from "./reducer/Payments/CheckoutSlice";

const rootReducer = combineReducers({
  userSlice: userSlice,
  newUserSlice: newUserSlice,
  forgetPasswordSlice: forgetPasswordSlice,
  newPasswordSlice: newPasswordSlice,
  listofKittySlice: listofKittySlice,
  updateKittySlice: UpdateKittyListSlice,
  deleteKittySlice: DeleteKittyListSlice,
  NewKittySlice: InsertNewKittySlice,
  listofKittyCatSlice: listofKittyCatSlice,
  updateKittyCatSlice: UpdateKittyCatListSlice,
  deleteKittyCatSlice: DeleteKittyCatListSlice,
  NewKittyCatSlice: InsertNewKittyCatSlice,
  UserProfile: UserProfileSlice,
  likeofKitty: likeofKittySlice,
  contributeKitty: contributeKittySlice,
  DetailsOfKitty: KittyDetailsSlice,
  CreateKitty: CreateKittySlice,
  UserList: UserListSlice,
  AlertSlice: AlertSlice,
  TokenExpire: TokenExpire,
  MyTontine: MyTontineSlice,
  CheckOut: CheckOutSlice,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

export default store;
