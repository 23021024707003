import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { createTheme } from "react-data-table-component";
import moment from "moment";
import Chip from "@mui/material/Chip";
import DataTable from "../../../../../../components/common/DataTableBase";
import { useAppDispatch, useAppSelector } from "../../../../../../store/store";
import { getlistofTontineDocumentLog } from "../../../../../../store/thunk/Tontine/MytontineThunk";
import CustomMPagination from "../../../../../common/CustomMPagination";

export default function AdminDocumentLogComponent() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = useState(0);
  const [pageCountShow, SetPageCountShow] = useState(0);
  const [recordLimit, SetRecordLimit] = useState(10);
  const adminTontineDocumentLogData = useAppSelector(
    (state) => state.MyTontine
  );

  const columns = [
    {
      name: t("tontineDocumentVerifyLabel"),
      selector: useCallback(
        (row) =>
          row.document_verified ? (
            <Chip
              label={t("yesLabel")}
              sx={{ background: "#592091", color: "#fff" }}
              size="small"
            />
          ) : (
            <Chip label={t("noLabel")} size="small" />
          ),
        []
      ),
    },
    {
      name: t("tontineDocumentStatusLabel"),
      selector: useCallback((row) => {
        switch (row.document_status) {
          case "REJECTED":
            return (
              <Chip
                label={t("documentRejectedStatus")}
                sx={{ background: "#ff0000", color: "#fff" }}
                size="small"
              />
            );
          case "ACCEPTED":
            return (
              <Chip
                label={t("documentApprovedStatus")}
                sx={{ background: "#008000", color: "#fff" }}
                size="small"
              />
            );

          default:
            return (
              <Chip label={t("documentPendingStatusLable")} size="small" />
            );
        }
      }, []),
    },
    {
      name: t("tontineDocumentUploadLabel"),
      selector: (row) =>
        moment(row.document_uploaded_at).format(
          localStorage.getItem("language") == "en" ? "YYYY-MM-DD" : "DD-MM-YYYY"
        ),
    },
    {
      name: t("tontineDocumentUploadByLabel"),
      selector: (row) => row.uploaded_by || "-",
    },

    {
      name: t("tontineActionLabel"),
      selector: (row) => row.action,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    dispatch(
      getlistofTontineDocumentLog({ limit: recordLimit, offSet: pageCount })
    );
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, recordLimit]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "#ccc",
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "#fff ",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        textAlign: "center",
        fontSize: "17px",
        display: "grid",
        placeContent: "center",
        backgroundColor: "#2a2828",
        color: "#fff",
        maxWidth: "40px",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        padding: "8px",
        fontSize: "14px",
        display: "grid",
        placeContent: "center",
      },
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );

  return (
    <>
      <Box className="kittyTable">
        <Box sx={{ borderRadius: 0, padding: { md: 2, xs: 1 } }}>
          <DataTable
            title={
              <>
                <Box
                  className="tableTitle"
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {t("tontineDocumentLabel")}
                </Box>
              </>
            }
            columns={columns}
            data={
              adminTontineDocumentLogData?.adminTontineDocumentLogList?.results
            }
            customStyles={customStyles}
            theme="solarized"
            noDataComponent={<Box sx={{ p: 3 }}>{t("norecordLbl")}</Box>}
            className="rdt_TableHeader"
            progressPending={adminTontineDocumentLogData.loading}
          />
          <CustomMPagination
            pageCount={pageCount}
            SetPageCount={SetPageCount}
            recordLimit={recordLimit}
            pageCountShow={pageCountShow}
            SetRecordLimit={SetRecordLimit}
            numRecords={
              adminTontineDocumentLogData?.adminTontineDocumentLogList
                ?.num_records
            }
            numCount={
              adminTontineDocumentLogData?.adminTontineDocumentLogList?.count
            }
          />
        </Box>
      </Box>
    </>
  );
}
