import * as React from "react";
import { useTranslation } from "react-i18next";
import "../../../assets/scss/common.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, Paper, useMediaQuery } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PersonIcon from "@mui/icons-material/Person";
import Profile from "./Tabs/Profile";
import DocumentTab from "./Tabs/DocumentTab";
import NewsLetter from "./Tabs/NewsLetter";
import MyCardDetails from "./Tabs/CardDetails/MyCardDetailsTab";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { CheckUserDocumentVerifyStatus } from "../../../store/thunk/Tontine/MytontineThunk";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { md: "10px 24px", xs: "10px" } }}>
          <Typography sx={{ width: "100%" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs(props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const smallScreen = useMediaQuery("(max-width: 991px)");
  const documentStatus = useAppSelector(
    (state) => state?.MyTontine?.userDocuments[0]
  );
  const [value, setValue] = React.useState(0);
  const UserProfile = useAppSelector((state) => state.UserProfile);

  React.useEffect(() => {
    dispatch(CheckUserDocumentVerifyStatus());
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ flexGrow: 1, display: "flex" }}>
      <Grid container className="slider-box vertical-slider-box">
        <Grid item md={3} xs={12} className="vertical-slider-tab">
          <Tabs
            orientation={smallScreen ? " horizonal" : "vertical"}
            variant="scrollable"
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { background: "#592091" } }}
            aria-label="Vertical tabs example"
            className="tabs-wrapper"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              justifyontent: "center",
              ".MuiButtonBase": {
                root: {
                  ".MuiSelected": {
                    background: "#592091",
                  },
                },
              },
            }}
          >
            <Tab
              icon={
                <PersonIcon
                  sx={{
                    marginRight: "10px",
                  }}
                />
              }
              label={t("myAccountProfileTabLable")}
              sx={{
                p: 2,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start",
              }}
              {...a11yProps(0)}
              onClick={() => {}}
            />

            <Tab
              icon={
                <ListAltIcon
                  sx={{
                    marginRight: "10px",
                  }}
                />
              }
              label={t("myAccountYourIdLable")}
              sx={{
                p: 2,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(1)}
            />
            <Tab
              icon={
                <CreditCardIcon
                  sx={{
                    marginRight: "10px",
                  }}
                />
              }
              label={t("MyCardDetailsLable")}
              sx={{
                p: 2,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(2)}
            />
            <Tab
              icon={
                <MailIcon
                  sx={{
                    marginRight: "10px",
                  }}
                />
              }
              label={t("myAccountNewsletterLable")}
              sx={{
                p: 2,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(3)}
            />
          </Tabs>
        </Grid>
        <Grid item md={9} xs={12} className="vertical-slider-tab">
          <TabPanel value={value} index={0}>
            <Typography
              sx={{
                mb: 1,
                textAlign: "center",
                color: "#592091",
                display: " table",
                margin: " 0 auto",
                fontWeight: " 600",
                lineHeight: "26px",
                fontSize: { md: "27px", xs: "18px" },
                padding: { md: "20px", xs: "10px" },
              }}
            >
              {t("myAccountProfileTabLable")}
            </Typography>
            <Paper
              sx={{
                p: 2,
                width: { md: "95%", xs: "auto" },
                border: "1px solid #ddd",
              }}
            >
              <Profile
                calledFor={
                  props.calledFor && props.calledFor === "admin_profile"
                    ? "admin_profile"
                    : ""
                }
              />
            </Paper>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography
              sx={{
                mb: 1,
                textAlign: "center",
                color: "#592091",
                display: " table",
                margin: " 0 auto",
                fontWeight: " 600",
                lineHeight: "26px",
                fontSize: { md: "27px", xs: "18px" },
                padding: { md: "20px", xs: "10px" },
              }}
            >
              {t("myAccountIdentityLable")}
            </Typography>
            <DocumentTab />
          </TabPanel>
          {documentStatus ? (
            <TabPanel value={value} index={2} style={{ width: "100%" }}>
              <Typography
                sx={{
                  mb: 1,
                  textAlign: "center",
                  color: "#592091",
                  display: " table",
                  margin: " 0 auto",
                  fontWeight: " 600",
                  lineHeight: "26px",
                  fontSize: { md: "27px", xs: "18px" },
                  padding: { md: "20px", xs: "10px" },
                  width: "100%",
                }}
                variant="h5"
              >
                {t("MyCardsLable")}
              </Typography>
              <MyCardDetails />
            </TabPanel>
          ) : (
            <TabPanel
              value={value}
              index={3}
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  placeContent: "center",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    mb: 1,
                    textAlign: "center",
                    color: "#592091",
                    display: " table",
                    margin: " 0 auto",
                    fontWeight: " 600",
                    lineHeight: "26px",
                    fontSize: { md: "27px", xs: "18px" },
                    padding: { md: "20px", xs: "10px" },
                    width: "100%",
                  }}
                  variant="h5"
                >
                  {t("IBAN&CARDAddValidation")}
                </Typography>
              </Box>
            </TabPanel>
          )}
          <TabPanel value={value} index={3} style={{ width: "100%" }}>
            <Typography
              sx={{
                mb: 1,
                textAlign: "center",
                color: "#592091",
                display: " table",
                margin: " 0 auto",
                fontWeight: " 600",
                lineHeight: "26px",
                fontSize: { md: "27px", xs: "18px" },
                padding: { md: "20px", xs: "10px" },
                width: "100%",
              }}
              variant="h5"
            >
              {t("myAccountMyNotificationLable")}
            </Typography>
            <NewsLetter />
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
}
