/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Container, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import React, { useEffect, useState } from "react";
import { contributeListKitty } from "../../../../store/thunk/Kitty/KittyDetailsThunk";
import { useParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { LoadingButton } from "@mui/lab";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { useTranslation } from "react-i18next";

export default function Contributions() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const [contributeCount, setContributeCount] = useState(10);
  const contributeList = useAppSelector(
    (state) => state.DetailsOfKitty?.contributeList
  );
  const list = useAppSelector((state) => state.DetailsOfKitty.list);
  useEffect(() => {
    dispatch(contributeListKitty({ id, limit: contributeCount }));
  }, [contributeCount]);

  return (
    <>
      <Box className="contributions-block">
        {list.kitty_for_settings?.length &&
        !list?.hide_option &&
        list.kitty_for_settings[0]?.hide_all_contributors_info ? null : (
          <>
            <Container maxWidth="xl" sx={{ p: "0 !important" }}>
              <Grid container sx={{ p: 0 }}>
                {contributeList?.results?.length > 0
                  ? contributeList?.results?.map((item) => (
                      <Grid item lg={4} md={6} xs={6} key={item?.user?.id}>
                        <Grid container sx={{ p: 0 }}>
                          <Grid item md={4} sx={{ p: "0px 5px", mb: 2 }}>
                            {" "}
                            <img
                              src={item?.user?.avatar}
                              alt="contribution-image"
                              loading="lazy"
                              style={{
                                maxHeight: "52px",
                                maxWidth: "52px",
                                border: "1px solid #ccc",
                              }}
                            />
                          </Grid>
                          <Grid item md={8}>
                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: "13px !important",
                                fontWeight: "600 !important",
                                overflow: "hidden !important",
                                textOverflow: "ellipsis !important",
                                whiteSpace: "nowrap !important",
                                maxWidth: "20ch !important",
                              }}
                            >
                              {item?.user?.first_name} {item?.user?.name}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                color: "#666 !important",
                                fontSize: "13px !important",
                              }}
                            >
                              {t("contributedLable")}
                            </Typography>
                            {item.is_hide && !list?.hide_option ? null : (
                              <Typography
                                variant="body"
                                sx={{
                                  fontSize: "12px !important",
                                  fontWeight: "600 !important",
                                  color: "#666 !important",
                                }}
                              >
                                <NumericFormat
                                  value={Number(item?.amount)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"€"}
                                />
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  : null}
                <Grid item xs={12}>
                  <Box className="readMore">
                    <LoadingButton
                      onClick={() => setContributeCount(contributeCount + 10)}
                      disabled={contributeList?.count / contributeCount < 1}
                      sx={{
                        backgroundColor: "#592091",
                        color: "#fff",
                        borderRadius: "20px",
                        fontWeight: "500",
                        "&:hover": {
                          background: " #7D0CED",
                        },
                        "&:disabled": {
                          background: "#d7e3f5",
                        },
                      }}
                    >
                      <ExpandCircleDownOutlinedIcon sx={{ mr: 1 }} />
                      <Typography variant="p">{t("ReadMoreLable")}</Typography>
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
      </Box>
    </>
  );
}
