/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import "./KittyTable.scss";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { listofInvitedKitty } from "../../store/thunk/Kitty/listofKittyThunk";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomMPagination from "../common/CustomMPagination";

function MyComponent() {
  const [recordLimit, SetRecordLimit] = useState(10);
  const [pageCount, SetPageCount] = useState(0);
  const [pageCountShow, SetPageCountShow] = React.useState(1);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const data = useAppSelector((state) => state.listofKittySlice);
  const [search, setSearch] = useState("");
  const profileDetails = useAppSelector((state) => state?.UserProfile?.details);

  const subPage = (id) => {
    navigate(`/kittypage/${id}/`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      listofInvitedKitty({ limit: recordLimit, offSet: pageCount, search })
    );
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, search, recordLimit]);

  const redirectPage = () => {
    if (profileDetails?.is_superuser) {
      navigate("/my-kitty");
    } else {
      navigate("/admin");
    }
  };

  return (
    <>
      <Box className="kittyTable">
        <Box sx={{ borderRadius: 0 }}>
          <Grid container>
            <Paper
              sx={{
                width: "100%",
                padding: { md: 2, xs: 1 },
                margin: "16px 16px 0px 16px",
                background: "rgba(89, 32, 145, 0.1411764706)",
              }}
            >
              <Box
                className="tableTitle"
                style={{
                  textTransform: "uppercase",
                  marginTop: "10px",
                }}
              >
                {t("kittyListinSection.0")}
              </Box>
              <Box>
                <Button
                  onClick={redirectPage}
                  sx={{
                    color: "#868484",
                    fontWeight: "600",
                    justifyContent: "left",
                  }}
                >
                  {"< "}
                  {t("backLabel")}
                </Button>
              </Box>
            </Paper>
            {data?.invitedKitty?.results?.map((item) => (
              <Grid
                item
                md={12}
                key={item.id}
                sx={{ flex: "0 0 100%", maxWidth: "100%" }}
              >
                <Paper
                  sx={{
                    p: { xs: 1, md: 2 },
                    border: "1px solid #ddd",
                    margin: "16px 16px 0px 16px",
                  }}
                >
                  <Grid
                    container
                    sx={{
                      justifyContent: "space-between",
                      display: { md: "flex", xs: "block" },
                    }}
                  >
                    <Grid
                      item
                      md={3}
                      sx={{
                        paddingRight: { md: "20px", xs: "0" },
                        borderRight: { md: "1px solid #ddd", xs: "0px" },
                      }}
                      onClick={() => {
                        subPage(item.kitty.id);
                      }}
                    >
                      <img
                        src={item.kitty.image}
                        alt="TontineImage"
                        style={{
                          width: "100%",
                          height: { md: "150px", xs: "100%" },
                          objectFit: "contain",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={9}
                      sx={{ p: { xs: 0, md: "0 16px" }, textAlign: "left" }}
                      onClick={() => {
                        subPage(item.kitty.id);
                      }}
                    >
                      <Box
                        sx={{
                          display: { xs: "block", md: "flex" },
                          justifyContent: "space-between",
                          alignContent: "center",
                          m: { md: 0, xs: "10px 0" },
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "600",
                            color: "#555",
                            mb: 1,
                            fontSize: { md: "18px", xs: "16px " },
                          }}
                        >
                          {item.kitty.name}
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "600",
                            color: "#555",
                            fontSize: "15px",
                          }}
                        >
                          {t("statusLabel")} :{" "}
                          <span
                            style={{
                              background:
                                item.kitty.status === "OPEN"
                                  ? "#008000"
                                  : "#ff0000",
                              color: "#fff",
                              padding: "3px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            {item.kitty.status}
                          </span>
                        </Typography>
                      </Box>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#555",
                          mb: 1,
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        {`${t("tontineCreatedByTitle")}: `}
                        <span style={{ fontSize: "15px", fontWeight: "100" }}>
                          {`${item.user.first_name} ${item.user.name}`}
                        </span>
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#555",
                          mb: 1,
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        {t("AmountLable")} :{" "}
                        <span style={{ fontSize: "15px", fontWeight: "100" }}>
                          {item.kitty.amount}{" "}
                        </span>
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#555",
                          mb: 1,
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        {t("createdOnLabel")} :{" "}
                        <span style={{ fontSize: "15px", fontWeight: "100" }}>
                          {item.kitty.created_at.slice(0, 10)}{" "}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}

            {!data?.invitedKitty?.results?.length && (
              <Grid item md={12}>
                <Paper
                  sx={{
                    p: 2,
                    border: "1px solid #ddd",
                    margin: "16px 16px 0px 16px",
                    color: "#525453cf",
                    backgroundColor: "#f1efeb",
                    textAlign: "center",
                  }}
                >
                  <Grid container>
                    <Grid item md={12}>
                      {data.loading ? <CircularProgress /> : t("norecordLbl")}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
          <Box sx={{ mt: 1 }}>
            <CustomMPagination
              pageCount={pageCount}
              SetPageCount={SetPageCount}
              recordLimit={recordLimit}
              pageCountShow={pageCountShow}
              SetRecordLimit={SetRecordLimit}
              numRecords={data.invitedKitty?.num_records}
              numCount={data.invitedKitty?.count}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MyComponent;
