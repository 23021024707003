import Button from "@mui/material/Button";
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useAppDispatch } from "../../store/store";
import { insertNewKittyCat } from "../../store/thunk/KittyCategory/InsertNewKittyCatThunk";
import FileUploader from "../common/imageUpload";
import { Grid, Box, FormHelperText, MenuItem } from "@mui/material";
import SearchBar from "../common/SearchBar";

export default function KittyCatUpdate(props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [formError, setFormError] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const dateObj = moment().format();
  const [searchTerm, setSearchTerm] = useState("");
  const [kittyCatData, setkittyCatData] = React.useState({
    category_name: "",
    category_type: "GROUPGIFTS",
    description: "",
    image: "",
    creation_date: dateObj.slice(0, 18),
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.setSearch(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const CategoryType = [
    {
      value: "GROUPGIFTS",
      label: "Group Gifts",
    },
    {
      value: "PROJECTCAUSES",
      label: "Projects & Causes",
    },
    {
      value: "COMMONEXPENSES",
      label: "Common Expenses",
    },
  ];

  const clearData = {
    category_name: "",
    description: "",
    created_at: dateObj.slice(0, 19),
    updated_at: dateObj.slice(0, 19),
    image: "",
  };

  const validateForm = (data) => {
    let error = [];

    if (data.category_name === "") {
      error.push("category_name");
    }
    if (data.description === "") {
      error.push("description");
    }
    // if (data.image === "") {
    //   error.push("image");
    // }

    setFormError(error);

    return error;
  };

  const handleClickOpen = () => {
    setkittyCatData(clearData);
    setFormError([]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updatekittyCatData = (text, event) => {
    setkittyCatData({
      ...kittyCatData,
      [text]: event.target.value,
    });

    const index = formError.indexOf(text);
    if (index !== -1) {
      formError.splice(index, 1);
    }
  };

  const handleSubmitCallback = () => {
    setkittyCatData(clearData);
    props.refreshSearch();
    handleClose();
  };

  const handleSubmit = () => {
    let error = validateForm(kittyCatData);
    if (error.length == 0) {
      let request = {
        ...kittyCatData,
        category_type: kittyCatData.category_type || "GROUPGIFTS",
        callback: handleSubmitCallback,
      };
      dispatch(insertNewKittyCat(request));
    }
  };

  const handleSelectFile = (file) => {
    setkittyCatData((kittyData) => ({
      ...kittyData,
      image: file,
    }));
  };

  return (
    <Box sx={{ display: { md: "flex", xs: "block" }, alignItems: "center" }}>
      <Box
        sx={{
          marginBottom: { lg: "0", xs: "10px" },
          mr: { md: "10px", xs: "0px" },
          mt: { md: "0", xs: "10px" },
        }}
      >
        <SearchBar searchVal={searchTerm} setSearchFunc={setSearchTerm} />
      </Box>
      <Button
        variant="contained"
        onClick={() => {
          handleClickOpen();
        }}
        sx={{
          border: "1px solid #592091",
          backgroundColor: "#592091",
          borderRadius: "5px !important",
          padding: { md: "7px 20px", xs: "10px" },
          color: "white",
          width: { md: "auto", xs: "100%" },
          "&:hover": {
            background: "#fff",
            color: "#592091",
          },
        }}
      >
        {t("kittyCatListinSection.2")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { m: { md: "30px", xs: "10px" } } }}
      >
        <DialogTitle
          sx={{
            textTransform: "uppercase",
            borderBottom: "1px solid #ddd",
            background:
              "linear-gradient(to left, #592091, #4b0082, #91bcff, #1064e7, #808080, #4b0082, #0161f7)",
            WebkitBackgroundClip: "text",
          }}
        >
          {t("kittyCatFieldLable.0")}
        </DialogTitle>
        <DialogContent sx={{ padding: { md: "0 24px", xs: "0 10px" } }}>
          <DialogContentText
            sx={{
              fontSize: { md: "20px", xs: "15px" },
              fontWeight: "600",
              m: "10px",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            {t("kittyCatFieldLable.1")}
          </DialogContentText>
          <Grid container>
            <Grid item sx={{ padding: "1px 4px", mt: 1 }} xs={12} md={6}>
              <FileUploader onSelectFile={handleSelectFile} />
              {formError.includes("image") && (
                <FormHelperText id="component-error-text" error>
                  {t("kittyCatFieldValidation.0")}
                </FormHelperText>
              )}
            </Grid>
            <Grid
              item
              sx={{ padding: { md: "1px 4px 1px 20px", xs: 0 } }}
              xs={12}
              md={6}
            >
              <Box sx={{ padding: "1px 4px" }}>
                <TextField
                  margin="dense"
                  size="small"
                  id="name"
                  value={kittyCatData.category_name}
                  onChange={(e) => {
                    updatekittyCatData("category_name", e);
                  }}
                  label={t("kittyCatFieldLable.2")}
                  type="text"
                  variant="outlined"
                  sx={{ display: "flex" }}
                  error={formError.includes("category_name")}
                  helperText={
                    formError.includes("category_name")
                      ? t("kittyCatFieldValidation.1")
                      : ""
                  }
                />
              </Box>
              <Box sx={{ padding: "1px 4px" }}>
                <TextField
                  margin="dense"
                  size="small"
                  value={kittyCatData.description}
                  onChange={(e) => {
                    updatekittyCatData("description", e);
                  }}
                  id="description"
                  label={t("kittyCatFieldLable.3")}
                  type="text"
                  sx={{ display: "flex" }}
                  variant="outlined"
                  error={formError.includes("description")}
                  helperText={
                    formError.includes("description")
                      ? t("kittyCatFieldValidation.2")
                      : ""
                  }
                />
              </Box>
              <Box sx={{ padding: "1px 4px" }}>
                <TextField
                  margin="dense"
                  size="small"
                  value={kittyCatData.category_type}
                  id="outlined-select-CategoryType"
                  select
                  label={t("kittyCatFieldLable.4")}
                  defaultValue="GROUPGIFTS"
                  onChange={(e) => {
                    updatekittyCatData("category_type", e);
                  }}
                  type="text"
                  sx={{ display: "flex" }}
                  variant="outlined"
                  error={formError.includes("category_type")}
                  helperText={
                    formError.includes("category_type")
                      ? "Please select Category Type"
                      : ""
                  }
                >
                  {CategoryType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            p: { md: "20px", xs: "10px" },
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{
              fontSize: "12px",
              background: "#fff",
              color: "#592091",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={() => {
              handleClose();
              setkittyCatData(clearData);
            }}
          >
            {t("cancelLable")}
          </Button>
          <Button
            sx={{
              background: "#592091",
              color: "#fff",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={() => {
              handleSubmit();
            }}
          >
            {t("insertLable")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
