import * as React from "react";
import "./ActivationLink.scss";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { resendEmailActivationLink } from "../../../store/thunk/ForgetPasswordThunk";
import { useAppDispatch } from "../../../store/store";

export default function ActivationLink() {
  const dispatch = useAppDispatch();
  const [disableBtn, setDisableBtn] = React.useState(false);
  const { email } = useParams();
  const { t } = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRequest = () => {
    setDisableBtn(true);
    let request = {
      email,
      callback: () => {
        setTimeout(() => {
          setDisableBtn(false);
        }, 1000);
      },
    };

    dispatch(resendEmailActivationLink(request));
  };

  return (
    <>
      <Box
        fullWidth
        sx={{
          background: "rgb(89, 32, 145)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            zIndex: 1,
            background: "#f1efeb",
            padding: 4,
            margin: 5,
            mt: "100px",
            width: "400px",
          }}
          className="ForgetPasswordForm"
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                align="left"
                width={"100%"}
                sx={{
                  textAlign: "center",
                  marginBottom: "20px",
                  fontWeight: "600",
                }}
              >
                {t("registerSuccessfully")}
              </Typography>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", fontSize: "16px", m: "10px 0px" }}
              >
                {t("registerSuccessfullyMessage")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 1,
                    width: "100%",
                    height: "50px",
                  }}
                  onClick={handleRequest}
                  disabled={disableBtn}
                >
                  {t("resendLinktoemail")}
                </Button>
                <Box className="alreadyMember" sx={{ mt: "10px" }}>
                  <Box>
                    {t("createAnAccountAlreadyLable")}{" "}
                    <Link to="/sign-in">{t("createAnAccountLoginLable")}</Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}
