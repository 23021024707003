import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: "",
  message: "",
  commonLoader:false,
};

const AlertSlice = createSlice({
  name: "setAlert",
  initialState,
  reducers: {
    setSuccessAlert: (state, action) => ({
      ...state,
      status:true,
      message: action.payload,
    }),
    setErrorAlert: (state, action) => ({
      ...state,
      status:false,
      message: action.payload,
    }),
    setPendingAlert: (state, action) => ({
      ...state,
      status:"pending",
      message: action.payload,
    }),
    setCommonLoader: (state, action) => ({
      ...state,
      commonLoader :action.payload,
    }),
  },
});

export const { setSuccessAlert, setErrorAlert, setPendingAlert, setCommonLoader } = AlertSlice.actions;

export default AlertSlice.reducer;
