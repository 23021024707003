/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../../../common/DataTableBase";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { listofKittyCat } from "../../../../../store/thunk/KittyCategory/listofKittyCatThunk";
import { Button, Box } from "@mui/material";
import { createTheme } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {
  GetPausedTontineDetails,
  ResumePausedTontine,
} from "../../../../../store/thunk/Tontine/MytontineThunk";
import CustomMPagination from "../../../../common/CustomMPagination";
if (!localStorage.getItem("SuperAdminKittyCategoryPageCount")) {
  localStorage.setItem("SuperAdminKittyCategoryPageCount", 0);
  localStorage.setItem("SuperAdminKittyCategoryRecordLimit", 10);
  localStorage.setItem("SuperAdminKittyCategorySearch", "");
}

function MyComponent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pageCount, SetPageCount] = useState(
    localStorage.getItem("SuperAdminKittyCategoryPageCount")
  );
  const [recordLimit, SetRecordLimit] = useState(
    localStorage.getItem("SuperAdminKittyCategoryRecordLimit")
  );
  const [pageCountShow, SetPageCountShow] = useState(
    (Number(pageCount) + Number(recordLimit)) / Number(recordLimit)
  );
  const { t } = useTranslation();
  const data = useAppSelector((state) => state.listofKittyCatSlice);
  const [search, setSearch] = useState(
    localStorage.getItem("SuperAdminKittyCategorySearch")
  );
  const PausedTontine = useAppSelector(
    (state) => state.MyTontine?.PausedTontineList
  );
  useEffect(() => {
    dispatch(GetPausedTontineDetails());
  }, []);
  // useEffect(() => {
  //   localStorage.setItem("SuperAdminKittyCategoryPageCount", Number(pageCount));
  //   localStorage.setItem(
  //     "SuperAdminKittyCategoryRecordLimit",
  //     Number(recordLimit)
  //   );
  //   localStorage.setItem("SuperAdminKittyCategorySearch", search);
  // }, [pageCount, recordLimit, search, localStorage.getItem("language")]);

  const columns = [
    {
      name: t("kittyCatListingTableLable.0"),
      selector: useCallback(
        (row) => (
          <img
            width={50}
            height={50}
            src={row.image}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/tontine/${row.id}/`, {
                state: { backRedirectUrl: "/admin-paused-tontine" },
              })
            }
          />
        ),
        []
      ),
      // width: "4rem",
    },
    {
      name: t("kittyCatListingTableLable.1"),
      selector: (row) => row.name,
    },
    {
      name: t("StartDateLable"),
      selector: (row) => row.creation_date,
    },
    {
      name: t("tontineFrequencyFieldLabel"),
      selector: (row) => row.frequency,
    },
    {
      name: t("FutureReliablityLable"),
      selector: (row) => row.future_reliability,
    },
    {
      name: t("tontineTableColumn.4"),
      selector: (row) => row.tontine_participants_id?.length,
    },
    {
      name: t("tontineStatusTitle"),
      selector: (row) => row.status,
    },
    {
      name: t("kittyCatListingTableLable.5"),
      width: "10rem",
      cell: useCallback(
        (row) => (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "rows",
            }}
          >
            <Button
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                p: "8px 6px",
                m: "unset",
                color: "#fff",
                backgroundColor: "#592091",
                "&:hover": {
                  backgroundColor: "#592091cf",
                },
              }}
              onClick={() => {
                dispatch(ResumePausedTontine(row?.id));
              }}
            >
              {t("ResumeTontineLable")}
            </Button>
          </Box>
        ),
        []
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    dispatch(
      listofKittyCat({
        limit: Number(recordLimit),
        offSet: Number(pageCount),
        search,
      })
    );
    SetPageCountShow(
      (Number(pageCount) + Number(recordLimit)) / Number(recordLimit)
    );
  }, [pageCount, recordLimit, search]);

  const refreshSearch = () => {
    dispatch(
      listofKittyCat({
        limit: Number(recordLimit),
        offSet: Number(pageCount),
        search: localStorage.getItem("SuperAdminKittyCategorySearch"),
      })
    );
  };

  // useEffect(() => {
  //   dispatch(
  //     listofKittyCat({
  //       limit: recordLimit,
  //       offSet: 0,
  //       search,
  //       callback: () => {
  //         if (search !== "") {
  //           SetPageCount(0);
  //         }
  //       },
  //     })
  //   );
  // }, [search]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "#ccc",
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "#fff ",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        textAlign: "center",
        fontSize: "17px",
        display: "grid",
        placeContent: "center",
        backgroundColor: "#2a2828",
        color: "#fff",
        maxWidth: "40px",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        padding: "8px",
        fontSize: "14px",
        display: "grid",
        placeContent: "center",
      },
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );

  return (
    <Box className="kittyTable">
      <Box sx={{ padding: { md: 2, xs: 1 } }}>
        <DataTable
          title={
            <>
              <Box
                className="tableTitle"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                {t("PausedTontineHeadLable")}
              </Box>
            </>
          }
          columns={columns}
          data={PausedTontine?.results}
          customStyles={customStyles}
          theme="solarized"
          progressPending={data.loading}
          noDataComponent={<Box sx={{ p: 3 }}>{t("norecordLbl")}</Box>}
          className="rdt_TableHeader"
        />
      </Box>
      <CustomMPagination
        pageCount={pageCount}
        SetPageCount={SetPageCount}
        recordLimit={recordLimit}
        pageCountShow={pageCountShow}
        SetRecordLimit={SetRecordLimit}
        numRecords={PausedTontine?.num_records}
        numCount={PausedTontine?.count}
      />
    </Box>
  );
}

export default MyComponent;
