import React from "react";
import DataTable from "react-data-table-component";
import Checkbox from "@mui/material/Checkbox";


import ArrowDownward from "@mui/icons-material/ArrowDownward";

const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };

function DataTableBase(props) {
  return (
    <DataTable
      selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={selectProps}
      sortIcon={sortIcon}
      {...props}
      striped
    />
  );
}

export default DataTableBase;
