import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setUserDetailsLoader,
  setUserDetailsSuccess,
  setUserDetailsError,
  setUserList,
  setListOfBlockedUsers,
} from "../../reducer/User/UserProfileSlice";

import axios from "axios";
import { DeleteData } from "../../reducer/User/TokenExpireSlice";
import { setCommonLoader, setSuccessAlert } from "../../reducer/AlertSlice";
import { setDeleteKitty } from "../../reducer/KittySlice/DeleteKittyListSlice";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";

export const ListOfAllUsers = createAsyncThunk(
  "ListOfAllUsers",
  async (_request, { dispatch }) => {
    let search = "";
    let userType = "";

    if (
      _request.search &&
      _request.search.toString() !== "" &&
      _request.search.toString() !== ",verified" &&
      _request.search.toString() !== ","
    ) {
      search = `&search=${_request.search}`;
    }

    if (
      _request.user_type &&
      _request.user_type !== "" &&
      _request.user_type !== ""
    ) {
      if (
        _request.user_type === "All Users" ||
        _request.user_type === "Tous les utilisateurs"
      ) {
        userType = "";
      } else {
        userType = `&user_type=${_request.user_type}`;
      }
    }

    dispatch(setUserDetailsLoader(true));
    axios
      .get(
        `api/v1/accounts/users/?limit=${_request.limit}&offset=${_request.offSet}${search}${userType}`,
        {
          headers: {
            Authorization: localStorage.getItem("UserInfo")
              ? `Token ${localStorage.getItem("UserInfo")}`
              : "",
          },
        }
      )
      .then((response) => {
        dispatch(setUserDetailsLoader(false));
        dispatch(setCommonLoader(false));
        dispatch(setUserDetailsSuccess(true));
        dispatch(setUserList(response.data));
      })
      .catch((err) => {
        dispatch(setUserDetailsError(err));
        setUserDetailsLoader(false);
        if (err?.response?.data?.detail === "Invalid token") {
          if (_request.callback) {
            _request.callback();
          }

          dispatch(DeleteData());
        }
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const DeleteUser = createAsyncThunk(
  "DeleteTontineDocument",
  async (_request, { dispatch }) => {
    axios
      .delete(`api/v1/accounts/users/${_request.id}/`)
      .then((response) => {
        _request.callback();
        dispatch(setDeleteKitty());
        dispatch(setSuccessAlert("User deleted successfully"));
      })
      .catch((err) => {
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListOfBlockedUsers = createAsyncThunk(
  "getListOfBlockedUsers",
  async (_request, { dispatch }) => {
    let search = "";
    if (_request.search && _request.search !== "") {
      search = `&search=${_request.search.toString()}`;
    }
    dispatch(setUserDetailsLoader(true));
    axios
      .get(
        `api/v1/accounts/block-users-list/?limit=${_request.limit}&offset=${_request.offSet}${search}`
      )
      .then((response) => {
        dispatch(setUserDetailsLoader(false));
        dispatch(setListOfBlockedUsers(response?.data));
        if (_request.callback()) {
          _request.callback();
        }
      })
      .catch((err) => {
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const unblockUserProfile = createAsyncThunk(
  "unblockUserProfile",
  async (_request, { dispatch }) => {
    const { limit, offSet, user_id, request } = _request;
    axios
      .patch(`/api/v1/accounts/users/${user_id}/`, request)
      .then((response) => {
        dispatch(getListOfBlockedUsers({ limit, offSet }));
      })
      .catch((err) => {
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
