import * as React from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import VerifyDocuments from "./VerifyDocuments";
import "./verifydocuments.scss";

export default function VerifyDocumentComponent() {
  return (
    <>
      <Header />
      <VerifyDocuments />
      <Footer />
    </>
  );
}
