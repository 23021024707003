import * as React from "react";
import { useTranslation } from "react-i18next";
import "../../assets/scss/common.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, Paper, useMediaQuery } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailIcon from "@mui/icons-material/Mail";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleIcon from "@mui/icons-material/People";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import { TempKittyUpdate } from "../../store/thunk/Kitty/KittyDetailsThunk";
import Update from "./Tabs/Update";
import GeneralSettings from "./Tabs/GeneralSettings";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/store";
import Invites from "./Tabs/Invites";
import AddImage from "./Tabs/AddImage";
import Invitations from "./Tabs/Invitations";
import {
  CheckUserDocumentStatus,
  GetListParticipantTontine,
} from "../../store/thunk/Tontine/MytontineThunk";
import ParticipantRanking from "./Tabs/ParticipantRearrangeLayout";
import ListOfTontineCycle from "./Tabs/ListOfTontineCycle";
import CurrentCycleDetails from "../../layout/tontinePage/tontineLanding/ContributionPage/CurrentCycleDetails";
import StartTonine from "./Tabs/StartTontine";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { md: "10px 24px", xs: "10px" } }}>
          <Typography sx={{ width: "100%" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs(props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { index } = useParams();
  const list = useAppSelector((state) => state.MyTontine.singleTontine);
  const documentStatus = useAppSelector((state) => state.MyTontine);
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    // dispatch(DetailsOfKitty(id));
  }, []);
  // React.useEffect(() => {
  //   dispatch(
  //     TempKittyUpdate({
  //       content: list.object_of_kitty,
  //       objKitty: true,
  //       id: list.id,
  //     })
  //   );
  // }, [list]);

  React.useEffect(() => {
    dispatch(CheckUserDocumentStatus({ navigate: navigate }));
    // dispatch(
    //   listofMyTontine({
    //     limit: 10,
    //     offSet: 0,
    //   })
    // );
    dispatch(GetListParticipantTontine(id));
    setValue(Number(index));
  }, []);

  const smallScreen = useMediaQuery("(max-width: 991px)");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ flexGrow: 1, display: "flex" }}>
      <Grid container className="slider-box vertical-slider-box">
        <Grid item md={2} className="vertical-slider-tab">
          <Tabs
            orientation={smallScreen ? " horizonal" : "vertical"}
            // orientation="vertical"
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { background: "#592091" } }}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              tabs: {
                borderRight: "none",
                overflow: "visible",
              },
              ".MuiButtonBase": {
                root: {
                  ".MuiSelected": {
                    background: "#592091",
                  },
                },
              },
            }}
          >
            <Tab
              icon={
                <VisibilityIcon
                  sx={{
                    marginRight: "10px",
                  }}
                />
              }
              label={t("tonetineGeneralTab")}
              sx={{
                p: 1,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start",
                textAlign: "left",
              }}
              {...a11yProps(0)}
              onClick={() => {
                dispatch(
                  TempKittyUpdate({
                    content: list.object_of_kitty,
                    objKitty: true,
                    id: list.id,
                  })
                );
              }}
            />
            {!list?.is_started ? (
              <Tab
                icon={
                  <MailIcon
                    sx={{
                      marginRight: " 10px",
                    }}
                  />
                }
                label={t("inviteLabel")}
                sx={{
                  p: 1,
                  fontWeight: "600",
                  flexDirection: "row !important",
                  justifyContent: "flex-start ",
                }}
                {...a11yProps(1)}
              />
            ) : null}
            <Tab
              icon={
                <ImageOutlinedIcon
                  sx={{
                    marginRight: " 10px",
                  }}
                />
              }
              label={t("imgLabel")}
              sx={{
                p: 1,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(2)}
            />

            <Tab
              icon={
                <DescriptionIcon
                  sx={{
                    marginRight: " 10px",
                  }}
                />
              }
              label={t("descLabel")}
              sx={{
                p: 1,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(3)}
            />

            {/* <Tab
              icon={
                <EuroIcon
                  sx={{
                    marginRight: " 10px",
                  }}
                />
              }
              label={t("amtLabel")}
              sx={{
                p: 1,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(3)}
            /> */}
            {/* <Tab
              icon={
                <NotificationsIcon
                  sx={{
                    marginRight: " 10px",
                  }}
                />
              }
              label={t("notificationLabel")}
              sx={{
                p: 1,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(4)}
            /> */}

            {!list?.is_started ? (
              <Tab
                icon={
                  <PeopleIcon
                    sx={{
                      marginRight: " 10px",
                    }}
                  />
                }
                label={t("invitationLabel")}
                sx={{
                  p: 1,
                  fontWeight: "600",
                  flexDirection: "row !important",
                  justifyContent: "flex-start ",
                }}
                {...a11yProps(4)}
              />
            ) : null}
            {!list?.is_started ? (
              <Tab
                icon={
                  <HistoryEduIcon
                    sx={{
                      marginRight: " 10px",
                    }}
                  />
                }
                label={t("ParticipantsRankingLable")}
                sx={{
                  p: 1,
                  fontWeight: "600",
                  flexDirection: "row !important",
                  justifyContent: "flex-start ",
                  textAlign: "left !important",
                }}
                {...a11yProps(5)}
              />
            ) : null}
            <Tab
              icon={
                <PaymentsIcon
                  sx={{
                    marginRight: " 10px",
                  }}
                />
              }
              label={t("TontineCyclesLable")}
              sx={{
                p: 1,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(6)}
            />
            {!list?.is_started ? (
              <Tab
                icon={
                  <PaymentsIcon
                    sx={{
                      marginRight: " 10px",
                    }}
                  />
                }
                label={t("TontineLandingPage.1")}
                sx={{
                  p: 1,
                  fontWeight: "600",
                  flexDirection: "row !important",
                  justifyContent: "flex-start ",
                }}
                {...a11yProps(7)}
              />
            ) : null}
            <Tab
              icon={
                <PaymentsIcon
                  sx={{
                    marginRight: " 10px",
                  }}
                />
              }
              label={t("CurrentCycleLable")}
              sx={{
                p: 1,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(8)}
            />
          </Tabs>
        </Grid>
        <Grid item md={10} sx={{ width: "100%" }}>
          <TabPanel value={value} index={0}>
            <Typography
              sx={{
                mb: 1,
                textAlign: "center !important",
                color: "#592091 !important",
                display: "table !important",
                margin: "0 auto !important",
                fontWeight: "600 !important",
                lineHeight: "26px !important",
                fontSize: { md: "27px !important", xs: "18px !important" },
                padding: { md: "20px !important", xs: "10px !important" },
              }}
            >
              {t("tonetineGeneralTab")}
            </Typography>
            <Paper
              sx={{
                p: 2,
                width: { md: "95%", xs: "auto" },
                border: "1px solid #ddd",
              }}
            >
              <GeneralSettings id={id} />
            </Paper>
          </TabPanel>
          <TabPanel value={value} index={list?.is_started ? 1 : 2}>
            <Typography
              sx={{
                mb: 1,
                textAlign: "center !important",
                color: "#592091 !important",
                display: "table !important",
                margin: "0 auto !important",
                fontWeight: "600 !important",
                lineHeight: "26px !important",
                fontSize: { md: "27px !important", xs: "18px !important" },
                padding: { md: "20px !important", xs: "10px !important" },
              }}
            >
              {t("imgLabel")}
            </Typography>
            <AddImage />
          </TabPanel>
          <TabPanel
            value={value}
            index={list?.is_started ? 2 : 3}
            style={{ width: "100%" }}
          >
            <Typography
              sx={{
                mb: 1,
                textAlign: "center !important",
                color: "#592091 !important",
                display: "table !important",
                margin: "0 auto !important",
                fontWeight: "600 !important",
                lineHeight: "26px !important",
                fontSize: { md: "27px !important", xs: "18px !important" },
                padding: { md: "20px !important", xs: "10px !important" },
                width: "100%",
              }}
              variant="h5"
            >
              {t("descLabel")}
            </Typography>
            <Update showCancel={false} />
          </TabPanel>
          {/* <TabPanel value={value} index={3}>
            <Typography
              sx={{
                mb: 1,
                textAlign: "center",
                color: "#592091",
                display: " table",
                margin: " 0 auto",
                fontWeight: " 600",
                lineHeight: "26px",
                fontSize: { md: "27px", xs: "18px" },
                padding: { md: "20px", xs: "10px" },
              }}
            >
              {t("amtLabel")}
            </Typography>
            <Amount />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Typography
              sx={{
                mb: 1,
                textAlign: "center",
                color: "#592091",
                display: " table",
                margin: " 0 auto",
                fontWeight: " 600",
                lineHeight: "26px",
                fontSize: { md: "27px", xs: "18px" },
                padding: { md: "20px", xs: "10px" },
              }}
            >
              {t("notificationLabel")}
            </Typography>
            <Notifications />
          </TabPanel> */}
          {!list?.is_started ? (
            <TabPanel value={value} index={1}>
              <Typography
                sx={{
                  mb: 1,
                  textAlign: "center !important",
                  color: "#592091 !important",
                  display: "table !important",
                  margin: "0 auto !important",
                  fontWeight: "600 !important",
                  lineHeight: "26px !important",
                  fontSize: { md: "27px !important", xs: "18px !important" },
                  padding: { md: "20px !important", xs: "10px !important" },
                }}
              >
                {t("inviteLabel")}
              </Typography>
              <Invites id={{ id, name: list.name }} />
            </TabPanel>
          ) : null}
          {!list?.is_started ? (
            <TabPanel value={value} index={4}>
              <Typography
                sx={{
                  mb: 1,
                  textAlign: "center !important",
                  color: "#592091 !important",
                  display: "table !important",
                  margin: "0 auto !important",
                  fontWeight: "600 !important",
                  lineHeight: "26px !important",
                  fontSize: { md: "27px !important", xs: "18px !important" },
                  padding: { md: "20px !important", xs: "10px !important" },
                }}
              >
                {t("invitationLabel")}
              </Typography>

              <Invitations id={{ id, name: list.name }} />
            </TabPanel>
          ) : null}
          {!list?.is_started ? (
            <TabPanel value={value} index={5}>
              <Typography
                sx={{
                  mb: 1,
                  textAlign: "center",
                  color: "#592091",
                  display: " table",
                  margin: " 0 auto",
                  fontWeight: "600",
                  lineHeight: "26px",
                  fontSize: { md: "27px", xs: "18px" },
                  padding: { md: "20px", xs: "10px" },
                }}
              >
                {t("TontineLandingPage.0")}
              </Typography>

              <ParticipantRanking />
            </TabPanel>
          ) : null}
          <TabPanel value={value} index={list?.is_started ? 3 : 6}>
            <Typography
              sx={{
                mb: 1,
                textAlign: "center",
                color: "#592091",
                display: " table",
                margin: " 0 auto",
                fontWeight: " 600",
                lineHeight: "26px",
                fontSize: { md: "27px", xs: "18px" },
                padding: { md: "20px", xs: "10px" },
              }}
            >
              {t("TontineCyclesLables")}
            </Typography>

            <ListOfTontineCycle />
          </TabPanel>
          {!list?.is_started ? (
            <TabPanel value={value} index={7}>
              <Typography
                sx={{
                  mb: 1,
                  textAlign: "center",
                  color: "#592091",
                  display: " table",
                  margin: " 0 auto",
                  fontWeight: " 600",
                  lineHeight: "26px",
                  fontSize: { md: "27px", xs: "18px" },
                  padding: { md: "20px", xs: "10px" },
                }}
              >
                {t("TontineLandingPage.1")}
              </Typography>

              <StartTonine data={list} />
            </TabPanel>
          ) : null}
          <TabPanel value={value} index={list?.is_started ? 4 : 8}>
            <Typography
              sx={{
                mb: 1,
                textAlign: "center",
                color: "#592091",
                display: " table",
                margin: " 0 auto",
                fontWeight: " 600",
                lineHeight: "26px",
                fontSize: { md: "27px", xs: "18px" },
                padding: { md: "20px", xs: "10px" },
              }}
            >
              {t("CurrentCycleListLable")}
            </Typography>

            <CurrentCycleDetails />
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
}
