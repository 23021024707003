import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setDetailsOfKitty,
  setDetailsOfKittyLoader,
  setDetailsOfKittySuccess,
  setSettingssOfKitty,
  setKittyUpdate,
  setKittyLike,
  setTempKittyUpdate,
  setDetailsOfKittyError,
  setKittyLikeData,
  setKittyContribute,
  setKittyContributeAmount,
  setKittyInvitationList,
  setAdminKittyLogList,
  setAdminKittyCategoryLogList,
  setAdminKittyLikeLogList,
  setAdminKittyContributeLogList,
  setAdminKittyCommentLogList,
  setAdminKittyMessageLogList,
  setAdminKittySettingLogList,
  setAdminKittyUpdateLogList,
  setAdminKittyInviteLogList,
  setSwapLink,
  setAdminKittyContributeList,
  setAdminKittyContributePayoutList,
  setAdminKittyPayoutLogList,
  setadminKittyPayoutIntentLogList,
} from "../../reducer/KittySlice/KittyDetailsSlice";
import { setSuccessAlert, setErrorAlert } from "../../reducer/AlertSlice";
import axios from "axios";
import { DeleteData } from "../../reducer/User/TokenExpireSlice";

if (localStorage.getItem("UserInfo")) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers["Authorization"] = localStorage.getItem("UserInfo")
    ? `Token ${localStorage.getItem("UserInfo")}`
    : "";
} else {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
}

export const DetailsOfKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));
    axios
      .get(`api/v1/kitty/kitty/${_request}/`)
      .then((response) => {
        dispatch(setDetailsOfKittyLoader(false));
        // dispatch(setSuccess(true));
        dispatch(setDetailsOfKitty(response.data));
        dispatch(setSettingssOfKitty(response.data.kitty_for_settings[0]));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        // _request.callback();
        // dispatch(setSuccess(false));
        dispatch(setDetailsOfKittyError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const CommentKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));
    axios
      .post(`/api/v1/kitty/kitty-comment/`, _request.data, {
        headers: {
          Authorization: localStorage.getItem("UserInfo")
            ? `Token ${localStorage.getItem("UserInfo")}`
            : `Token ${_request.token}`,
        },
      })
      .then((response) => {
        dispatch(setDetailsOfKittyLoader(false));
        dispatch(setSuccessAlert("Message Send"));
        dispatch(DetailsOfKitty(response.data.kitty.id));
        // dispatch(setSuccess(true));
        // dispatch(setDetailsOfKitty(response));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        // dispatch(setSuccess(false));
        // dispatch(setDetailsOfKittyError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const DeleteCommentKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));
    axios
      .delete(`api/v1/kitty/kitty-comment/${_request.id}/`)
      .then((response) => {
        dispatch(setDetailsOfKittyLoader(false));
        dispatch(DetailsOfKitty(_request.kitty));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const EditCommentKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));
    axios
      .patch(`api/v1/kitty/kitty-comment/${_request.id.id}/`, _request.data)
      .then((response) => {
        dispatch(setDetailsOfKittyLoader(false));
        dispatch(DetailsOfKitty(_request.id.kitty));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const UpdateofKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));
    axios
      .post(`/api/v1/kitty/kitty-updates/`, _request)
      .then((response) => {
        dispatch(setDetailsOfKittyLoader(false));
        dispatch(DetailsOfKitty(response.data.kitty));
        dispatch(setKittyUpdate(false));
        // dispatch(setSuccess(true));
        // dispatch(setDetailsOfKitty(response));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        // dispatch(setSuccess(false));
        // dispatch(setDetailsOfKittyError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const DeleteUpdateofKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));
    axios
      .delete(`/api/v1/kitty/kitty-updates/${_request.id}/`)
      .then((response) => {
        dispatch(setDetailsOfKittyLoader(false));
        dispatch(DetailsOfKitty(_request.kitty));
        setKittyUpdate(false);
        // dispatch(setSuccess(true));
        // dispatch(setDetailsOfKitty(response));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        // dispatch(setSuccess(false));
        // dispatch(setDetailsOfKittyError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const likeofKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    axios
      .post(`api/v1/kitty/kitty-like/`, _request)
      .then((response) => {
        dispatch(setKittyLike(response.data));
        dispatch(DetailsOfKitty(_request.kitty));
        dispatch(getKittyLikeDetail({ kitty_id: _request.kitty }));
        if (_request.callback) {
          _request.callback();
        }
      })
      .catch((err) => {
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const likeEditofKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));
    axios
      .patch(`api/v1/kitty/kitty-like/${_request.id.id}/`, _request.data)
      .then((response) => {
        dispatch(setDetailsOfKittyLoader(false));
        dispatch(DetailsOfKitty(_request.data.kitty));
        dispatch(setKittyLike(response.data));
        dispatch(getKittyLikeDetail({ kitty_id: _request.data.kitty }));
        if (_request.callback) {
          _request.callback();
        }
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const TempKittyUpdate = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setTempKittyUpdate(_request));
  }
);

export const editUpdateofKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    axios
      .patch(`api/v1/kitty/kitty-updates/${_request?.id}/`, _request?.data)
      .then((response) => {
        dispatch(setDetailsOfKittyLoader(false));
        dispatch(DetailsOfKitty(response.data.kitty));
        dispatch(setKittyUpdate(false));
        // dispatch(setSuccess(true));
        // dispatch(setDetailsOfKitty(response));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        // dispatch(setSuccess(false));
        // dispatch(setDetailsOfKittyError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const emailInvite = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    axios
      .post(`/api/v1/kitty/invite-kitty-email/`, _request)
      .then((response) => {
        dispatch(setDetailsOfKittySuccess(true));
        dispatch(setSuccessAlert("Invite Send"));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittySuccess(false));
        dispatch(
          setErrorAlert("Something went wrong, try again after some time")
        );
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const emailInviteSwapKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    axios
      .post(`/api/v1/kitty/invite-email-kitty-swapping/`, _request)
      .then((response) => {
        dispatch(setDetailsOfKittySuccess(true));
        dispatch(setSuccessAlert("Invite Send"));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittySuccess(false));
        dispatch(
          setErrorAlert("Something went wrong, try again after some time")
        );
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getKittyLikeDetail = createAsyncThunk(
  "getKittyLikeDetail",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    let kitty_id = "";
    if (_request.kitty_id) {
      kitty_id = `kitty_id=${_request.kitty_id}`;
    }

    axios
      .get(`api/v1/kitty/kitty-like/?${kitty_id}`)
      .then((response) => {
        dispatch(setKittyLikeData(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const AddMessageKitty = createAsyncThunk(
  "AddMessageKitty",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));
    axios
      .post(`/api/v1/kitty/kitty-message/`, _request)
      .then((response) => {
        dispatch(setDetailsOfKittyLoader(false));
        dispatch(DetailsOfKitty(response.data.kitty));
        dispatch(_request.callback());
        dispatch(setSuccessAlert("Message added successfully"));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const UpdateMessageKitty = createAsyncThunk(
  "UpdateMessageKitty",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));
    axios
      .patch(`api/v1/kitty/kitty-message/${_request.messageId}/`, {
        message: _request.message,
        user: _request.user,
        kitty: _request.kitty,
      })
      .then((response) => {
        dispatch(setDetailsOfKittyLoader(false));
        dispatch(DetailsOfKitty(_request.kitty));
        dispatch(_request.callback());
        dispatch(setSuccessAlert("Message updated successfully"));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const DeleteMessageKitty = createAsyncThunk(
  "DeleteMessageKitty",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));
    axios
      .delete(`api/v1/kitty/kitty-message/${_request.id}/`)
      .then((response) => {
        dispatch(setDetailsOfKittyLoader(false));
        dispatch(DetailsOfKitty(_request.kitty));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));

        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const contributeListKitty = createAsyncThunk(
  "contributeListKitty",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));
    axios
      .get(
        `api/v1/kitty/get-contributors-by-kitty-id/?kitty_id=${_request.id}&limit=${_request.limit}`
      )
      .then((response) => {
        dispatch(setDetailsOfKittyLoader(false));
        dispatch(setKittyContribute(response.data));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));

        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const contributeAmountKitty = createAsyncThunk(
  "contributeAmountKitty",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));
    axios
      .get(
        `api/v1/kitty/get-contributed-amount-by-kitty-id/?kitty_id=${_request}`
      )
      .then((response) => {
        dispatch(setDetailsOfKittyLoader(false));
        dispatch(setKittyContributeAmount(response.data));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));

        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const InvitationListOfKitty = createAsyncThunk(
  "contributeAmountKitty",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));
    axios
      .get(
        `api/v1/kitty/get-invite-on-kitty/?kitty_id=${_request.id}&limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setDetailsOfKittyLoader(false));
        dispatch(setKittyInvitationList(response.data));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofKittyLog = createAsyncThunk(
  "getListofKittyLog",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    axios
      .get(
        `api/v1/kitty/kitty-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminKittyLogList(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofKittyCategoryLog = createAsyncThunk(
  "getListofKittyCategoryLog",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    axios
      .get(
        `api/v1/kitty/kitty-category-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminKittyCategoryLogList(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofKittyLikeLog = createAsyncThunk(
  "getListofKittyLikeLog",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    axios
      .get(
        `api/v1/kitty/kitty-like-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminKittyLikeLogList(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofKittyContributeLog = createAsyncThunk(
  "getListofKittyContributeLog",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    axios
      .get(
        `api/v1/kitty/kitty-contribute-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminKittyContributeLogList(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofKittyCommentLog = createAsyncThunk(
  "getListofKittyCommentLog",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    axios
      .get(
        `api/v1/kitty/kitty-comment-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminKittyCommentLogList(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofKittyMessageLog = createAsyncThunk(
  "getListofKittyMessageLog",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    axios
      .get(
        `api/v1/kitty/kitty-message-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminKittyMessageLogList(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofKittySettingLog = createAsyncThunk(
  "getListofKittySettingLog",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    axios
      .get(
        `api/v1/kitty/kitty-setting-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminKittySettingLogList(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofKittyUpdateLog = createAsyncThunk(
  "getListofKittyUpdateLog",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    axios
      .get(
        `api/v1/kitty/kitty-update-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminKittyUpdateLogList(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofKittyInviteLog = createAsyncThunk(
  "getListofKittyInviteLog",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    axios
      .get(
        `api/v1/kitty/kitty-invite-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminKittyInviteLogList(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const KittySwapLinkCheck = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    axios
      .post(`api/v1/kitty/check-kitty-swap-link/`, _request)
      .then((response) => {
        dispatch(
          setSwapLink({
            link: response.data.is_link,
            id: response.data.details[0].id,
          })
        );
      })
      .catch((err) => {
        dispatch(setDetailsOfKittySuccess(false));
        dispatch(setErrorAlert(err.response.data.message));
        if (_request.navigate) {
          _request?.navigate("/my-account");
        }
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofKittyContribute = createAsyncThunk(
  "getListofKittyContribute",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    axios
      .get(
        `api/v1/kitty/kitty-contribute/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminKittyContributeList(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofKittyContributePayoutList = createAsyncThunk(
  "getListofKittyContributePayoutList",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    axios
      .get(
        `/api/v1/kitty/kitty-payout/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminKittyContributePayoutList(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const RequestKittyContributePayout = createAsyncThunk(
  "RequestKittyContributePayout",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    axios
      .post(`/api/v1/kitty/kitty-payout/`, _request.request)
      .then((response) => {
        dispatch(setAdminKittyContributePayoutList(response.data));
        dispatch(setSuccessAlert("Request send successfully"));
        dispatch(setDetailsOfKittyLoader(false));
        if (_request.callback) {
          _request.callback();
        }
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const UpdateKittyContributePayout = createAsyncThunk(
  "RequestKittyContributePayout",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));
    axios
      .patch(`api/v1/kitty/kitty-payout/${_request.id}/`, _request.request)
      .then((response) => {
        dispatch(setSuccessAlert("Respose send successfully"));
        dispatch(setDetailsOfKittyLoader(false));
        if (_request.callback) {
          _request.callback();
        }
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofKittyPayoutLog = createAsyncThunk(
  "getListofKittyPayoutLog",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    axios
      .get(
        `api/v1/kitty/kitty-payout-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminKittyPayoutLogList(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
export const getListofKittyPayoutIntentsLog = createAsyncThunk(
  "getListofKittyPayoutLog",
  async (_request, { dispatch }) => {
    dispatch(setDetailsOfKittyLoader(true));

    axios
      .get(
        `api/v1/kitty/kitty-payment-intent-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setadminKittyPayoutIntentLogList(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittyLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
