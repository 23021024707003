import * as React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import AdminBlock from "./Admin";
import { Box } from "@mui/material";

export default function Admin() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Box sx={{ background: "#f1efeb" }} className="min-height-div">
        <AdminBlock />
      </Box>
      <Footer />
    </>
  );
}
