import React from "react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import classNames from "classnames";
import "./styles.scss";
import {
  GiHamburgerMenu,
  GiRibbon,
  GiEarthAfricaEurope,
  GiBookshelf,
  GiHealthNormal,
  GiFruitTree,
} from "react-icons/gi";
import { MdOutlineSportsVolleyball, MdOutlineDraw } from "react-icons/md";
import { IoBag, IoLogoOctocat } from "react-icons/io5";
import { FaHandHoldingHeart } from "react-icons/fa";
import { TbHeartHandshake } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavigationMenuDemo = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigate = (category) => {
    navigate(`/money-pots-innerPage/` + category);
  };

  return (
    <NavigationMenu.Root className="NavigationMenuRoot">
      <NavigationMenu.List className="NavigationMenuList">
        <NavigationMenu.Item sx={{ cursor: "pointer" }}>
          <NavigationMenu.Trigger className="NavigationMenuTrigger">
            {t("projects")}
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="NavigationMenuContent">
            <ul className="List two">
              <ListItem
                className="navTitle"
                onClick={() => handleNavigate("allkitty")}
              >
                <GiHamburgerMenu className="navIcon" color="#b6ad99" />
                <p>{t("navigationMenu.0")}</p>
              </ListItem>
              <ListItem onClick={() => handleNavigate(8)}>
                <GiRibbon className="navIcon" color="#69e957" />{" "}
                <p>{t("navigationMenu.1")}</p>
              </ListItem>
              <ListItem onClick={() => handleNavigate(15)}>
                <GiEarthAfricaEurope className="navIcon" color="#578ee9" />
                <p>{t("navigationMenu.2")}</p>
              </ListItem>
              <ListItem onClick={() => handleNavigate(12)}>
                <MdOutlineSportsVolleyball
                  className="navIcon"
                  color="#009aa5"
                />
                <p>{t("navigationMenu.3")}</p>
              </ListItem>
              <ListItem onClick={() => handleNavigate(13)}>
                <IoBag className="navIcon" color="#575f8d" />{" "}
                <p>{t("navigationMenu.4")}</p>
              </ListItem>
              <ListItem onClick={() => handleNavigate(11)}>
                <GiBookshelf className="navIcon" color="#3e4aa3" />{" "}
                <p>{t("navigationMenu.5")}</p>
              </ListItem>
              <ListItem onClick={() => handleNavigate(14)}>
                <IoLogoOctocat className="navIcon" color="#ff6242" />
                <p>{t("navigationMenu.6")}</p>
              </ListItem>
              <ListItem onClick={() => handleNavigate(2)}>
                <MdOutlineDraw className="navIcon" color="#e03f87" />{" "}
                <p>{t("navigationMenu.7")}</p>
              </ListItem>
              <ListItem onClick={() => handleNavigate(10)}>
                <GiHealthNormal className="navIcon" color="#00cd75" />{" "}
                <p>{t("navigationMenu.8")}</p>
              </ListItem>
              <ListItem onClick={() => handleNavigate(3)}>
                <FaHandHoldingHeart className="navIcon" color="#f6aa27" />{" "}
                <p>{t("navigationMenu.9")}</p>
              </ListItem>
              <ListItem onClick={() => handleNavigate(9)}>
                <GiFruitTree className="navIcon" color="#248e5e" />{" "}
                <p>{t("navigationMenu.10")}</p>
              </ListItem>
              <ListItem>
                <TbHeartHandshake className="navIcon" color="#b4a17c" />{" "}
                <p>{t("navigationMenu.11")}</p>
              </ListItem>
            </ul>
          </NavigationMenu.Content>
        </NavigationMenu.Item>

        <NavigationMenu.Indicator className="NavigationMenuIndicator">
          <div className="Arrow" />
        </NavigationMenu.Indicator>
      </NavigationMenu.List>

      <div className="ViewportPosition">
        <NavigationMenu.Viewport className="NavigationMenuViewport" />
      </div>
    </NavigationMenu.Root>
  );
};

const ListItem = React.forwardRef(
  ({ className, children, title, ...props }, forwardedRef) => (
    <li>
      <NavigationMenu.Link asChild>
        <a
          className={classNames("ListItemLink", className)}
          {...props}
          ref={forwardedRef}
        >
          <div className="ListItemHeading">{title}</div>
          <p className="ListItemText">{children}</p>
        </a>
      </NavigationMenu.Link>
    </li>
  )
);

export default NavigationMenuDemo;
