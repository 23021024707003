import React, { useEffect, useState } from "react";
import SearchBar from "../../../../common/SearchBar";
import { Box } from "@mui/material";

function SearchTontine(props) {
  const [searchTerm, setSearchTerm] = useState(props.search);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.setSearch(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <div>
      <Box
        sx={{
          marginBottom: { lg: "0", xs: "10px" },
          mr: "10px",
          width: { md: "auto", xs: "100%" },
        }}
      >
        <SearchBar searchVal={searchTerm} setSearchFunc={setSearchTerm} />
      </Box>
    </div>
  );
}

export default SearchTontine;
