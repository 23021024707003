import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useAppDispatch } from "../../store/store";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDialogBox(props) {
  const { t } = useTranslation();
  const add = window.location.pathname;
  const { handleClose, deleteUpdateKitty, open } = props;
  return (
    <>
      <Dialog
        sx={{ padding: { md: "0 24px", xs: "0 10px" } }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            fontWeight: "600",
            background: "#592091",
            color: "#fff",
            marginBottom: "20px !important",
            textAlign: "center",
          }}
        >
          {add.includes("tontine")
            ? t("deleteTontineConfirmMsg")
            : add.includes("user")
            ? "Are you sure want to delete this user?"
            : add.includes("commission")
            ? "Are you sure want to delete this commission range"
            : `${t("deleteActionLable.0")} "${props.data.name}" ${t(
                "deleteActionLable.3"
              )}`}
        </DialogTitle>
        <DialogContent sx={{ p: "0px 24px 10px 24px" }}>
          <DialogContentText id="alert-dialog-slide-description">
            {add.includes("tontine")
              ? t("deleteLoseTontineConfirmMsg")
              : add.includes("user")
              ? "Once it deleted, you will lose all data of this user."
              : add.includes("commission")
              ? "Once it deleted, you will lose all data this range."
              : `${t("deleteActionLable.2")} ${props.data.name} ${t(
                  "deleteActionLable.3"
                )}.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="delete-cancel-button"
            sx={{
              fontSize: "12px",
              background: "#fff",
              color: "#592091",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={() => handleClose()}
          >
            {t("cancelLable")}
          </Button>
          <Button
            sx={{
              background: "#ff0000",
              color: "#fff",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #ff0000",
              "&:hover": {
                color: "#ff0000",
                background: "#fff",
              },
            }}
            onClick={() => {
              handleClose();
              deleteUpdateKitty();
            }}
          >
            {t("deleteLable")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
