import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useAppSelector } from "../../store/store";
import CheckOutForm from "./CheckoutForm";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
// import "./Payments.scss";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function AppPay(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };
  const checkOutData = useAppSelector((state) => state.CheckOut);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    setClientSecret(checkOutData?.client_data?.client_secret);
  }, [checkOutData]);

  const appearance = {
    theme: "flat",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            width: "360px",
            background: "#592091",
            color: "#fff",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {t("paymentPage.0")}
        </DialogTitle>
        <DialogContent sx={{ p: "16px !important" }}>
          <div>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckOutForm
                  email={{
                    email: props?.email?.email,
                    amount: props?.email?.amount,
                    handleClose: props?.email?.ChangeOpen,
                  }}
                />
              </Elements>
            )}
            {!clientSecret && (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
