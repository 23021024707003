import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { createTheme } from "react-data-table-component";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import i18Next from "../../../../../i18Next";
import DataTable from "../../../../../components/common/DataTableBase";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import {
  deleteAdminTontine,
  getListofAdminTontine,
} from "../../../../../store/thunk/Tontine/MytontineThunk";
import CreateNewTontine from "../../../../../layout/tontinePage/TontineStepper/index";
import DeleteDialogBox from "../../../../../components/Kitty/KittyDeleteDialog";
import SearchTontine from "./SearchTontine";
import CustomMPagination from "../../../../common/CustomMPagination";

if (!localStorage.getItem("SuperAdminTontinePageCount")) {
  localStorage.setItem("SuperAdminTontinePageCount", 0);
  localStorage.setItem("SuperAdminTontineRecordLimit", 10);
  localStorage.setItem("SuperAdminTontineSearch", "");
}

function AdminTontineComponent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [firstReload, setFirstReload] = useState(false); //To check first reload
  const [pageCount, SetPageCount] = useState(
    localStorage.getItem("SuperAdminTontinePageCount")
  );
  const [recordLimit, SetRecordLimit] = useState(
    localStorage.getItem("SuperAdminTontineRecordLimit")
  );
  const [pageCountShow, SetPageCountShow] = useState(
    (Number(pageCount) + Number(recordLimit)) / Number(recordLimit)
  );
  const { t } = useTranslation();
  const adminTontineStats = useAppSelector((state) => state.MyTontine);

  const [search, setSearch] = useState(
    localStorage.getItem("SuperAdminTontineSearch")
  );
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [deleteId, setdeleteId] = React.useState("");

  useEffect(() => {
    localStorage.setItem("SuperAdminTontinePageCount", Number(pageCount));
    localStorage.setItem("SuperAdminTontineRecordLimit", Number(recordLimit));
    localStorage.setItem("SuperAdminTontineSearch", search);
  }, [pageCount, recordLimit, search]);

  React.useEffect(() => {
    i18Next.changeLanguage(
      localStorage.getItem("language") ? localStorage.getItem("language") : "fr"
    );
  }, []);

  const handleClickOpenDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  };

  const deleteTontineRequest = () => {
    dispatch(deleteAdminTontine(deleteId));
  };

  const editTontineView = (editId) => {
    navigate(`/admin-tontine-edit/0/${editId}`);
  };

  const columns = [
    {
      name: t("tontineTableColumn.0"),
      selector: useCallback(
        (row) => (
          <img
            width={50}
            height={50}
            src={row.image}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/tontine/${row.id}/`, {
                state: { backRedirectUrl: "/admin-tontine-list" },
              })
            }
          />
        ),
        []
      ),
      // width: "4rem",
    },
    {
      name: t("tontineTableColumn.1"),
      selector: (row) => row.name,
    },
    {
      name: t("tontineTableColumn.2"),
      selector: (row) => row.status,
    },
    {
      name: t("tontineTableColumn.3"),
      selector: (row) => row.amount,
    },
    {
      name: t("tontineTableColumn.4"),
      selector: (row) => row.participants,
    },
    {
      name: t("tontineTableColumn.5"),
      selector: (row) =>
        row.created_at
          ? moment(row.created_at).format(
              localStorage.getItem("language") == "en"
                ? "YYYY-MM-DD"
                : "DD-MM-YYYY"
            )
          : "",
    },
    {
      name: t("tontineTableColumn.7"),
      selector: (row) =>
        row.payment_date
          ? moment(row.payment_date).format(
              localStorage.getItem("language") == "en"
                ? "YYYY-MM-DD"
                : "DD-MM-YYYY"
            )
          : "",
    },
    {
      name: t("kittyCatListingTableLable.5"),
      width: "6rem",
      cell: useCallback(
        (row) => (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "rows",
              width: "1rem",
            }}
          >
            <Box style={{ width: "2rem", display: "flex" }}>
              <Button onClick={() => editTontineView(row?.id)}>
                <EditIcon sx={{ color: "#592091", fill: "#592091" }} />
              </Button>
            </Box>
            <Box
              style={{
                width: "2rem",
                display: "flex",
                margin: "10px",
                lineHeight: 0,
                minWidth: "64px",
                padding: 0,
              }}
            >
              <Tooltip title={t("deleteLable")}>
                <Button
                  onClick={() => {
                    handleClickOpenDialog();
                    setdeleteId(row.id);
                  }}
                >
                  <DeleteIcon sx={{ color: "#ff0000", fill: "#ff0000" }} />
                </Button>
              </Tooltip>
            </Box>
          </Box>
        ),
        []
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);
  useEffect(() => {
    if (firstReload) {
      SetPageCount(0);

      dispatch(
        getListofAdminTontine({
          limit: Number(recordLimit),
          offSet: Number(pageCount),
          search: search,
        })
      );
      SetPageCountShow(1);
    }
  }, [search]);

  useEffect(() => {
    if (Number(pageCount) >= adminTontineStats?.adminTontineList?.count) {
      SetPageCount(Number(pageCount) - Number(recordLimit));
    }
    dispatch(
      getListofAdminTontine({
        limit: Number(recordLimit),
        offSet: Number(pageCount),
        search: search,
      })
    );
    SetPageCountShow(
      (Number(pageCount) + Number(recordLimit)) / Number(recordLimit)
    );
  }, [pageCount, recordLimit, search]);

  useEffect(() => {
    setFirstReload(true);
  }, []);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "#ccc",
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "#fff ",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        textAlign: "center",
        fontSize: "17px",
        display: "grid",
        placeContent: "center",
        backgroundColor: "#2a2828",
        color: "#fff",
        maxWidth: "40px",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        padding: "8px",
        fontSize: "14px",
        display: "grid",
        placeContent: "center",
      },
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );

  return (
    <Box className="kittyTable">
      <Box p={2}>
        <DataTable
          title={
            <>
              <Box
                className="tableTitle"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                {t("tontineHeaderLabel")}
              </Box>
            </>
          }
          columns={columns}
          data={adminTontineStats?.adminTontineList?.results}
          customStyles={customStyles}
          theme="solarized"
          progressPending={adminTontineStats.loading}
          actions={
            <>
              <SearchTontine search={search} setSearch={setSearch} />
              <CreateNewTontine callFrom="admin" />
            </>
          }
          noDataComponent={<Box sx={{ p: 3 }}>{t("norecordLbl")}</Box>}
          className="rdt_TableHeader"
        />
      </Box>
      <CustomMPagination
        pageCount={pageCount}
        SetPageCount={SetPageCount}
        recordLimit={recordLimit}
        pageCountShow={pageCountShow}
        SetRecordLimit={SetRecordLimit}
        numRecords={adminTontineStats?.adminTontineList?.num_records}
        numCount={adminTontineStats?.adminTontineList?.count}
      />
      <DeleteDialogBox
        handleClickOpen={handleClickOpenDialog}
        handleClose={handleCloseDialog}
        deleteUpdateKitty={deleteTontineRequest}
        open={openDeleteDialog}
        data=""
      />
    </Box>
  );
}

export default AdminTontineComponent;
