/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "../../../../common/DataTableBase";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { Button, Box } from "@mui/material";
import { createTheme } from "react-data-table-component";
import { GetCommissionKitty } from "../../../../../store/thunk/Payments/CheckOutThunk";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import UpdateCommissionDialog from "./UpdateCommissionDialog";
import DeleteCommissionDialog from "./KittyCommissionDelete";
import CustomMPagination from "../../../../common/CustomMPagination";

function MyComponent() {
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = useState(0);
  const [pageCountShow, SetPageCountShow] = useState(0);
  const [recordLimit, SetRecordLimit] = useState(10);
  const [updateDialog, SetUpdateDialog] = useState(false);
  const [updateData, SetUpdateData] = useState({});
  const { t } = useTranslation();
  const checkOutData = useAppSelector((state) => state.CheckOut);
  const columns = [
    {
      name: t("commissionIniteRangeLbl"),
      selector: (row) => row.range_start,
    },
    {
      name: t("commissionRangeendLbl"),
      selector: (row) => row.range_end,
    },
    {
      name: t("commissionPercentageLbl"),
      selector: (row) => row.commissions,
    },
    {
      name: t("actionListLabel"),
      width: "6rem",
      cell: useCallback(
        (row) => (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "rows",
              width: "1rem",
            }}
          >
            <Box style={{ width: "2rem", display: "flex" }}>
              <Button
                onClick={() => {
                  SetUpdateDialog(!updateDialog);
                  SetUpdateData(row);
                }}
              >
                <Tooltip title={t("editLable")}>
                  <ModeEditOutlineIcon
                    sx={{ color: "#592091", fill: "#592091" }}
                  />
                </Tooltip>
              </Button>
            </Box>
            <Box
              style={{
                width: "2rem",
                display: "flex",
                margin: "10px",
                lineHeight: 0,
                minWidth: "64px",
                padding: 0,
              }}
            >
              <Button>
                <DeleteCommissionDialog data={row} />
              </Button>
            </Box>
          </Box>
        ),
        []
      ),
    },
  ];

  useEffect(() => {
    dispatch(GetCommissionKitty({ limit: recordLimit, offSet: pageCount }));
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "#ccc",
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "#fff ",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        textAlign: "center",
        fontSize: "17px",
        display: "grid",
        placeContent: "center",
        backgroundColor: "#2a2828",
        color: "#fff",
        maxWidth: "40px",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        padding: "8px",
        fontSize: "14px",
        display: "grid",
        placeContent: "center",
      },
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );

  const closeUpdateDialog = () => {
    SetUpdateDialog(false);
  };

  return (
    <Box className="kittyTable">
      {updateDialog && (
        <UpdateCommissionDialog
          data={updateData}
          callback={closeUpdateDialog}
          limit={recordLimit}
          offSet={pageCount}
        />
      )}
      <Box sx={{ padding: { md: 2, xs: 1 } }}>
        <DataTable
          title={
            <>
              <Box
                className="tableTitle"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                {t("KittyCommissionLable")}
              </Box>
            </>
          }
          columns={columns}
          data={checkOutData?.KittyCommissionData?.results}
          customStyles={customStyles}
          theme="solarized"
          // selectableRows
          progressPending={checkOutData.loading}
          noDataComponent={<Box sx={{ p: 3 }}>{t("norecordLbl")}</Box>}
          className="rdt_TableHeader"
          actions={
            <>
              <Button
                variant="contained"
                onClick={() => {
                  SetUpdateDialog(!updateDialog);
                  SetUpdateData(null);
                }}
                sx={{
                  border: "1px solid #592091",
                  backgroundColor: "#592091",
                  padding: "7px 20px",
                  color: "white",
                  width: { md: "auto", xs: "100%" },
                  "&:hover ": {
                    background: "#fff",
                    color: "#592091",
                  },
                }}
              >
                {t("commissionInsertBtnLbl")}
              </Button>
            </>
          }
        />
      </Box>
      <CustomMPagination
        pageCount={pageCount}
        SetPageCount={SetPageCount}
        recordLimit={recordLimit}
        pageCountShow={pageCountShow}
        SetRecordLimit={SetRecordLimit}
        numRecords={checkOutData?.KittyCommissionData?.num_records}
        numCount={checkOutData?.KittyCommissionData?.count}
      />
    </Box>
  );
}

export default MyComponent;
