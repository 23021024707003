import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CatchingPokemonIcon from "@mui/icons-material/CatchingPokemon";
import { useTranslation } from "react-i18next";
import listItemImage1 from "../../../../assets/Images/list-item_background1.jpg";
import listItemImage2 from "../../../../assets/Images/list-item_background2.jpg";
import SubmoneypotsImage from "../../../../assets/Images/submoneypotsImage.png";
import { useAppDispatch } from "../../../../store/store";
import { useEffect } from "react";
import { GetCommissionKitty } from "../../../../store/thunk/Payments/CheckOutThunk";

export default function InnerLowestFees() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(GetCommissionKitty());
  }, []);
  return (
    <Box>
      <Box sx={{ p: { md: "20px 0", xs: "10px 0px" } }}>
        <Container>
          <Grid container className="">
            <Grid item md={6} xs={12}>
              <Paper
                sx={{
                  margin: { md: "20px", xs: "10px" },
                  padding: { md: "20px", xs: "10px" },
                  height: { md: "90px", xs: "unset" },
                  textAlign: { md: "left", xs: "center" },
                }}
              >
                <Grid container sx={{ flexWrap: { md: "nowrap", xs: "wrap" } }}>
                  <Grid
                    item
                    md={2}
                    sx={{ margin: { md: "0", xs: "10px auto" } }}
                  >
                    <CheckIcon
                      sx={{
                        color: "var(--text-white-color)",
                        background: "#592091",
                        fontSize: "37px",
                        borderRadius: "50%",
                        padding: "10px",
                        mr: 2,
                      }}
                    />
                  </Grid>
                  <Grid item md={10}>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#592091",
                        fontWeight: "600",
                        marginBottom: "5px",
                      }}
                    >
                      {t("innerLowestFeesMainBlock.0")}
                    </Typography>
                    <Typography variant="body" sx={{ color: "#555" }}>
                      {t("innerLowestFeesMainBlock.1")}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper
                sx={{
                  margin: { md: "20px", xs: "10px" },
                  padding: { md: "20px", xs: "10px" },
                  height: { md: "90px", xs: "unset" },
                  textAlign: { md: "left", xs: "center" },
                }}
              >
                <Grid container sx={{ flexWrap: { md: "nowrap", xs: "wrap" } }}>
                  <Grid
                    item
                    md={2}
                    sx={{ margin: { md: "0", xs: "10px auto" } }}
                  >
                    <CheckIcon
                      sx={{
                        color: "var(--text-white-color)",
                        background: "#592091",
                        fontSize: "37px",
                        borderRadius: "50%",
                        padding: "10px",
                        mr: 2,
                      }}
                    />
                  </Grid>
                  <Grid item md={10}>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#592091",
                        fontWeight: "600",
                        marginBottom: "5px",
                      }}
                    >
                      {t("innerLowestFeesMainBlock.2")}
                    </Typography>
                    <Typography variant="body" sx={{ color: "#555" }}>
                      {t("innerLowestFeesMainBlock.3")}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="md">
          <Box
            sx={{
              textAlign: "center",
              marginBottom: { md: "50px", xs: "30px" },
              p: 1,
            }}
          >
            <Typography
              className="top-subheading"
              variant="p"
              sx={{ fontSize: "30px", textAlign: "center" }}
            >
              {t("innerLowestFeesMainBlock.4")}
            </Typography>
            <Grid container className="">
              <Grid
                item
                md={12}
                xs={12}
                sx={{ display: "grid", placeContent: "center" }}
              >
                <Paper
                  sx={{
                    margin: "20px",
                    padding: "20px",
                    height: { md: "25.625rem", xs: "unset" },
                    width: { md: "18.75rem", xs: "auto" },
                    padding: "1.5rem",
                  }}
                >
                  <CreditCardIcon
                    sx={{
                      color: "var(--text-white-color)",
                      fontSize: "37px",
                      borderRadius: "50%",
                      padding: "10px",
                      border: " 0.375rem solid #769cd9",
                      backgroundColor: "#91bcff",
                      marginBottom: "20px",
                    }}
                  />
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#592091",
                      fontWeight: "600",
                      marginBottom: "5px",
                    }}
                  >
                    {t("innerLowestFeesMainBlock.5")}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      display: " table",
                      margin: "50px auto 20px",
                      background: "#769cd9",
                      borderRadius: "20px",
                      color: "#fff",
                    }}
                  >
                    2.8%-5.5%
                  </Button>
                  <Typography variant="body" sx={{ color: "#555" }}>
                    {t("innerLowestFeesMainBlock.6")}:
                  </Typography>
                  <Box>
                    <Grid container sx={{ justifyContent: "center" }}>
                      <Grid item md={4} sx={{ p: "0 5px" }}>
                        <Button
                          variant="contained"
                          disabled
                          sx={{
                            display: "table",
                            margin: "10px auto",
                            minWidth: "3.125rem",
                            height: "30px",
                            lineHeight: "20px",
                            borderRadius: "20px",
                          }}
                        >
                          {t("simpleRates.1")}
                        </Button>
                        <span
                          style={{
                            fontSize: ".75rem",
                            color: "#555",
                            display: "block",
                          }}
                        >
                          {t("upto")}
                        </span>
                        <strong style={{ fontSize: ".75rem", color: "#555" }}>
                          €250
                        </strong>
                      </Grid>
                      <Grid item md={4} sx={{ p: "0 5px" }}>
                        <Button
                          variant="contained"
                          disabled
                          sx={{
                            display: "table",
                            margin: "10px auto",
                            minWidth: "3.125rem",
                            height: "30px",
                            lineHeight: "20px",
                            borderRadius: "20px",
                          }}
                        >
                          5.5%
                        </Button>
                        <span
                          style={{
                            fontSize: ".75rem",
                            color: "#555",
                            display: "block",
                          }}
                        >
                          {t("innerLowestFeesMainBlock.24")} €250
                        </span>
                        <strong style={{ fontSize: ".75rem", color: "#555" }}>
                          {t("innerLowestFeesMainBlock.26")} €15,000
                        </strong>
                      </Grid>
                      <Grid item md={4} sx={{ p: "0 5px" }}>
                        <Button
                          variant="contained"
                          disabled
                          sx={{
                            display: "table",
                            margin: "10px auto",
                            minWidth: "3.125rem",
                            height: "30px",
                            lineHeight: "20px",
                            borderRadius: "20px",
                          }}
                        >
                          2.8%
                        </Button>
                        <span
                          style={{
                            fontSize: ".75rem",
                            color: "#555",
                            display: "block",
                          }}
                        >
                          {t("innerLowestFeesMainBlock.25")}
                        </span>
                        <strong style={{ fontSize: ".75rem", color: "#555" }}>
                          €15,000
                        </strong>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <Paper
                  sx={{
                    margin: "20px",
                    padding: "20px",
                    height: { md: "25.625rem", xs: "unset" },
                    width: { md: "18.75rem", xs: "auto" },
                    padding: "1.5rem",
                  }}
                >
                  <CreditCardIcon
                    sx={{
                      color: "var(--text-white-color)",
                      fontSize: "37px",
                      borderRadius: "50%",
                      padding: "10px",
                      border: " 0.375rem solid #769cd9",
                      backgroundColor: "#91bcff",
                      marginBottom: "20px",
                    }}
                  />
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#592091",
                      fontWeight: "600",
                      marginBottom: "5px",
                    }}
                  >
                    {t("innerLowestFeesMainBlock.26")}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      display: " table",
                      margin: "50px auto 20px",
                      background: "#769cd9",
                      borderRadius: "20px",
                      color: "#fff",
                    }}
                  >
                    2.8% - 5.5%
                  </Button>
                  <Typography variant="body" sx={{ color: "#555" }}>
                    {t("innerLowestFeesMainBlock.6")}:
                  </Typography>
                  <Box>
                    <Grid container sx={{ justifyContent: "center" }}>
                      <Grid item md={4} sx={{ p: "0 5px" }}>
                        <Button
                          variant="contained"
                          disabled
                          sx={{
                            display: "table",
                            margin: "10px auto",
                            minWidth: "3.125rem",
                            height: "30px",
                            lineHeight: "20px",
                            borderRadius: "20px",
                          }}
                        >
                          Free
                        </Button>
                        <span
                          style={{
                            fontSize: ".75rem",
                            color: "#555",
                            display: "block",
                          }}
                        >
                          Upto
                        </span>
                        <strong style={{ fontSize: ".75rem", color: "#555" }}>
                          €250
                        </strong>
                      </Grid>
                      <Grid item md={4} sx={{ p: "0 5px" }}>
                        <Button
                          variant="contained"
                          disabled
                          sx={{
                            display: "table",
                            margin: "10px auto",
                            minWidth: "3.125rem",
                            height: "30px",
                            lineHeight: "20px",
                            borderRadius: "20px",
                          }}
                        >
                          5.5%
                        </Button>
                        <span
                          style={{
                            fontSize: ".75rem",
                            color: "#555",
                            display: "block",
                          }}
                        >
                          {t("innerLowestFeesMainBlock.24")} €250
                        </span>
                        <strong style={{ fontSize: ".75rem", color: "#555" }}>
                          to €15,000
                        </strong>
                      </Grid>
                      <Grid item md={4} sx={{ p: "0 5px" }}>
                        <Button
                          variant="contained"
                          disabled
                          sx={{
                            display: "table",
                            margin: "10px auto",
                            minWidth: "3.125rem",
                            height: "30px",
                            lineHeight: "20px",
                            borderRadius: "20px",
                          }}
                        >
                          2.8%
                        </Button>
                        <span
                          style={{
                            fontSize: ".75rem",
                            color: "#555",
                            display: "block",
                          }}
                        >
                          {t("innerLowestFeesMainBlock.25")}
                        </span>
                        <strong style={{ fontSize: ".75rem", color: "#555" }}>
                          €15,000
                        </strong>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid> */}
            </Grid>
          </Box>
          <Typography
            variant="h2"
            sx={{
              fontSize: { md: "30px", xs: "18px" },
              mt: "10px",
              fontWeight: "600",
              textAlign: "center",
              color: "#555",
            }}
          >
            {t("innerLowestFeesMainBlock.9")}
          </Typography>
        </Container>
        <Box
          sx={{
            borderBottom: "2px solid #e6e6e6",
            width: " 100%",
            color: "#e6e6e6",
            position: "relative",
            mt: { md: "70px", xs: "30px" },
          }}
        >
          <CatchingPokemonIcon
            className="divider"
            sx={{
              margin: "0px",
              position: "absolute",
              left: "50%",
              top: "-12px",
              background: " white",
              borderRadius: "50%",
            }}
          />
        </Box>
        <Box sx={{ p: { md: "70px 0px 0px", xs: "20px 0px 0px" } }}>
          <Container maxWidth="md">
            <Grid container>
              <Grid
                item
                md={6}
                sx={{
                  padding: { md: "30px", xs: "10px" },
                  textAlign: "center",
                }}
              >
                <img
                  src={listItemImage2}
                  alt=""
                  style={{
                    borderRadius: " 50%",
                    width: "16.25rem",
                    height: "16.25rem",
                    objectFit: "cover",
                    margin: "0 auto 20px",
                    display: "table",
                  }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    color: "#592091",
                    fontWeight: "600",
                    marginBottom: "5px",
                  }}
                >
                  {t("innerLowestFeesMainBlock.7")}
                </Typography>
                <Typography
                  variant="body"
                  sx={{
                    color: "#555",
                    marginBottom: "5px",
                  }}
                >
                  {t("innerLowestFeesMainBlock.10")}
                  <span style={{ color: "#592091", fontWeight: "600" }}>
                    {" "}
                    €1,171
                  </span>
                  <span style={{ color: "#000", fontWeight: "600" }}>
                    {" "}
                    {t("innerLowestFeesMainBlock.11")},
                  </span>
                  {t("innerLowestFeesMainBlock.12")}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  padding: { md: "30px", xs: "10px" },
                  textAlign: "center",
                }}
              >
                <img
                  src={listItemImage1}
                  alt=""
                  style={{
                    borderRadius: " 50%",
                    width: "16.25rem",
                    height: "16.25rem",
                    objectFit: "cover",
                    margin: "0 auto 20px",
                    display: "table",
                  }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    color: "#592091",
                    fontWeight: "600",
                    marginBottom: "5px",
                  }}
                >
                  {t("innerLowestFeesMainBlock.13")}
                </Typography>
                <Typography
                  variant="body"
                  sx={{
                    marginBottom: "5px",
                    color: " #555",
                  }}
                >
                  {t("innerLowestFeesMainBlock.14")}
                  <span style={{ color: "#000", fontWeight: "600" }}>
                    {" "}
                    {t("innerLowestFeesMainBlock.15")},{" "}
                  </span>
                  {t("innerLowestFeesMainBlock.16")}{" "}
                  <span style={{ color: "#592091", fontWeight: "600" }}>
                    {" "}
                    €2,074{" "}
                  </span>
                  {t("innerLowestFeesMainBlock.17")}
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Box
        className="SubmoneypotsImage"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={SubmoneypotsImage} alt="SubmoneypotsImage" />
      </Box>
    </Box>
  );
}
