import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: "",
  error: "",
  success: "",
  UserList: "",
};

const listofUserSlice = createSlice({
  name: "listofUserSlice",
  initialState,
  reducers: {
    setListofUser: (state, action) => ({
      ...state,
      UserList: action.payload,
    }),
    setUserLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setUserListSuccess: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    setUserListError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
  },
});

export const { setListofUser, setUserLoader, setUserListSuccess, setUserListError } = listofUserSlice.actions;

export default listofUserSlice.reducer;