import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { insertNewKitty } from "../../store/thunk/Kitty/InsertNewKittyThunk";
import { listofKittyCat } from "../../store/thunk/KittyCategory/listofKittyCatThunk";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import FileUploader from "../common/imageUpload";
import RichTextEditor from "react-rte";
import ClearIcon from "@mui/icons-material/Clear";
import SearchBar from "../common/SearchBar";
import { listofKittyForAdmin } from "../../store/thunk/Kitty/listofKittyThunk";

export default function KittyUpdate(props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const categoryData = useAppSelector((state) => state.listofKittyCatSlice);
  const [formError, setFormError] = React.useState([]);
  const session = useAppSelector((state) => state.UserProfile);
  const [searchTerm, setSearchTerm] = useState(
    localStorage.getItem("SuperAdminKittySearch")
  );
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createValueFromString("", "html")
  );
  /**
   * Handle editor's change.
   * @param {import('react-rte').EditorValue} value Value from the editor.
   */
  const [open, setOpen] = React.useState(false);
  const [kittyData, setKittyData] = React.useState({
    name: "",
    object_of_kitty: "",
    amount: "",
    category: "",
    image: "",
  });

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.setSearch(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  function onChangeTextEditor(value) {
    setEditorValue(value);
    setKittyData({
      ...kittyData,
      object_of_kitty: value.toString("html"),
    });

    const index = formError.indexOf("object_of_kitty");
    if (index !== -1) {
      formError.splice(index, 1);
    }
  }

  const clearData = {
    name: "",
    object_of_kitty: "",
    amount: "",
    category: "",
    image: "",
  };

  const validateForm = (data) => {
    let error = [];

    if (data.name === "") {
      error.push("name");
    }
    if (data.object_of_kitty === "" || data.object_of_kitty === "<p><br></p>") {
      error.push("object_of_kitty");
    }
    if (data.category === "") {
      error.push("category");
    }

    if (data.amount !== "" && data.amount <= 0) {
      error.push("amount");
    }
    // if (data.image === null || data.image === "") {
    //   error.push("image");
    // }
    setFormError(error);

    return error;
  };

  const theme = createTheme({
    TextField: {
      outlined: {
        padding: "10px 14px",
      },
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updatekittyData = (text, event) => {
    if (text == "amount") {
      if (event.target.value >= 0 && event.target.value <= 999999999) {
        setKittyData({
          ...kittyData,
          [text]: event.target.value,
        });
      }
    } else {
      setKittyData({
        ...kittyData,
        [text]: event.target.value,
      });
    }

    const index = formError.indexOf(text);
    if (index !== -1) {
      formError.splice(index, 1);
    }
  };

  const updatekittyCategory = (newValue) => {
    setKittyData({
      ...kittyData,
      category: newValue.id,
    });

    const index = formError.indexOf("category");
    if (index !== -1) {
      formError.splice(index, 1);
    }
  };

  const handleSelectFile = (file) => {
    setKittyData((kittyData) => ({
      ...kittyData,
      image: file,
    }));
  };

  const handleSubmitCallback = () => {
    setSearchTerm("");
    setEditorValue(RichTextEditor.createValueFromString("", "html"));
    setKittyData(clearData);
    props.SetPageCount(0);
    if (localStorage.getItem("is_superuser") == "true") {
      dispatch(
        listofKittyForAdmin({
          limit: props.limit,
          offSet: props.offSet,
        })
      );
    }
  };

  const handleSubmit = () => {
    let error = validateForm(kittyData);
    if (error.length == 0) {
      let request = {
        ...kittyData,
        user: session?.details?.id,
        callback: handleSubmitCallback,
      };
      dispatch(insertNewKitty(request));
      handleClose();
    }
  };

  const handleChange = (event) => {
    props.setCategory(event.target.value);
    props.SetPageCount(0);
  };

  const handleChangeStatus = (event) => {
    props.setStatus(event.target.value);
    props.SetPageCount(0);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: { md: "flex", xs: "block" } }}>
        <Box sx={{ mr: { md: "10px", xs: "0" } }}>
          <FormControl
            sx={{
              minWidth: { md: "100px", xs: "100%" },
              mb: { md: "0", xs: "10px" },
            }}
            size="small"
          >
            <InputLabel id="demo-select-categories">
              {t("statusLabel")}
            </InputLabel>
            <Select
              labelId="demo-select-status"
              id="select-status"
              defaultValue={
                localStorage.getItem("language") === "en" ? "All" : "Toute"
              }
              value={props.status}
              label={t("statusLabel")}
              onChange={handleChangeStatus}
              sx={{ backgroundColor: "#fff" }}
            >
              <MenuItem
                key="2"
                value={
                  localStorage.getItem("language") === "en" ? "All" : "Toute"
                }
              >
                {t("allLable")}
              </MenuItem>
              <MenuItem key="0" value="OPEN">
                {t("tontineField.5")}
              </MenuItem>
              <MenuItem key="1" value="CLOSED">
                {t("tontineField.6")}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mr: { md: "10px", xs: "0" } }}>
          <FormControl
            sx={{
              minWidth: { md: "150px", xs: "100%" },
              mb: { md: "0", xs: "10px" },
            }}
            size="small"
          >
            <InputLabel id="demo-select-categories">
              {t("kittyFieldLable.3")}
            </InputLabel>
            <Select
              labelId="demo-select-categories"
              id="select-category"
              value={props.category}
              label={t("kittyFieldLable.3")}
              onChange={handleChange}
              sx={{ backgroundColor: "#fff" }}
            >
              <MenuItem
                key={0}
                value={
                  localStorage.getItem("language") === "en" ? "All" : "Toute"
                }
              >
                {t("allLable")}
              </MenuItem>
              {categoryData.list.results?.map((data) => {
                return (
                  <MenuItem key={data?.id} value={data?.id}>
                    {t('category.'+data?.category_name).replace('category.','')}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            marginBottom: { lg: "0", xs: "10px" },
            mr: "10px",
            width: { md: "auto", xs: "100%" },
          }}
        >
          <SearchBar searchVal={searchTerm} setSearchFunc={setSearchTerm} />
        </Box>
        <Button
          variant="contained"
          onClick={() => {
            handleClickOpen();
            dispatch(
              listofKittyCat({
                callback: () => {
                  props.SetPageCount(0);
                },
              })
            );
          }}
          sx={{
            border: "1px solid #592091",
            backgroundColor: "#592091",
            padding: "7px 20px",
            color: "white",
            width: { md: "auto", xs: "100%" },
            "&:hover ": {
              background: "#fff",
              color: "#592091",
            },
          }}
        >
          {t("kittyListinSection.2")}
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{ sx: { m: { md: "30px", xs: "10px" } } }}
        >
          <DialogTitle
            sx={{
              textTransform: "uppercase",
              borderBottom: "1px solid #ddd",
              background:
                "linear-gradient(to left, #592091, #4b0082, #91bcff, #1064e7, #808080, #4b0082, #0161f7)",
              WebkitBackgroundClip: "text",
            }}
          >
            {t("kittyFieldLable.0")}
          </DialogTitle>
          <DialogContent sx={{ padding: { md: "0 24px", xs: "0 10px" } }}>
            <DialogContentText
              sx={{ fontSize: "20px", fontWeight: "600", m: "10px 0" }}
            >
              {t("kittyFieldLable.1")}
            </DialogContentText>
            <Grid container>
              <Grid item sx={{ padding: "1px 4px", mt: 1 }} xs={12} md={6}>
                <FileUploader onSelectFile={handleSelectFile} />
                {formError.includes("image") && (
                  <FormHelperText id="component-error-text" error>
                    {t("kittyCatFieldValidation.2")}
                  </FormHelperText>
                )}
              </Grid>
              <Grid
                item
                sx={{ padding: { md: "1px 4px 1px 20px", xs: 0 } }}
                xs={12}
                md={6}
              >
                <Box sx={{ padding: "1px 4px" }}>
                  <TextField
                    autoFocus
                    size="small"
                    margin="dense"
                    id="name"
                    value={kittyData.name}
                    onChange={(e) => {
                      updatekittyData("name", e);
                    }}
                    label={t("kittyFieldLable.2")}
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    variant="outlined"
                    error={formError.includes("name")}
                    helperText={
                      formError.includes("name")
                        ? t("kittyFieldValidation.1")
                        : ""
                    }
                  />
                </Box>
                <Box item sx={{ padding: "9px 4px 1px 4px", mt: 1 }}>
                  <FormControl sx={{ width: " 100%" }}>
                    <Autocomplete
                      id="country-select-demo"
                      error={formError.includes("category")}
                      size="small"
                      sx={{ width: "100%" }}
                      options={categoryData.list.results}
                      autoHighlight
                      getOptionLabel={(option) => t('category.'+option.category_name).replace('category.','')}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {t('category.'+option.category_name).replace('category.','')}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("kittyFieldLable.3")}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                      value={categoryData.category_name}
                      onChange={(e, newValue) => {
                        updatekittyCategory(newValue);
                      }}
                    />
                    {formError.includes("category") && (
                      <FormHelperText id="component-error-text" error>
                        {t("kittyFieldValidation.2")}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box item sx={{ padding: "1px 4px", mt: 1 }}>
                  <TextField
                    margin="dense"
                    size="small"
                    value={kittyData.amount}
                    onChange={(e) => {
                      updatekittyData("amount", e);
                    }}
                    onKeyPress={preventMinus}
                    id="amount"
                    label={t("kittyFieldLable.4")}
                    type="number"
                    inputProps={{
                      autoComplete: "off",
                      min: 0,
                      max: 999999999,
                    }}
                    fullWidth
                    variant="outlined"
                    error={formError.includes("amount")}
                    helperText={
                      formError.includes("amount")
                        ? t("kittyFieldValidation.4")
                        : ""
                    }
                  />
                </Box>
                <Box style={{ fontSize: "13px" }} />
              </Grid>
              <Box sx={{ width: "100%", marginTop: "20px" }}>
                <RichTextEditor
                  onChange={onChangeTextEditor}
                  value={editorValue}
                  sx={{ width: "100%" }}
                  placeholder={t("kittyFieldLable.5")}
                  editorClassName="richtext-editor-input"
                />
                <FormHelperText
                  sx={{
                    marginBottom: "10px",
                    marginLeft: "10px",
                    color: "red",
                  }}
                >
                  {formError.includes("object_of_kitty")
                    ? t("kittyFieldValidation.3")
                    : ""}
                </FormHelperText>
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              p: { md: "20px", xs: "10px" },
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => {
                handleClose();
                setKittyData(clearData);
              }}
              sx={{
                fontSize: "12px",
                background: "#fff",
                color: "#592091",
                display: "block",
                padding: "8px 20px",
                fontWeight: "600",
                letterSpacing: " 2px",
                border: "1px solid #592091",
                "&:hover": {
                  color: "#592091",
                  background: "#fff",
                },
              }}
            >
              {t("cancelLable")}
            </Button>
            <Button
              sx={{
                background: "#592091",
                color: "#fff",
                display: "block",
                padding: "8px 20px",
                fontWeight: "600",
                letterSpacing: " 2px",
                border: "1px solid #592091",
                "&:hover": {
                  color: "#592091",
                  background: "#fff",
                },
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {t("insertLable")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
}
