import React from "react";
import Footer from "../components/common/Footer"
import Header from "../components/common/Header";
import AfterContributionPage from "../components/Kitty/afterContribution";

function AfterContributionComponent() {
    return (
        <>
            <Header />
            <AfterContributionPage />
            <Footer />
        </>
    );
}
export default AfterContributionComponent;
