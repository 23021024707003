import { Box, Button, Container, Grid, Typography } from "@mui/material";
import CatchingPokemonIcon from "@mui/icons-material/CatchingPokemon";
import { useTranslation } from "react-i18next";
import "./lowestFees.scss";
import StepSliderBg from "../../../assets/Images/a-pair-of-hands-typing-away.jpg";
import { Link } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function LowestFees() {
  const { t } = useTranslation();
  return (
    <>
      <Box
        id="LowestFees-block"
        component="section"
        className="container"
        sx={{
          padding: {
            xs: "40px 0px",
            md: "40px 0",
          },
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Container
          className="slider-box"
          sx={{ zIndex: "9", position: "relative" }}
        >
          <Box
            sx={{
              textAlign: "center",
              marginBottom: { md: "50px", xs: "30px" },
              p: 1,
            }}
          >
            <Typography
              className="top-subheading"
              variant="h2"
              sx={{
                fontSize: { md: "30px", xs: "18px" },
                mt: "10px",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {t("tonetinePage.0")}
            </Typography>
          </Box>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid
              item
              md={6}
              sx={{
                p: { md: "0px 20px", xs: "0" },
                order: { md: "unset", xs: "1" },
              }}
            >
              <Typography variant="span" className="subtitle">
                {t("tonetinePage.1")}
              </Typography>
              <Typography variant="h5" className="title">
                {t("tonetinePage.2")}
              </Typography>
              <Typography variant="h6">{t("tonetinePage.3")}</Typography>
              <Button variant="contained">
                <Link
                  to="/how-It-Works"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  {t("tonetinePage.4")}
                </Link>
              </Button>
            </Grid>
            <Grid item md={6} sx={{ p: { md: "0px 20px", xs: "0" } }}>
              <Box sx={{ position: "relative", p: 3 }}>
                <LazyLoadImage
                  alt={StepSliderBg}
                  effect="blur"
                  src={StepSliderBg}
                  width="100%"
                />

                {/* <img src={StepSliderBg} alt="alt" style={{ width: "100%" }} /> */}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          borderBottom: "2px solid #e6e6e6",
          width: " 100%",
          color: "#e6e6e6",
          position: "relative",
        }}
      >
        <CatchingPokemonIcon
          className="divider"
          sx={{
            margin: "0px",
            position: "absolute",
            left: "50%",
            top: "-12px",
            background: " white",
            borderRadius: "50%",
          }}
        />
      </Box>
    </>
  );
}
