import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import "./tontine.scss";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { Grid, Tooltip } from "@mui/material";
import CreateTontine from "./CreateNewTontine";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  setMyTontineCreateFunction,
  setMyTontineCreateSuccess,
} from "../../../store/reducer/Tontine/MyTontineSlice";
import Invite from "../../../components/tontineSettings/Tabs/Invites";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import { CheckUserDocumentVerifyStatus } from "../../../store/thunk/Tontine/MytontineThunk";
import { LoadingButton } from "@mui/lab";

export default function ResponsiveDialog({ callFrom }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [activeStep, setActiveStep] = React.useState(0);
  const documentStatus = useAppSelector((state) => state.MyTontine);

  const steps = [
    {
      label: t("createTontineStepLabel"),
    },
    {
      label: t("shareTontineStepLabel"),
    },
  ];

  React.useEffect(() => {
    dispatch(CheckUserDocumentVerifyStatus());
  }, []);

  React.useEffect(() => {
    if (documentStatus.CreateTontineSuccess) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      dispatch(setMyTontineCreateSuccess(false));
    }
  }, [documentStatus]);

  const handleNext = () => {
    if (activeStep === 0) {
      dispatch(setMyTontineCreateFunction(true));
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const myTontine = useAppSelector((state) => state.MyTontine);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && (reason == "backdropClick" || reason == "escapeKeyDown")) {
      return;
    }
    setOpen(false);
    setActiveStep(0);
  };

  const handleFinish = () => {
    setActiveStep(0);
    setOpen(false);
  };
  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          background: "#592091",
          border: "1px solid #592091",
          p: { md: "6px 16px", xs: "10px" },
          " &:hover ": {
            background: "#fff",
            color: "#592091",
          },
        }}
      >
        {t("createTontineLabel")}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth={"lg"}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle id="responsive-dialog-title" sx={{ fontWeight: "600" }}>
            {t("createTontineLabel")}
          </DialogTitle>
          <Button
            onClick={() => {
              handleClose();
            }}
            sx={{ float: "right" }}
          >
            <CancelTwoToneIcon />
          </Button>
        </Box>
        <DialogContent sx={{ p: 0 }}>
          <Box
            sx={{
              minWidth: "100%",
              backgroundColor: "#f1efeb",
            }}
          >
            <Grid
              container
              sx={{
                backgroundColor: "#f1efeb",
                display: { md: "flex", xs: "block" },
              }}
            >
              <Grid
                item
                xs={12}
                md={3}
                sx={{ backgroundColor: "#592091", color: "#fff" }}
              >
                <Box sx={{ padding: "1rem 0.8rem" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: "25px !important",
                      fontSize: "30px !important",
                      mb: 1,
                    }}
                  >{`${t("stepLabel")} ${activeStep + 1}`}</Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "18px !important", mb: "50px !important" }}
                  >
                    {t("stepInfoLabel")}
                  </Typography>
                  <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    sx={{ color: "#fff" }}
                  >
                    {steps.map((step, index) => (
                      <Step
                        key={step.label}
                        sx={{
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: "#fff",
                          },
                          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                            {
                              color: "#fff",
                            },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "#fff",
                          },
                          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                            {
                              color: "#fff",
                            },
                          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text":
                            {
                              fill: "#000",
                            },
                          "& .MuiStepLabel-labelContainer": {
                            color: "#fff",
                          },
                        }}
                      >
                        <StepLabel style={{ color: "#fff" }}>
                          {step.label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Grid>
              <Grid item xs={12} md={9} sx={{ pt: 2 }}>
                {activeStep === 0 && <CreateTontine callFrom={callFrom} />}
                {activeStep === 1 && (
                  <Invite
                    id={{
                      id: documentStatus?.newTontineDetails?.id,
                      name: documentStatus?.newTontineDetails?.name,
                    }}
                    handleFinish={handleFinish}
                  />
                )}
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box sx={{ mb: 2 }}>
                    {activeStep === 1 &&
                    !documentStatus?.docStatus?.is_verified &&
                    documentStatus?.error ==
                      "you haven't upload your document yet" ? (
                      <></>
                    ) : (
                      <>
                        {activeStep !== 1 ? (
                          <div>
                            <Tooltip
                              title={
                                activeStep === 1 &&
                                !documentStatus?.docStatus?.is_verified
                                  ? documentStatus?.error
                                  : t("nextStepLabel")
                              }
                              placement="top"
                              arrow
                              leaveDelay={800}
                              sx={{ backgroundColor: "#592091" }}
                            >
                              <span>
                                <LoadingButton
                                  // disabled={
                                  //   activeStep === 1 &&
                                  // !documentStatus?.docStatus?.is_verified
                                  // }
                                  loading={myTontine?.createLoading}
                                  variant="contained"
                                  onClick={() =>
                                    activeStep === steps.length - 1
                                      ? handleFinish()
                                      : handleNext()
                                  }
                                  sx={{ mt: 1, background: "#592091" }}
                                >
                                  {activeStep === steps.length - 1
                                    ? t("finishStepLabel")
                                    : t("continueStepLabel")}
                                </LoadingButton>
                              </span>
                            </Tooltip>
                          </div>
                        ) : null}
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
