import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import {
  setCheckoutSuccess,
  setClientSecretData,
  setKittyBenificiaryDetails,
  setKittyCommissionData,
  setPaymentError,
  setPaymentErrorState,
  setPaymentReciptLink,
  setTontineCommissionData,
  setCheckoutLoader,
} from "../../reducer/Payments/CheckoutSlice";
import { setErrorAlert, setSuccessAlert } from "../../reducer/AlertSlice";
import { fewUpdateKitty } from "../Kitty/UpdateKittyListThunk";
import { setListOfSavedCards } from "../../reducer/User/UserProfileSlice";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Authorization"] =
  localStorage.getItem("UserInfo") !== ""
    ? localStorage.getItem("UserInfo")
      ? `Token ${localStorage.getItem("UserInfo")}`
      : ""
    : "";
// axios.defaults.headers["Access-Control-Allow-Origin"] = "https://graph.facebook.com";
// axios.defaults.headers["Access-Control-Allow-Methods"] = "https://graph.facebook.com";
// // axios.defaults.headers["Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept"] = "https://graph.facebook.com";

export const CheckoutForm = createAsyncThunk(
  "CheckoutForm",
  async (_request, { dispatch }) => {
    let form_data = new FormData();
    form_data.append("amount", _request?.request?.amount * 100);
    form_data.append("customer", _request?.customer);
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    axios
      .post("api/v1/checkout/create-payment-intent/", form_data)
      .then((response) => {
        dispatch(setPaymentErrorState(false));
        dispatch(setClientSecretData(response?.data));
      })
      .catch((err) => {
        dispatch(setPaymentError(err.response?.data?.message));
        dispatch(setPaymentErrorState(true));
        dispatch(setErrorAlert(err.response?.data?.message.split(":")[1]));
      });
  }
);

export const CheckPaymentIntent = createAsyncThunk(
  "CheckPaymentIntent",
  async (_request, { dispatch }) => {
    let form_data = new FormData();
    form_data.append("payment_id", _request?.payment_id);
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    axios
      .post("api/v1/checkout/check-payment-intent/", form_data)
      .then((response) => {
        if (response?.data?.status == "succeeded") {
          _request.callbackTimeFunction();
          _request.callback();
        }
      })
      .catch((err) => {});
  }
);

export const ReciptofPaymentIntent = createAsyncThunk(
  "ReciptofPaymentIntent",
  async (_request, { dispatch }) => {
    let form_data = new FormData();
    form_data.append("payment_intent_id", _request?.payment_intent_id);
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    axios
      .post("api/v1/checkout/generate-charge-receipt/", form_data)
      .then((response) => {
        dispatch(setPaymentReciptLink(response?.data?.data[0]?.receipt_url));
      })
      .catch((err) => {});
  }
);

export const GetCommissionKitty = createAsyncThunk(
  "GetCommissionKitty",
  async (_request, { dispatch }) => {
    dispatch(setCheckoutLoader(true));

    let limit = 10;
    let offSet = 0;

    if (_request?.limit && _request?.limit !== "") {
      limit = _request.limit;
    }

    if (_request?.offSet && _request?.offSet !== "") {
      offSet = _request.offSet;
    }
    axios
      .get(`api/v1/kitty/kitty-commissions/?limit=${limit}&offset=${offSet}`)
      .then((response) => {
        dispatch(setKittyCommissionData(response?.data));
        dispatch(setCheckoutLoader(false));
      })
      .catch((err) => {
        dispatch(setCheckoutSuccess(false));
        dispatch(setCheckoutLoader(false));
        dispatch(setErrorAlert("Something went wrong."));
      });
  }
);

export const CreateCommissionKitty = createAsyncThunk(
  "CreateCommissionKitty",
  async (_request, { dispatch }) => {
    axios
      .post("api/v1/kitty/kitty-commissions/", _request.request)
      .then((response) => {
        dispatch(setPaymentErrorState(false));
        dispatch(GetCommissionKitty({}));
        dispatch(setSuccessAlert("Commission created"));
        _request.callback();
      })
      .catch((err) => {
        dispatch(setPaymentErrorState(true));
        dispatch(setCheckoutSuccess(false));
        if (
          err?.response?.data?.non_field_errors &&
          err?.response?.data?.non_field_errors[0]
        ) {
          dispatch(setErrorAlert("This range is already exist"));
        } else {
          dispatch(setErrorAlert("Something went wrong."));
        }
      });
  }
);

export const UpdateCommissionKitty = createAsyncThunk(
  "UpdateCommissionKitty",
  async (_request, { dispatch }) => {
    const { limit, offSet } = _request;
    axios
      .patch(
        `api/v1/kitty/kitty-commissions/${_request?.id}/`,
        _request.request
      )
      .then((response) => {
        dispatch(GetCommissionKitty({ limit, offSet }));
        dispatch(setSuccessAlert("Commission updated"));
        _request.callback();
      })
      .catch((err) => {
        dispatch(setCheckoutSuccess(false));
        dispatch(setErrorAlert("Something went wrong."));
      });
  }
);

export const deleteCommissionKitty = createAsyncThunk(
  "deleteCommissionKitty",
  async (_request, { dispatch }) => {
    axios
      .delete(`api/v1/kitty/kitty-commissions/${_request}/`)
      .then((response) => {
        dispatch(GetCommissionKitty({}));
        dispatch(setSuccessAlert("Commission deleted"));
        // _request.callback();
      })
      .catch((err) => {
        dispatch(setCheckoutSuccess(false));
        dispatch(setErrorAlert("Something went wrong."));
      });
  }
);

export const GetKittyBeneficiaryDetails = createAsyncThunk(
  "GetKittyBeneficiaryDetails",
  async (_request, { dispatch }) => {
    axios
      .get(`api/v1/kitty/kitty-beneficiary/?kitty_id=${_request}`)
      .then((response) => {
        dispatch(setKittyBenificiaryDetails(response?.data?.results[0]));
        _request.callback();
      })
      .catch((err) => {});
  }
);

export const KittyBeneficiaryDetails = createAsyncThunk(
  "KittyBeneficiaryDetails",
  async (_request, { dispatch }) => {
    axios
      .post("api/v1/kitty/kitty-beneficiary/", _request)
      .then((response) => {
        dispatch(GetCommissionKitty({}));
        dispatch(GetKittyBeneficiaryDetails(_request.kitty));
        dispatch(
          fewUpdateKitty({
            id: _request.kitty,
            is_beneficiary: true,
          })
        );
        _request.callback();
      })
      .catch((err) => {
        dispatch(setCheckoutSuccess(false));
        dispatch(setErrorAlert("Something went wrong."));
      });
  }
);

export const UpdateKittyBeneficiaryDetails = createAsyncThunk(
  "UpdateKittyBeneficiaryDetails",
  async (_request, { dispatch }) => {
    axios
      .patch(`api/v1/kitty/kitty-beneficiary/${_request.id}/`, _request.request)
      .then((response) => {
        dispatch(GetKittyBeneficiaryDetails(_request.kitty));
        dispatch(setSuccessAlert("Data updated"));
        _request.callback();
      })
      .catch((err) => {
        dispatch(setCheckoutSuccess(false));
        dispatch(setErrorAlert("Something went wrong."));
      });
  }
);

export const InsertNewUserCardDetails = createAsyncThunk(
  "InsertNewUserCardDetails",
  async (_request, { dispatch }) => {
    let form_data = new FormData();
    form_data.append("number", _request?.request?.number);
    form_data.append("exp_month", _request?.request?.exp_month);
    form_data.append("exp_year", _request?.request?.exp_year);
    form_data.append("cvc", _request?.request?.cvc);
    form_data.append("customer_id", _request?.request?.customer_id);
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    axios
      .post("api/v1/checkout/save-customer-card-token/", form_data)
      .then((response) => {
        dispatch(setCheckoutSuccess(true));
        dispatch(setSuccessAlert("Card saved"));
        if (_request.callback) {
          _request.callback();
        }
      })
      .catch((err) => {
        dispatch(setCheckoutSuccess(false));
        dispatch(setErrorAlert(err.response?.data?.message.split(":")[1]));
      });
  }
);

export const GetTontineCommission = createAsyncThunk(
  "GetTontineCommission",
  async (_request, { dispatch }) => {
    dispatch(setCheckoutLoader(true));
    let limit = 10;
    let offSet = 0;

    if (_request.limit && _request.limit !== "") {
      limit = _request.limit;
    }

    if (_request.offSet && _request.offSet !== "") {
      offSet = _request.offSet;
    }

    axios
      .get(
        `api/v1/tontine/tontine-commissions/?limit=${limit}&offset=${offSet}`
      )
      .then((response) => {
        dispatch(setTontineCommissionData(response?.data));
        dispatch(setCheckoutLoader(false));
      })
      .catch((err) => {
        dispatch(setCheckoutLoader(false));
      });
  }
);

export const MyCardDetails = createAsyncThunk(
  "MyCardDetails",
  async (_request, { dispatch }) => {
    let form_data = new FormData();
    form_data.append("customer", _request);
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    axios
      .post(`api/v1/checkout/get-customer-card-details/`, form_data)
      .then((response) => {
        dispatch(setListOfSavedCards(response?.data));
      })
      .catch((err) => {});
  }
);

export const DeleteMyCard = createAsyncThunk(
  "DeleteMyCard",
  async (_request, { dispatch }) => {
    let form_data = new FormData();
    form_data.append("customer", _request.customer);
    form_data.append("card", _request.card);
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    axios
      .post(`api/v1/checkout/delete-card/`, form_data)
      .then((response) => {
        dispatch(MyCardDetails(_request?.customer));
        dispatch(setCheckoutSuccess(true));
        dispatch(setSuccessAlert("Card deleted"));
      })
      .catch((err) => {});
  }
);

export const MakeMyCardDefault = createAsyncThunk(
  "MakeMyCardDefault",
  async (_request, { dispatch }) => {
    let form_data = new FormData();
    form_data.append("customer", _request.customer);
    form_data.append("card", _request.card);
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    axios
      .post(`api/v1/checkout/set-card-default/`, form_data)
      .then((response) => {
        dispatch(MyCardDetails(_request?.customer));
        dispatch(setCheckoutSuccess(true));
        dispatch(setSuccessAlert("Default Card changed"));
      })
      .catch((err) => {});
  }
);

export const CreateCommissionTontine = createAsyncThunk(
  "CreateCommissionTontine",
  async (_request, { dispatch }) => {
    const { limit, offSet } = _request;
    axios
      .post("api/v1/tontine/tontine-commissions/", _request.request)
      .then((response) => {
        dispatch(setPaymentErrorState(false));
        dispatch(GetTontineCommission({ limit, offSet }));
        dispatch(setSuccessAlert("Commission created"));
        _request.callback();
      })
      .catch((err) => {
        dispatch(setPaymentErrorState(true));
        if (
          err?.response?.data?.non_field_errors &&
          err?.response?.data?.non_field_errors[0]
        ) {
          dispatch(setErrorAlert("This range is already exist"));
        } else {
          dispatch(setErrorAlert("Something went wrong."));
        }
      });
  }
);

export const UpdateCommissionTontine = createAsyncThunk(
  "UpdateCommissionTontine",
  async (_request, { dispatch }) => {
    const { limit, offSet } = _request;
    axios
      .patch(
        `api/v1/tontine/tontine-commissions/${_request?.id}/`,
        _request.request
      )
      .then((response) => {
        dispatch(GetTontineCommission({ limit, offSet }));
        dispatch(setSuccessAlert("Commission updated"));
        _request.callback();
      })
      .catch((err) => {});
  }
);

export const deleteCommissionTontine = createAsyncThunk(
  "deleteCommissionTontine",
  async (_request, { dispatch }) => {
    axios
      .delete(`api/v1/tontine/tontine-commissions/${_request}/`)
      .then((response) => {
        dispatch(GetTontineCommission({ limit: 10, offSet: 0 }));
        dispatch(setSuccessAlert("Commission deleted"));
      })
      .catch((err) => {});
  }
);
