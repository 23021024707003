/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Paper } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import "./KittyTable.scss";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { listofUserKitty } from "../../store/thunk/Kitty/listofKittyThunk";
import { Box, Button, Typography } from "@mui/material";
import DeleteAlertBBox from "./KittyDelete";
import InsertNewKitty from "./KittyInsertNew";
import { Link, useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import { listofKittyCat } from "../../store/thunk/KittyCategory/listofKittyCatThunk";
import CustomMPagination from "../common/CustomMPagination";

function MyComponent() {
  const [recordLimit, SetRecordLimit] = useState(10);
  const [pageCount, SetPageCount] = useState(0);
  const [pageCountShow, SetPageCountShow] = React.useState(1);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const data = useAppSelector((state) => state.listofKittySlice);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const profileDetails = useAppSelector((state) => state?.UserProfile?.details);

  useEffect(() => {
    dispatch(listofKittyCat({}));
  }, []);

  const subPage = (id) => {
    navigate(`/kittypage/${id}/`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount]);

  useEffect(() => {
    dispatch(
      listofUserKitty({
        limit: recordLimit,
        offSet: pageCount,
        search,
        category,
        status,
      })
    );
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, search, recordLimit, category, status]);

  const redirectPage = () => {
    if (profileDetails?.is_superuser) {
      navigate("/my-kitty");
    } else {
      navigate("/admin");
    }
  };

  return (
    <>
      <Box className="kittyTable">
        <Box sx={{ borderRadius: 0 }}>
          <Grid container>
            <Paper
              sx={{
                width: "100%",
                padding: { md: 2, xs: 1 },
                margin: "16px 16px 0px 16px",
                background: "rgba(89, 32, 145, 0.1411764706)",
                display: { md: "flex", xs: "block" },
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Box
                  className="tableTitle"
                  style={{
                    textTransform: "uppercase",
                    marginTop: "10px",
                  }}
                >
                  {t("kittyListinSection.0")}
                </Box>
                <Box>
                  <Button
                    onClick={redirectPage}
                    sx={{
                      color: "#868484",
                      fontWeight: "600",
                      justifyContent: "left",
                    }}
                  >
                    {"< "}
                    {t("backLabel")}
                  </Button>
                </Box>
              </Box>
              <Box sx={{ mt: 0 }}>
                <InsertNewKitty
                  search={search}
                  setSearch={setSearch}
                  SetPageCount={SetPageCount}
                  setCategory={setCategory}
                  setStatus={setStatus}
                />
              </Box>
            </Paper>
            {data?.list?.results?.map((item) => (
              <Grid
                item
                md={12}
                key={item.id}
                sx={{ flex: "0 0 100%", maxWidth: "100%" }}
              >
                <Paper
                  sx={{
                    p: { xs: 1, md: 2 },
                    border: "1px solid #ddd",
                    margin: "16px 16px 0px 16px",
                  }}
                >
                  <Grid
                    container
                    sx={{
                      justifyContent: "space-between",
                      display: { md: "flex", xs: "block" },
                    }}
                  >
                    <Grid
                      item
                      md={3}
                      sx={{
                        paddingRight: { md: "20px", xs: "0" },
                        borderRight: { md: "1px solid #ddd", xs: "0px" },
                      }}
                      onClick={() => {
                        subPage(item.id);
                      }}
                    >
                      <img
                        src={item.image}
                        alt="TontineImage"
                        style={{
                          width: "100%",
                          height: { md: "150px", xs: "100%" },
                          objectFit: "contain",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={7}
                      sx={{ p: "0 16px" }}
                      onClick={() => {
                        subPage(item.id);
                      }}
                    >
                      <Box
                        sx={{
                          display: { xs: "block", md: "flex" },
                          justifyContent: "space-between",
                          alignContent: "center",
                          m: { md: 0, xs: "10px 0" },
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "600",
                            color: "#555",
                            mb: 1,
                            fontSize: { md: "18px", xs: "16px" },
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "600",
                            color: "#555",
                            fontSize: "15px",
                          }}
                        >
                          {t("statusLabel")} :{" "}
                          <span
                            style={{
                              background:
                                item.status === "OPEN" ? "#008000" : "#ff0000",
                              color: "#fff",
                              padding: "3px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            {item.status}
                          </span>
                        </Typography>
                      </Box>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#555",
                          mb: 1,
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        {`${t("myKitty.2")}: `}
                        <span style={{ fontSize: "15px", fontWeight: "100" }}>
                          {item.category.category_name}
                        </span>
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#555",
                          mb: 1,
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        {t("AmountLable")} :{" "}
                        <span style={{ fontSize: "15px", fontWeight: "100" }}>
                          {item.amount}{" "}
                        </span>
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#555",
                          mb: 1,
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        {t("createdOnLabel")} :{" "}
                        <span style={{ fontSize: "15px", fontWeight: "100" }}>
                          {item.created_at.slice(0, 10)}{" "}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      sx={{
                        paddingLeft: { md: "20px", xs: "0px" },
                        borderLeft: { md: "1px solid #ddd", xs: 0 },
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: "center",
                          background: "#f5564a",
                          marginBottom: "10px",
                          padding: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "3px",
                          color: "#fff",
                        }}
                      >
                        <DeleteAlertBBox data={item} />
                      </Box>
                      <Box
                        sx={{
                          textAlign: "center",
                          background: "#808080",
                          marginBottom: "10px",
                          padding: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "3px",
                          color: "#fff",
                        }}
                      >
                        <Link to={`/kitty-settings/0/${item.id}`}>
                          <Button
                            sx={{ textDecoration: "none", color: "#fff" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: " center",
                                justifyContent: "center",
                                fontWeight: " 600",
                                fontSize: "18px",
                              }}
                            >
                              <SettingsIcon sx={{ color: "#fff ", mr: 1 }} />
                              {t("spendKittyOptionSettingsLable")}
                            </Box>
                          </Button>
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
            {!data?.list?.results?.length && (
              <Grid item md={12}>
                <Paper
                  sx={{
                    p: 2,
                    border: "1px solid #ddd",
                    margin: "16px 16px 0px 16px",
                    color: "#525453cf",
                    backgroundColor: "#f1efeb",
                    textAlign: "center",
                  }}
                >
                  <Grid container>
                    <Grid item md={12}>
                      {data.loading ? <CircularProgress /> : t("norecordLbl")}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>

          <Box sx={{ mt: 1 }}>
            <CustomMPagination
              pageCount={pageCount}
              SetPageCount={SetPageCount}
              recordLimit={recordLimit}
              pageCountShow={pageCountShow}
              SetRecordLimit={SetRecordLimit}
              numRecords={data.list?.num_records}
              numCount={data.list.count}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MyComponent;
