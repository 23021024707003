import * as React from "react";
import { useTranslation } from "react-i18next";
import "../../assets/scss/common.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, Paper, useMediaQuery } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleIcon from "@mui/icons-material/People";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import CommentIcon from "@mui/icons-material/Comment";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DescriptionIcon from "@mui/icons-material/Description";
import EuroIcon from "@mui/icons-material/Euro";
import {
  InvitationListOfKitty,
  TempKittyUpdate,
  contributeAmountKitty,
} from "../../store/thunk/Kitty/KittyDetailsThunk";
import Update from "../Kitty/ourFee/Update";
import Visibility from "./Tabs/Visibility";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/store";
import Amount from "./Tabs/Amount";
import Invites from "./Tabs/Invites";
import AddImage from "./Tabs/AddImage";
import Contributions from "../Kitty/ourFee/tabPanel/Contributions";
import Messages from "../Kitty/ourFee/tabPanel/Messages";
import Notifications from "./Tabs/Notifications";
import Invitations from "./Tabs/Invitations";
// import PayoutKitty from "./Tabs/PayoutKitty";

const PayoutKitty = React.lazy(() => import("./Tabs/PayoutKitty"));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { md: "10px 24px", xs: "10px" } }}>
          <Typography sx={{ width: "100%" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs(props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { index } = useParams();
  const list = useAppSelector((state) => state.DetailsOfKitty.list);
  const contributeAmount = useAppSelector(
    (state) => state.DetailsOfKitty?.contributeAmount
  );
  React.useEffect(() => {
    dispatch(contributeAmountKitty(id));
    dispatch(InvitationListOfKitty({ id, limit: 10, offSet: 0 }));
  }, []);
  React.useEffect(() => {
    dispatch(
      TempKittyUpdate({
        content: list.object_of_kitty,
        objKitty: true,
        id: list.id,
      })
    );
  }, [list]);
  const [value, setValue] = React.useState(Number(index));
  const smallScreen = useMediaQuery("(max-width: 991px)");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ flexGrow: 1, display: "flex" }}>
      <Grid container className="slider-box vertical-slider-box">
        <Grid item md={2} className="vertical-slider-tab">
          <Tabs
            orientation={smallScreen ? " horizonal" : "vertical"}
            // orientation="vertical"
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { background: "#592091" } }}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              tabs: {
                borderRight: "none",
                overflow: "visible",
              },
              ".MuiButtonBase": {
                root: {
                  ".MuiSelected": {
                    background: "#592091",
                  },
                },
              },
            }}
          >
            <Tab
              icon={
                <VisibilityIcon
                  sx={{
                    marginRight: "10px",
                  }}
                />
              }
              label={t("visibilityLabel")}
              sx={{
                p: 2,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start",
              }}
              {...a11yProps(0)}
              onClick={() => {
                dispatch(
                  TempKittyUpdate({
                    content: list.object_of_kitty,
                    objKitty: true,
                    id: list.id,
                  })
                );
              }}
            />

            <Tab
              icon={
                <ImageOutlinedIcon
                  sx={{
                    marginRight: "10px",
                  }}
                />
              }
              label={t("imgLabel")}
              sx={{
                p: 2,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(1)}
            />

            <Tab
              icon={
                <DescriptionIcon
                  sx={{
                    marginRight: "10px",
                  }}
                />
              }
              label={t("descLabel")}
              sx={{
                p: 2,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(2)}
            />

            <Tab
              icon={
                <EuroIcon
                  sx={{
                    marginRight: "10px",
                  }}
                />
              }
              label={t("amtLabel")}
              sx={{
                p: 2,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(3)}
            />
            <Tab
              icon={
                <NotificationsIcon
                  sx={{
                    marginRight: "10px",
                  }}
                />
              }
              label={t("notificationLabel")}
              sx={{
                p: 2,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(4)}
            />
            <Tab
              icon={
                <MailIcon
                  sx={{
                    marginRight: "10px",
                  }}
                />
              }
              label={t("inviteLabel")}
              sx={{
                p: 2,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(5)}
            />
            <Tab
              icon={
                <PaymentsIcon
                  sx={{
                    marginRight: "10px",
                  }}
                />
              }
              label={t("contributeLabel")}
              sx={{
                p: 2,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(6)}
            />
            <Tab
              icon={
                <PeopleIcon
                  sx={{
                    marginRight: "10px",
                  }}
                />
              }
              label={t("invitationLabel")}
              sx={{
                p: 2,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(7)}
            />
            <Tab
              icon={
                <CommentIcon
                  sx={{
                    marginRight: "10px",
                  }}
                />
              }
              label={t("Messages")}
              sx={{
                p: 2,
                fontWeight: "600",
                flexDirection: "row !important",
                justifyContent: "flex-start ",
              }}
              {...a11yProps(8)}
            />
            {contributeAmount?.total_contribution !== null || 0 ? (
              <Tab
                icon={
                  <AccountBalanceWalletIcon
                    sx={{
                      marginRight: "10px",
                    }}
                  />
                }
                label={t("KittyPayoutLable")}
                sx={{
                  p: 2,
                  fontWeight: "600",
                  flexDirection: "row !important",
                  justifyContent: "flex-start ",
                  textAlign: "left",
                }}
                {...a11yProps(9)}
              />
            ) : null}
          </Tabs>
        </Grid>
        <Grid item md={10} sx={{ width: "100%" }}>
          <TabPanel value={value} index={0}>
            <Typography
              sx={{
                textAlign: "center !important",
                color: "#592091 !important",
                display: "table !important",
                margin: "0 auto !important",
                fontWeight: "600 !important",
                lineHeight: "26px !important",
                fontSize: { md: "27px !important", xs: "18px !important" },
                padding: { md: "20px !important", xs: "10px !important" },
              }}
            >
              {t("visibilityLabel")}
            </Typography>
            <Paper
              sx={{
                p: 2,
                width: { md: "95%", xs: "auto" },
                border: "1px solid #ddd",
              }}
            >
              <Visibility id={id} />
            </Paper>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography
              sx={{
                textAlign: "center !important",
                color: "#592091 !important",
                display: "table !important",
                margin: "0 auto !important",
                fontWeight: "600 !important",
                lineHeight: "26px !important",
                fontSize: { md: "27px !important", xs: "18px !important" },
                padding: { md: "20px !important", xs: "10px !important" },
              }}
            >
              {t("imgLabel")}
            </Typography>
            <AddImage />
          </TabPanel>
          <TabPanel value={value} index={2} style={{ width: "100%" }}>
            <Typography
              sx={{
                textAlign: "center !important",
                color: "#592091 !important",
                display: "table !important",
                margin: "0 auto !important",
                fontWeight: "600 !important",
                lineHeight: "26px !important",
                fontSize: { md: "27px !important", xs: "18px !important" },
                padding: { md: "20px !important", xs: "10px !important" },
                width: "100%",
              }}
              variant="h5"
            >
              {t("descLabel")}
            </Typography>

            <Update showCancel={false} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Typography
              sx={{
                textAlign: "center !important",
                color: "#592091 !important",
                display: "table !important",
                margin: "0 auto !important",
                fontWeight: "600 !important",
                lineHeight: "26px !important",
                fontSize: { md: "27px !important", xs: "18px !important" },
                padding: { md: "20px !important", xs: "10px !important" },
              }}
            >
              {t("amtLabel")}
            </Typography>
            <Amount />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Typography
              sx={{
                textAlign: "center !important",
                color: "#592091 !important",
                display: "table !important",
                margin: "0 auto !important",
                fontWeight: "600 !important",
                lineHeight: "26px !important",
                fontSize: { md: "27px !important", xs: "18px !important" },
                padding: { md: "20px !important", xs: "10px !important" },
              }}
            >
              {t("notificationLabel")}
            </Typography>
            <Notifications />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Typography
              sx={{
                textAlign: "center !important",
                color: "#592091 !important",
                display: "table !important",
                margin: "0 auto !important",
                fontWeight: "600 !important",
                lineHeight: "26px !important",
                fontSize: { md: "27px !important", xs: "18px !important" },
                padding: { md: "20px !important", xs: "10px !important" },
              }}
            >
              {t("inviteLabel")}
            </Typography>

            <Invites id={{ id, name: list.name }} />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Typography
              sx={{
                textAlign: "center !important",
                color: "#592091 !important",
                display: "table !important",
                margin: "0 auto !important",
                fontWeight: "600 !important",
                lineHeight: "26px !important",
                fontSize: { md: "27px !important", xs: "18px !important" },
                padding: { md: "20px !important", xs: "10px !important" },
              }}
            >
              {t("contributeLabel")}
            </Typography>

            <Contributions />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <Typography
              sx={{
                textAlign: "center !important",
                color: "#592091 !important",
                display: "table !important",
                margin: "0 auto !important",
                fontWeight: "600 !important",
                lineHeight: "26px !important",
                fontSize: { md: "27px !important", xs: "18px !important" },
                padding: { md: "20px !important", xs: "10px !important" },
              }}
            >
              {t("invitationLabel")}
            </Typography>

            <Invitations id={{ id, name: list.name }} />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <Typography
              sx={{
                textAlign: "center !important",
                color: "#592091 !important",
                display: "table !important",
                margin: "0 auto !important",
                fontWeight: "600 !important",
                lineHeight: "26px !important",
                fontSize: { md: "27px !important", xs: "18px !important" },
                padding: { md: "20px !important", xs: "10px !important" },
              }}
            >
              {t("messageLabel")}
            </Typography>

            <Messages id={id} />
          </TabPanel>
          <TabPanel value={value} index={9}>
            <Typography
              sx={{
                textAlign: "center !important",
                color: "#592091 !important",
                display: "table !important",
                margin: "0 auto !important",
                fontWeight: "600 !important",
                lineHeight: "26px !important",
                fontSize: { md: "27px !important", xs: "18px !important" },
                padding: { md: "20px !important", xs: "10px !important" },
              }}
            >
              {t("KittyPayoutLable")}
            </Typography>
            <React.Suspense
              fallback={
                <div sx={{ minHeight: "540px", background: "#f1efeb" }}></div>
              }
            >
              <PayoutKitty data={list} />
            </React.Suspense>
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
}
