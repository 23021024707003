import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setDeleteKitty,
  setKittyDeleteError,
  setKitttyDeleteSuccess,
} from "../../reducer/KittySlice/DeleteKittyListSlice";
import axios from "axios";
import { setSuccessAlert, setErrorAlert } from "../../reducer/AlertSlice";
import { DeleteData } from "../../reducer/User/TokenExpireSlice";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Authorization"] = localStorage.getItem("UserInfo")
  ? `Token ${localStorage.getItem("UserInfo")}`
  : "";

export const deleteKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    axios
      .delete(`api/v1/kitty/kitty/${_request.id}/`)
      .then((response) => {
        dispatch(setDeleteKitty(response.data));
        dispatch(setSuccessAlert("Kitty Deleted"));
        dispatch(setKitttyDeleteSuccess(true));
        if (_request.callback) {
          _request.callback();
        }
      })
      .catch((err) => {
        dispatch(setKittyDeleteError(err));
        dispatch(setErrorAlert(err?.response?.data?.message));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
