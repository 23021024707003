import * as React from "react";
import PropTypes from "prop-types";
// import Box from 'react-swipeable-views';
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import AllTontineTable from "./AllTontine";
import MyTontineTable from "./MyTontine";
import UserPausedTontine from "./UserPausedTontine";
import { Container } from "@mui/material";
// import SignInPopUp from "../../../components/SignIn/SignInPopup";
const SignInPopUp = React.lazy(() =>
  import("../../../components/SignIn/SignInPopup")
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { md: 2, xs: 1 } }}>
          <Typography sx={{ width: " 100%" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function TontineTab() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <Container sx={{ pt: 10 }}>
      {localStorage.getItem("UserInfo") ? (
        <Paper className="kittyTable" sx={{ border: "1px solid #ddd", mb: 5 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            sx={{
              background: "#ddd",
              color: "#000",
              "& .MuiTab-root": {
                height: "70px",
                fontSize: { md: "20px", xs: "13px" },
                fontWeight: "600",
              },
              "& .MuiTab-root.Mui-selected": {
                background: "rgb(145, 188, 255)",
                color: "#fff",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#91bcff",
              },
            }}
          >
            <Tab label={t("tontineTab2")} {...a11yProps(0)} />
            <Tab label={t("tontineTab1")} {...a11yProps(1)} />
            <Tab label={t("PausedTontineLable")} {...a11yProps(2)} />
          </Tabs>
          <Box
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <MyTontineTable />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <AllTontineTable />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <UserPausedTontine />
            </TabPanel>
          </Box>
        </Paper>
      ) : (
        <React.Suspense
          fallback={
            <div sx={{ minHeight: "540px", background: "#f1efeb" }}></div>
          }
        >
          <SignInPopUp hidePopupFunc={{ close: true }} />
        </React.Suspense>
      )}
    </Container>
  );
}
