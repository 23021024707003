import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './assets/locales/en/Translation.json';
import translationFR from './assets/locales/fr/Translation.json';

const resources = {
    en: {
        translation: translationEN
      },
  fr: {
    translation: translationFR
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;