import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Box, Typography, Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ConfirmDialogComponent from "../../../../../components/common/ConfirmDialog";
import {
  updateTontineAssignee,
  getTontineParticipants,
} from "../../../../../store/thunk/Tontine/MytontineThunk";
import { useAppDispatch } from "../../../../../store/store";

function AssignUserTontineRow({ details, recordLimit, pageCount }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [userId, setUserId] = React.useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [participantsList, setParticipantsList] = useState([]);

  const [requestData, setRequestData] = useState({
    tontine_id: 0,
    swap_user: 0,
    participant_id: 0,
  });

  const handleChange = (event) => {
    setUserId(event.target.value);
  };

  const getTontineParticipantsCallback = (participants) => {
    setParticipantsList(participants);
  };

  useEffect(() => {
    dispatch(
      getTontineParticipants({
        tontine_id: details?.id,
        callBack: getTontineParticipantsCallback,
      })
    );
    setUserId(null);
  }, [details]);

  const confirmUserChange = (data) => {
    setShowConfirmDialog(true);
    setConfirmMessage("Are you sure want to assign tontine to selected user?");
    const selectedParticipant = participantsList.filter((participant) => {
      return participant.id == userId;
    });

    setRequestData({
      tontine_id: data?.id,
      swap_user: selectedParticipant[0]?.user_id,
      participant_id: selectedParticipant[0]?.id,
    });
  };

  const assignUserTontine = () => {
    dispatch(
      updateTontineAssignee({
        tontine_id: requestData.tontine_id,
        swap_user: requestData.swap_user,
        participant_id: requestData.participant_id,
        limit: recordLimit,
        offSet: pageCount,
        callback: () => {
          setUserId(null);
          setRequestData({
            tontine_id: 0,
            swap_user: 0,
            participant_id: 0,
          });
        },
      })
    );
  };

  const confirmSuccessCallback = () => {
    setShowConfirmDialog(false);
    setShowConfirmDialog(false);
    assignUserTontine();
  };

  const confirmCancelCallback = () => {
    setShowConfirmDialog(false);
    setUserId(details?.user?.id);
    setRequestData({
      tontine_id: 0,
      swap_user: 0,
      participant_id: 0,
    });
  };

  return (
    <>
      <Grid item md={12} key={details.id}>
        <Paper
          sx={{
            p: 2,
            border: "1px solid #ddd",
            margin: { md: "16px 16px 0px 16px", xs: "10px" },
          }}
        >
          <Grid
            container
            sx={{ display: { md: "flex", xs: "block" }, alignItems: "center" }}
          >
            <Grid item md={4} sx={{ p: { md: "0 16px", xs: 0 } }}>
              <Typography
                variant="h5"
                sx={{
                  color: "#555 !important",
                  mb: "8px !important",
                  fontSize: "15px !important",
                  fontWeight: "600 !important",
                }}
              >
                {t("tontineHeaderLabel")}:{" "}
                <span style={{ fontSize: "15px", fontWeight: "100" }}>
                  {details?.name}
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                paddingLeft: { md: "20px", xs: "0px" },
              }}
            >
              {participantsList?.length ? (
                <Box sx={{ minWidth: 120, maxWidth: 250 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      {t("tontineSelectUserLabel")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userId}
                      label={t("tontineSelectUserLabel")}
                      onChange={handleChange}
                      MenuProps={{
                        style: {
                          maxHeight: 220,
                        },
                      }}
                    >
                      {participantsList?.map((participant) => {
                        return (
                          <MenuItem
                            value={participant.id}
                            key={participant.id}
                          >{`${participant.full_name}`}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Box sx={{ minWidth: 120, maxWidth: 250, color: "#909090" }}>
                  {t("tontineNoParticipatLabel")}
                </Box>
              )}
            </Grid>
            <Grid
              item
              md={2}
              sx={{
                paddingLeft: { md: "20px", xs: 0 },
                borderLeft: { md: "1px solid #ddd", xs: "0px" },
                pt: { md: 0, xs: "20px" },
              }}
            >
              <Button
                sx={{
                  background: "#592091",
                  color: "#fff",
                  display: "block",
                  padding: "8px 20px",
                  fontWeight: "600",
                  letterSpacing: "2px",
                  border: "1px solid #592091",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#592091",
                    background: "#fff",
                  },
                  "&:disabled": {
                    color: "#fff",
                    background: "#876ba3",
                  },
                }}
                disabled={
                  participantsList?.length && userId && userId !== ""
                    ? false
                    : true
                }
                onClick={() => confirmUserChange(details)}
              >
                {t("tontineAssignLabel")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {showConfirmDialog && (
        <ConfirmDialogComponent
          confirmSuccessFunc={confirmSuccessCallback}
          confirmCancelFunc={confirmCancelCallback}
          title={t("confirmationLabel")}
          contentText={confirmMessage}
          dialogStatus={showConfirmDialog}
        />
      )}
    </>
  );
}

export default AssignUserTontineRow;
