import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Chip } from "@mui/material";
import { createTheme } from "react-data-table-component";
import moment from "moment";
import DataTable from "../../../../../common/DataTableBase";
import { useAppDispatch, useAppSelector } from "../../../../../../store/store";
import { getTontinePayoutLogList } from "../../../../../../store/thunk/Tontine/MytontineThunk";
import CustomMPagination from "../../../../../common/CustomMPagination";

export default function AdminTontinePayoutLogComponent() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = useState(0);
  const [pageCountShow, SetPageCountShow] = useState(0);
  const [recordLimit, SetRecordLimit] = useState(10);
  const adminTontinePayoutLogData = useAppSelector((state) => state.MyTontine);

  const columns = [
    {
      name: t("TontineNameLabel"),
      minWidth: "250px",
      selector: (row) => row.tontine_name,
    },
    {
      name: t("TontinePayoutIBANLabel"),
      minWidth: "200px",
      selector: (row) => row.iban,
    },
    {
      name: t("TontinePayoutActualAmtLabel"),
      minWidth: "150px",
      selector: (row) => row.actual_amount,
    },
    {
      name: t("TontinePayoutAmtLabel"),
      minWidth: "150px",
      selector: (row) => row.payout_amount,
    },
    {
      name: t("TontinePayoutCommissionLabel"),
      minWidth: "200px",
      selector: (row) => row.payout_commission,
    },
    {
      name: t("TontinePayoutDateLabel"),
      minWidth: "170px",
      selector: (row) =>
        row.payout_date
          ? moment(row.payout_date).format(
              localStorage.getItem("language") == "en"
                ? "YYYY-MM-DD"
                : "DD-MM-YYYY"
            )
          : "-",
    },
    {
      name: t("TontinePayoutIsPaidLabel"),
      minWidth: "85px",
      selector: useCallback((row) => {
        return (
          <Chip
            label={row.is_paid ? "Yes" : "No"}
            sx={{
              background: row.is_paid ? "#008000" : "#ff0000",
              color: "#fff",
            }}
            size="small"
          />
        );
      }, []),
    },
    {
      name: t("TontinePayouttoLabel"),
      minWidth: "300px",
      selector: (row) => row.payout_to,
    },
    {
      name: t("TontinePayoutReasonLabel"),
      minWidth: "100px",
      selector: (row) => row.reason || "-",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    dispatch(
      getTontinePayoutLogList({ limit: recordLimit, offSet: pageCount })
    );
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, recordLimit]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "#ccc",
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "#fff ",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        textAlign: "center",
        fontSize: "17px",
        display: "grid",
        placeContent: "center",
        backgroundColor: "#2a2828",
        color: "#fff",
        maxWidth: "40px",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        padding: "8px",
        fontSize: "14px",
        display: "grid",
        placeContent: "center",
      },
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );

  const recordCount =
    adminTontinePayoutLogData?.adminTontinePayoutLogList?.count;

  return (
    <>
      <Box className="kittyTable">
        <Box sx={{ borderRadius: 0, padding: { md: 2, xs: 1 } }}>
          <DataTable
            title={
              <>
                <Box
                  className="tableTitle"
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {t("TontinePayoutLogHeader")}
                </Box>
              </>
            }
            columns={columns}
            data={adminTontinePayoutLogData?.adminTontinePayoutLogList?.results}
            customStyles={customStyles}
            theme="solarized"
            noDataComponent={<Box sx={{ p: 3 }}>{t("norecordLbl")}</Box>}
            className="rdt_TableHeader"
            progressPending={adminTontinePayoutLogData?.loading}
          />
          <CustomMPagination
            pageCount={pageCount}
            SetPageCount={SetPageCount}
            recordLimit={recordLimit}
            pageCountShow={pageCountShow}
            SetRecordLimit={SetRecordLimit}
            numRecords={
              adminTontinePayoutLogData?.adminTontinePayoutLogList?.num_records
            }
            numCount={
              adminTontinePayoutLogData?.adminTontinePayoutLogList?.count
            }
          />
        </Box>
      </Box>
    </>
  );
}
