import * as React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ListofKitty from "./KittyTab";
import { Box } from "@mui/material";

export default function Admin() {
  return (
    <>
      <Header />
      <Box sx={{ background: "#f1efeb" }} className="min-height-div">
        <ListofKitty />
      </Box>
      <Footer />
    </>
  );
}
