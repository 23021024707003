import React from "react";
import Footer from "../components/common/Footer"
import Header from "../components/common/Header";
import TontineBeforeLoginPage from "./tontinePage/tontineLanding/tontineBeforeLoginPage";

function TontineBeforeLoginComponent() {
  return (
    <>
      <Header />
      <TontineBeforeLoginPage />
      <Footer />
    </>
  );
}
export default TontineBeforeLoginComponent;
