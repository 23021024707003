import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function LegalImprint() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        pt: "5rem",
        backgroundColor: "#f1efeb",
        width: "100%",
        pb: "0 !important",
      }}
    >
      <Container>
        <Divider>
          <Typography
            className="top-subheading"
            variant="h3"
            sx={{
              fontWeight: "700",
              fontSize: { md: "30px", xs: "25px" },
              m: "10px 0px",
            }}
          >
            {t("LegalImprint.0")}
          </Typography>
        </Divider>
        <Box>
          <Box sx={{ m: "20px 0", textAlign: { md: "justify", xs: "left" } }}>
            <Paper>
              <Box sx={{ p: { md: 2, xs: 1 }, backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{ fontSize: { md: "16px", xs: "13px" } }}
                >
                  {t("LegalImprint.1")}
                </Typography>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0" }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { md: "22px", xs: "18px" },
                    fontWeight: "600",
                    display: "block",
                    p: { md: 2, xs: "16px 8px" },
                    color: "#592091",
                  }}
                >
                  {t("LegalImprint.2.0")}
                </Typography>
                <Divider />
                <Box sx={{ p: { md: 2, xs: 1 } }}>
                  <Typography>{t("LegalImprint.2.1")}</Typography>
                  <Typography
                    variant="p"
                    sx={{
                      display: "block",
                      fontSize: "18px",
                      fontWeight: "500",
                      display: "block",
                      p: "10px 0px",
                    }}
                  >
                    {t("LegalImprint.2.2")}
                  </Typography>
                  <Box sx={{ border: "1px solid #cfcbca", m: "8px 0px", p: 1 }}>
                    <Typography variant="p" sx={{ display: "block" }}>
                      MAKAV
                    </Typography>
                    <Typography variant="p" sx={{ display: "block" }}>
                      {" "}
                      {t("LegalImprint.2.3")}
                    </Typography>
                    <Typography variant="p" sx={{ display: "block" }}>
                      {t("LegalImprint.2.4")}
                    </Typography>
                    <Typography variant="p" sx={{ display: "block" }}>
                      {t("LegalImprint.2.5")}
                    </Typography>
                    <Typography variant="p" sx={{ display: "block" }}>
                      {t("LegalImprint.2.6")}
                    </Typography>
                  </Box>
                  <Typography variant="p" sx={{ display: "block" }}>
                    {t("LegalImprint.2.7")}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0" }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { md: "22px", xs: "18px" },
                    fontWeight: "600",
                    display: "block",
                    p: { md: 2, xs: "16px 8px" },
                    color: "#592091",
                  }}
                >
                  {t("LegalImprint.3")}
                </Typography>
                <Divider />
                <Box sx={{ p: { md: 2, xs: 1 } }}>
                  <Typography variant="p">{t("LegalImprint.4")}</Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0", textAlign: { md: "justify", xs: "left" } }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { md: "22px", xs: "18px" },
                    fontWeight: "600",
                    display: "block",
                    p: { md: 2, xs: "16px 8px" },
                    color: "#592091",
                  }}
                >
                  {t("LegalImprint.5")}
                </Typography>
                <Divider />
                <Box sx={{ p: { md: 2, xs: 1 } }}>{t("LegalImprint.6")}</Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0" }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { md: "22px", xs: "18px" },
                    fontWeight: "600",
                    display: "block",
                    p: { md: 2, xs: "16px 8px" },
                    color: "#592091",
                  }}
                >
                  {t("LegalImprint.7")}
                </Typography>
                <Divider />
                <Box sx={{ p: { md: 2, xs: 1 } }}>{t("LegalImprint.8")}</Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0", textAlign: { md: "justify", xs: "left" } }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { md: "22px", xs: "18px" },
                    fontWeight: "600",
                    display: "block",
                    p: { md: 2, xs: "16px 8px" },
                    color: "#592091",
                  }}
                >
                  {t("LegalImprint.9")}
                </Typography>
                <Divider />
                <Box sx={{ p: { md: 2, xs: 1 } }}>{t("LegalImprint.10")}</Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0", textAlign: { md: "justify", xs: "left" } }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { md: "22px", xs: "18px" },
                    fontWeight: "600",
                    display: "block",
                    p: { md: 2, xs: "16px 8px" },
                    color: "#592091",
                  }}
                >
                  {t("LegalImprint.11")}
                </Typography>
                <Divider />
                <Box sx={{ p: { md: 2, xs: 1 } }}>{t("LegalImprint.12")}</Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0" }}>
            <Paper>
              <Box
                sx={{
                  textAlign: { md: "justify", xs: "left" },
                  backgroundColor: "#dac0f4",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { md: "22px", xs: "18px" },
                    fontWeight: "600",
                    display: "block",
                    p: { md: 2, xs: "16px 8px" },
                    color: "#592091",
                  }}
                >
                  {t("LegalImprint.13")}
                </Typography>
                <Divider />
                <Box sx={{ p: { md: 2, xs: 1 } }}>{t("LegalImprint.14")}</Box>
              </Box>
            </Paper>
          </Box>
          <Box
            sx={{
              m: "20px 0 0 0",
              textAlign: { md: "justify", xs: "left" },
              pb: 3,
            }}
          >
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { md: "22px", xs: "18px" },
                    fontWeight: "600",
                    display: "block",
                    p: { md: 2, xs: "16px 8px" },
                    color: "#592091",
                  }}
                >
                  {t("LegalImprint.15")}
                </Typography>
                <Divider />
                <Box sx={{ p: { md: 2, xs: 1 } }}>{t("LegalImprint.16")}</Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default LegalImprint;
