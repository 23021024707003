import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as React from "react";
import CardBlock from "./KittyCardBlock";
import "./style.scss";

export default function KittyOption() {
  const { t } = useTranslation();
  return (
    <>
      <Container className="adminCard">
        <Box container sx={{ pt: 10 }}>
          <Box sx={{ textAlign: "center", mb: 4, mt: 2 }}>
            <Typography
              className="admin-heading"
              variant="h3"
              sx={{ fontSize: { md: "30px", xs: "22px" } }}
            >
              {" "}
              {t("myKitty.0")}
            </Typography>
            <Typography
              className="admin-subheading"
              variant="h3"
              sx={{ fontSize: { md: "30px", xs: "22px" } }}
            >
              {" "}
              {t("myKitty.1")}
            </Typography>
          </Box>
          <CardBlock />
        </Box>
      </Container>
    </>
  );
}
