import * as React from "react";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch } from "../../../../../store/store";
import { deleteKitty } from "../../../../../store/thunk/Kitty/DeleteKittyListThunk";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import DeleteDialogBox from "../../../../../components/Kitty/KittyDeleteDialog";
import { Box } from "@mui/material";
import { listofKittyForAdmin } from "../../../../../store/thunk/Kitty/listofKittyThunk";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteAlertBBox(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const deleteUpdateKitty = () => {
    dispatch(
      deleteKitty({
        id: props.data.id,
        callback: () => {
          dispatch(
            listofKittyForAdmin({
              limit: props.recordLimit,
              offSet: props.pageCount,
              search: props.search,
              category: props.category,
              status: props.status,
            })
          );
        },
      })
    );
  };

  return (
    <>
      <Tooltip title={t("deleteLable")}>
        <Button
          onClick={handleClickOpen}
          sx={{ textDecoration: "none", color: "#fff", minWidth: "unset" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: " center",
              justifyContent: "center",
              fontWeight: " 600",
              fontSize: "18px",
            }}
          >
            <DeleteIcon sx={{ color: "#ff0000", mr: 1 }} />
          </Box>
        </Button>
      </Tooltip>
      <DeleteDialogBox
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        deleteUpdateKitty={deleteUpdateKitty}
        open={open}
        data={props.data}
      />
    </>
  );
}
