import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  GetPausedTontineDetails,
  ResumePausedTontine,
} from "../../../store/thunk/Tontine/MytontineThunk";
import {
  Box,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomMPagination from "../../../components/common/CustomMPagination";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#592091ab",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function MyTontineTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = React.useState(0);
  const [pageCountShow, SetPageCountShow] = React.useState(1);
  const [recordLimit, SetRecordLimit] = React.useState(10);

  const PausedTontine = useAppSelector(
    (state) => state.MyTontine?.PausedTontineList
  );

  const loginUserDetails = useAppSelector(
    (state) => state?.UserProfile?.details
  );

  React.useEffect(() => {
    dispatch(GetPausedTontineDetails());
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, recordLimit]);

  const myTontine = useAppSelector((state) => state.MyTontine);

  const detailPage = (id) => {
    navigate(`/tontine/${id}`, {
      state: { backRedirectUrl: "/tontine-admin" },
    });
  };

  return (
    <>
      <Box sx={{ margin: "10px 0" }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={myTontine?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Paper
          sx={{
            p: 2,
            backgroundColor: "#59209124",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="h5"
              sx={{
                textTransform: "uppercase",
                fontWeight: " 600",
                fontSize: "16px",
              }}
            >
              {t("PausedTontineLable")}
            </Typography>
          </Box>
        </Paper>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">{"Profile"}</StyledTableCell>
              <StyledTableCell>{t("tontineTableColumn.1")}</StyledTableCell>
              <StyledTableCell align="center">
                {t("tontineTableColumn.2")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("tontineTableColumn.3")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("tontineFrequencyFieldLabel")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("FutureReliability")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("StartDateLable")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("NumberParticipantsLable")}
              </StyledTableCell>

              <StyledTableCell align="center" sx={{ width: "20px" }}>
                {t("ResumeTontineLable")}
              </StyledTableCell>
              {/* <StyledTableCell align="center" sx={{ width: "20px" }}>
                {t("tontineTableColumn.9")}
              </StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {PausedTontine?.results?.length ? (
              PausedTontine?.results?.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    <img
                      src={row.image}
                      alt="Tontine"
                      height={60}
                      width={85}
                      onClick={() => detailPage(row.id)}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    onClick={() => detailPage(row.id)}
                  >
                    <Box sx={{ width: "160px" }}>{row.name}</Box>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.id)}
                  >
                    {t(row.status)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.id)}
                  >
                    {row.amount}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.id)}
                  >
                    {t(row.frequency)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.id)}
                  >
                    {row.future_reliability}%
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.id)}
                  >
                    {row?.creation_date !== null
                      ? moment(row?.creation_date).format(
                          localStorage.getItem("language") == "en"
                            ? "YYYY-MM-DD"
                            : "DD-MM-YYYY"
                        )
                      : t("NotstartedLable")}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.id)}
                  >
                    {row.tontine_participants_id?.length}
                  </StyledTableCell>
                  {loginUserDetails?.id === row?.user?.id ? (
                    <StyledTableCell align="center">
                      <>
                        <Button
                          sx={{
                            background: "#9069b5",
                            color: "#fff",
                            borderRadius: "10px",
                            "&:hover": {
                              background: "#fff",
                              color: "#592091",
                              border: "1px solid #592091",
                              borderRadius: "10px",
                            },
                          }}
                          onClick={() => {
                            dispatch(ResumePausedTontine(row?.id));
                          }}
                        >
                          {t("ResumeTontineLable")}
                        </Button>
                      </>
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center">
                      <Typography variant="p">
                        {t("TontineHoldLable")}
                      </Typography>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow key="1">
                <StyledTableCell
                  scope="row"
                  colSpan={10}
                  sx={{
                    color: "#525453cf",
                    backgroundColor: "#f1efeb",
                    textAlign: "center",
                  }}
                >
                  {t("norecordLbl")}
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ mt: 1 }}>
        <CustomMPagination
          pageCount={pageCount}
          SetPageCount={SetPageCount}
          recordLimit={recordLimit}
          pageCountShow={pageCountShow}
          SetRecordLimit={SetRecordLimit}
          numRecords={PausedTontine?.num_records}
          numCount={PausedTontine?.count}
        />
      </Box>
    </>
  );
}
