import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDialogComponent(props) {
  const { t } = useTranslation();

  const {
    confirmSuccessFunc,
    confirmCancelFunc,
    title,
    contentText,
    dialogStatus,
    confirmOkBGColor,
  } = props;

  return (
    <div>
      <Dialog
        sx={{ padding: { md: "0 24px", xs: "0 10px" } }}
        open={dialogStatus}
        TransitionComponent={Transition}
        keepMounted
        onClose={confirmCancelFunc}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            fontWeight: "600",
            background: "#592091",
            color: "#fff",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              background: "#fff",
              color: "#592091",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={confirmCancelFunc}
          >
            {t("noLabel")}
          </Button>
          <Button
            sx={{
              background: confirmOkBGColor ? confirmOkBGColor : "#592091",
              color: "#fff",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: "2px",
              border: `1px solid ${
                confirmOkBGColor ? confirmOkBGColor : "#592091"
              }`,
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={confirmSuccessFunc}
          >
            {t("yesLabel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
