import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, Divider, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import DehazeIcon from "@mui/icons-material/Dehaze";
import "../Header/Header.scss";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";
import HoverMenu from "../NavigationMenu/index";
import UserDrawer from "./UserDrawer";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import i18Next from "../../../i18Next";
import Logo from "../../../assets/Images/likelemba-blanc.png";
import { UserProfileDetails } from "../../../store/thunk/UserProfileThunk";

const navTheme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontSize: 14,
    h6: {
      fontSize: "1.25rem",
      fontWeight: 900,
      lineHeight: "1.2em",
    },
  },
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "#f1efeb !important",
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 120,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

function Header() {
  const dispatch = useAppDispatch();
  const userAuthData = useAppSelector((state) => state.userSlice);
  const [offset, setOffset] = React.useState(0);
  const [topMenuBarColor, setTopMenuBarColor] = React.useState("#592091");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const [flag, setFlag] = React.useState(
    localStorage.getItem("language") === "en" ? "gb" : "fr"
  );

  const changeLanguageHandler = (language) => {
    if (language) localStorage.setItem("language", language);
    i18Next.changeLanguage(
      localStorage.getItem("language") ? localStorage.getItem("language") : "fr"
    );
    setFlag(localStorage.getItem("language") === "en" ? "gb" : "fr");
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    changeLanguageHandler();
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    if (localStorage.getItem("UserInfo")) {
      dispatch(UserProfileDetails());
    }
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // React.useEffect(() => {
  //   if (offset) {
  //     if (pathName === "/") {
  //       if (window.scrollY >= 20) {
  //         setTopMenuBarColor("#592091 ");
  //       } else {
  //         setTopMenuBarColor("transparent");
  //       }
  //     }
  //   }
  // }, [offset]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <CloseIcon
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        sx={{
          position: "absolute",
          right: "0",
          fontSize: "23px",
          padding: "3px",
          margin: "6px",
          color: "white",
          zIndex: "9",
        }}
      />
      <List sx={{ p: "0 !important" }}>
        <ListItem
          disablePadding
          sx={{ background: "#4b0082", display: "block" }}
        >
          <Box sx={{ display: { md: "none", xs: "block" } }}>
            {userAuthData.token ? (
              <Typography variant="h6" sx={{ fontSize: 14 }}>
                <UserDrawer />
              </Typography>
            ) : (
              <Link className="NavRoutes" to="/sign-in/">
                <Button sx={{ p: "0 !important" }}>
                  <LockIcon
                    sx={{
                      color: "#592091",
                      background: "#ffffff",
                      borderRadius: "50%",
                      margin: "10px",
                      fontSize: "20px",
                      padding: "5px",
                    }}
                  />
                  <Typography
                    variant="h6"
                    className="button1"
                    sx={{ color: "#fff", fontSize: 14 }}
                  >
                    {t("login")}
                  </Typography>
                </Button>
              </Link>
            )}
          </Box>
        </ListItem>
        <ListItem disablePadding sx={{ cursor: "pointer" }}>
          <ListItemButton>
            <Link to="/how-it-works">
              <Typography
                variant="h6"
                sx={{ fontSize: 14, padding: "8px 12px" }}
              >
                {t("howItWorks")}
              </Typography>
            </Link>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding sx={{ cursor: "pointer" }}>
          <ListItemButton>
            <Link to="/how-to-create-tontine">
              <Typography
                variant="h6"
                sx={{ fontSize: 14, padding: "8px 12px" }}
              >
                {t("TONTINE")}
              </Typography>
            </Link>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding sx={{ cursor: "pointer" }}>
          <ListItemButton>
            <Link to="/money-pots-innerPage/allkitty">
              <Typography
                variant="h6"
                sx={{ fontSize: 14, padding: "8px 12px" }}
              >
                {t("projects")}
              </Typography>
            </Link>
          </ListItemButton>
        </ListItem>
        <Divider />
      </List>
    </Box>
  );

  return (
    <ThemeProvider theme={navTheme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          elevation={0}
          // style={{ background: topMenuBarColor }}
          style={{ background: "#592091" }}
        >
          <Toolbar>
            <Grid
              container
              sx={{
                flexWrap: "nowrap",
                justifyContent: " space-between",
                height: "50px",
              }}
            >
              <Grid item md={3}>
                <Link
                  to="/"
                  style={{
                    color: "#fff",
                    display: "block",
                    padding: "5px",
                    width: "100px",
                  }}
                >
                  <img src={Logo} alt="LIKELEMBA" style={{ width: "100%" }} />
                </Link>
              </Grid>
              <Grid item md={9} sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    display: { md: "flex", xs: "none" },
                    alignItems: "center",
                  }}
                >
                  <SearchIcon sx={{ color: "#fff" }} />
                  <HoverMenu />
                  <Link to="/how-to-create-tontine">
                    <Typography
                      variant="h6"
                      sx={{ fontSize: 14, padding: "8px 12px" }}
                      className="button1"
                    >
                      {t("TONTINE")}
                    </Typography>
                  </Link>
                  <Link to="/how-it-works">
                    <Typography
                      variant="h6"
                      sx={{ fontSize: 14, padding: "8px 12px" }}
                      className="button1"
                    >
                      {t("howItWorks")}
                    </Typography>
                  </Link>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    height: "50px",
                  }}
                >
                  <Box sx={{ display: "inline", textAlign: "right" }}>
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      disableElevation
                      onClick={handleClick}
                      endIcon={
                        <KeyboardArrowDownIcon
                          sx={{ color: "#f1efeb", marginLeft: 0 }}
                        />
                      }
                      sx={{
                        backgroundColor: topMenuBarColor,
                        padding: 0,
                        height: " 100%",
                        "&:hover": {
                          backgroundColor: topMenuBarColor,
                        },
                      }}
                    >
                      <img
                        loading="lazy"
                        width="25"
                        height="15"
                        src={`https://flagcdn.com/w20/${flag}.png`}
                        srcSet={`https://flagcdn.com/w40/${flag}.png 2x`}
                        alt=""
                      />
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      {flag === "gb" ? (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            changeLanguageHandler("fr");
                          }}
                          disableRipple
                        >
                          <img
                            loading="lazy"
                            width="20"
                            style={{ padding: "0 5px 0 0" }}
                            src={`https://flagcdn.com/w20/fr.png`}
                            srcSet={`https://flagcdn.com/w40/fr.png 2x`}
                            alt=""
                          />
                          française
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            changeLanguageHandler("en");
                          }}
                          disableRipple
                        >
                          <img
                            loading="lazy"
                            width="20"
                            style={{ padding: "0 5px 0 0" }}
                            src={`https://flagcdn.com/w20/gb.png`}
                            srcSet={`https://flagcdn.com/w40/gb.png 2x`}
                            alt=""
                          />
                          English
                        </MenuItem>
                      )}
                    </StyledMenu>
                  </Box>
                  <Box sx={{ display: { md: "block", xs: "none" } }}>
                    {userAuthData.token ? (
                      <UserDrawer />
                    ) : (
                      <Link
                        className="NavRoutes"
                        to="/sign-in/"
                        style={{
                          display: "block",
                          height: "100%",
                          lineHeight: "45px",
                        }}
                      >
                        <Button>
                          <LockIcon
                            sx={{
                              color: "#ffffff",
                              fontSize: 16,
                            }}
                          />
                          <Typography
                            variant="h6"
                            className="button1"
                            sx={{ color: "#fff", fontSize: 14 }}
                          >
                            {t("login")}
                          </Typography>
                        </Button>
                      </Link>
                    )}
                  </Box>
                </Box>
                <Grid
                  item
                  md={2}
                  sx={{
                    display: { md: "none", xs: "flex" },
                    alignItems: "center",
                  }}
                >
                  {["right"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Button
                        onClick={toggleDrawer(anchor, true)}
                        sx={{ minWidth: "unset !important" }}
                      >
                        {" "}
                        <DehazeIcon sx={{ color: "#fff" }} />
                      </Button>
                      <SwipeableDrawer anchor={anchor} open={state[anchor]}>
                        {list(anchor)}
                      </SwipeableDrawer>
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}

export default Header;
