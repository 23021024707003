import { Box, Container, Typography } from "@mui/material";
import SecurityImage from "../../../../assets/Images/cradit-debit-card.png";
import AccordionComponent from "../../../../components/common/Accordion";
import { useTranslation } from "react-i18next";

export default function CardVerifiedBlock() {
    const { t } = useTranslation();
    return (
        <Box sx={{ p: "0px 0px 50px" }}>
            <Container>
                <Box>
                    <Typography
                        className="top-subheading"
                        variant="h5"
                        sx={{ textAlign: "center", fontWeight: "600", mb: 2 }}
                    >
                        {t("servicePageHeading.2")}
                    </Typography>
                    <img
                        src={SecurityImage}
                        alt=""
                        style={{ margin: "0 auto 40px", display: "table" }}
                    />
                </Box>
            </Container>
            <Container maxWidth="md">
                <Box>
                    <AccordionComponent
                        title=
                        {t("servicePageQuestion.6")}
                        content={t("servicePageAnswer.6")} />
                    <AccordionComponent
                        title=
                        {t("servicePageQuestion.7")}
                        content={t("servicePageAnswer.7")} />
                </Box>
            </Container>
        </Box>
    );
}
