import { Box, Grid, Typography } from "@mui/material";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Image1 from "../../../assets/Images/wedding-vows-and-wedding-rings.jpg";
import Image2 from "../../../assets/Images/wrtiting-tools.jpg";
import Image3 from "../../../assets/Images/saving-money-piggy-bank.jpg";
import MoneyPotsFormGroup from "../../../components/common/MoneyPotsFormGroup";
import "../slider/slider.scss";

document.documentElement.style.setProperty("--animate-duration", "2s");
export default function HomePageSlider() {
  const { t } = useTranslation();
  const items = [
    {
      name: t("homePageSlider.0.name"),
      description: t("homePageSlider.0.description"),
      imgPath: Image1,
      footerText: t("homePageSlider.0.footerText"),
    },
    {
      name: t("homePageSlider.1.name"),
      description: t("homePageSlider.1.description"),
      imgPath: Image2,
      footerText: t("homePageSlider.1.footerText"),
    },
    {
      name: t("homePageSlider.2.name"),
      description: t("homePageSlider.2.description"),
      imgPath: Image3,
      footerText: t("homePageSlider.2.footerText"),
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    nav: true,
    initialSlide: 0,
    slidesToScroll: 1,
    fade: true,
  };
  return (
    <>
      <Box
        id="HomePageSlider"
        component="section"
        className="container"
        sx={{
          padding: {
            xs: "40px 0px",
            md: "0px",
            position: "relative",
            overflow: "hidden",
            padding: "0px !important",
          },
        }}
      >
        <Box
          className="home-slider-box"
          sx={{ zIndex: "9", position: "relative" }}
        >
          <Slider {...settings} sx={{ position: "absolute" }}>
            {items.map((item) => (
              <Grid
                className="Home-slider-grid"
                sx={{
                  position: "relative",
                }}
              >
                {" "}
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    pt: 0,
                    position: "absolute",
                    top: "25%",
                    transform: "translate(-50%, 0%)",
                    left: "50%",
                    zIndex: "99",
                    textAlign: "center",
                    color: "#fff",
                    background: "#7d0cee85",
                    padding: { md: "40px", xs: "20px" },
                    width: { md: "auto", sm: "70%", xs: "95%" },
                  }}
                >
                  <Typography
                    vairant="h2"
                    className="HomePageSliderHeader"
                    sx={{
                      fontSize: { md: "25px", xs: "16px" },
                      display: "table",
                      width: "100%",
                      marginBottom: { md: "60px", xs: "30px" },
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    vairant="h6"
                    className="HomePageSliderParagragh"
                    sx={{
                      fontSize: { md: "16px", xs: "14px" },
                      paddingTop: { xs: "50px", md: "60px", lg: "30px" },
                      paddingBottom: "10px",
                    }}
                  >
                    {item.description}
                  </Typography>

                  <Typography
                    vairant="body"
                    className="HomePageSliderParagragh"
                    sx={{ fontSize: { md: "16px", xs: "14px" } }}
                  >
                    {item.footerText}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="slider-images">
                    <img
                      src={item.imgPath}
                      alt={item.name}
                      style={{ width: "100%", objectFit: "cover" }}
                    />
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Slider>
          <MoneyPotsFormGroup />
        </Box>
      </Box>
    </>
  );
}
