import * as React from "react";
import Header from "../../Header/index";
import Footer from "../../Footer/index";
import NewPassword from "./CreateNewPassword";
import { Box } from "@material-ui/core";
import "../../../../assets/scss/common.scss";
export default function Admin() {
  return (
    <>
      <Header />
      <Box className="min-height-div" sx={{ backgroundColor: "#592091" }}>
        <NewPassword />
      </Box>
      <Footer />
    </>
  );
}
