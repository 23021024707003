import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setListofMyTontine,
  setMyTontineLoader,
  setMyTontineSuccess,
  setMyTontineError,
  setSingleMyTontine,
  setTontineLink,
  setTontineInvites,
  setTontineDocStatus,
  setMyTontineCreateFunction,
  setMyTontineCreateSuccess,
  setUserDocuments,
  setNewMyTontine,
  setUserDocumentList,
  setListofSubscribedTontine,
  setTontineLoader,
  setListofAdminTontine,
  setAdminTontineLogList,
  setAdminTontineParticipateLogList,
  setAdminTontineDocumentLogList,
  setAdminTontineInviteLogList,
  setCycleOfTontine,
  setMyTontineLinkCheckloading,
  setTontineParticipantList,
  setMyCreateTontineLoader,
  setCurrentCycleDetails,
  setPausedTontineList,
  setTontinePayoutListing,
  setadminTontineCycleLogList,
  setadminTontineCycleUserLogList,
  setadminTontineSubscriptionLogList,
  setadminTontinePayoutLogList,
} from "../../reducer/Tontine/MyTontineSlice";
import axios from "axios";
import { DeleteData } from "../../reducer/User/TokenExpireSlice";
import { setErrorAlert, setSuccessAlert } from "../../reducer/AlertSlice";
import {
  setDetailsOfKittyLoader,
  setDetailsOfKittySuccess,
} from "../../reducer/KittySlice/KittyDetailsSlice";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Authorization"] = localStorage.getItem("UserInfo")
  ? `Token ${localStorage.getItem("UserInfo")}`
  : "";

export const listofUserTontine = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setMyTontineLoader(true));
    axios
      .get(
        `/api/v1/tontine/get-tontine-list/?limit=${_request.limit}&offset=${_request.offset}`
      )
      .then((response) => {
        dispatch(setMyTontineLoader(false));
        dispatch(setMyTontineSuccess(true));
        dispatch(setListofMyTontine(response.data));
      })
      .catch((err) => {
        dispatch(setMyTontineLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const SingleTontine = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setMyTontineLoader(true));
    axios
      .get(`api/v1/tontine/tontine/${_request}/`)
      .then((response) => {
        dispatch(setMyTontineLoader(false));
        dispatch(setMyTontineSuccess(true));
        dispatch(setSingleMyTontine(response.data));
      })
      .catch((err) => {
        dispatch(setMyTontineLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const CreateNewTontine = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setMyCreateTontineLoader(true));

    let form_data = new FormData();

    form_data.append("name", _request.name);
    form_data.append("amount", _request.amount);
    form_data.append("user", _request.user);
    form_data.append("frequency", _request.frequency);
    form_data.append("description", _request.description);
    form_data.append("participants", _request.participants);
    if (_request.start_day !== null) {
      form_data.append("start_day", _request.start_day);
    }
    if (_request.image) {
      form_data.append("image", _request.image || "");
    }
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    axios
      .post("api/v1/tontine/tontine/", form_data)
      .then((response) => {
        dispatch(setSuccessAlert("Tontine Created"));
        dispatch(setNewMyTontine(response.data));
        dispatch(setMyCreateTontineLoader(false));
        dispatch(setMyTontineCreateFunction(false));
        dispatch(setMyTontineSuccess(true));
        dispatch(setMyTontineCreateSuccess(true));
        dispatch(listofUserTontine({ limit: 10, offset: 0 }));
        _request.callback();
      })
      .catch((err) => {
        dispatch(setMyTontineCreateFunction(false));
        dispatch(setMyCreateTontineLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const deleteMyTontine = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    axios
      .delete(`api/v1/tontine/tontine/${_request}/`)
      .then((response) => {
        dispatch(setSuccessAlert("Tontine deleted successfully"));
        dispatch(setMyTontineSuccess(true));
        dispatch(listofUserTontine({ limit: 10, offset: 0 }));
      })
      .catch((err) => {
        dispatch(setMyTontineSuccess(false));
        dispatch(setErrorAlert(err.response.data.message));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const updateMyTontine = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    let form_data = new FormData();
    if (_request.name) {
      form_data.append("name", _request.name);
    }
    if (_request.amount) {
      form_data.append("amount", _request.amount);
    }
    if (_request.status) {
      form_data.append("status", _request.status);
    }
    if (_request.description) {
      form_data.append("description", _request.description);
    }
    if (_request.frequency) {
      form_data.append("frequency", _request.frequency);
    }
    if (_request.start_day) {
      form_data.append("start_day", _request.start_day);
    }
    if (_request.creation_date) {
      form_data.append(
        "creation_date",
        _request.creation_date.includes("T")
          ? _request.creation_date
          : `${_request.creation_date}`
      );
    }
    if (_request.image) {
      form_data.append("image", _request.image || "");
    }
    axios.defaults.headers["Content-Type"] = "multipart/form-data";

    axios
      .patch(`api/v1/tontine/tontine/${_request.id}/`, form_data)
      .then((response) => {
        dispatch(setSuccessAlert("Tontine updated successfully"));
        dispatch(setMyTontineSuccess(true));
        dispatch(listofUserTontine({ limit: 10, offset: 0 }));
        if (_request.callback) {
          _request.callback();
        }
        dispatch(SingleTontine(response.data.id));
      })
      .catch((err) => {
        dispatch(setErrorAlert("something wrong try again"));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const emailInviteTontine = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    axios
      .post(`api/v1/tontine/invite-tontine-email/`, _request.request)
      .then((response) => {
        dispatch(setDetailsOfKittySuccess(true));
        dispatch(setSuccessAlert("Invite Send"));
        if (_request.callback()) {
          _request.callBack();
        }
      })
      .catch((err) => {
        dispatch(setDetailsOfKittySuccess(false));
        dispatch(
          setErrorAlert("Something went wrong, try again after some time")
        );
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const participantTontine = createAsyncThunk(
  "participantTontine",
  async (_request, { dispatch }) => {
    const req = {
      tontine: _request.tontine,
      user: _request.user,
    };
    axios
      .post(`api/v1/tontine/tontine-participant/`, req)
      .then((response) => {
        dispatch(setDetailsOfKittySuccess(true));
        dispatch(setSuccessAlert("You are participant now"));
        if (_request.navigate) {
          _request?.navigate(`/tontine/${response.data.tontine.id}`);
        }
      })
      .catch((err) => {
        dispatch(setDetailsOfKittySuccess(false));
        dispatch(setErrorAlert(err.response.data.message));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
export const GetListParticipantTontine = createAsyncThunk(
  "GetListParticipantTontine",
  async (_request, { dispatch }) => {
    axios
      .get(
        `api/v1/tontine/tontine-participant/?tontine_id=${_request}&limit=1000&offset=0`
      )
      .then((response) => {
        dispatch(setTontineParticipantList(response?.data));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittySuccess(false));
        dispatch(setErrorAlert(err.response.data.message));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const participantReplyTontine = createAsyncThunk(
  "participantRejectTontine",
  async (_request, { dispatch }) => {
    let data = {
      is_verify: _request.is_verify,
      status: _request.status,
    };
    axios
      .patch(`api/v1/tontine/get-invite-on-tontine/${_request.id}/`, data)
      .then((response) => {
        dispatch(setDetailsOfKittySuccess(true));
        if (_request.navigate) {
          _request.navigate("/");
        }
      })
      .catch((err) => {
        dispatch(setDetailsOfKittySuccess(false));
        dispatch(setErrorAlert(err.response.data.message));
        if (_request.navigate) {
          _request?.navigate("/my-account");
        }
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const TontineLinkCheck = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setMyTontineLinkCheckloading(true));
    axios
      .post(`api/v1/tontine/check-tontine-link/`, _request)
      .then((response) => {
        dispatch(setMyTontineLinkCheckloading(false));
        dispatch(
          setTontineLink({
            link: response.data.is_link,
            id: response.data.details[0].id,
          })
        );
      })
      .catch((err) => {
        dispatch(setDetailsOfKittySuccess(false));
        dispatch(setMyTontineLinkCheckloading(false));
        dispatch(setErrorAlert(err.response.data.message));
        if (_request.navigate) {
          _request?.navigate("/my-account");
        }
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const ListOfTontineInvites = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    axios
      .get(
        `api/v1/tontine/get-invite-on-tontine/?tontine_id=${_request.id}&limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setTontineInvites(response.data));
        dispatch(listofUserTontine({ limit: 10, offset: 0 }));
      })
      .catch((err) => {
        dispatch(setErrorAlert("something wrong try again"));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const CheckUserDocumentStatus = createAsyncThunk(
  "CheckUserDocumentStatus",
  async (_request, { dispatch }) => {
    axios
      .get(`api/v1/tontine/check-user-document-verify/`)
      .then((response) => {
        dispatch(setTontineDocStatus(response.data));
        dispatch(listofUserTontine({ limit: 10, offset: 0 }));
      })
      .catch((err) => {
        dispatch(setMyTontineError(err?.response?.data?.message));
        dispatch(setTontineDocStatus({ is_verified: false }));
      });
  }
);

export const CheckUserDocumentVerifyStatus = createAsyncThunk(
  "CheckUserDocumentVerifyStatus",
  async (_request, { dispatch }) => {
    dispatch(setTontineLoader(true));
    axios
      .get(`api/v1/tontine/tontine-document/`)
      .then((response) => {
        dispatch(setTontineLoader(false));
        dispatch(setUserDocuments(response?.data?.results));
      })
      .catch((err) => {
        dispatch(setTontineLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getUserDocuments = createAsyncThunk(
  "getUserDocuments",
  async (_request, { dispatch }) => {
    axios
      .get(
        `api/v1/tontine/tontine-document-not-verify-list/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setUserDocumentList(response?.data));
      })
      .catch((err) => {
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const approveRejectDocument = createAsyncThunk(
  "approveRejectDocument",
  async (_request, { dispatch }) => {
    const { documentId, limit, offSet } = _request;
    axios
      .patch(`api/v1/tontine/tontine-document/${documentId}/`, _request.request)
      .then(() => {
        dispatch(setSuccessAlert("Status updated successfully"));
        dispatch(
          CheckUserDocumentVerifyStatus({
            limit,
            offSet,
          })
        );
        if (_request.callbackPayout()) {
          _request.callbackPayout();
        }
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setErrorAlert("something wrong try again"));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const listofSubscribedTontine = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setMyTontineLoader(true));

    // let offSet = "";
    // let search = "";
    // let category = "";
    // if (_request.offSet) {
    //   offSet = `&offset=${_request.offSet}`;
    // }

    // if (_request.search) {
    //   search = `&search=${_request.search}`;
    // }
    // if (_request.category) {
    //   category = `&category=${_request.category}`;
    // }
    axios
      .get(
        `api/v1/tontine/get-tontine-participated-by-login-user/?limit=${_request.limit}&offset=${_request.offset}`
      )
      .then((response) => {
        dispatch(setMyTontineLoader(false));
        dispatch(setMyTontineSuccess(true));
        // dispatch(setSuccess(true));
        dispatch(setListofSubscribedTontine(response.data));
      })
      .catch((err) => {
        dispatch(setMyTontineLoader(false));
        // dispatch(setSuccess(false));
        // dispatch(setError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofAdminTontine = createAsyncThunk(
  "getListofAdminTontine",
  async (_request, { dispatch }) => {
    dispatch(setMyTontineLoader(true));

    let search = "";
    if (_request.search) {
      search = `&search=${_request.search}`;
    }
    axios
      .get(
        `api/v1/tontine/get-all-tontine/?limit=${_request.limit}&offset=${_request.offSet}${search}`
      )
      .then((response) => {
        dispatch(setMyTontineLoader(false));
        dispatch(setMyTontineSuccess(true));

        dispatch(setListofAdminTontine(response.data));
        if (_request.callback()) {
          _request.callBack();
        }
      })
      .catch((err) => {
        dispatch(setMyTontineLoader(false));

        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const deleteAdminTontine = createAsyncThunk(
  "deleteAdminTontine",
  async (_request, { dispatch }) => {
    axios
      .delete(`api/v1/tontine/tontine/${_request}/`)
      .then((response) => {
        dispatch(setSuccessAlert("Tontine deleted successfully"));
        dispatch(getListofAdminTontine({ limit: 10, offSet: 0 }));
      })
      .catch((err) => {
        dispatch(setErrorAlert(err?.response?.data?.message));
        dispatch(setMyTontineSuccess(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const listofTontineLog = createAsyncThunk(
  "listofTontineLog",
  async (_request, { dispatch }) => {
    dispatch(setTontineLoader(true));

    axios
      .get(
        `api/v1/tontine/tontine-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminTontineLogList(response.data));
        dispatch(setTontineLoader(false));
      })
      .catch((err) => {
        dispatch(setTontineLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getlistofTontineParticipateLog = createAsyncThunk(
  "getlistofTontineParticipateLog",
  async (_request, { dispatch }) => {
    dispatch(setTontineLoader(true));
    axios
      .get(
        `api/v1/tontine/tontine-participate-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminTontineParticipateLogList(response.data));
        dispatch(setTontineLoader(false));
      })
      .catch((err) => {
        dispatch(setTontineLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getlistofTontineCycleLogs = createAsyncThunk(
  "getlistofTontineCycleLogs",
  async (_request, { dispatch }) => {
    dispatch(setTontineLoader(true));
    axios
      .get(
        `api/v1/tontine/tontine-cycle-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setadminTontineCycleLogList(response.data));
        dispatch(setTontineLoader(false));
      })
      .catch((err) => {
        dispatch(setTontineLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getlistofTontineDocumentLog = createAsyncThunk(
  "getlistofTontineDocumentLog",
  async (_request, { dispatch }) => {
    dispatch(setTontineLoader(true));

    axios
      .get(
        `api/v1/tontine/tontine-document-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminTontineDocumentLogList(response.data));
        dispatch(setTontineLoader(false));
      })
      .catch((err) => {
        dispatch(setTontineLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
export const getlistofTontineInviteLog = createAsyncThunk(
  "getlistofTontineInviteLog",
  async (_request, { dispatch }) => {
    dispatch(setTontineLoader(true));

    axios
      .get(
        `api/v1/tontine/tontine-invite-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setAdminTontineInviteLogList(response.data));
        dispatch(setTontineLoader(false));
      })
      .catch((err) => {
        dispatch(setTontineLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getTontineParticipants = createAsyncThunk(
  "getTontineParticipants",
  async (_request, { dispatch }) => {
    const { tontine_id } = _request;
    dispatch(setTontineLoader(true));
    axios
      .get(`api/v1/tontine/tontine-participants-list/?tontine_id=${tontine_id}`)
      .then((response) => {
        dispatch(setTontineLoader(false));
        _request.callBack(response?.data || []);
      })
      .catch((err) => {
        dispatch(setErrorAlert("something wrong try again"));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const updateTontineAssignee = createAsyncThunk(
  "updateTontineAssignee",
  async (_request, { dispatch }) => {
    const { tontine_id, swap_user, participant_id, limit, offSet } = _request;
    dispatch(setTontineLoader(true));
    axios
      .post(`api/v1/tontine/tontine-swap-users/`, {
        tontine_id,
        swap_user,
        participant_id,
      })
      .then((response) => {
        dispatch(setSuccessAlert("Assignee updated successfully"));
        dispatch(setTontineLoader(false));
        dispatch(getListofAdminTontine({ limit, offSet }));
      })
      .catch((err) => {
        dispatch(setErrorAlert("something wrong try again"));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const emailReminderToParticipants = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    axios
      .get(
        `api/v1/tontine/send-all-participants-mail/?tontine_id=${_request.id}`
      )
      .then((response) => {
        if (_request.callback) {
          _request.callback();
        }
        dispatch(setDetailsOfKittySuccess(true));
        dispatch(setSuccessAlert("Reminder Send"));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittySuccess(false));
        dispatch(
          setErrorAlert("Something went wrong, try again after some time")
        );
        _request.callBack();
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const tontineCycleDetails = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    axios
      .get(`api/v1/tontine/tontine-cycle-list/?tontine_id=${_request}`)
      .then((response) => {
        if (_request.callback) {
          _request.callback();
        }
        dispatch(setCycleOfTontine(response?.data));
      })
      .catch((err) => {
        dispatch(setDetailsOfKittySuccess(false));
        dispatch(
          setErrorAlert("Something went wrong, try again after some time")
        );
        _request.callBack();
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const SwapParticipantRankings = createAsyncThunk(
  "SwapParticipantRankings",
  async (_request, { dispatch }) => {
    dispatch(setTontineLoader(true));
    axios.defaults.headers["Content-Type"] = "application/json";
    axios
      .post(`api/v1/tontine/participant-rearrange/`, _request)
      .then((response) => {
        dispatch(GetListParticipantTontine(_request?.tontine_id));
        dispatch(setSuccessAlert(response?.data?.message));
      })
      .catch((err) => {
        dispatch(setErrorAlert("something wrong try again"));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const GetCurrentCycleDetails = createAsyncThunk(
  "GetCurrentCycleDetails",
  async (_request, { dispatch }) => {
    dispatch(setTontineLoader(true));
    axios.defaults.headers["Content-Type"] = "application/json";
    axios
      .get(`api/v1/tontine/get-current-cycle-details/?tontine_id=${_request}`)
      .then((response) => {
        dispatch(setCurrentCycleDetails(response?.data));
      })
      .catch((err) => {
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const GetPausedTontineDetails = createAsyncThunk(
  "GetPausedTontineDetails",
  async (_request, { dispatch }) => {
    dispatch(setTontineLoader(true));
    axios.defaults.headers["Content-Type"] = "application/json";
    axios
      .get(`api/v1/tontine/tontine-hold/`)
      .then((response) => {
        dispatch(setTontineLoader(false));
        dispatch(setPausedTontineList(response?.data));
      })
      .catch((err) => {
        dispatch(setTontineLoader(false));
        dispatch(setErrorAlert("something wrong try again"));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const ResumePausedTontine = createAsyncThunk(
  "ResumePausedTontine",
  async (_request, { dispatch }) => {
    axios.defaults.headers["Content-Type"] = "application/json";
    axios
      .patch(`/api/v1/tontine/tontine-hold/${_request}/`)
      .then((response) => {
        dispatch(GetPausedTontineDetails());
        dispatch(SingleTontine(_request));
      })
      .catch((err) => {
        dispatch(setErrorAlert(err.response.data.message));
        dispatch(setMyTontineSuccess(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getListofTontinePayoutList = createAsyncThunk(
  "getListofTontinePayoutList",
  async (_request, { dispatch }) => {
    dispatch(setTontineLoader(true));

    axios
      .get(
        `api/v1/tontine/tontine-payout/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setTontinePayoutListing(response.data));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setTontineLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const UpdateParticipantTontine = createAsyncThunk(
  "participantTontine",
  async (_request, { dispatch }) => {
    const req = {
      payout_status: _request.payout_status,
    };
    axios
      .patch(`api/v1/tontine/tontine-participant/${_request.id}/`, req)
      .then((response) => {
        dispatch(setDetailsOfKittySuccess(true));
        dispatch(
          getListofTontinePayoutList({
            limit: 10,
            offSet: 0,
          })
        );
      })
      .catch((err) => {
        dispatch(setDetailsOfKittySuccess(false));
        dispatch(setErrorAlert(err.response.data.message));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const UpdateTontinePayoutStatus = createAsyncThunk(
  "UpdateTontinePayoutStatus",
  async (_request, { dispatch }) => {
    dispatch(setTontineLoader(true));

    axios
      .patch(`api/v1/tontine/tontine-payout/${_request.id}/`, _request?.request)
      .then((response) => {
        dispatch(UpdateParticipantTontine(_request?.NextAPICall));
        dispatch(setDetailsOfKittyLoader(false));
      })
      .catch((err) => {
        dispatch(setTontineLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getTontineCycleUserLogList = createAsyncThunk(
  "getTontineCycleUserLogList",
  async (_request, { dispatch }) => {
    dispatch(setTontineLoader(true));
    axios
      .get(
        `api/v1/tontine/tontine-cycle-user-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setadminTontineCycleUserLogList(response.data));
        dispatch(setTontineLoader(false));
      })
      .catch((err) => {
        dispatch(setTontineLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getTontineSubscriptionLogList = createAsyncThunk(
  "getTontineSubscriptionLogList",
  async (_request, { dispatch }) => {
    dispatch(setTontineLoader(true));
    axios
      .get(
        `api/v1/tontine/tontine-subscription-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setadminTontineSubscriptionLogList(response.data));
        dispatch(setTontineLoader(false));
      })
      .catch((err) => {
        dispatch(setTontineLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getTontinePayoutLogList = createAsyncThunk(
  "getTontinePayoutLogList",
  async (_request, { dispatch }) => {
    dispatch(setTontineLoader(true));
    axios
      .get(
        `api/v1/tontine/tontine-payout-logs/?limit=${_request.limit}&offset=${_request.offSet}`
      )
      .then((response) => {
        dispatch(setadminTontinePayoutLogList(response.data));
        dispatch(setTontineLoader(false));
      })
      .catch((err) => {
        dispatch(setTontineLoader(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
