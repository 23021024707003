import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {} from "../../../store/thunk/Kitty/UpdateKittyListThunk";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../../store/store";
import {
  ResumePausedTontine,
  SingleTontine,
  updateMyTontine,
} from "../../../store/thunk/Tontine/MytontineThunk";

function Visibility(id) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tontineDetails = useAppSelector((state) => state.MyTontine);
  const [formError, setFormError] = React.useState([]);
  const [newdata, setNewData] = React.useState({});

  React.useEffect(() => {
    dispatch(SingleTontine(id.id));
  }, []);

  React.useEffect(() => {
    setNewData({
      name: tontineDetails?.singleTontine?.name,
      amount: tontineDetails?.singleTontine?.amount,
      id: tontineDetails?.singleTontine?.id,
      status: tontineDetails?.singleTontine?.status,
      frequency: tontineDetails?.singleTontine?.frequency,
      start_day: tontineDetails?.singleTontine?.start_day,
    });
  }, [tontineDetails]);
  const validateForm = (data) => {
    let error = [];

    if (data.name === "") {
      error.push("name");
    }
    setFormError(error);

    return error;
  };

  const updateData = (text, event) => {
    if (text === "amount") {
      // Regex pattern to match up to two decimal places
      const regex = /^(\d*\.?\d{0,2}).*$/;
      const newValue = event.target.value.replace(regex, "$1");

      setNewData({
        ...newdata,
        [text]: newValue,
      });
    } else {
      setNewData({
        ...newdata,
        [text]: event.target.value,
      });
    }
  };

  const handleSubmit = () => {
    let error = validateForm(newdata);
    if (error.length === 0) {
      // let req = {
      //   end_date : newSettingData.end_date,
      //   kitty_visibility : kitty_visibility,
      //   id : id,
      // }
      dispatch(updateMyTontine(newdata));
    }
  };

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "600 !important",
            textTransform: "uppercase !important",
            marginBottom: "10px !important",
            fontSize: "16px !important",
          }}
        >
          {t("tontineField.0")}:
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          size="small"
          id="name"
          value={newdata.name}
          onChange={(e) => {
            updateData("name", e);
          }}
          type="text"
          fullWidth
          variant="outlined"
          error={formError.includes("name")}
          helperText={
            formError.includes("name")
              ? t("kittySettingVisibilityValidation.0")
              : ""
          }
        />
      </Grid>
      {!tontineDetails?.singleTontine?.is_started ? (
        <>
          <Grid xs={12}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600 !important",
                textTransform: "uppercase !important",
                marginBottom: "10px !important",
                marginTop: "20px !important",
                fontSize: "16px !important",
              }}
            >
              {t("tontineField.1")}:
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              size="small"
              id="name"
              value={newdata.amount}
              onChange={(e) => {
                updateData("amount", e);
              }}
              type="text"
              fullWidth
              variant="outlined"
              // error={formError.includes("name")}
              // helperText={
              //   formError.includes("name")
              //     ? "Please enter name of kitty"
              //     : ""
              // }
            />
          </Grid>
          <Grid xs={12}>
            <FormControl margin="normal">
              <FormLabel
                id="demo-controlled-radio-buttons-group1"
                sx={{
                  fontWeight: "600 !important",
                  textTransform: "uppercase !important",
                  marginBottom: "10px !important",
                  fontSize: "16px !important",
                  color: "#000 !important",
                }}
              >
                {t("tontineField.2")}:
              </FormLabel>
              <RadioGroup
                name="controlled-radio-buttons-group1"
                value={tontineDetails?.status}
                sx={{ display: "inline-block" }}
                onChange={(e) => {
                  updateData("status", e);
                }}
              >
                <FormControlLabel
                  value="OPEN"
                  control={<Radio checked={newdata?.status === "OPEN"} />}
                  label={t("tontineField.5")}
                />
                <FormControlLabel
                  value="CLOSED"
                  control={<Radio checked={newdata?.status === "CLOSED"} />}
                  label={t("tontineField.6")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600 !important",
                textTransform: "uppercase !important",
                marginBottom: "10px !important",
                marginTop: "20px !important",
                fontSize: "16px !important",
              }}
            >
              {`${t("tontineFrequencyFieldLabel")}:`}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Box item sx={{ padding: "1px 0", minWidth: "250px" }}>
                <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    {t("tontineFrequencyFieldLabel")}
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={newdata.frequency ? newdata.frequency : ""}
                    label={t("tontineFrequencyFieldLabel")}
                    onChange={(e) => {
                      updateData("frequency", e);
                    }}
                  >
                    <MenuItem value={"WEEKLY"}>{t("WeeklyLable")}</MenuItem>
                    <MenuItem value={"MONTHLY"}>{t("MonthlyLable")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sx={{ textAlign: "left" }}>
            <Box item sx={{ padding: "1px 0", mt: 1, minWidth: "250px" }}>
              <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                <InputLabel id="demo-select-small-label">
                  {newdata.frequency === "WEEKLY" ||
                  newdata.frequency === "BIWEEKLY"
                    ? t("SelectDayDateLable.0")
                    : t("SelectDayDateLable.1")}
                </InputLabel>
                {newdata.frequency === "WEEKLY" ? (
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    error={formError.includes("start_day")}
                    value={newdata.start_day}
                    label={t("tontineFrequencyFieldLabel")}
                    onChange={(e) => {
                      updateData("start_day", e);
                    }}
                  >
                    <MenuItem value={"0"}>{t("WeekdaysLable.0")}</MenuItem>
                    <MenuItem value={"1"}>{t("WeekdaysLable.1")}</MenuItem>
                    <MenuItem value={"2"}>{t("WeekdaysLable.2")}</MenuItem>
                    <MenuItem value={"3"}>{t("WeekdaysLable.3")}</MenuItem>
                    <MenuItem value={"4"}>{t("WeekdaysLable.4")}</MenuItem>
                    <MenuItem value={"5"}>{t("WeekdaysLable.5")}</MenuItem>
                    <MenuItem value={"6"}>{t("WeekdaysLable.6")}</MenuItem>
                  </Select>
                ) : (
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    error={formError.includes("start_day")}
                    value={Number(newdata.start_day)}
                    label={t("tontineFrequencyFieldLabel")}
                    onChange={(e) => {
                      updateData("start_day", e);
                    }}
                  >
                    <MenuItem value={1}>01</MenuItem>
                    <MenuItem value={2}>02</MenuItem>
                    <MenuItem value={3}>03</MenuItem>
                    <MenuItem value={4}>04</MenuItem>
                    <MenuItem value={5}>05</MenuItem>

                    <MenuItem value={6}>06</MenuItem>
                    <MenuItem value={7}>07</MenuItem>
                    <MenuItem value={8}>08</MenuItem>
                    <MenuItem value={9}>09</MenuItem>
                    <MenuItem value={10}>10</MenuItem>

                    <MenuItem value={11}>11</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={13}>13</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={15}>15</MenuItem>

                    <MenuItem value={16}>16</MenuItem>
                    <MenuItem value={17}>17</MenuItem>
                    <MenuItem value={18}>18</MenuItem>
                    <MenuItem value={19}>19</MenuItem>
                    <MenuItem value={20}>20</MenuItem>

                    <MenuItem value={21}>21</MenuItem>
                    <MenuItem value={22}>22</MenuItem>
                    <MenuItem value={23}>23</MenuItem>
                    <MenuItem value={24}>24</MenuItem>
                    <MenuItem value={25}>25</MenuItem>

                    <MenuItem value={26}>26</MenuItem>
                    <MenuItem value={27}>27</MenuItem>
                    <MenuItem value={28}>28</MenuItem>
                  </Select>
                )}
                {formError.includes("start_day") && (
                  <FormHelperText id="component-error-text" error>
                    {"Please select start day"}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Grid>
        </>
      ) : null}
      {tontineDetails?.singleTontine?.status === "HOLD" ? (
        <Grid xs={12} sx={{ textAlign: "center" }}>
          <Typography
            variant="h6"
            sx={{ textAlign: "left", fontSize: "18px", fontWeight: "600" }}
          >
            Resume your tontine:
          </Typography>
          <Button
            onClick={() =>
              dispatch(ResumePausedTontine(tontineDetails?.singleTontine?.id))
            }
            sx={{
              background: "#1994e6",
              color: "#fff",
              display: "block",
              marginTop: "10px",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
              },
            }}
          >
            {t("ResumeTontineLable")}
          </Button>
        </Grid>
      ) : null}
      <Grid
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Button
          onClick={() => handleSubmit()}
          sx={{
            background: "#592091",
            color: "#fff",
            display: "block",
            marginTop: "10px",
            padding: "8px 20px",
            fontWeight: "600",
            letterSpacing: " 2px",
            border: "1px solid #592091",
            "&:hover": {
              color: "#592091",
            },
          }}
        >
          {t("submitLabel")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default Visibility;
