import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
};

const DeleteLoginInfo = createSlice({
  name: "newUserSlice",
  initialState,
  reducers: {
    DeleteData: () => {
      window.location.href = process.env.REACT_APP_TOKEN_EXPIRE_REDIRECT;
      localStorage.clear();
    },
  },
});

export const { DeleteData } = DeleteLoginInfo.actions;

export default DeleteLoginInfo.reducer;
