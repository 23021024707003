import * as React from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Tooltip } from "@mui/material";

export default function PDFViewerDialogComponent({
  dialogStatus,
  handleClose,
  pdfSrc,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={dialogStatus} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          sx={{
            textTransform: "uppercase",
            borderBottom: "1px solid #ddd",
            WebkitBackgroundClip: "text",
          }}
        >
          {t("Document")}
          <Tooltip title={t("closeLabel")}>
            <IconButton
              color="default"
              sx={{ float: "right" }}
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent
          sx={{
            height: "500px",
            maxHeight: "unset",
            overflow: "hidden",
            padding: 0,
          }}
        >
          <iframe
            src={pdfSrc}
            width="100%"
            height="400px"
            style={{ overflowX: "hidden" }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            p: { md: "20px", xs: "10px" },
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{
              fontSize: "12px",
              background: "#fff",
              color: "#592091",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: "2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={handleClose}
          >
            {t("closeLabel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
