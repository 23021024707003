import { Box, Container, Typography, Divider, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import TontineImage from "../../../../assets/Images/baby-reaches-for-her-sister.jpg";
import { Link } from "react-router-dom";

export default function TontineBeforeLoginPage() {
  const { t } = useTranslation();
  return (
    <Box sx={{ p: { md: "80px 0 50px", xs: "55px 0 50px" } }}>
      <Container
        sx={{
          mb: { md: "30px", xs: "10px" },
          mt: "10px",
          p: { md: "0px !important", xs: "10px !important" },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            className="top-subheading"
            variant="p"
            sx={{ display: "block", fontSize: "30px" }}
          >
            {t("tontineLabel")}
          </Typography>
          <Link to="/tontine-admin">
            <Button
              variant="contained"
              sx={{
                background: "#592091",
                border: "1px solid #592091",
                "&:hover": {
                  background: "#fff",
                  color: "#592091",
                },
              }}
            >
              {t("tontineTab2")}
            </Button>
          </Link>
        </Box>
      </Container>
      <Container
        sx={{ textAlign: "left", p: { md: 4, xs: 1 } }}
        className="howItWorkBlock"
      >
        <Box
          sx={{
            width: { md: "50%", xs: "100%" },
            float: { md: "left", xs: "unset" },
            paddingRight: { md: "20px", xs: "0" },
          }}
        >
          <img
            src={TontineImage}
            alt="TontineImage"
            style={{ width: "100%" }}
          />
        </Box>
        <Box sx={{ mt: { md: 0, xs: 2 } }}>
          <Typography
            variant="h5"
            className="tontine-title"
            sx={{ m: "0px 0px 20px 0 !important", p: "0 !important" }}
          >
            {t("howTontineWork.0")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body">
            {t("howTontineWork.1")}
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: "#592091", fontWeight: "600", mb: 1, mt: 2 }}
          >
            {t("howTontineWork.2")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body">
            {t("howTontineWork.3")}
          </Typography>
          <Divider
            sx={{
              borderBottom: "2px solid #e6e6e6",
              width: " 100%",
              m: "20px 0",
            }}
          />
          <Typography
            variant="h6"
            sx={{ color: "#592091", fontWeight: "600", mb: 1, mt: 1 }}
          >
            {t("howTontineWork.4")}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: "#592091", fontWeight: "600", mb: 1 }}
          >
            {t("howTontineWork.5")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body1">
            {t("howTontineWork.6")}
          </Typography>
          <Divider
            sx={{
              borderBottom: "2px solid #e6e6e6",
              width: " 100%",
              m: "20px 0",
            }}
          />
          <Typography
            variant="subtitle1"
            sx={{ color: "#592091", fontWeight: "600", mb: 1 }}
          >
            {t("howTontineWork.7")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body1">
            {t("howTontineWork.8")}
          </Typography>
          <Divider
            sx={{
              borderBottom: "2px solid #e6e6e6",
              width: " 100%",
              m: "20px 0",
            }}
          />
          <Typography
            variant="subtitle1"
            sx={{ color: "#592091", fontWeight: "600", mb: 1 }}
          >
            {t("howTontineWork.9")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body1">
            {t("howTontineWork.10")}
          </Typography>
          <Divider
            sx={{
              borderBottom: "2px solid #e6e6e6",
              width: " 100%",
              m: "20px 0",
            }}
          />
          <Typography
            variant="subtitle1"
            sx={{ color: "#592091", fontWeight: "600", mb: 1 }}
          >
            {t("howTontineWork.11")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body1">
            {t("howTontineWork.12")}
            <br />
            {t("howTontineWork.13")}
          </Typography>
          <Divider
            sx={{
              borderBottom: "2px solid #e6e6e6",
              width: " 100%",
              m: "20px 0",
            }}
          />
          <Typography
            variant="h6"
            sx={{ color: "#592091", fontWeight: "600", mb: 1 }}
          >
            {t("howTontineWork.14")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body1">
            {t("howTontineWork.15")}
            <br />
            {t("howTontineWork.16")}
            <br />
            {t("howTontineWork.17")}
            <br />
            {t("howTontineWork.18")}
          </Typography>
          <Divider
            sx={{
              borderBottom: "2px solid #e6e6e6",
              width: " 100%",
              m: "20px 0",
            }}
          />
          <Typography
            variant="h6"
            sx={{ color: "#592091", fontWeight: "600", mb: 1 }}
          >
            {t("howTontineWork.19")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body1">
            {t("howTontineWork.20")}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
