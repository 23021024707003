import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  success: "",
  like: {},
};

const likeofKittySlice = createSlice({
  name: "likeofKittySlice",
  initialState,
  reducers: {
    setLikeofKitty: (state, action) => ({
      ...state,
      like: action.payload,
    }),
    setLikeKittyLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setLikeKittySuccess: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    setLikeKittyError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
  },
});

export const {
    setLikeofKitty,
  setLikeKittyLoader,
  setLikeKittySuccess,
  setLikeKittyError,
} = likeofKittySlice.actions;

export default likeofKittySlice.reducer;
