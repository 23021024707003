import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  amount: 1000,
  amount_capturable: 0,
  success: false,
  client_data: {},
  contributeResponse: {},
  documentUploadResponse: {},
  ReciptLink: "",
  paymentError: "",
  paymentErrorState: false,
  KittyCommissionData: {},
  KittyBenificiaryDetails: {},
  TontineCommissionData: {},
};

const CheckOutSlice = createSlice({
  name: "CheckOutSlice",
  initialState,
  reducers: {
    setClientSecretData: (state, action) => ({
      ...state,
      client_data: action.payload,
    }),
    setUserLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setNewUserError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    setPaymentReciptLink: (state, action) => ({
      ...state,
      ReciptLink: action.payload,
    }),
    setPaymentError: (state, action) => ({
      ...state,
      paymentError: action.payload,
    }),
    setPaymentErrorState: (state, action) => ({
      ...state,
      paymentErrorState: action.payload,
    }),
    setKittyCommissionData: (state, action) => ({
      ...state,
      KittyCommissionData: action.payload,
    }),
    setKittyBenificiaryDetails: (state, action) => ({
      ...state,
      KittyBenificiaryDetails: action.payload,
    }),
    setCheckoutSuccess: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    setTontineCommissionData: (state, action) => ({
      ...state,
      TontineCommissionData: action.payload,
    }),
    setCheckoutLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
  },
});

export const {
  setNewUserData,
  setNewUserError,
  setContributeResponse,
  setDocumentUploadResponse,
  setUserLoading,
  setClientSecretData,
  setPaymentReciptLink,
  setPaymentError,
  setPaymentErrorState,
  setKittyCommissionData,
  setKittyBenificiaryDetails,
  setCheckoutSuccess,
  setTontineCommissionData,
  setCheckoutLoader,
} = CheckOutSlice.actions;

export default CheckOutSlice.reducer;
