import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { countries } from "../../../SignUp/Country";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  signUpNewUser,
  updateUserProfile,
} from "../../../../store/thunk/newUserThunk";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

function InsertEditUser(props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [userStatus, setUserStatus] = useState("");
  const userLoader = useAppSelector((state) => state.userSlice);

  useEffect(() => {
    if (props.data.email) {
      handleClickOpen();
    }

    if (props.data && props.data.id) {
      setNewFormValue({
        id: props.data?.id,
        email: props.data?.email,
        username: props.data?.username,
        first_name: props.data?.first_name,
        password: "UserByAdmin@123456789",
        name: props.data?.name,
        city: props.data?.city,
        birth_date: props.data?.birth_date || "2000-01-01",
        address: props.data?.address,
        postal_code: props.data?.postal_code,
        country: props.data?.country || "FR",
      });
    }

    setUserStatus(() => {
      return props.data?.is_superuser ? 2 : props.data?.is_guest ? 1 : 0;
    });
  }, [props.data]);

  const [newFormValue, setNewFormValue] = React.useState({
    email: "",
    username: "",
    first_name: "",
    password: "UserByAdmin@123456789",
    name: "",
    city: "",
    birth_date: "2000-01-01",
    address: "",
    postal_code: "",
    country: "FR",
    is_verified: true,
  });

  const clearData = {
    email: "",
    username: "",
    first_name: "",
    password: "UserByAdmin@123456789",
    name: "",
    city: "",
    birth_date: "2000-01-01",
    address: "",
    postal_code: "",
    country: "FR",
    is_verified: true,
  };

  const [formError, setFormError] = React.useState([]);

  const handleNewUserChange = (name, event) => {
    setNewFormValue({
      ...newFormValue,
      [name]: event.target.value,
    });
  };
  function handleDateChange(newValue) {
    setNewFormValue({
      ...newFormValue,
      birth_date: `${newValue?.$y}-${newValue?.$M + 1}-${newValue?.$D}`,
    });
  }

  function handleCountryChange(newValue) {
    setNewFormValue({
      ...newFormValue,
      country: newValue,
    });
  }
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.data.ResetData();
    setNewFormValue(clearData);
    setFormError([]);
  };

  var mailformat =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

  const validateForm = (data) => {
    let error = [];
    if (data.email === "" || !data.email) {
      error.push("email");
    }
    if (!data?.email?.match(mailformat) || !data.email) {
      error.push("email");
    }
    if (data.first_name === "" || !data.first_name) {
      error.push("first_name");
    }
    if (data.name === "" || !data.name) {
      error.push("name");
    }
    if (data.city === "" || !data.city) {
      error.push("city");
    }
    if (data.birth_date === null || data.birth_date.includes("undefined")) {
      error.push("birth_date");
    }
    if (data.address === "" || !data.address) {
      error.push("address");
    }
    if (
      !data.postal_code ||
      data.postal_code === "" ||
      data.postal_code.toString().split("").length < 5 ||
      data.postal_code.toString().split("").length > 8
    ) {
      error.push("postal_code");
    }
    if (data.country === null) {
      error.push("country");
    }

    setFormError(error);

    return error;
  };

  const handleSignUp = () => {
    let error = validateForm(newFormValue);
    if (error.length == 0) {
      let request = {
        ...newFormValue,
        username: newFormValue.email,
        is_active: true,
      };

      dispatch(
        signUpNewUser({
          request: request,
          callback: () => {
            setOpen(false);
            props.data.ResetData();
            setNewFormValue(clearData);
            setFormError([]);
            props.data = {};
          },
        })
      );
    }
  };

  const handleUpdateUser = () => {
    let error = validateForm(newFormValue);

    const userType = () => {
      return userStatus == 0
        ? { is_superuser: false, is_guest: false, is_staff: false }
        : userStatus == 1
        ? { is_superuser: false, is_guest: true, is_staff: false }
        : userStatus == 2
        ? { is_superuser: true, is_guest: false, is_staff: true }
        : {};
    };
    if (error.length == 0) {
      let temp = userType();
      let request = {
        ...newFormValue,
        ...temp,
      };
      props.data.ResetData();
      dispatch(
        updateUserProfile({
          request: request,
          user_id: newFormValue?.id,
          callback: () => {
            setOpen(false);
            props.data.ResetData();
            setNewFormValue(clearData);
            setFormError([]);
            props.data = {};
          },
        })
      );
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          mt: 1,
          border: "1px solid #592091",
          backgroundColor: "#592091",
          color: "#fff",
          fontWeight: "500",
          "&:hover": {
            background: "#fff",
            color: "#592091",
          },
        }}
      >
        {t("insertUserLabel")}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => props.data.ResetData({})}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            color: "#fff",
            marginBottom: "10px",
            background: "rebeccapurple",
            textTransform: "uppercase",
          }}
        >
          {"Create new user"}
        </DialogTitle>
        <DialogContent sx={{ p: "10px" }}>
          <DialogContentText sx={{ mb: 1 }}>
            Fill following details
          </DialogContentText>
          <Grid container xs={12}>
            <Grid item xs={6} sx={{ pl: 1, pr: 1 }}>
              <TextField
                size="small"
                margin="dense"
                id="name"
                label={t("createFirstNameLable")}
                type="firstname"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  handleNewUserChange("first_name", e);
                }}
                value={newFormValue.first_name}
                error={formError.includes("first_name")}
                helperText={
                  formError.includes("first_name")
                    ? t("createAccountFirstNameValidationLable")
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} sx={{ pl: 1, pr: 1 }}>
              <TextField
                size="small"
                margin="dense"
                id="name"
                label={t("createLastNameLable")}
                type="Name"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  handleNewUserChange("name", e);
                }}
                value={newFormValue.name}
                error={formError.includes("name")}
                helperText={
                  formError.includes("name")
                    ? t("createAccountLastNameValidationLable")
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={6} sx={{ pl: 1, pr: 1 }}>
              <Box sx={{ mb: 2, height: "40px" }}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  error={formError.includes("birth_date")}
                >
                  {/* <Stack fullWidth > */}
                  <DesktopDatePicker
                    label={t("createDOBLable")}
                    inputFormat={
                      localStorage.getItem("language") == "en"
                        ? "YYYY-MM-DD"
                        : "DD-MM-YYYY"
                    }
                    value={newFormValue.birth_date}
                    onChange={(newValue) => handleDateChange(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        margin="dense"
                      />
                    )}
                  />
                  {/* </Stack> */}
                  {formError.includes("birth_date") && (
                    <FormHelperText id="component-error-text" error>
                      {t("createAccountDOBValidationLable")}
                    </FormHelperText>
                  )}
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ pl: 1, pr: 1 }}>
              <TextField
                disabled={props.data?.id}
                size="small"
                margin="dense"
                id="email"
                label={t("createEmailLable")}
                type="email"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  handleNewUserChange("email", e);
                }}
                value={newFormValue.email}
                error={formError.includes("email")}
                helperText={
                  formError.includes("email")
                    ? t("createAccountEmailValidationLable")
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={6} sx={{ pl: 1, pr: 1 }}>
              <TextField
                size="small"
                margin="dense"
                id="address"
                label={t("createAddressLable")}
                type="text"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  handleNewUserChange("address", e);
                }}
                value={newFormValue.address}
                error={formError.includes("address")}
                helperText={
                  formError.includes("address")
                    ? t("createAccountAddressValidationLable")
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} sx={{ pl: 1, pr: 1 }}>
              <TextField
                size="small"
                margin="dense"
                id="Postal code"
                label={t("createPostalCodeLable")}
                type="Name"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  handleNewUserChange("postal_code", e);
                }}
                value={newFormValue.postal_code}
                error={formError.includes("postal_code")}
                helperText={
                  formError.includes("postal_code")
                    ? t("createAccountPostalValidationLable")
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={6} sx={{ pl: 1, pr: 1 }}>
              <TextField
                size="small"
                margin="dense"
                id="city"
                label={t("createCityLable")}
                type="text"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  handleNewUserChange("city", e);
                }}
                value={newFormValue.city}
                error={formError.includes("city")}
                helperText={
                  formError.includes("city")
                    ? t("createAccountCityValidationLable")
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} sx={{ pl: 1, pr: 1 }}>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <FormControl fullWidth variant="outlined" margin="dense">
                  <Autocomplete
                    id="country-select-demo"
                    options={countries}
                    error={formError.includes("country")}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="country-text"
                        size="small"
                        label={t("createCountryLable")}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "country", // disable autocomplete and autofill
                        }}
                      />
                    )}
                    // value={newUser.country}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, newValue) =>
                      handleCountryChange(newValue.code)
                    }
                    freeSolo
                    defaultValue={{
                      code: "FR",
                      label: "France",
                      phone: "33",
                      suggested: true,
                    }}
                  />
                  {formError.includes("country") && (
                    <FormHelperText id="component-error-text" error>
                      {t("createAccountCountryValidationLable")}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ pl: 1, pr: 1 }}>
              <Box>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    {t("userTypeLabel")}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={userStatus}
                    onChange={(e) => {
                      setUserStatus(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label={t("verifiedUserLabel")}
                    />
                    {props.data?.is_guest && (
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={t("guestUserLabel")}
                      />
                    )}
                    {!props.data?.is_guest && (
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={t("superUserLabel")}
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{
              fontSize: "12px",
              background: "#fff",
              color: "#592091",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={handleClose}
          >
            {t("closeLabel")}
          </Button>
          <LoadingButton
            loading={userLoader?.UserLoader}
            variant="outlined"
            sx={{
              fontSize: "12px",
              background: "#663399",
              color: "#fff",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#fff",
                background: "#663399",
              },
            }}
            onClick={() => {
              props.data?.email ? handleUpdateUser() : handleSignUp();
            }}
            autoFocus
          >
            {props.data?.email ? t("updateLable") : t("createButtonLbl")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InsertEditUser;
