import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import ResponsiveDrawer from "../../topbar";
import Settings from "../../../MyAccount/index";
export default function AdminUserManagementContent() {
  return (
    <Box sx={{ display: "flex"}}>
      <CssBaseline />
      <ResponsiveDrawer pageComponent={<Settings />} />
    </Box>
  );
}
