import * as React from "react";
import "./ForgetPassword.scss";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormHelperText, Grid, Typography } from "@mui/material";
import { forgetPassword } from "../../../../store/thunk/ForgetPasswordThunk";
import { useAppDispatch } from "../../../../store/store";

export default function SimplePaper() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = React.useState({ email: "" });
  const [formError, setFormError] = React.useState([]);
  const { t } = useTranslation();

  const handleChange = (event) => {
    setEmail({
      ...email,
      email: event.target.value,
    });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleRequest();
    }
  };

  var mailformat =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

  const validateForm = (data) => {
    let error = [];

    if (data.email === "") {
      error.push("email");
    }
    if (!data.email.match(mailformat)) {
      error.push("email");
    }
    setFormError(error);
    return error;
  };

  const handleRequest = () => {
    let error = validateForm(email);
    if (!error.includes("email")) {
      let request = {
        request: {
          email,
        },
        callback: () => {
          navigate("/");
        },
      };
      dispatch(forgetPassword(request));
    }
  };

  // const handleRequest = () => {

  //   dispatch(forgetPassword(email));
  //   navigate("/");
  // };

  return (
    <>
      <Box
        fullWidth
        sx={{
          background: "rgb(89, 32, 145)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            zIndex: 1,
            background: "#f1efeb",
            padding: 4,
            margin: 5,
            mt: "100px",
            width: "400px",
          }}
          className="ForgetPasswordForm"
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                align="left"
                width={"100%"}
                sx={{
                  textAlign: "center",
                  marginBottom: "20px",
                  fontWeight: "600",
                }}
              >
                {t("forgottenPassword")}
              </Typography>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", fontSize: "16px", m: "10px 0px" }}
              >
                <span style={{ color: "red" }}>{t("help")}!</span>{" "}
                {t("easyToAsk")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <FormControl
                  fullWidth
                  sx={{ m: "10px 0px" }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-email" required>
                    {t("emailAddress")}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email"
                    value={email.email}
                    error={formError.includes("email")}
                    onChange={(e) => handleChange(e)}
                    endAdornment={
                      <InputAdornment position="end"></InputAdornment>
                    }
                    label={t("emailAddress")}
                    onKeyDown={handleKeyDown}
                  />
                  {formError.includes("email") && (
                    <FormHelperText id="component-error-text" error>
                      {t("emailValidation")}
                    </FormHelperText>
                  )}
                </FormControl>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 1,
                    width: "100%",
                    height: "50px",
                    background: "#91bcff",
                  }}
                  onClick={handleRequest}
                >
                  {t("sendLinktoemail")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}
