import { Box, Button, FormHelperText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RichTextEditor from "react-rte";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useParams } from "react-router-dom";
import { updateMyTontine } from "../../../store/thunk/Tontine/MytontineThunk";

function Update() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const tontineDetails = useAppSelector((state) => state.MyTontine);
  const [formError, setFormError] = React.useState([]);
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createValueFromString("", "html")
  );

  useEffect(() => {
    if (tontineDetails?.singleTontine?.description) {
      setEditorValue(
        RichTextEditor.createValueFromString(
          tontineDetails?.singleTontine?.description,
          "html"
        )
      );
    }
  }, []);
  /**
   * Handle editor's change.
   * @param {import('react-rte').EditorValue} value Value from the editor.
   */

  const validateForm = (data) => {
    let error = [];

    if (data.description === "") {
      error.push("description");
    }
    setFormError(error);

    return error;
  };

  function onChangeTextEditor(value) {
    setEditorValue(value);
  }

  const handleSave = () => {
    let req = {
      description: editorValue.toString("html"),
      id: id,
    };
    let error = validateForm(req);
    if (error.length === 0) {
      dispatch(updateMyTontine(req));
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <RichTextEditor
          onChange={onChangeTextEditor}
          value={editorValue}
          sx={{ width: "100%" }}
          placeholder={"description"}
          editorClassName="richtext-editor-input"
        />
        <FormHelperText
          sx={{
            marginBottom: "10px",
            marginLeft: "10px",
            color: "red",
          }}
        >
          {formError.includes("description") ? "Please enter description" : ""}
        </FormHelperText>
      </Box>
      <Box>
        <Button
          sx={{
            margin: "20px",
            backgroundColor: "#592091",
            borderRadius: "20px",
            color: "#fff",
            p: "8px 15px",
            fontSize: { md: "17px", xs: "10px" },
            "&:hover": {
              backgroundColor: "#a4badb",
              textDecoration: "none",
              color: "#000",
            },
          }}
          variant="contained"
          onClick={handleSave}
        >
          {t("saveLabel")}
        </Button>
      </Box>
    </>
  );
}

export default Update;
