import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  newKittySuccess: false,
  newKitty:{},
  newKittyTempData:{},
  kittyTempData:{}
};
const NewKittySlice = createSlice({
  name: 'newKittySlice',
  initialState,
  reducers: {
    setNewKitty: (state, action) => ({
      ...state,
      newKitty: action.payload,
    }),
    setNewKittyTempData: (state, action) => ({
      ...state,
      newKittyTempData: action.payload,
    }),
    setKittyInsertLoader: (state, action) => ({
        ...state,
        loading: action.payload,
      }),
    setKittyInsertSuccess: (state, action) => ({
        ...state,
        success: action.payload,
      }),
    setNewKittyError: (state, action) => ({
        ...state,
        error: action.payload,
      }),
      setKittyTempData: (state, action) => ({
        ...state,
        kittyTempData: action.payload,
      }),
      
  }
});

export const { setNewKitty, setNewKittyError, setKittyInsertSuccess, setKittyTempData, setNewKittyTempData, setKittyInsertLoader } = NewKittySlice.actions;

export default NewKittySlice.reducer;