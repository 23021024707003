import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  success: "",
  KittyCatID: "",
};

const deleteKittyCatSlice = createSlice({
  name: "deleteKittyCatSlice",
  initialState,
  reducers: {
    setDeleteKittyCat: (state, action) => ({
      ...state,
      KittyID: action.payload,
    }),
    setKittyCatDeleteLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setSuccess: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    setKittyCatDeleteError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
  },
});

export const {
  setDeleteKittyCat,
  setKittyCatDeleteLoader,
  setSuccess,
  setKittyCatDeleteError,
} = deleteKittyCatSlice.actions;

export default deleteKittyCatSlice.reducer;
