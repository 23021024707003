import React from "react";
import CycleList from "../../../layout/tontinePage/tontineLanding/ContributionPage/CycleDetails";
function ListOfTontineCycle() {
  return (
    <>
      <CycleList />
    </>
  );
}

export default ListOfTontineCycle;
