import { Box, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import {
  DeleteMyCard,
  MakeMyCardDefault,
  MyCardDetails,
} from "../../../../../store/thunk/Payments/CheckOutThunk";
import DeleteDialogBox from "./DeleteCard";
import { useTranslation } from "react-i18next";

function ListOfMyCards() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const ListOfCards = useAppSelector(
    (state) => state?.UserProfile?.MyCardDetails
  );
  const [deleteCardID, setDeleteCardID] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const deleteUpdateKitty = () => {
    dispatch(
      DeleteMyCard({
        customer: profileDetails?.stripe_customer_id,
        card: deleteCardID?.id,
      })
    );
  };
  const profileDetails = useAppSelector((state) => state?.UserProfile?.details);

  useEffect(() => {
    dispatch(MyCardDetails(profileDetails?.stripe_customer_id));
  }, [profileDetails]);

  return (
    <>
      {ListOfCards?.data?.length > 0 ? (
        <Box>
          {ListOfCards?.data?.map((item) => {
            return (
              <>
                <Box>
                  <Paper>
                    <Grid
                      className="list-of-my-details-box"
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: { xs: "column", md: "row", sm: "row" },
                      }}
                      container
                    >
                      <Grid
                        xs={12}
                        md={5}
                        className="list-of-my-details-txt-first"
                        sx={{
                          p: 2,
                        }}
                      >
                        <Typography variant="p" className="default-text">
                          {`${t("CardNoLable")} : XXXX XXXX XXXX ${
                            item?.last4
                          }`}
                        </Typography>
                      </Grid>
                      <Grid xs={12} md={3}>
                        <Typography variant="p" className="default-text-year">
                          {`${t("ExpDateLable")} :${item?.exp_month}/${
                            item?.exp_year
                          }`}
                        </Typography>
                      </Grid>
                      <Grid xs={12} md={4}>
                        <Box className="list-of-my-details-txt-second">
                          <Button className="btn-default-card">
                            {ListOfCards?.default_card === item?.id ? (
                              <Chip
                                label={t("DefaultLable")}
                                variant="outlined"
                              />
                            ) : null}
                          </Button>
                          {ListOfCards?.data?.length < 2 ||
                          ListOfCards?.default_card === item?.id ? null : (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: {
                                  xs: "column",
                                  md: "row",
                                  sm: "row",
                                },
                              }}
                            >
                              <Button
                                className="btn-list-of-my-details-card"
                                onClick={() => {
                                  setDeleteCardID(item);
                                  handleClickOpen();
                                }}
                                sx={{
                                  border: "1px solid blue",
                                  borderRadius: "20px",
                                  m: 1,
                                  "&:hover": {
                                    backgroundColor: "#4b2373",
                                    color: "#fff",
                                  },
                                }}
                              >
                                {t("deleteLable")}
                              </Button>
                              <Button
                                className="btn-list-of-my-details-card"
                                onClick={() => {
                                  dispatch(
                                    MakeMyCardDefault({
                                      customer:
                                        profileDetails?.stripe_customer_id,
                                      card: item?.id,
                                    })
                                  );
                                }}
                                sx={{
                                  border: "1px solid blue",
                                  borderRadius: "20px",
                                  m: 1,
                                  "&:hover": {
                                    backgroundColor: "#4b2373",
                                    color: "#fff",
                                  },
                                }}
                              >
                                {t("MakeDefaultLable")}
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </>
            );
          })}
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{t("NoCardLable")}</Typography>
          </Box>
        </>
      )}
      <DeleteDialogBox
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        deleteUpdateKitty={deleteUpdateKitty}
        open={open}
        data={deleteCardID}
      />
    </>
  );
}

export default ListOfMyCards;
