import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import "../../../../assets/scss/common.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { createTheme } from "react-data-table-component";
import { Button, Box, Badge, Avatar, Chip } from "@mui/material";
import DataTable from "../../../../components/common/DataTableBase";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  DeleteUser,
  ListOfAllUsers,
} from "../../../../store/thunk/Admin/UserListThunk";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteDialogBox from "../../../Kitty/KittyDeleteDialog";
import ListDropDown from "./SearchFilterAdminUserManagement";
import InsertEditUser from "./InsertEditUser";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CheckIcon from "@mui/icons-material/Check";
import DocumentViewer from "./DocumentViewer";
import CustomMPagination from "../../../common/CustomMPagination";

function AdminTontineComponent() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = React.useState(0);
  const [pageCountShow, SetPageCountShow] = useState(0);
  const [recordLimit, SetRecordLimit] = useState(10);
  const [filter, setFilter] = React.useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [firstRender, setFirstRender] = useState(true);

  const UserList = useAppSelector((state) => state.UserProfile);

  React.useEffect(() => {
    setFirstRender(false);
    dispatch(
      ListOfAllUsers({
        limit: recordLimit,
        offSet: pageCount,
        search: [searchTerm],
        user_type: filter,
      })
    );
  }, [recordLimit, pageCount]);

  React.useEffect(() => {
    if (!firstRender) {
      if (pageCount > 0) {
        SetPageCount(0);
      } else {
        dispatch(
          ListOfAllUsers({
            limit: recordLimit,
            offSet: 0,
            search: [searchTerm],
            user_type: filter,
          })
        );
      }
    }
  }, [searchTerm, filter]);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openDocumentDialog, setOpenDocumentDialog] = React.useState(false);
  const [deleteId, setdeleteId] = React.useState("");
  const [UserDocument, setUserDocument] = React.useState({});

  const handleClickOpenDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleClickOpenDialogDocuments = () => {
    setOpenDocumentDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseDialogDocuments = () => {
    setOpenDocumentDialog(false);
  };

  const deleteKittyRequest = () => {
    dispatch(
      DeleteUser({
        id: deleteId,
        callback: () => {
          dispatch(ListOfAllUsers({ limit: recordLimit, offSet: pageCount }));
        },
      })
    );
  };

  const DocumentVeiwer = () => {};

  const LastPageCount = () => {
    return Math.round(
      (UserList?.ListofUsers?.count / Number(recordLimit) -
        Math.floor(UserList?.ListofUsers?.count / Number(recordLimit))) *
        Number(recordLimit)
    );
  };
  const columns = [
    {
      name: t("tontineTableColumn.0"),
      selector: useCallback(
        (row) =>
          row.is_guest ? (
            <Chip
              sx={{ borderRadius: 1 }}
              avatar={<Avatar alt="Natacha" src={row.avatar} />}
              label={t("guestUserLabel")}
              variant="outlined"
            />
          ) : row.is_superuser &&
            row?.documents_user_id_for_tontine[0]?.is_verified ? (
            <Badge
              sx={{
                ".MuiSvgIcon-root": {
                  fontSize: "0.75rem",
                  p: "unset",
                },
                ".css-1yv9cii-MuiBadge-badge": {
                  p: "unset",
                  backgroundColor: "#90EE90 !important",
                },
              }}
              color="secondary"
              badgeContent={<CheckIcon sx={{ strokeWidth: "40px" }} />}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
            >
              {" "}
              <img
                width={50}
                height={50}
                style={{ borderRadius: "100%", border: "solid 3px #ffd700" }}
                src={row.avatar}
                alt=""
              />
            </Badge>
          ) : row.is_superuser ? (
            <img
              width={50}
              height={50}
              style={{ borderRadius: "100%", border: "solid 3px #ffd700" }}
              src={row.avatar}
              alt=""
            />
          ) : row?.documents_user_id_for_tontine[0]?.is_verified ? (
            <Badge
              sx={{
                ".MuiSvgIcon-root": {
                  fontSize: "0.75rem",
                  p: "unset",
                },
                ".css-1yv9cii-MuiBadge-badge": {
                  p: "unset",
                  backgroundColor: "#90EE90 !important",
                },
              }}
              color="secondary"
              badgeContent={<CheckIcon sx={{ strokeWidth: "40px" }} />}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
            >
              <img
                width={50}
                height={50}
                style={{ borderRadius: "50%" }}
                src={row.avatar}
                alt=""
              />
            </Badge>
          ) : (
            <img
              width={50}
              height={50}
              style={{ borderRadius: "50%" }}
              src={row.avatar}
              alt=""
            />
          ),
        []
      ),
      width: "9rem",
    },
    {
      name: t("tontineNameFieldLabel"),
      selector: (row) =>
        `${row.first_name ? row.first_name : ""} ${row.name ? row.name : ""}`,
    },
    {
      name: t("myAccountDOBLable"),
      selector: (row) =>
        row.birth_date
          ? moment(row.birth_date).format(
              localStorage.getItem("language") == "en"
                ? "YYYY-MM-DD"
                : "DD-MM-YYYY"
            )
          : "",
    },
    {
      name: t("myAccountEmailLable"),
      selector: (row) => row.email,
      width: "16rem",
    },
    {
      name: t("cityLabel"),
      selector: (row) => row.city,
    },
    {
      name: t("myAccountCountryLable"),
      selector: (row) => row.country,
    },
    {
      name: t("userTypeLabel"),
      selector: (row) =>
        row.is_guest
          ? "Guest User"
          : row.is_superuser
          ? "Super User"
          : "Verified User",
    },
    {
      name: t("kittyCatListingTableLable.5"),
      width: "7rem",
      cell: useCallback(
        (row) => (
          <Box sx={{ display: "flex", p: "unset", m: "unset" }}>
            <Tooltip title={t("UserDocumentLable")}>
              <Button
                sx={{ minWidth: "unset" }}
                // disabled={!documentStatus?.docStatus?.is_verified}
                onClick={() => {
                  handleClickOpenDialogDocuments();
                  setUserDocument(row);
                }}
              >
                <InsertDriveFileOutlinedIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t("editLable")}>
              <Button
                sx={{ minWidth: "unset", p: "unset", m: "unset" }}
                // disabled={!documentStatus?.docStatus?.is_verified}
                onClick={() => {
                  // navigate(`/admin-user-settings-management/${row.id}`);
                  // dispatch(setUserTempDetails(row));
                  setUserDetails(row);
                }}
              >
                <ModeIcon sx={{ color: "#592091" }} />
              </Button>
            </Tooltip>
            <Tooltip title={t("deleteLable")}>
              <Button
                sx={{ minWidth: "unset", p: "unset", m: "unset" }}
                // disabled={!documentStatus?.docStatus?.is_verified}
                onClick={() => {
                  handleClickOpenDialog();
                  setdeleteId(row.id);
                }}
              >
                <DeleteIcon sx={{ color: "#ff0000" }} />
              </Button>
            </Tooltip>
          </Box>
        ),
        []
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, recordLimit]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "#ccc",
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "#fff ",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        textAlign: "center",
        fontSize: "17px",
        display: "grid",
        placeContent: "center",
        backgroundColor: "#2a2828",
        color: "#fff",
        maxWidth: "40px",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        padding: "8px",
        fontSize: "14px",
        display: "grid",
        placeContent: "center",
      },
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );

  return (
    <Box className="kittyTable">
      <Box p={2}>
        <DataTable
          title={
            <>
              <Box
                className="tableTitle"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                {t("tontineTableColumn.10")}
              </Box>
            </>
          }
          columns={columns}
          data={UserList?.ListofUsers?.results}
          customStyles={customStyles}
          theme="solarized"
          progressPending={UserList?.loading}
          actions={
            <Box
              sx={{
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
              }}
            >
              <ListDropDown
                data={{
                  recordLimit,
                  pageCount,
                  setFilter,
                  setSearchTerm,
                  SetPageCount,
                }}
              />
              <InsertEditUser
                data={{
                  ...userDetails,
                  ResetData: () => {
                    setUserDetails({});
                  },
                }}
              />
            </Box>
          }
          noDataComponent={<Box sx={{ p: 3 }}>{t("norecordLbl")}</Box>}
          className="rdt_TableHeader"
        />
      </Box>
      <CustomMPagination
        pageCount={pageCount}
        SetPageCount={SetPageCount}
        recordLimit={recordLimit}
        pageCountShow={pageCountShow}
        LastPageCount={LastPageCount}
        SetRecordLimit={SetRecordLimit}
        numRecords={UserList?.ListofUsers?.num_records}
        numCount={UserList?.ListofUsers?.count}
      />
      <DeleteDialogBox
        handleClickOpen={handleClickOpenDialog}
        handleClose={handleCloseDialog}
        deleteUpdateKitty={deleteKittyRequest}
        open={openDeleteDialog}
        data={UserList}
      />
      <DocumentViewer
        handleClickOpenDialogDocuments={handleClickOpenDialogDocuments}
        handleCloseDialogDocuments={handleCloseDialogDocuments}
        DocumentVeiwer={DocumentVeiwer}
        open={openDocumentDialog}
        data={UserDocument}
      />
    </Box>
  );
}

export default AdminTontineComponent;
