import React from "react";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import InnerServicePage from "./homePage/service/innerServicePage";
import InnerServiceMainBlock from "./homePage/service/innerServicePage/InnerServiceMainBlock";

function InnerServicePageComponent() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <Header />
            <InnerServiceMainBlock />
            <InnerServicePage />
            <Footer />
        </>
    );
}
export default InnerServicePageComponent;
