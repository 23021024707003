import * as React from "react";
import "../../../assets/scss/common.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Grid, Paper, useMediaQuery } from "@mui/material";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PeopleIcon from "@mui/icons-material/People";
import { useAppSelector } from "../../../store/store";
import { setNewKittyData } from "../../../store/reducer/KittySlice/CreateKittySlice";
import { useDispatch } from "react-redux";
import { setTempKittyData } from "../../../store/thunk/Kitty/InsertNewKittyThunk";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { md: "10px 24px", xs: 0 } }}>
          <Typography sx={{ display: "block" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const smallScreen = useMediaQuery("(max-width: 991px)");
  const kittyName = useAppSelector((state) => state.CreateKitty);
  const data = useAppSelector((state) => state.listofKittyCatSlice);
  const [active, setActive] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [kittyCatData, setKittyCatData] = React.useState({
    name: kittyName,
    category: "",
    category_name: "",
    user: 1,
  });

  React.useEffect(() => {
    if (kittyName.newKittyCreate.id) {
      let checkKittyCat = data?.list?.results
        ?.filter((data) => data.id == kittyName.newKittyCreate.id)
        .map((cat) => {
          return cat;
        });
      setValue(1);
      setActive(checkKittyCat[0]);
      dispatch(
        setNewKittyData({
          name: kittyCatData?.name?.newKittyCreate?.kittyName,
          category: kittyName.newKittyCreate.id,
          category_name: checkKittyCat[0]?.category_name,
        })
      );
      dispatch(
        setTempKittyData({
          name: kittyCatData?.name?.newKittyCreate?.kittyName,
          category: kittyName.newKittyCreate.id,
          category_name: checkKittyCat[0]?.category_name,
        })
      );
    }
  }, [data]);

  React.useEffect(() => {
    dispatch(setNewKittyData(kittyCatData));
    dispatch(setTempKittyData(kittyCatData));
  }, [kittyCatData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActive(null);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, display: "flex" }}>
        <Grid container className="slider-box kittycreation-vertical-slider">
          <Grid item md={4} xs={12} className="vertical-slider-tab">
            <Tabs
              orientation={smallScreen ? " horizonal" : "vertical"}
              variant="scrollable"
              value={value}
              scrollButtons="auto"
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
              }}
            >
              <Tab
                sx={{
                  width: { md: "100%", xs: "33%" },
                  p: { md: "12px 16px", xs: "0" },
                }}
                label={
                  <>
                    <Paper
                      sx={{
                        display: { md: "flex", xs: "block" },
                        alignItems: "center",
                        width: "100%",
                        padding: { md: "16px 20px", xs: "10px" },
                        background: "#eae7e1",
                        borderTop: " 0.5rem solid #eae7e1",
                        height: { md: "8.25rem", xs: "4.25rem" },
                        maxHeight: "8.25rem",
                      }}
                    >
                      <Box>
                        <CardGiftcardIcon
                          fontSize="inherit"
                          {...a11yProps(0)}
                          sx={{
                            fontSize: { md: "45px", xs: "30px" },
                            paddingRight: { md: " 20px", xs: "0" },
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          textAlign: { md: "left", xs: "center" },
                          color: "#555",
                        }}
                      >
                        <Box
                          sx={{
                            fontWeight: "600",
                            fontSize: { md: "20px", xs: "13px" },
                            mb: { md: "0", xs: "10px" },
                            textTransform: "capitalize",
                          }}
                        >
                          {t("categorySelectOptions.0.title")}
                        </Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "16px",
                            textTransform: "lowercase",
                            display: { md: "block", xs: "none" },
                          }}
                        >
                          {t("categorySelectOptions.0.subtitle")}
                        </Typography>
                      </Box>
                    </Paper>
                  </>
                }
              />
              <Tab
                sx={{
                  width: { md: "100%", xs: "33%" },
                  p: { md: "12px 16px", xs: "0" },
                }}
                label={
                  <>
                    <Paper
                      sx={{
                        display: { md: "flex", xs: "block" },
                        alignItems: "center",
                        width: "100%",
                        padding: { md: "16px 20px", xs: "10px" },
                        background: "#eae7e1",
                        borderTop: " 0.5rem solid #eae7e1",
                        height: { md: "8.25rem", xs: "4.25rem" },
                        maxHeight: "8.25rem",
                      }}
                    >
                      <Box>
                        <FavoriteBorderIcon
                          fontSize="inherit"
                          {...a11yProps(1)}
                          sx={{
                            fontSize: { md: "45px", xs: "30px" },
                            paddingRight: { md: " 20px", xs: "0" },
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          textAlign: { md: "left", xs: "center" },
                          color: "#555",
                        }}
                      >
                        <Box
                          sx={{
                            fontWeight: "600",
                            fontSize: { md: "20px", xs: "13px" },
                            mb: { md: "0", xs: "10px" },
                            textTransform: "capitalize",
                          }}
                        >
                          {t("categorySelectOptions.1.title")}
                        </Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "16px",
                            textTransform: "lowercase",
                            display: { md: "block", xs: "none" },
                          }}
                        >
                          {t("categorySelectOptions.1.subtitle")}
                        </Typography>
                      </Box>
                    </Paper>
                  </>
                }
              />
              <Tab
                sx={{
                  width: { md: "100%", xs: "33%" },
                  p: { md: "12px 16px", xs: "0" },
                }}
                label={
                  <>
                    <Paper
                      sx={{
                        display: { md: "flex", xs: "block" },
                        alignItems: "center",
                        width: "100%",
                        padding: { md: "16px 20px", xs: "10px" },
                        background: "#eae7e1",
                        borderTop: " 0.5rem solid #eae7e1",
                        height: { md: "8.25rem", xs: "4.25rem" },
                        maxHeight: "8.25rem",
                      }}
                    >
                      <Box>
                        <PeopleIcon
                          fontSize="inherit"
                          {...a11yProps(2)}
                          sx={{
                            fontSize: { md: "45px", xs: "30px" },
                            paddingRight: { md: " 20px", xs: "0" },
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          textAlign: { md: "left", xs: "center" },
                          color: "#555",
                        }}
                      >
                        <Box
                          sx={{
                            fontWeight: "600",
                            fontSize: { md: "20px", xs: "13px" },
                            mb: { md: "0", xs: "10px" },
                            textTransform: "capitalize",
                          }}
                        >
                          {t("categorySelectOptions.2.title")}
                        </Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "16px",
                            textTransform: "lowercase",
                            display: { md: "block", xs: "none" },
                          }}
                        >
                          {t("categorySelectOptions.2.title")}
                        </Typography>
                      </Box>
                    </Paper>
                  </>
                }
              />
            </Tabs>
          </Grid>
          <Grid item md={8}>
            <TabPanel value={value} index={0}>
              <Paper sx={{ p: { md: 2, xs: 1 } }}>
                <Grid container sx={{ alignItems: "center" }}>
                  {data?.list?.results
                    ?.filter((data) => data.category_type === "GROUPGIFTS")
                    .map((item) => (
                      <Grid
                        id="kitty-category-card"
                        className={`category-selection Home-slider-grid ${
                          active == item && "active"
                        }`}
                        md={3}
                        xs={6}
                        sx={{
                          position: "relative",
                          padding: "10px",
                        }}
                        onClick={() => {
                          setActive(item);
                          setKittyCatData({
                            ...kittyCatData,
                            category: item.id,
                            category_name: item.category_name,
                          });
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          // onClick={subPage}
                        >
                          <Box className="slider-images">
                            <LazyLoadImage
                              alt={item.category_name}
                              effect="blur"
                              width={"100%"}
                              src={item.image}
                            />
                            {/* <img
                              src={item.image}
                              loading="lazy"
                              alt={item.category_name}
                              style={{
                                width: "100%",
                                objectFit: "cover",
                              }}
                            /> */}
                            <Typography
                              variant="h6"
                              sx={{
                                fontSize: "13px",
                                p: "5px 3px",
                                textAlign: "center",
                                background: "#7d0cee",
                                color: "#fff",
                              }}
                            >
                              {" "}
                              {t('category.'+item.category_name).replace('category.','')}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Paper>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Paper sx={{ p: { md: 2, xs: 1 } }}>
                <Grid container>
                  {data?.list?.results
                    ?.filter((data) => data.category_type === "PROJECTCAUSES")
                    .map((item) => (
                      <Grid
                        className={`category-selection Home-slider-grid ${
                          active == item && "active"
                        }`}
                        md={3}
                        xs={6}
                        sx={{
                          position: "relative",
                          padding: "10px",
                        }}
                        onClick={() => {
                          setActive(item);
                          setKittyCatData({
                            ...kittyCatData,
                            category: item.id,
                            category_name: item.category_name,
                          });
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          // onClick={subPage}
                        >
                          <Box className="slider-images">
                            <LazyLoadImage
                              alt={item.category_name}
                              effect="blur"
                              width={"100%"}
                              src={item.image}
                            />

                            {/* <img
                              src={item.image}
                              loading="lazy"
                              alt={item.category_name}
                              style={{
                                width: "100%",
                                height: "155px",
                                objectFit: "cover",
                              }}
                            /> */}
                            <Typography
                              variant="h6"
                              sx={{
                                fontSize: "13px",
                                p: "5px 3px",
                                textAlign: "center",
                                background: "#7d0cee",
                                color: "#fff",
                              }}
                            >
                              {" "}
                              {t('category.'+item.category_name).replace('category.','')}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Paper>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Paper sx={{ p: { md: 2, xs: 1 } }}>
                <Grid container>
                  {data?.list?.results
                    ?.filter((data) => data.category_type === "COMMONEXPENSES")
                    .map((item) => (
                      <Grid
                        className={`category-selection Home-slider-grid ${
                          active == item && "active"
                        }`}
                        md={3}
                        xs={6}
                        sx={{
                          position: "relative",
                          padding: "10px",
                        }}
                        onClick={() => {
                          setActive(item);
                          setKittyCatData({
                            ...kittyCatData,
                            category: item.id,
                            category_name: item.category_name,
                          });
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          // onClick={subPage}
                        >
                          <Box className="slider-images">
                            <LazyLoadImage
                              alt={item.category_name}
                              effect="blur"
                              src={item.image}
                              width={"100%"}
                            />
                            {/* <img
                              src={item.image}
                              loading="lazy"
                              alt={item.category_name}
                              style={{
                                width: "100%",
                                height: "155px",
                                objectFit: "cover",
                              }}
                            /> */}
                            <Typography
                              variant="h6"
                              sx={{
                                fontSize: "13px",
                                p: "5px 3px",
                                textAlign: "center",
                                background: "#7d0cee",
                                color: "#fff",
                              }}
                            >
                              {" "}
                              {t('category.'+item.category_name).replace('category.','')}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Paper>
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
