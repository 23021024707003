import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { getListofAdminTontine } from "../../../../../store/thunk/Tontine/MytontineThunk";
import AdminUserTontineRow from "./AdminUserTontineRow";
import CustomMPagination from "../../../../common/CustomMPagination";

function AssignUserTontine() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = useState(0);
  const [pageCountShow, SetPageCountShow] = useState(0);
  const [recordLimit, SetRecordLimit] = useState(10);
  const adminTontineList = useAppSelector(
    (state) => state.MyTontine.adminTontineList
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    dispatch(getListofAdminTontine({ limit: recordLimit, offSet: pageCount }));
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, recordLimit]);

  return (
    <>
      <Box className="kittyTable">
        <Box sx={{ borderRadius: 0 }}>
          <Grid
            container
            sx={{ display: { md: "flex", xs: "block" }, textAlign: "left" }}
          >
            <Paper
              sx={{
                width: { md: "100%", xs: "auto" },
                padding: 2,
                margin: { md: "16px 16px 0px 16px", xs: "10px" },
                background: "rgba(89, 32, 145, 0.1411764706)",
              }}
            >
              <Box
                className="tableTitle"
                style={{
                  textTransform: "uppercase",
                }}
              >
                {t("tontineAssignUserLabel")}
              </Box>
            </Paper>
            {adminTontineList?.results.length
              ? adminTontineList?.results?.map((item) => (
                  <AdminUserTontineRow
                    details={item}
                    key={item.id}
                    recordLimit={recordLimit}
                    pageCount={pageCount}
                  />
                ))
              : null}
            {!adminTontineList?.results.length && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "80px",
                  color: "#525453cf",
                  backgroundColor: "#f1efeb",
                  margin: "16px",
                  borderRadius: "10px",
                }}
              >
                <Box>{t("norecordLbl")}</Box>
              </Box>
            )}
          </Grid>
          <CustomMPagination
            pageCount={pageCount}
            SetPageCount={SetPageCount}
            recordLimit={recordLimit}
            pageCountShow={pageCountShow}
            SetRecordLimit={SetRecordLimit}
            numRecords={adminTontineList?.num_records}
            numCount={adminTontineList?.count}
          />
        </Box>
      </Box>
    </>
  );
}

export default AssignUserTontine;
