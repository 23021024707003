import React from "react";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import TontinePage from "./tontinePage/tontineAdmin/TontineTab";
import { Box } from "@mui/material";

function TontineAdminComponent() {
  return (
    <>
      <Header />
      <Box className="min-height-div">
        <TontinePage />
      </Box>
      <Footer />
    </>
  );
}
export default TontineAdminComponent;
