import { Box, Container, Paper, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
// import OtherImage from "../../assets/Images/OtherOther.webp";
import VerticalTabs from "./VerticalTabs";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { setErrorAlert } from "../../store/reducer/AlertSlice";
import { DetailsOfKitty } from "../../store/thunk/Kitty/KittyDetailsThunk";

export default function Settings() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const list = useAppSelector((state) => state.DetailsOfKitty.list);
  const profileDetails = useAppSelector((state) => state?.UserProfile?.details);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(DetailsOfKitty(id));
  }, []);

  useEffect(() => {
    // Redirect to listing page is visited setting page other users
    if (
      Object.keys(profileDetails).length > 0 &&
      Object.keys(list).length > 0 &&
      !list?.hide_option
    ) {
      dispatch(setErrorAlert(t("KittySettingsValidation")));
      navigate("/admin");
    }
  }, [list]);

  return (
    <Box className="howItWorkBlock" sx={{ pt: 10, pb: 5 }}>
      <Container>
        <Typography variant="h5" className="tontine-title">
          {t("kittySettingsLabel")}
        </Typography>
        <Paper
          sx={{ p: "10px 10px", mb: 2, display: "flex", flexDirection: "rows" }}
        >
          <img
            src={list.image}
            loading="lazy"
            height="100px"
            width="100px"
            style={{ objectFit: "cover" }}
            alt={t("kittyFieldLable.0")}
          />
          <Box sx={{ m: "4px", display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "17px",
                fontWeight: " 600",
                padding: "10px",
              }}
            >
              {list.name}
            </Typography>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                color: "#868484",
                fontWeight: "600",
                justifyContent: "left",
              }}
            >
              {"< "}
              {t("back")}
            </Button>
          </Box>
        </Paper>
        <Paper>
          <VerticalTabs />
        </Paper>
      </Container>
    </Box>
  );
}
