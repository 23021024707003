import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from 'moment';
import PdfRecipt from "./pdfRecipt";

export default function CuntributeReceiptDialogs(props) {
  const { closeDialog, contributeInfoData } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [maxWidth, setMaxWidth] = React.useState("xl");
  const { t } = useTranslation();

  const downloadAsPDF = () => {
    const input = document.getElementById("content-to-download");

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4");
      const imgData = canvas.toDataURL("image/png");

      const imgWidth = 190; // Effective width considering left and right margins
      const pageHeight = 277; // Effective height considering top and bottom margins
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const leftMargin = 10; // Margin on the left side in mm
      const topMargin = 10; // Margin on the top side in mm

      pdf.addImage(
        imgData,
        "PNG",
        leftMargin,
        topMargin,
        imgWidth,
        imgHeight,
        "",
        ""
      );

      pdf.save(`Receipt_${moment().format('MM_DD_YYYY_HH_MM_SS')}.pdf`);
    });
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        open="true"
        sx={{ maxWidth: "unset !important", width: "100%" }}
        onClose={closeDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t("receiptLabel")}
        </DialogTitle>
        <DialogContent sx={{ overflowX: "hidden" }}>
          <PdfRecipt contributeInfoData={contributeInfoData} />
        </DialogContent>
        <DialogActions
          sx={{
            p: { md: "20px", xs: "10px" },
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={closeDialog}
            sx={{
              fontSize: "12px",
              background: "#fff",
              color: "#592091",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
          >
            {t("closeLabel")}
          </Button>
          <Button
            sx={{
              background: "#592091",
              color: "#fff",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={downloadAsPDF}
          >
            {t("downloadLabel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
