import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import MainListItems from "../dashboardItems";
import Nopicture from "../../../../assets/Images/no-picture.webp";
import { Link } from "react-router-dom";
import { Button, Avatar } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import UserDrawer from "../../../common/Header/UserDrawer";
import LockIcon from "@mui/icons-material/Lock";
import i18Next from "../../../../i18Next";
import { useAppSelector } from "../../../../store/store";
import Logo from "../../../../assets/Images/Logo-white.png";

const MainListItems = React.lazy(() => import("../dashboardItems"));

const drawerWidth = 240;
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "#f1efeb !important",
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 120,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));
function ResponsiveDrawer(props) {
  const userAuthData = useAppSelector((state) => state.userSlice);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [topMenuBarColor, setTopMenuBarColor] = React.useState("#592091");
  const profileDetails = useAppSelector((state) => state?.UserProfile?.details);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const [flag, setFlag] = React.useState(
    localStorage.getItem("language") === "en" ? "gb" : "fr"
  );
  const changeLanguageHandler = (language) => {
    if (language) localStorage.setItem("language", language);
    i18Next.changeLanguage(
      localStorage.getItem("language") ? localStorage.getItem("language") : "fr"
    );
    setFlag(localStorage.getItem("language") === "en" ? "gb" : "fr");
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    i18Next.changeLanguage(
      localStorage.getItem("language") ? localStorage.getItem("language") : "fr"
    );
  }, []);

  const drawer = (
    <div>
      <Box sx={{ textAlign: "center", p: 3 }}>
        <Avatar
          alt={profileDetails?.first_name}
          sx={{
            width: 95,
            height: 95,
            borderRadius: "50%",
            border: " 1px solid #ddd",
            margin: "0 auto 20px",
          }}
          src={profileDetails?.avatar ? profileDetails?.avatar : Nopicture}
        />
        <Typography
          variant="h6"
          sx={{
            fontSize: "18px",
            fontWeight: " 600",
            textTransform: "uppercase",
          }}
        >
          {`${profileDetails?.first_name}`}
        </Typography>
        <Typography variant="body" sx={{ fontSize: "15px" }}>
          {`${profileDetails?.email}`}
        </Typography>
      </Box>
      <Divider />
      <React.Suspense
        fallback={
          <div sx={{ minHeight: "540px", background: "#592091" }}></div>
        }
      >
        <List>
          <MainListItems />
        </List>
      </React.Suspense>
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        width: " 100%",
      }}
    >
      <CssBaseline />
      <AppBar
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#592091",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Link to="/">
            <img
              src={Logo}
              alt="LIKELEMBA"
              height={42}
              style={{ paddingTop: "2px" }}
            />
          </Link>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                height: "50px",
              }}
            >
              <Box sx={{ display: "inline", textAlign: "right" }}>
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                  endIcon={
                    <KeyboardArrowDownIcon
                      sx={{ color: "#f1efeb", marginLeft: 0 }}
                    />
                  }
                  sx={{
                    backgroundColor: topMenuBarColor,
                    padding: 0,
                    height: " 100%",
                    "&:hover": {
                      backgroundColor: topMenuBarColor,
                    },
                  }}
                >
                  <img
                    loading="lazy"
                    width="25"
                    height="15"
                    src={`https://flagcdn.com/w20/${flag}.png`}
                    srcSet={`https://flagcdn.com/w40/${flag}.png 2x`}
                    alt=""
                  />
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {flag === "gb" ? (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        changeLanguageHandler("fr");
                      }}
                      disableRipple
                    >
                      <img
                        loading="lazy"
                        width="20"
                        style={{ padding: "0 5px 0 0" }}
                        src={`https://flagcdn.com/w20/fr.png`}
                        srcSet={`https://flagcdn.com/w40/fr.png 2x`}
                        alt=""
                      />
                      française
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        changeLanguageHandler("en");
                      }}
                      disableRipple
                    >
                      <img
                        loading="lazy"
                        width="20"
                        style={{ padding: "0 5px 0 0" }}
                        src={`https://flagcdn.com/w20/gb.png`}
                        srcSet={`https://flagcdn.com/w40/gb.png 2x`}
                        alt=""
                      />
                      English
                    </MenuItem>
                  )}
                </StyledMenu>
              </Box>
              <Box sx={{ display: { md: "block", xs: "none" } }}>
                {userAuthData.token ? (
                  <UserDrawer />
                ) : (
                  <Link
                    className="NavRoutes"
                    to="/sign-in/"
                    style={{
                      display: "block",
                      height: "100%",
                      lineHeight: "45px",
                    }}
                  >
                    <Button>
                      <LockIcon
                        sx={{
                          color: "#ffffff",
                          fontSize: 16,
                        }}
                      />
                      <Typography
                        variant="h6"
                        className="button1"
                        sx={{ color: "#fff", fontSize: 14 }}
                      >
                        {t("login")}
                      </Typography>
                    </Button>
                  </Link>
                )}
              </Box>
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ ml: "5px", display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#592091",
              color: " #fff",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#592091",
              color: " #fff",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: "65px",
          overflow: "hidden",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {props.pageComponent}
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
