import * as React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getContributePaymentInfo } from "../../../store/thunk/newUserThunk";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import Logo from "../../../assets/Images/Logo-white.png";

export default function PdfRecipt(props) {
  const { t } = useTranslation();
  const { contributeInfoData } = props;
  const dispatch = useAppDispatch();

  const contributePaymentInfo = useAppSelector(
    (state) => state.newUserSlice?.contributePaymentResponse
  );

  React.useEffect(() => {
    dispatch(
      getContributePaymentInfo({
        request: {
          payment_intent_id: contributeInfoData?.payment_intent_id,
        },
      })
    );
  }, []);

  return (
    <div id="content-to-download" style={{ minWidth: "700px" }}>
      <div
        className="clearfix"
        style={{
          content: '""',
          display: "table",
          clear: "both",
          width: "100%",
          padding: "10px 0",
          marginBottom: "20px",
          borderBottom: "1px solid #AAAAAA",
        }}
      >
        <div id="logo" style={{ float: "left" }}>
          <img
            src={Logo}
            alt="Logo"
            style={{ height: " 40px", background: "#592091", padding: "10px" }}
          />
        </div>
        <div id="company" style={{ float: "right" }}>
          <h2
            className="name"
            style={{
              fontSize: "14px",
              fontWeight: "normal",
              marginBottom: "5px",
              marginTop: "0px",
            }}
          >
            <strong>{t("companyNameLabel")}:</strong> MAKAV
          </h2>
          <h2
            className="name"
            style={{
              fontSize: "14px",
              fontWeight: "normal",
              marginBottom: "5px",
              marginTop: "0px",
            }}
          >
            <strong>{t("headOfficeLabel")}:</strong> 05 Clos des vignes <br />
            37800 Noyant de Touraine
          </h2>
          <h2
            className="name"
            style={{
              fontSize: "14px",
              fontWeight: "normal",
              marginBottom: "5px",
              marginTop: "0px",
            }}
          >
            <strong>{t("websiteLabel")}:</strong>
            <a
              style={{ color: "#0087C3", textDecoration: "none" }}
              href="mk-likelemba.com"
            >
              {" "}
              mk-likelemba.com
            </a>
          </h2>
        </div>
      </div>
      <div>
        <div
          id="details"
          className="clearfix"
          style={{
            content: '""',
            display: "table",
            clear: "both",
            width: "100%",
            marginBottom: "50px",
          }}
        >
          <div
            id="client"
            style={{
              paddingLeft: "6px",
              borderLeft: "6px solid #0087C3",
              float: "left",
            }}
          >
            <div
              className="to"
              style={{ color: "#777777", marginBottom: "5px" }}
            >
              <strong>{t("invoiceLabel")} : </strong>
            </div>
            <h2
              className="name"
              style={{
                marginTop: "0px",
                fontSize: "14px",
                fontWeight: "normal",
                marginBottom: "5px",
                color: "#777777",
              }}
            >
              <strong>{t("contributerLabel")}: </strong>
              {contributeInfoData.is_anonymous
                ? `${t("afterContribution.6")}`
                : `${contributeInfoData?.user?.first_name} ${contributeInfoData?.user?.name}`}
            </h2>
            {contributeInfoData?.user?.email ? (
              <div className="email">
                <a
                  style={{ color: "#0087C3", textDecoration: "none" }}
                  href={`mailto:${contributeInfoData?.user?.email}`}
                >
                  {contributeInfoData?.user?.email}
                </a>
              </div>
            ) : null}
          </div>
          <div id="invoice" style={{ float: "right", textAlign: "right" }}>
            <div
              className="date"
              style={{
                fontSize: "1.1em",
                color: "#777777",
                margin: "0  0 5px 0",
              }}
            >
              <strong>{t("dateLabel")} : </strong>
              {contributePaymentInfo.data
                ? moment(contributePaymentInfo.data[0].created_at).format(
                    localStorage.getItem("language") == "en"
                      ? "YYYY-MM-DD"
                      : "DD-MM-YYYY"
                  )
                : ""}
            </div>
            <div className="time" style={{ color: "#777777" }}>
              <strong>{t("timeLabel")} : </strong>
              {contributePaymentInfo.data
                ? moment(contributePaymentInfo.data[0].created_at).format(
                    "HH:MM A"
                  )
                : ""}
            </div>
          </div>
        </div>
        <table
          border={0}
          cellSpacing={0}
          cellPadding={0}
          style={{
            width: "100%",
            borderCollapse: "collapse",
            borderSpacing: 0,
            marginBottom: "20px",
          }}
        >
          <thead>
            <tr>
              <th
                className="no"
                style={{
                  fontWeight: "bold",
                  color: "#FFFFFF",
                  fontSize: "1.6em",
                  background: "#592091",
                  padding: "20px",
                  textAlign: "left",
                  borderBottom: "1px solid #FFFFFF",
                }}
              >
                #
              </th>
              <th
                className="desc"
                style={{
                  fontSize: "1.2em",
                  fontWeight: "bold",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  padding: "20px",
                  background: "#EEEEEE",
                  borderBottom: "1px solid #FFFFFF",
                }}
              >
                {t("kittyNameLabel")}
              </th>
              <th
                className="qty"
                style={{
                  fontSize: "1.2em",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                  padding: "20px",
                  background: "#DDDDDD",
                  textAlign: "left",
                  borderBottom: "1px solid #FFFFFF",
                }}
              >
                {t("cardNumberLabel")}
              </th>
              <th
                className="total"
                style={{
                  fontSize: "1.2em",
                  background: "#592091",
                  color: "#FFFFFF",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                  padding: "20px",
                  textAlign: "left",
                  borderBottom: "1px solid #FFFFFF",
                }}
              >
                {t("totalPaidLabel")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className="no"
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.6em",
                  background: "#592091",
                  padding: "20px",
                  textAlign: "left",
                  borderBottom: "1px solid #FFFFFF",
                }}
              >
                1
              </td>
              <td className="desc" style={{ textAlign: "left" }}>
                <h3
                  style={{
                    fontWeight: "normal",
                    fontSize: "1.2em",
                    background: "#EEEEEE",
                    padding: "24px 20px",
                    margin: "0px",
                    textAlign: "left",
                    borderBottom: "1px solid #FFFFFF",
                  }}
                >
                  {contributeInfoData?.kitty?.name}
                </h3>
              </td>
              <td
                className="qty"
                style={{
                  fontSize: "1.2em",
                  padding: "20px",
                  background: "#DDDDDD",
                  textAlign: "left",
                  borderBottom: "1px solid #FFFFFF",
                }}
              >
                XXXXXXXXXXXX
                {contributePaymentInfo.data
                  ? contributePaymentInfo?.data[0]?.payment_method_details?.card
                      ?.last4
                  : "XXXX"}
              </td>
              <td
                className="total"
                style={{
                  background: "#592091",
                  color: "#FFFFFF",
                  padding: "20px",
                  textAlign: "left",
                  borderBottom: "1px solid #FFFFFF",
                }}
              >
                €
                {contributePaymentInfo.data
                  ? contributePaymentInfo?.data[0]?.amount / 100
                  : "0.00"}{" "}
              </td>
            </tr>
          </tbody>
          <tfoot
            style={{
              padding: "10px 20px",
              background: "#FFFFFF",
              borderBottom: "none",
              fontSize: "1.2em",
              whiteSpace: "nowrap",
              borderTop: "1px solid #AAAAAA",
            }}
          >
            <tr>
              <td
                colSpan={1}
                style={{
                  padding: "20px",
                  background: "#EEEEEE",
                  textAlign: "left",
                  borderBottom: "1px solid #FFFFFF",
                }}
              />
              <td
                colSpan={2}
                style={{
                  fontWeight: "bold",
                  padding: "20px",
                  background: "#EEEEEE",
                  textAlign: "left",
                  borderBottom: "1px solid #FFFFFF",
                }}
              >
                {t("subTotalLabel")}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  padding: "20px",
                  background: "#EEEEEE",
                  textAlign: "left",
                  borderBottom: "1px solid #FFFFFF",
                }}
              >
                €
                {contributePaymentInfo.data
                  ? contributePaymentInfo?.data[0]?.amount / 100
                  : "0.00"}{" "}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
