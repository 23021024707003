import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Input, TextField } from "@material-ui/core";
import { addContributeWithUserData } from "../../store/thunk/newUserThunk";
import { Button, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "./Payments.scss";
import { useTranslation } from "react-i18next";

export default function CheckoutForm(props) {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentState, setPaymentState] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [email, setEmail] = useState(props?.email?.email);
  const [amount, setamount] = useState(props?.email?.amount);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const checkOutData = useAppSelector((state) => state.CheckOut);
  const { t } = useTranslation();

  useEffect(() => {
    setClientSecret(checkOutData?.client_data?.client_secret);
  }, [checkOutData]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `http://${window?.location?.host}/checkout/success`,
      },
    });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
    setPaymentState(false);
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  const appearance = {
    theme: "flat",
  };

  return (
    <>
      {paymentState ? (
        <form id="payment-form" onSubmit={handleSubmit}>
          {/* <LinkAuthenticationElement
        id="link-authentication-element"
        value={emailId}
        onChange={(e) => setEmail(e.value.email)}
      /> */}
          <Typography
            variant="p"
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              mb: 1,
              display: "block",
            }}
          >
            {t("emailLabel")}
          </Typography>
          <Box
            sx={{
              maxWidth: { md: "354px", xs: "100%" },
              width: "100%",
              mb: 1,
              backgroundColor: "#f1f1f1",
            }}
          >
            <TextField
              id="outlined-basic"
              size="small"
              disabled
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.value.email)}
            />
          </Box>
          <Typography
            variant="p"
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              mb: 1,
              display: "block",
            }}
          >
            {t("kittyContributeAmountTitle")}
          </Typography>
          <Box
            sx={{
              maxWidth: { md: "354px", xs: "100%" },
              width: "100%",
              backgroundColor: "#f1f1f1",
              mb: 1,
            }}
          >
            <TextField
              id="outlined-basic"
              size="small"
              disabled
              fullWidth
              variant="outlined"
              value={`€ ${amount}`}
              onChange={(e) => setamount(e.value.amount)}
            />
          </Box>
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
          />
          <Box sx={{ display: "flex", placeContent: "center", mt: 2 }}>
            <button
              type="submit"
              disabled={isLoading || !stripe || !elements}
              id="submit"
            >
              <Button
                id="button-text"
                variant="outlined"
                onClick={() => props?.email?.handleClose()}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: { md: "20px", xs: "5px" },
                  padding: "7px 20px",
                  width: { md: "auto", xs: "100%" },
                  borderColor: "592091",
                  color: "592091",
                }}
              >
                {t("cancelLable")}
              </Button>
              <Button
                disabled={!stripe}
                id="button-text"
                sx={{
                  backgroundColor: "#592091",
                  borderRadius: { md: "20px", xs: "5px" },
                  padding: "7px 20px",
                  color: "white",
                  width: { md: "auto", xs: "100%" },
                  ml: 1,
                  "&:hover": {
                    backgroundColor: "#7d0cee",
                  },
                }}
              >
                {isLoading ? (
                  <CircularProgress sx={{ color: "#fff" }} />
                ) : (
                  <>{t("paymentPage.7")}</>
                )}
              </Button>
            </button>
          </Box>
        </form>
      ) : (
        <Box
          sx={{
            maxWidth: { md: "354px", xs: "100%" },
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box className="zoom-in-out-box">
            <CancelOutlinedIcon sx={{ fontSize: "140px", color: "#ff0000" }} />
          </Box>
          <Box>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              {message}
            </Typography>
          </Box>
          <Box>
            <Button
              onClick={() => {
                setPaymentState(true);
              }}
              sx={{
                m: 2,
                backgroundColor: "#592091",
                borderRadius: { md: "20px", xs: "5px" },
                padding: "7px 20px",
                color: "white",
                width: { md: "auto", xs: "100%" },
                "&:hover": {
                  backgroundColor: "#7d0cee",
                },
              }}
            >
              {t("paymentPage.1")}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
