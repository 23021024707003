import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function AboutUs() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        pt: "5rem",
        backgroundColor: "#f1efeb",
        width: "100%",
        pb: "16px !important",
      }}
    >
      <Container>
        <Divider>
          <Typography
            className="top-subheading"
            variant="h3"
            sx={{
              fontWeight: "700",
              fontSize: { md: "30px", xs: "25px" },
              m: "10px 0px",
            }}
          >
            {t("AboutUsPage.0")}
          </Typography>
        </Divider>
        <Paper>
          <Box sx={{ p: 4, backgroundColor: "#dac0f4" }}>
            <Box sx={{ m: "20px 0", textAlign: { md: "justify", xs: "left" } }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { md: "22px", xs: "16px" },
                  margin: "1rem",
                  fontWeight: "700",
                  ml: 0,
                  color: "#592091",
                }}
              >
                Rachide Mabila-Kipoupa
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { md: "22px", xs: "16px" },
                  margin: "1rem",
                  ml: 0,
                  color: "#592091",
                }}
              >
                {t("AboutUsPage.1")}
              </Typography>
            </Box>
            <Box sx={{ fontSize: { md: "16px", xs: "13px" } }}>
              {t("AboutUsPage.2")}
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { md: "22px", xs: "16px" },
                  margin: "1rem",
                  ml: 0,
                  color: "#592091",
                }}
              >
                {t("AboutUsPage.3")}
              </Typography>
            </Box>
            <Box sx={{ fontSize: { md: "16px", xs: "13px" } }}>
              {t("AboutUsPage.4")}
            </Box>
            <Box sx={{ fontSize: { md: "16px", xs: "13px" } }}>
              {t("AboutUsPage.5")}
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { md: "22px", xs: "16px" },
                  margin: "1rem",
                  ml: 0,
                  color: "#592091",
                }}
              >
                {t("AboutUsPage.6")}
              </Typography>
            </Box>
            <Box sx={{ fontSize: { md: "16px", xs: "13px" } }}>
              {t("AboutUsPage.7")}
            </Box>
            <Box sx={{ fontSize: { md: "16px", xs: "13px" } }}>
              {t("AboutUsPage.8")}
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { md: "22px", xs: "16px" },
                  margin: "1rem",
                  ml: 0,
                  color: "#592091",
                }}
              >
                {t("AboutUsPage.9")}
              </Typography>
            </Box>
            <Box sx={{ fontSize: { md: "16px", xs: "13px" } }}>
              {t("AboutUsPage.10")}
            </Box>
            <Box sx={{ fontSize: { md: "16px", xs: "13px" } }}>
              {t("AboutUsPage.11")}
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { md: "22px", xs: "16px" },
                  margin: "1rem",
                  ml: 0,
                  color: "#592091",
                }}
              >
                {t("AboutUsPage.12")}
              </Typography>
            </Box>
            <Box sx={{ fontSize: { md: "16px", xs: "13px" } }}>
              {t("AboutUsPage.13")}
            </Box>
            <Box sx={{ fontSize: { md: "16px", xs: "13px" } }}>
              {t("AboutUsPage.14")}
            </Box>
            <Box sx={{ fontSize: { md: "16px", xs: "13px" } }}>
              {t("AboutUsPage.15")}
            </Box>
            <Box sx={{ fontSize: { md: "16px", xs: "13px" } }}>
              {t("AboutUsPage.16")}
              <a
                href="https://www.makav-sd.com"
                style={{ marginLeft: "4px" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                www.makav-sd.com
              </a>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}

export default AboutUs;
