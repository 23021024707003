import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorAlert, setSuccessAlert } from "../reducer/AlertSlice";
import { setNewPassword, setNewEmailLink } from "../reducer/User/SliceUser";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";

export const forgetPassword = createAsyncThunk(
  "forgetPassword",
  async (_request, { dispatch }) => {
    axios
      .post("/api/v1/accounts/reset-password-request/", _request.request.email)
      .then((response) => {
        dispatch(setNewPassword(response));
        _request.callback();
        dispatch(
          setSuccessAlert(
            "Password change link has been forwarded to your email"
          )
        );
      })
      .catch((err) => {
        dispatch(setNewPassword(err));
        dispatch(setErrorAlert(err?.response?.data?.message));
        if (err?.response?.data?.email) {
          dispatch(setErrorAlert(err?.response?.data?.email[0]));
        }
      });
  }
);

export const resendEmailActivationLink = createAsyncThunk(
  "resendEmailActivationLink",
  async (_request, { dispatch }) => {
    axios
      .post("/api/v1/accounts/resend-user-activation/", {
        email: _request.email,
      })
      .then((response) => {
        dispatch(setNewEmailLink(response));
        dispatch(setSuccessAlert(response?.data?.message));
        _request.callback();
      })
      .catch((err) => {
        dispatch(setNewEmailLink(err));
        dispatch(setErrorAlert(err?.response?.data?.email[0]));
        _request.callback();
      });
  }
);
