import { Helmet } from "react-helmet";
import LowestFees from "./lowestFees";
import MoneyPots from "./moneyPots/moneyPots";
import Service from "./service";
import SimpleRates from "./simpleRates";
import HomePageSlider from "./slider/slider";
import { useEffect } from "react";

export default function HomePage() {
  <Helmet>
    <meta property="og:url" content={process.env.REACT_APP_REDIRECT_URL} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Likelemba" />
    <meta
      name="description"
      content="Use Kitty & Tontine services for contribute money"
    />
    <meta
      property="og:description"
      content="Use Kitty & Tontine services for contribute money"
    />
    <meta name="keywords" content="kitty, tontine, contribution" />
  </Helmet>;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HomePageSlider />
      <MoneyPots />
      <LowestFees />
      <SimpleRates />
      <Service />
    </>
  );
}
