import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { SingleTontine } from "../../../store/thunk/Tontine/MytontineThunk";
import { useParams } from "react-router-dom";
import { Box } from "@material-ui/core";

const initialItems = [
  { id: "item-1", ranking: "Item 1" },
  { id: "item-2", ranking: "Item 2" },
  { id: "item-3", ranking: "Item 3" },
  { id: "item-4", ranking: "Item 4" },
];

function ParticipantRearrange(props) {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [items, setItems] = React.useState(initialItems);
  const tontineDetails = useAppSelector(
    (state) => state.MyTontine?.TontineParticipantList
  );

  React.useEffect(() => {
    dispatch(SingleTontine(id));
  }, []);

  React.useEffect(() => {
    let temp = items.map((item, index) => ({
      id: item.id,
      ranking: index + 1,
    }));
    props.handleChange(temp);
  }, [items]);

  React.useEffect(() => {
    setItems(
      tontineDetails?.results?.length > 0
        ? tontineDetails?.results
        : initialItems
    );
  }, [tontineDetails]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const draggedItem = items[result.source.index];
    const remainingItems = [...items];
    remainingItems.splice(result.source.index, 1);
    remainingItems.splice(result.destination.index, 0, draggedItem);

    setItems(remainingItems);
  };

  return (
    <>
      <Box>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="items">
            {(provided) => (
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => (
                  <Draggable
                    key={item.ranking}
                    draggableId={item?.ranking.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          userSelect: "none",
                          margin: "0 0 8px 0",
                          minHeight: "50px",
                          border: "1px solid #ddd",
                          ...provided.draggableProps.style,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: "#f4f4f4",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "left",
                              alignItems: "left",
                              flexDirection: "row",
                              fontSize: { md: "24px", xs: "16px" },
                              fontWeight: "700",
                            }}
                          >
                            <Box
                              sx={{
                                width: { md: "60px", xs: "40px" },
                                aspectRatio: "1/1",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#592091",
                                color: "#fff",
                                m: "24px 74px 24px 24px",
                              }}
                            >
                              {index + 1}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  mr: 1,
                                }}
                              >
                                {`${item?.user?.first_name} ${item?.user?.name}`}
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              width: { md: "60px", xs: "40px" },
                              aspectRatio: "1/1",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#6bd636",
                              color: "#fff",
                              m: 3,
                              fontSize: "24px",
                              fontWeight: "700",
                            }}
                          >
                            {item.ranking}
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </>
  );
}

export default ParticipantRearrange;
