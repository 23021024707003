import * as React from "react";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import NoPicture from "../../../assets/Images/anonymous.PNG";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";
import ChangeName from "./ChangeName";
import EuroIcon from "@mui/icons-material/Euro";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  ContributeWithUserData,
  UpdateContributeWithUserData,
  updateContributeUser,
} from "../../../store/thunk/newUserThunk";
import moment from "moment";
import Message from "./Message";
import ContributionReceiptDialog from "./ContributionReceipt";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import HideSourceIcon from "@mui/icons-material/HideSource";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import { ReciptofPaymentIntent } from "../../../store/thunk/Payments/CheckOutThunk";

export default function AfterContributionPage() {
  const [showContibutionReceipt, setShowContibutionReceipt] =
    React.useState(false);
  const { id } = useParams();
  const { token } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const contributeInfo = useAppSelector(
    (state) => state.newUserSlice?.contributeResponse
  );
  const ReciptInfo = useAppSelector((state) => state.CheckOut);

  useEffect(() => {
    dispatch(ContributeWithUserData(id));
    localStorage.removeItem("guestUserToken");
    localStorage.removeItem("kittyContributeData");
  }, []);

  useEffect(() => {
    dispatch(
      ReciptofPaymentIntent({
        payment_intent_id: contributeInfo?.payment_intent_id,
      })
    );
  }, [contributeInfo]);

  const showContributeReceiptDialog = () => {
    setShowContibutionReceipt(true);
  };

  const hideContributeReceiptDialog = () => {
    setShowContibutionReceipt(false);
  };

  return (
    <Box sx={{ pt: 8 }}>
      <Container>
        <Grid container sx={{ pt: 1, pb: 1 }}>
          <Grid item md={12} xs={12}>
            <Box
              sx={{ display: "grid", placeContent: "center", width: "100%" }}
            >
              <Typography
                variant="h5"
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "600",
                  m: "10px 0px",
                }}
              >
                {t("afterContribution.0")}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <Paper sx={{ p: 2, m: { md: 2, xs: 0 } }}>
              <Grid>
                <Grid container>
                  <Grid item md={3} xs={12}>
                    <img
                      src={contributeInfo?.kitty?.image}
                      loading="lazy"
                      alt="Farewell"
                      style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "scale-down",
                      }}
                    />
                  </Grid>
                  <Grid item md={9} xs={12} sx={{ pt: { md: 0, xs: 1 } }}>
                    <Box sx={{ marginLeft: "10px" }}>
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: " 20px",
                          fontWeight: "600",
                          marginBottom: "8px",
                        }}
                      >
                        {t("afterContribution.1")}
                      </Typography>
                      <Typography variant="body">
                        {t("afterContribution.2")}{" "}
                        <strong> "{`${contributeInfo?.kitty?.name}`}" </strong>
                        {`${t("afterContribution.3")} "${
                          contributeInfo?.kitty?.user.first_name
                        } ${contributeInfo?.kitty?.user.name}" ${t(
                          "afterContribution.4"
                        )}`}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={8} xs={12}>
            <Paper sx={{ p: 2, m: { md: 2, xs: "16px 0px" } }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: " 16px",
                  fontWeight: "600",
                  marginBottom: "8px",
                }}
              >
                {t("afterContribution.5")}
              </Typography>
              <Grid container>
                <Grid item md={3} xs={4}>
                  <img
                    src={
                      contributeInfo.is_anonymous
                        ? NoPicture
                        : contributeInfo?.user?.avatar
                    }
                    alt="NoPicture"
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item md={9} xs={8} sx={{ p: 1 }}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: " 14px",
                      fontWeight: "600",
                      marginBottom: { md: "8px", xs: 0 },
                    }}
                  >
                    {contributeInfo.is_anonymous
                      ? `${t("afterContribution.6")}`
                      : `${contributeInfo?.user?.first_name} ${contributeInfo?.user?.name}`}
                  </Typography>
                  <Typography
                    variant="body"
                    sx={{
                      fontSize: " 13px",
                      marginBottom: { md: "8px", xs: 0 },
                    }}
                  >{`${t("afterContribution.7")} ${moment(
                    contributeInfo.created_at
                  ).format(
                    localStorage.getItem("language") == "en"
                      ? "YYYY-MM-DD"
                      : "DD-MM-YYYY"
                  )}`}</Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: " 13px",
                      marginBottom: "8px",
                      mt: "8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "600",
                        fontSize: "15px",
                        display: "flex",
                        alignItems: " center",
                        marginRight: "8px",
                      }}
                    >
                      <EuroIcon
                        sx={{
                          fontSize: "18px",
                        }}
                      />{" "}
                      {contributeInfo.amount}{" "}
                    </span>
                    {contributeInfo.is_hide
                      ? `(${t("afterContribution.8")})`
                      : null}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{ mb: 1, pr: { md: 1, xs: 0 }, mt: 1 }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        UpdateContributeWithUserData({
                          id: contributeInfo?.id,
                          data: { is_anonymous: !contributeInfo.is_anonymous },
                          token: token,
                        })
                      );
                    }}
                    sx={{
                      width: "100%",
                      background: "#d1d1d1",
                      color: "gray",
                      fontWeight: "600",
                      "&:hover": {
                        background: "#d1d1d1",
                      },
                    }}
                  >
                    {contributeInfo.is_anonymous ? (
                      `${t("afterContribution.16")}`
                    ) : (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <VoicemailIcon sx={{ mr: 1 }} />
                        {t("afterContribution.10")}
                      </Box>
                    )}
                  </Button>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{ mb: 1, pr: { md: 1, xs: 0 }, mt: 1 }}
                >
                  <ChangeName />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{ mb: 1, pr: { md: 1, xs: 0 }, mt: 1 }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        UpdateContributeWithUserData({
                          id: contributeInfo?.id,
                          data: { is_hide: !contributeInfo?.is_hide },
                          token: token,
                        })
                      );
                    }}
                    sx={{
                      width: "100%",
                      background: "#d1d1d1",
                      color: "gray",
                      fontWeight: "600",
                      "&:hover": {
                        background: "#d1d1d1",
                      },
                    }}
                  >
                    {contributeInfo?.is_hide ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <VisibilityIcon sx={{ mr: 1 }} />
                        {t("afterContribution.12")}
                      </Box>
                    ) : (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <HideSourceIcon sx={{ mr: 1 }} />
                        {t("afterContribution.13")}
                      </Box>
                    )}
                  </Button>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{ mb: 1, pr: { md: 1, xs: 0 }, mt: 1 }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      background: "#d1d1d1",
                      color: "gray",
                      fontWeight: "600",
                      "&:hover": {
                        background: "#d1d1d1",
                      },
                    }}
                    onClick={showContributeReceiptDialog}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <PrintIcon sx={{ mr: 1 }} />
                      {t("afterContribution.14")}
                    </Box>
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <Paper sx={{ p: { md: 2, xs: 1 }, m: { md: 2, xs: "16px 0px" } }}>
              <Typography
                variant="h4"
                sx={{
                  fontSize: " 16px",
                  fontWeight: "600",
                  marginBottom: "16px",
                  textAlign: "center",
                }}
              >
                {t("afterContribution.15")}
              </Typography>
              <Box
                sx={{
                  display: { md: "flex", xs: "block" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    m: { md: "0 5px", xs: "0px 0px 10px 0px" },
                    background: "#095ec9",
                    width: { md: "auto", xs: "100%" },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FacebookIcon sx={{ mr: 1 }} />
                    <FacebookShareButton
                      url={`${process.env.REACT_APP_REDIRECT_URL}/kittypage/${contributeInfo?.kitty?.id}`}
                      quote={"Kitty"}
                      hashtag={"#hashtag"}
                      description={"Contribute to kitty"}
                      className="Demo__some-network__share-button"
                      sx={{
                        background: "#00B2FF",
                        color: "#fff",
                        m: 2,
                        ml: 0,
                        "&:hover": {
                          background: "#00B2FF",
                          color: "#fff",
                          m: 2,
                          ml: 0,
                        },
                      }}
                    >
                      Facebook
                    </FacebookShareButton>
                  </Box>
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    m: { md: "0 5px", xs: "0px 0px 10px 0px" },
                    background: "#3e9826",
                    width: { md: "auto", xs: "100%" },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <WhatsAppIcon sx={{ mr: 1 }} />
                    <WhatsappShareButton
                      url={`${process.env.REACT_APP_REDIRECT_URL}/kittypage/${contributeInfo?.kitty?.id}`}
                      quote={"Kitty"}
                      hashtag={"#hashtag"}
                      description={"Contribute to kitty"}
                      className="Demo__some-network__share-button whatsapp-share-button"
                      sx={{
                        background: "#25D366",
                        color: "#fff",
                        m: 2,
                        ml: 0,
                        "&:hover": {
                          background: "#25D366",
                          color: "#fff",
                          m: 2,
                          ml: 0,
                        },
                      }}
                    >
                      {t("kittySettingInvite.1")}
                    </WhatsappShareButton>
                  </Box>
                </Button>
                <EmailShareButton
                  url={`${process.env.REACT_APP_REDIRECT_URL}/kittypage/${contributeInfo?.kitty?.id}`}
                  quote={"Kitty"}
                  hashtag={"#hashtag"}
                  description={"Contribute to kitty"}
                  className="Demo__some-network__share-button"
                  sx={{
                    background: "#00B2FF",
                    color: "#fff",
                    m: 2,
                    ml: 0,
                    "&:hover": {
                      background: "#00B2FF",
                      color: "#fff",
                      m: 2,
                      ml: 0,
                    },
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      m: { md: "0 5px", xs: "0px 0px 10px 0px" },
                      background: "#db951c",
                      width: { md: "auto", xs: "100%" },
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <EmailIcon sx={{ mr: 1 }} /> Email
                    </Box>
                  </Button>
                </EmailShareButton>
              </Box>
            </Paper>
            <Button
              variant="contained"
              sx={{ m: "0 auto 20px", display: "table", background: "#592091" }}
              onClick={() => {
                navigate(`/kittypage/${contributeInfo?.kitty?.id}`);
              }}
            >
              {t("back")}
            </Button>
          </Grid>
          <Grid item md={4} xs={12}>
            <Paper sx={{ p: 1, m: { md: 2, xs: "16px 0px" } }}>
              <Grid container>
                <Grid item md={12} xs={12} sx={{ p: 1 }}>
                  <Message />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      {showContibutionReceipt && (
        <ContributionReceiptDialog
          openDialog={showContributeReceiptDialog}
          closeDialog={hideContributeReceiptDialog}
          contributeInfoData={contributeInfo}
        />
      )}
    </Box>
  );
}
