import { Box, Container, Typography } from "@mui/material";
import SecurityImage from "../../../../assets/Images/Security.png";
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import AccordionComponent from "../../../../components/common/Accordion";
import { useTranslation } from "react-i18next";

export default function TransactionsSecurity() {
    const { t } = useTranslation();
    return (
        <Box sx={{ p: { md: "35px 0px 50px", xs: "50px 0px" } }}>
            <Container>
                <Box>
                    <Typography
                        className="top-subheading"
                        variant="h5"
                        sx={{ textAlign: "center", fontWeight: "600", mb: 2 }}
                    >
                        {t("servicePageHeading.0")}
                    </Typography>
                    <img
                        src={SecurityImage}
                        alt=""
                        style={{ margin: "0 auto", display: "table" }}
                    />
                </Box>
            </Container>
            <Container maxWidth="md">
                <Box>
                    <AccordionComponent
                        title=
                        {t("servicePageQuestion.0")}
                        content={t("servicePageAnswer.0")} />
                    <AccordionComponent
                        title=
                        {t("servicePageQuestion.1")}
                        content={t("servicePageAnswer.1")} />
                    <AccordionComponent
                        title=
                        {t("servicePageQuestion.2")}
                        content={t("servicePageAnswer.2")} />
                    {/* <AccordionComponent
                        title="The website LIKELEMBA.com is secure, all the normal site's path is encrypted in  https including the payment pages"
                        content="<p>When you visit the LIKELEMBA site, a closed padlock appears in the address bar, to the left of the site address. It may be accompanied by the prefix https:// depending on your browser. This proves that a valid security certificate is in place. With this, you can confirm that the connection to the website you are visiting is encrypted and, in some cases, who owns it.</p>
                <p>If the closed padlock or the domain LIKELEMBA.com does not appear at the beginning of the address bar, it is not the real LIKELEMBA site. A site without the https protocol is a site considered not secure.</p>
                "/>
                    <AccordionComponent
                        title="
            Report a security breach or incident"
                        content="<p>If, as a visitor to the LIKELEMBA.com site, and despite our efforts behind the scenes, you spontaneously detect a vulnerability or a security incident that has escaped all of our controls, we invite you to inform our teams, who will analyse your feedback and prioritise any necessary corrections according to the impact on the security of the service.</p>
                <p>You can send us any useful information to security-alert@LIKELEMBA.com.</p>
                <p>Please note that we do not currently have an active Bug Bounty programme.</p>
                <p>This paragraph is therefore not an authorisation or an incitement to actively search for vulnerabilities on the LIKELEMBA.com site or to attempt to break into our computer systems, as this work is usually entrusted to staff or service providers duly authorised to do so.</p>
                " />
                    <AccordionComponent
                        title="
            Transactions are authenticated using the 3D-secure protocol, if necessary"
                        content="This verification process is not automated. It is only triggered in specific cases, defined by our Anti-Fraud Team and your bank. If the 3D-Secure window does not pop up on your payment page, it means that your transaction has been deemed as secure."
                    />
                    <AccordionComponent
                        title="Transactions are verified by a dedicated team"
                        content="In order to ensure the authenticity of each transaction, we have implemented a fraud detection tool that allows us to fight effectively against unauthenticated transactions. We advise our users on good practices such as 3D-Secure, required by some banks."
                    /> */}
                </Box>
            </Container>
            <Box sx={{
                width: " 100%",
                color: "#e6e6e6", position: "relative",
                margin: "50px 0px"
            }}>
                <CatchingPokemonIcon className="divider" sx={{
                    margin: "0px",
                    position: "absolute",
                    left: "50%",
                    top: "-12px",
                    background: " white",
                    borderRadius: "50%",
                }} />
            </Box>
        </Box>
    );
}
