import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useAppDispatch } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { activateEmail } from "../../../store/thunk/userThunk";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, rgb(89 32 145) 0%, rgb(89 32 145 / 69%) 50%, rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, rgb(89 32 145) 0%, rgb(89 32 145 / 69%) 50%, rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(89 32 145) 0%, rgb(89 32 145 / 69%) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(89 32 145) 0%, rgb(89 32 145 / 69%) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <DoneOutlineIcon />,
    2: <LocalPostOfficeIcon />,
    3: <TagFacesIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

function VerifyEmail() {
  const [step, setStep] = useState(1);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const uid = urlParams.get("uid");
  const token = urlParams.get("token");

  const changeLanguageHandler = (language) => {
    i18next.changeLanguage(
      localStorage.getItem("language") ? localStorage.getItem("language") : "fr"
    );
  };

  useEffect(() => {
    changeLanguageHandler();
  }, []);

  const steps = [
    t("AccountActivationPage.0"),
    t("AccountActivationPage.1"),
    t("AccountActivationPage.2"),
  ];

  const handleActivation = () => {
    let request = {
      request: {
        uid,
        token,
      },
      callback: () => {
        navigate("/sign-in/");
      },
    };
    dispatch(activateEmail(request));
    setStep(2);
  };

  return (
    <Box
      sx={{
        display: "grid",
        placeContent: "center",
        minHeight: { md: "700px", xs: "unset" },
      }}
    >
      <Paper
        sx={{
          borderRadius: "10px",
          pt: { md: 5, xs: 2 },
          pb: { md: 5, xs: 2 },
          maxWidth: "600px",
          textAlign: "center",
          m: "100px 10px 50px",
          border: "1px solid #ddd",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            pb: { md: 5, xs: 2 },
            fontSize: { md: "24px", xs: "16px" },
          }}
        >
          {t("AccountActivationPage.3")}
        </Typography>
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={step}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label} sx={{ mb: { md: 5, xs: 2 } }}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            padding: { md: "10px 60px", xs: "10px 20px" },
          }}
        >
          {t("AccountActivationPage.4")}
        </Typography>
        <Button
          sx={{
            mt: 2,
            background: " #91bcff",
            color: " #000",
            padding: " 10px 20px",
            fontWeight: "600",
            border: " 2px solid #91bcff",
          }}
          onClick={handleActivation}
        >
          {t("AccountActivationPage.5")}
        </Button>
      </Paper>
    </Box>
  );
}

export default VerifyEmail;
