import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setNewKitty,
  setNewKittyError,
  setKittyInsertSuccess,
  setKittyTempData,
  setKittyInsertLoader,
  setNewKittyTempData,
} from "../../reducer/KittySlice/InsertNewKittySlice";
import axios from "axios";
import { listofUserKitty } from "./listofKittyThunk";
import {
  setSuccessAlert,
  setErrorAlert,
  setCommonLoader,
} from "../../reducer/AlertSlice";
import {
  TabIndexData,
  setNewKittyCreate,
} from "../../reducer/KittySlice/CreateKittySlice";
import { DetailsOfKitty } from "./KittyDetailsThunk";
import { DeleteData } from "../../reducer/User/TokenExpireSlice";
import { UserProfileDetails } from "../UserProfileThunk";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Authorization"] = localStorage.getItem("UserInfo")
  ? `Token ${localStorage.getItem("UserInfo")}`
  : "";
export const insertNewKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setCommonLoader(true));
    dispatch(setKittyInsertLoader(true));
    let form_data = new FormData();

    form_data.append("name", _request.name.newKittyCreate || _request.name);
    form_data.append("object_of_kitty", _request.object_of_kitty || "");
    form_data.append("amount", _request.amount || "");
    form_data.append("user", _request.user || _request.id);
    form_data.append("category", _request.category);

    if (_request.image) {
      form_data.append("image", _request.image);
    }

    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    if (_request.token)
      axios.defaults.headers["Authorization"] = `Token ${_request.token}`;
    dispatch(setKittyInsertSuccess(false));
    axios
      .post("api/v1/kitty/kitty/", form_data)
      .then((response) => {
        window.localStorage.removeItem("kittyData");
        window.localStorage.removeItem("UserId");
        dispatch(setKittyInsertSuccess(true));
        dispatch(setCommonLoader(false));
        dispatch(setKittyInsertLoader(false));
        dispatch(setNewKitty(response));
        dispatch(UserProfileDetails());
        dispatch(setSuccessAlert("Kitty Created"));
        dispatch(listofUserKitty({ limit: 10, offSet: 0 }));
        dispatch(DetailsOfKitty(response.data.id));
        dispatch(setNewKittyCreate(""));
        dispatch(setNewKittyTempData({}));
        dispatch(setKittyTempData({}));
        dispatch(TabIndexData(0));
        if (_request.callback) {
          _request.callback();
        }

        if (_request.navigate) {
          _request.navigate("/kittypage/" + response.data.id);
        }
      })
      .catch((err) => {
        dispatch(setKittyInsertSuccess(false));
        dispatch(setCommonLoader(false));
        dispatch(setKittyInsertLoader(false));
        dispatch(setErrorAlert(err?.response?.data?.message));
        dispatch(setNewKittyError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const setTempKittyData = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setKittyTempData(_request));
  }
);

export const insertFaceBookNewKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    dispatch(setCommonLoader(true));

    let form_data = new FormData();

    form_data.append(
      "name",
      _request.newKittyData.name.newKittyCreate || _request.name
    );
    form_data.append("object_of_kitty", _request.object_of_kitty || "");
    form_data.append("amount", _request.amount || "");
    form_data.append("user", _request.id);
    form_data.append(
      "category",
      _request.newKittyData.category || _request.category
    );

    if (_request.image) {
      form_data.append("image", _request.image);
    }

    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    if (_request.token)
      axios.defaults.headers["Authorization"] = `Token ${_request.token}`;
    dispatch(setKittyInsertSuccess(false));
    axios
      .post("api/v1/kitty/kitty/", form_data)
      .then((response) => {
        window.localStorage.removeItem("kittyData");
        dispatch(setKittyInsertSuccess(true));
        dispatch(setCommonLoader(false));
        dispatch(setNewKitty(response));
        dispatch(setSuccessAlert("Kitty Created"));
        dispatch(DetailsOfKitty(response.data.id));
        dispatch(TabIndexData(0));
        // dispatch(setNewKittyCreate(""));

        if (_request.callback) {
          _request.callback();
        }

        if (_request.navigate) {
          _request.navigate("/kittypage/" + response.data.id);
        }
      })
      .catch((err) => {
        dispatch(setCommonLoader(false));
        dispatch(setKittyInsertSuccess(false));
        dispatch(setErrorAlert(err?.response?.data?.message));
        dispatch(setNewKittyError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
