import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CategorySelect from "./CategorySelect";
import FileMoreDetails from "./FileMoreDetails";
// import LoginCheck from "./LoginCheck";
import { useAppSelector } from "../../../store/store";
import { useDispatch } from "react-redux";
import { UserProfileDetails } from "../../../store/thunk/UserProfileThunk";
import {
  TabIndexData,
  setNewKittyCreate,
  setNewKittyData,
  setTriggerFunction,
} from "../../../store/reducer/KittySlice/CreateKittySlice";
import { Container } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  insertNewKitty,
  setTempKittyData,
} from "../../../store/thunk/Kitty/InsertNewKittyThunk";
import { UserFacebookCode } from "../../../store/thunk/userThunk";
import { setUserData } from "../../../store/reducer/User/SliceUser";
import { useNavigate } from "react-router-dom";
import {
  setKittyTempData,
  setNewKittyTempData,
} from "../../../store/reducer/KittySlice/InsertNewKittySlice";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";

const LoginCheck = React.lazy(() => import("./LoginCheck"));

export default function KittyStepper() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlParams = new URL(window.location.href);
  const code = urlParams.searchParams.get("code");
  const [token, setToken] = React.useState(localStorage.getItem("UserInfo"));
  const [activeStep, setActiveStep] = React.useState(0);
  const [flag, setFlag] = React.useState(0);
  const [completed, setCompleted] = React.useState(false);
  const kittyName = useAppSelector((state) => state.CreateKitty);
  const userData = useAppSelector((state) => state.UserProfile);
  const KittyCreated = useAppSelector((state) => state.NewKittySlice);
  const session = useAppSelector((state) => state.userSlice);
  const NewUserEntry = useAppSelector((state) => state.UserProfile);
  const kittyTempData = useAppSelector((state) => state.NewKittySlice);

  const steps = [
    t("createKittyStepper1"),
    t("createKittyStepper2"),
    t("createKittyStepper3"),
  ];

  React.useEffect(() => {
    setToken(localStorage.getItem("UserInfo"));
    if (!kittyTempData.kittyTempData.category) setActiveStep(0);
    if (!kittyName.newKittyData.category) setActiveStep(0);
    if (kittyName.newKittyCreate) {
      localStorage.setItem("kittyData", JSON.stringify(kittyName));
    }
    // setActiveStep(KittyCreated.activeTabIndex)
    dispatch(UserProfileDetails());
    if (code) {
      dispatch(
        UserFacebookCode({
          code: code,
          t,
          callback: (token) => {
            dispatch(setUserData(token.token));
            let temp = JSON.parse(localStorage.getItem("kittyData"));
            dispatch(setTempKittyData(temp));
            window.close();
          },
          callback2: (token) => {
            dispatch(setUserData(token.token));
            let temp = JSON.parse(localStorage.getItem("kittyData"));
            dispatch(setTempKittyData(temp));
            setActiveStep(2);
            window.close();
          },
        })
      );
    }
    if (activeStep === 0) {
      dispatch(TabIndexData(1));
    }
    if (kittyName?.newKittyCreate === "") {
      dispatch(setNewKittyTempData({}));
      dispatch(TabIndexData(0));
      dispatch(setKittyTempData({}));
      dispatch(setNewKittyCreate({}));
      dispatch(setNewKittyData({ name: "", category: "", category_name: "" }));
      navigate("/");
    }
    if (
      kittyName?.newKittyCreate.id &&
      localStorage.getItem("UserInfo") &&
      userData.details.country
    ) {
      setActiveStep(activeStep + 2);
    }

    if (KittyCreated.success) setActiveStep(0);
  }, []);

  React.useEffect(() => {}, [kittyTempData]);

  React.useEffect(() => {
    if (
      NewUserEntry.success &&
      localStorage.getItem("UserId")?.id &&
      kittyTempData.kittyTempData.category !== "" &&
      kittyName.newKittyData.category
    ) {
      setActiveStep(2);
    }
    if (
      localStorage.getItem("UserId")?.country &&
      localStorage.getItem("UserId")?.id &&
      kittyTempData.kittyTempData.category !== ""
    ) {
      setActiveStep(2);
    }
  }, [NewUserEntry, token]);

  React.useEffect(() => {
    const path = window.location.pathname;

    const checkUser = setInterval(() => {
      if (kittyName?.newKittyCreate === "" || !path.includes("kittycreation")) {
        window.clearInterval(checkUser);
      }

      if (session.token && session.success) {
        dispatch(TabIndexData(2));
        window.clearInterval(checkUser);
      }

      let UserCheck = JSON.parse(localStorage.getItem("UserId"));
      if (
        UserCheck?.country &&
        UserCheck?.id &&
        kittyTempData.kittyTempData.category_name !== ""
      ) {
        setActiveStep(2);
        window.clearInterval(checkUser);
      }
      if (localStorage.getItem("UserInfo") && userData.details.country) {
        window.clearInterval(checkUser);
      }
    }, 1500);
  }, []);

  const totalSteps = () => {
    return steps.length;
  };
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    if (activeStep == 2 && localStorage.getItem("UserInfo")) {
      dispatch(setTriggerFunction(true));
    }
    if (activeStep === 2) {
      setCompleted(true);
    }
    isLastStep() && !allStepsCompleted()
      ? steps.findIndex((step, i) => !(i in completed))
      : kittyTempData.kittyTempData.category !== "" ||
        kittyTempData?.kittyTempData?.name?.newKittyCreate?.id
      ? activeStep === 1 && !localStorage.getItem("UserInfo")
        ? handleLog()
        : setActiveStep(activeStep + 1)
      : handleAlert();
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    dispatch(setTriggerFunction(true));
    const newCompleted = completed;
    dispatch(insertNewKitty(kittyTempData.newKittyTempData));
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };
  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const showToastMessage = () => {
    toast.error(t("KittyCategorySelectValidationLable"), {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showToastMessage2 = () => {
    toast.error(t("LoginFirstValidation"), {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  function handleAlert() {
    showToastMessage();
    setFlag(1);
    setActiveStep(0);
  }
  function handleLog() {
    showToastMessage2();
    setFlag(1);
    setActiveStep(1);
  }
  return (
    <Box sx={{ width: "100%", marginBottom: "50px" }}>
      {flag === 1 ? <ToastContainer autoClose={4000} /> : <></>}
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1, py: 1, width: "100%" }}>
            {activeStep === 0 && (
              <Box>
                <CategorySelect />
              </Box>
            )}
            {activeStep === 1 && (
              <Box>
                <React.Suspense
                  fallback={
                    <div
                      sx={{ minHeight: "540px", background: "#f1efeb" }}
                    ></div>
                  }
                >
                  <LoginCheck />
                </React.Suspense>
              </Box>
            )}
            {activeStep === 2 && (
              <Box>
                <FileMoreDetails />
              </Box>
            )}
          </Typography>
          <Box
            sx={{
              position: " fixed",
              bottom: " 0",
              background: "#592091",
              width: "100%",
              left: " 0",
              padding: "10px",
              marginTop: "20px",
              zIndex: "9",
              boxSizing: "border-box",
            }}
          >
            <Container
              sx={{
                display: "flex",
                flexDirection: "row",
                p: 0,
                justifyContent: "space-between",
              }}
            >
              {/* <Button
                variant="outlined"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{
                  mr: 1,
                  background: "#fff",
                  color: "#592091 !important",
                  "&:hover": {
                    background: "#fff",
                    color: "#592091 !important",
                  },
                }}
              >
                {t("backLabel")}
              </Button> */}
              <Box
                sx={{ flex: "1 1 auto", display: { md: "block", xs: "none" } }}
              />
              <Box>
                <Button
                  onClick={handleNext}
                  disabled={KittyCreated.loading}
                  variant="contained"
                  sx={{
                    mr: 1,
                    background: "#91bcff",
                    "&:hover": {
                      background: "#91bcff",
                    },
                    "&:disabled": {
                      background: "#d7e3f5",
                    },
                  }}
                >
                  {t("nextLabel")}
                </Button>
                {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <Typography
                      variant="caption"
                      sx={{ display: "inline-block", color: "#fff" }}
                    >
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : completed && kittyTempData?.newKittyTempData?.name ? (
                    <LoadingButton
                      onClick={handleComplete}
                      disabled={!kittyTempData?.newKittyTempData?.name}
                      loading={KittyCreated.loading}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                      sx={{
                        borderColor: "#592091",
                        color: "#592091",
                        background: "#fff",
                        "&:hover": {
                          background: "#fff",
                          color: "#592091 !important",
                          borderColor: "#fff",
                        },
                      }}
                    >
                      {t("createKittyLabel")}
                    </LoadingButton>
                  ) : null)}
              </Box>
            </Container>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
