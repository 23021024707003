import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { loginUserGoogle } from "../../../store/thunk/userThunk";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { insertNewKitty } from "../../../store/thunk/Kitty/InsertNewKittyThunk";
import { TabIndexData } from "../../../store/reducer/KittySlice/CreateKittySlice";
import { UserProfileDetails } from "../../../store/thunk/UserProfileThunk";

function GoogleUser(props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const kittyName = useAppSelector((state) => state.CreateKitty);
  const { t } = useTranslation();

  const responseMessage = (response) => {
    const userObject = jwt_decode(response.credential);
    localStorage.setItem("user", JSON.stringify(userObject));
    const { email } = userObject;
    const doc = {
      email: email,
    };
    dispatch(
      loginUserGoogle({
        code: {
          email: doc.email,
          username: doc.email,
          token: response.credential,
          social_account: 1,
          is_verified: true,
        },
        callback: () => {
          // navigate("/sign-up")
          // window.location.pathname = '/sign-up'
          if (!kittyName.activeTabIndex == 1) {
            navigate("/sign-up");
          }
          if (
            !kittyName.activeTabIndex == 1 &&
            window.location.pathname.includes("/sign-up")
          ) {
            window.location.reload();
          }
          dispatch(TabIndexData(1));
        },
        callback2: (token) => {
          dispatch(TabIndexData(2));
          dispatch(UserProfileDetails());
          if (window.location.pathname.includes("/sign-up")) navigate("/admin");
        },
      })
    );
  };

  const errorMessage = (error) => {};

  return (
    <div>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText={t("loginPage.8")}
        onSuccess={responseMessage}
        onFailure={errorMessage}
        cookiePolicy={"single_host_origin"}
        className="GOOGLE"
      />
    </div>
  );
}
export default GoogleUser;
