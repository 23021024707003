import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setListofKittyCat,
  setKittyCatLoader,
} from "../../reducer/KittyCategorySlice/listofKittySliceCat";
import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Authorization"] = localStorage.getItem("UserInfo")
  ? `Token ${localStorage.getItem("UserInfo")}`
  : "";

export const listofKittyCat = createAsyncThunk(
  "listofKittyCat",
  async (_request, { dispatch }) => {
    dispatch(setKittyCatLoader(true));

    let limit = 1000;
    let offSet = 0;
    let search = "";

    if (_request.limit && _request.limit !== "") {
      limit = _request.limit;
    }

    if (_request.offSet && _request.offSet !== "") {
      offSet = _request.offSet;
    }

    if (_request.search && _request.search !== "") {
      search = `&search=${_request.search}`;
    }

    axios
      .get(
        `api/v1/kitty/kitty-category/?limit=${limit}&offset=${offSet}${search}`,
        _request
      )
      .then((response) => {
        dispatch(setKittyCatLoader(false));
        // dispatch(setSuccess(true));
        dispatch(setListofKittyCat(response.data));
        if (_request?.callback()) {
          _request.callback();
        }
      })
      .catch((err) => {
        dispatch(setKittyCatLoader(false));
        // dispatch(setSuccess(false));
        // dispatch(setError(err));
      });
  }
);
