import React from "react";
import Footer from "../../Footer";
import Header from "../../Header";
import FAQUpperPart from "./FAQUpperPart";
import MainBlockFAQ from "./MainBlockFAQ";

function InnerServicePageComponent() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <MainBlockFAQ />
      <FAQUpperPart />
      <Footer />
    </>
  );
}
export default InnerServicePageComponent;
