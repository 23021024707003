import { Box, Container, Paper, Typography, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function HowItWorkBlock() {
  const { t } = useTranslation();
  return (
    <Box className="howItWorkBlock" sx={{ pt: { md: 10, xs: 8 }, pb: 5 }}>
      <Container>
        <Typography variant="h5" className="tontine-title">
          {t("howTontineWork.0")}
        </Typography>
        <Paper
          sx={{
            p: { md: "50px 30px", xs: "20px" },
            borderRadius: "10px",
            background: "#7d0cee3b",
            mb: { md: 8, xs: 2 },
          }}
        >
          <Typography sx={{ mb: 2 }} variant="body">
            {t("howTontineWork.1")}
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: "#592091", fontWeight: "600", mb: 1, mt: 2 }}
          >
            {t("howTontineWork.2")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body">
            {t("howTontineWork.3")}
          </Typography>
          <Divider
            sx={{
              borderBottom: "2px solid #e6e6e6",
              width: " 100%",
              m: "20px 0",
            }}
          />
          <Typography
            variant="h6"
            sx={{ color: "#592091", fontWeight: "600", mb: 1, mt: 1 }}
          >
            {t("howTontineWork.4")}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: "#592091", fontWeight: "600", mb: 1 }}
          >
            {t("howTontineWork.5")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body1">
            {t("howTontineWork.6")}
          </Typography>
          <Divider
            sx={{
              borderBottom: "2px solid #e6e6e6",
              width: " 100%",
              m: "20px 0",
            }}
          />
          <Typography
            variant="subtitle1"
            sx={{ color: "#592091", fontWeight: "600", mb: 1 }}
          >
            {t("howTontineWork.7")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body1">
            {t("howTontineWork.8")}
          </Typography>
          <Divider
            sx={{
              borderBottom: "2px solid #e6e6e6",
              width: " 100%",
              m: "20px 0",
            }}
          />
          <Typography
            variant="subtitle1"
            sx={{ color: "#592091", fontWeight: "600", mb: 1 }}
          >
            {t("howTontineWork.9")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body1">
            {t("howTontineWork.10")}
          </Typography>
          <Divider
            sx={{
              borderBottom: "2px solid #e6e6e6",
              width: " 100%",
              m: "20px 0",
            }}
          />
          <Typography
            variant="subtitle1"
            sx={{ color: "#592091", fontWeight: "600", mb: 1 }}
          >
            {t("howTontineWork.11")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body1">
            {t("howTontineWork.12")}
            <br />
            {t("howTontineWork.13")}
          </Typography>
          <Divider
            sx={{
              borderBottom: "2px solid #e6e6e6",
              width: " 100%",
              m: "20px 0",
            }}
          />
          <Typography
            variant="h6"
            sx={{ color: "#592091", fontWeight: "600", mb: 1 }}
          >
            {t("howTontineWork.14")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body1">
            {t("howTontineWork.15")}
            <br />
            {t("howTontineWork.16")}
            <br />
            {t("howTontineWork.17")}
            <br />
            {t("howTontineWork.18")}
          </Typography>
          <Divider
            sx={{
              borderBottom: "2px solid #e6e6e6",
              width: " 100%",
              m: "20px 0",
            }}
          />
          <Typography
            variant="h6"
            sx={{ color: "#592091", fontWeight: "600", mb: 1 }}
          >
            {t("howTontineWork.19")}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body1">
            {t("howTontineWork.20")}
          </Typography>
        </Paper>
        <Typography variant="h5" className="tontine-title">
          {t("howTontineWork.21")}
        </Typography>
        <Paper
          sx={{
            p: { md: "50px 30px", xs: "20px" },
            borderRadius: "10px",
            background: "#7d0cee3b",
          }}
        >
          <Typography variant="body" sx={{ fontWeight: "600", mb: 1 }}>
            {t("howTontineWork.22")}
          </Typography>
          <ul style={{ marginBottom: "20px" }}>
            <li style={{ listStyle: "circle" }}>{t("howTontineWork.23")}</li>
            <li style={{ listStyle: "circle" }}>{t("howTontineWork.24")}</li>
            <li style={{ listStyle: "circle" }}>{t("howTontineWork.25")}</li>
            <li style={{ listStyle: "circle" }}>{t("howTontineWork.26")}</li>
          </ul>
        </Paper>
      </Container>
    </Box>
  );
}
