import React from "react";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import HomePageSlider from "./homePage/slider/slider";
import KittyCard from "./homePage/moneyPots/MoneyPotsInnerPage/KittyCard";
import { Box } from "@mui/material";

function MoneyPotsInnerPageComonent() {
  return (
    <>
      <Header />
      <Box sx={{ background: "#f1efeb" }} className="min-height-div">
        <HomePageSlider />
        <KittyCard />
      </Box>
      <Footer />
    </>
  );
}
export default MoneyPotsInnerPageComonent;
