import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  Paper,
  Box,
  Backdrop,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useDispatch } from "react-redux";
import UpdateIcon from "@mui/icons-material/Update";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";
import Viewer from "react-viewer";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PDFViewerDialogComponent from "../../VerifyDocument/PDFViewerDialog";
import { useAppSelector } from "../../../../store/store";
import { CheckUserDocumentVerifyStatus } from "../../../../store/thunk/Tontine/MytontineThunk";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDialogBox(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const loaderUser = useAppSelector((state) => state?.MyTontine?.loading);
  const userDocuments = props.data?.documents_user_id_for_tontine;
  const [showDocumentUploadOptions, setShowDocumentUploadOptions] =
    React.useState(true);
  const [showDocumentSuccess, setShowDocumentSuccess] = React.useState(false);
  const [showDocumentApprovePending, setShowDocumentApprovePending] =
    React.useState(false);
  const [showDocumentAccept, setShowDocumentAccept] = React.useState(false);
  const [showDocumentDecline, setShowDocumentDecline] = React.useState(false);
  const [showImageViewer, setShowImageViewer] = React.useState(false);
  const [showPdfViewer, setShowPdfViewer] = React.useState(false);
  const [activeImageIndex, setActiveImageIndex] = React.useState(null);
  const [imageList, setImageList] = React.useState([]);
  const [pdfPath, setPdfPath] = React.useState("");
  const [currentDocumentId, setCurrentDocumentId] = React.useState(0);

  React.useEffect(() => {
    if (userDocuments?.length > 0 && userDocuments[0]?.status === "ACCEPTED") {
      // Show Verify Section
      setShowDocumentUploadOptions(false);
      setShowDocumentSuccess(true);
      setShowDocumentApprovePending(false);
      setShowDocumentAccept(true);
    } else if (
      userDocuments?.length > 0 &&
      userDocuments[0]?.status === "PENDING"
    ) {
      // Show Pending Verify Section
      setShowDocumentUploadOptions(false);
      setShowDocumentSuccess(true);
      setShowDocumentApprovePending(true);
      setShowDocumentAccept(false);
    } else if (
      userDocuments?.length > 0 &&
      userDocuments[0]?.status === "REJECTED"
    ) {
      // Show Rejected Section
      setShowDocumentUploadOptions(false);
      setShowDocumentSuccess(true);
      setShowDocumentApprovePending(false);
      setShowDocumentAccept(false);
      setShowDocumentDecline(true);
    } else {
      // Show Upload Section
      setShowDocumentUploadOptions(true);
      setShowDocumentSuccess(false);
      setShowDocumentApprovePending(false);
      setShowDocumentAccept(false);
    }

    if (userDocuments?.length > 0 && userDocuments[0]) {
      setCurrentDocumentId(userDocuments[0]?.id);
    }
  }, [userDocuments]);

  React.useEffect(() => {
    dispatch(CheckUserDocumentVerifyStatus({}));
  }, []);

  const getFileName = (imagePath) => {
    const filePath = imagePath?.substring(imagePath.lastIndexOf("/") + 1);
    const filename = filePath?.split("?")[0];
    return filename;
  };

  const getImageCount = (imageUrl) => {
    const imageObj = imageList.filter(function (entry) {
      return entry.src === imageUrl;
    });

    return imageObj?.length || 0;
  };

  const hideImageViewer = () => {
    setShowImageViewer(false);
  };

  const viewImageViewer = (imagePath) => {
    const imageIndex = imageList.findIndex((x) => x.src === imagePath);
    setActiveImageIndex(imageIndex);
    setShowImageViewer(true);
  };

  const hidePdfDocumentViewer = (event, reason) => {
    if (reason && (reason == "backdropClick" || reason == "escapeKeyDown")) {
      return;
    }
    setShowPdfViewer(false);
    setPdfPath("");
  };

  const { handleCloseDialogDocuments, open } = props;
  return (
    <>
      <Dialog
        sx={{ padding: { md: "0 24px", xs: "0 10px" } }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialogDocuments}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            fontWeight: "600",
            background: "#592091",
            color: "#fff",
            marginBottom: "20px !important",
            textAlign: "center",
          }}
        >
          {t("DocumentViewerLable")}
        </DialogTitle>
        <DialogContent sx={{ p: "0px 24px 10px 24px" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid
              container
              spacing={1}
              sx={{
                display: { md: "flex", xs: "block" },
                justifyContent: " center",
              }}
            >
              {/* Document Upload Success */}
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loaderUser}
              // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {showDocumentSuccess && (
                <>
                  {showDocumentApprovePending ? (
                    <>
                      <Grid
                        item
                        // md={6}
                        xs={12}
                        sx={{
                          justifyContent: "center",
                          mb: "100px ",
                        }}
                      >
                        <Box>
                          <Paper sx={{ p: "10px" }}>
                            <Typography
                              variant="subtitle1"
                              component={"span"}
                              gutterBottom
                              sx={{
                                pl: "8px",
                                fontWeight: "700",
                                display: "block",
                                textAlign: "left",
                              }}
                            >
                              {t("documentStatus")}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              component={"span"}
                              gutterBottom
                              sx={{
                                pl: "8px",
                                display: "block",
                                textAlign: "left",
                              }}
                            >
                              {`${t("documentAddedOnLable")}: ${moment(
                                userDocuments[0]?.created_at
                              ).format(
                                localStorage.getItem("language") == "en"
                                  ? "YYYY-MM-DD"
                                  : "DD-MM-YYYY"
                              )}`}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              component={"span"}
                              gutterBottom
                              sx={{
                                pl: "8px",
                                fontWeight: "700",
                                display: "flex",
                                alignItems: "center",
                                textAlign: "left",
                                color: "#592091",
                              }}
                            >
                              <UpdateIcon
                                sx={{ mr: "5px", fontSize: "20px" }}
                              />
                              {t("documentPendingStatusLable")}
                            </Typography>
                            {userDocuments[0]?.image_for_tontine_document?.map(
                              (documentData) => {
                                const fileExt = getFileName(documentData?.image)
                                  .split(".")
                                  .pop();

                                if (!getImageCount(documentData?.image)) {
                                  if (fileExt !== "pdf") {
                                    setImageList([
                                      ...imageList,
                                      {
                                        src: documentData?.image,
                                        alt: getFileName(documentData?.image),
                                      },
                                    ]);
                                  }
                                }

                                return fileExt === "pdf" ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      pl: "8px",
                                      alignItems: "center",
                                    }}
                                    key={documentData?.id}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <InsertDriveFileIcon
                                        sx={{ fontSize: "20px", mr: "5px" }}
                                      />
                                      <span
                                        onClick={() => {
                                          setPdfPath(documentData?.image);
                                          setShowPdfViewer(true);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {getFileName(documentData?.image)}
                                      </span>
                                    </Box>
                                    <Tooltip
                                      title={t("viewLable")}
                                      placement="top"
                                    >
                                      <VisibilityIcon
                                        sx={{
                                          pl: "5px",
                                          fill: "#592091",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setPdfPath(documentData?.image);
                                          setShowPdfViewer(true);
                                        }}
                                      />
                                    </Tooltip>
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      pl: "8px",
                                      alignItems: "center",
                                    }}
                                    key={documentData?.id}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <InsertDriveFileIcon
                                        sx={{ fontSize: "20px", mr: "5px" }}
                                      />
                                      <span
                                        className=""
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "13px",
                                        }}
                                        onClick={() =>
                                          viewImageViewer(documentData?.image)
                                        }
                                      >
                                        {getFileName(documentData?.image)}
                                      </span>
                                    </Box>
                                    <Tooltip
                                      title={t("viewLable")}
                                      placement="top"
                                    >
                                      <VisibilityIcon
                                        sx={{
                                          pl: "5px",
                                          fill: "#592091",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          viewImageViewer(documentData?.image)
                                        }
                                      />
                                    </Tooltip>
                                  </Box>
                                );
                              }
                            )}
                          </Paper>
                          {userDocuments[0]?.iban ? (
                            <Box sx={{ mt: "10px", color: "#212121" }}>
                              IBAN: {userDocuments[0]?.iban || ""}
                            </Box>
                          ) : null}
                        </Box>
                      </Grid>
                    </>
                  ) : showDocumentAccept ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          justifyContent: "center",
                          mb: "100px ",
                        }}
                      >
                        <Paper sx={{ p: "10px" }}>
                          <Typography
                            variant="subtitle1"
                            component={"span"}
                            gutterBottom
                            sx={{
                              pl: "8px",
                              fontWeight: "700",
                              display: "block",
                              textAlign: "left",
                            }}
                          >
                            {t("documentStatus")}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component={"span"}
                            gutterBottom
                            sx={{
                              pl: "8px",
                              display: "block",
                              textAlign: "left",
                            }}
                          >
                            {`${t("documentAddedOnLable")}: ${moment(
                              userDocuments[0]?.created_at
                            ).format(
                              localStorage.getItem("language") == "en"
                                ? "YYYY-MM-DD"
                                : "DD-MM-YYYY"
                            )}`}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component={"span"}
                            gutterBottom
                            sx={{
                              pl: "8px",
                              fontWeight: "700",
                              display: "flex",
                              alignItems: "center",
                              textAlign: "left",
                              color: "#592091",
                            }}
                          >
                            <DoneIcon
                              sx={{
                                mr: "5px",
                                fontSize: "20px",
                                fontWeight: "bold",
                              }}
                            />
                            {t("documentApprovedStatus")}
                          </Typography>

                          {userDocuments[0]?.image_for_tontine_document?.map(
                            (documentData) => {
                              const fileExt = getFileName(documentData?.image)
                                .split(".")
                                .pop();

                              if (!getImageCount(documentData?.image)) {
                                if (fileExt !== "pdf") {
                                  setImageList([
                                    ...imageList,
                                    {
                                      src: documentData?.image,
                                      alt: getFileName(documentData?.image),
                                    },
                                  ]);
                                }
                              }

                              return fileExt === "pdf" ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    pl: "8px",
                                    alignItems: "center",
                                  }}
                                  key={documentData?.id}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <InsertDriveFileIcon
                                      sx={{ fontSize: "20px", mr: "5px" }}
                                    />
                                    <span
                                      onClick={() => {
                                        setPdfPath(documentData?.image);
                                        setShowPdfViewer(true);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {getFileName(documentData?.image)}
                                    </span>
                                  </Box>
                                  <Tooltip
                                    title={t("viewLable")}
                                    placement="top"
                                  >
                                    <VisibilityIcon
                                      sx={{
                                        pl: "5px",
                                        fill: "#592091",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setPdfPath(documentData?.image);
                                        setShowPdfViewer(true);
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    pl: "8px",
                                    alignItems: "center",
                                  }}
                                  key={documentData?.id}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <InsertDriveFileIcon
                                      sx={{ fontSize: "20px", mr: "5px" }}
                                    />
                                    <span
                                      className=""
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "13px",
                                      }}
                                      onClick={() =>
                                        viewImageViewer(documentData?.image)
                                      }
                                    >
                                      {getFileName(documentData?.image)}
                                    </span>
                                  </Box>
                                  <Tooltip
                                    title={t("viewLable")}
                                    placement="top"
                                  >
                                    <VisibilityIcon
                                      sx={{
                                        pl: "5px",
                                        fill: "#592091",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        viewImageViewer(documentData?.image)
                                      }
                                    />
                                  </Tooltip>
                                </Box>
                              );
                            }
                          )}
                        </Paper>
                        {userDocuments[0]?.iban ? (
                          <Box sx={{ mt: "10px", color: "#212121" }}>
                            IBAN: {userDocuments[0]?.iban || ""}
                          </Box>
                        ) : null}
                      </Grid>
                    </>
                  ) : showDocumentDecline ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          justifyContent: "center",
                          mb: "100px ",
                        }}
                      >
                        <Paper sx={{ p: "10px" }}>
                          <Typography
                            variant="subtitle1"
                            component={"span"}
                            gutterBottom
                            sx={{
                              pl: "8px",
                              fontWeight: "700",
                              display: "block",
                              textAlign: "left",
                            }}
                          >
                            {t("documentStatus")}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component={"span"}
                            gutterBottom
                            sx={{
                              pl: "8px",
                              display: "block",
                              textAlign: "left",
                            }}
                          >
                            {t("documentAddedOnLable")} :{" "}
                            {userDocuments?.length
                              ? moment(userDocuments[0].created_at).format(
                                localStorage.getItem("language") == "en"
                                  ? "YYYY-MM-DD"
                                  : "DD-MM-YYYY"
                              )
                              : ""}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component={"span"}
                            gutterBottom
                            sx={{
                              pl: "8px",
                              display: "block",
                              textAlign: "left",
                            }}
                          >
                            {`${t("reasonLable")}: ${userDocuments[0]?.reason}`}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component={"span"}
                            gutterBottom
                            sx={{
                              pl: "8px",
                              fontWeight: "700",
                              display: "flex",
                              alignItems: "center",
                              textAlign: "left",
                              color: "#592091",
                            }}
                          >
                            <CloseIcon
                              sx={{
                                mr: "5px",
                                fontSize: "20px",
                                fontWeight: "bold",
                              }}
                            />
                            {t("documentRejectedStatus")}
                          </Typography>
                          {userDocuments[0]?.image_for_tontine_document?.map(
                            (documentData) => {
                              const fileExt = getFileName(documentData?.image)
                                .split(".")
                                .pop();

                              if (!getImageCount(documentData?.image)) {
                                if (fileExt !== "pdf") {
                                  setImageList([
                                    ...imageList,
                                    {
                                      src: documentData?.image,
                                      alt: getFileName(documentData?.image),
                                    },
                                  ]);
                                }
                              }

                              return fileExt === "pdf" ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    pl: "8px",
                                    alignItems: "center",
                                  }}
                                  key={documentData?.id}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <InsertDriveFileIcon
                                      sx={{ fontSize: "20px", mr: "5px" }}
                                    />
                                    <span
                                      onClick={() => {
                                        setPdfPath(documentData?.image);
                                        setShowPdfViewer(true);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {getFileName(documentData?.image)}
                                    </span>
                                  </Box>
                                  <Tooltip
                                    title={t("viewLable")}
                                    placement="top"
                                  >
                                    <VisibilityIcon
                                      sx={{
                                        pl: "5px",
                                        fill: "#592091",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setPdfPath(documentData?.image);
                                        setShowPdfViewer(true);
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    pl: "8px",
                                    alignItems: "center",
                                  }}
                                  key={documentData?.id}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <InsertDriveFileIcon
                                      sx={{ fontSize: "20px", mr: "5px" }}
                                    />
                                    <span
                                      className=""
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "13px",
                                      }}
                                      onClick={() =>
                                        viewImageViewer(documentData?.image)
                                      }
                                    >
                                      {getFileName(documentData?.image)}
                                    </span>
                                  </Box>
                                  <Tooltip
                                    title={t("viewLable")}
                                    placement="top"
                                  >
                                    <VisibilityIcon
                                      sx={{
                                        pl: "5px",
                                        fill: "#592091",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        viewImageViewer(documentData?.image)
                                      }
                                    />
                                  </Tooltip>
                                </Box>
                              );
                            }
                          )}
                        </Paper>
                        {/* <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: "10px",
                          }}
                        >
                          <Button
                            onClick={showUploadOptions}
                            sx={{
                              background: "#592091",
                              color: "#fff",
                              display: "block",
                              marginTop: "10px",
                              padding: "8px 20px",
                              fontWeight: "600",
                              letterSpacing: " 2px",
                              border: "1px solid #592091",
                              "&:hover": {
                                color: "#592091",
                              },
                              "&:disabled": {
                                color: "#fff",
                                background: "#876ba3",
                              },
                            }}
                          >
                            {t("Add New Document")}
                          </Button>
                        </Box> */}
                      </Grid>
                    </>
                  ) : null}
                </>
              )}
              {showDocumentUploadOptions && (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mb: "100px ",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        p: 4,
                        fontSize: "22px",
                        fontWeight: "600",
                        color: "#592091",
                        fontFamily: "cursive",
                      }}
                    >
                      Not uploaded yet
                    </Typography>
                  </Grid>
                </>
              )}
              {showImageViewer && (
                <Viewer
                  visible={showImageViewer}
                  onClose={hideImageViewer}
                  showTotal={false}
                  noNavbar={true}
                  noImgDetails={true}
                  attribute={false}
                  rotatable={true}
                  changeable={false}
                  disableKeyboardSupport={true}
                  scalable={false}
                  images={imageList}
                  activeIndex={activeImageIndex}
                />
              )}
              {showPdfViewer && (
                <PDFViewerDialogComponent
                  dialogStatus={showPdfViewer}
                  handleClose={hidePdfDocumentViewer}
                  pdfSrc={pdfPath}
                />
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="delete-cancel-button"
            sx={{
              fontSize: "12px",
              background: "#fff",
              color: "#592091",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={() => handleCloseDialogDocuments()}
          >
            {t("cancelLable")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
