import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { countries } from "./Country";
import { updateUserProfile } from "../../../../store/thunk/newUserThunk";
import { useAppSelector } from "../../../../store/store";
import { useParams } from "react-router-dom";
import { setUserDetails } from "../../../../store/reducer/User/UserProfileSlice";

const mailformat =
  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

function Profile(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const session = useAppSelector((state) => state);
  const [formError, setFormError] = React.useState([]);
  const franceCountry = countries.find((country) => country.code == "FR");
  const [countryState, setCountryState] = React.useState(franceCountry);
  const { t } = useTranslation();
  const [profileDetails, setProfileDetails] = useState({});

  const loginUserDetails = useAppSelector(
    (state) => state?.UserProfile?.details
  );
  const tempUserDetails = useAppSelector(
    (state) => state?.UserProfile?.tempDetails
  );
  useEffect(() => {
    id == "my" || (props.calledFor && props.calledFor === "admin_profile")
      ? setProfileDetails(loginUserDetails)
      : setProfileDetails(tempUserDetails);
  }, [tempUserDetails, loginUserDetails, id]);

  const [profileData, setProfileData] = React.useState({
    email: "",
    first_name: "",
    name: "",
    city: "",
    birth_date: "",
    address: "",
    postal_code: "",
    country: "FR",
    language: "FR",
  });

  useEffect(() => {
    setProfileData({
      email: profileDetails?.email,
      first_name: profileDetails?.first_name,
      name: profileDetails?.name,
      city: profileDetails?.city,
      birth_date: profileDetails?.birth_date || "",
      address: profileDetails?.address,
      postal_code: profileDetails?.postal_code,
      country: profileDetails?.country,
      language: profileDetails?.language,
    });
    const countryData = countries.find(
      (country) => country.code == profileDetails?.country
    );

    setCountryState(countryData);
  }, [profileDetails]);

  const validateForm = (data) => {
    let error = [];

    if (!data.email) {
      error.push("email");
    }
    if (!data.email.match(mailformat)) {
      error.push("email");
    }

    if (!data.first_name) {
      error.push("first_name");
    }
    if (!data.name) {
      error.push("name");
    }
    if (!data.city) {
      error.push("city");
    }
    if (!data.birth_date) {
      error.push("birth_date");
    }
    if (!data.address) {
      error.push("address");
    }
    if (!data.postal_code) {
      error.push("postal_code");
    }
    if (!data.country) {
      error.push("country");
    }

    setFormError(error);

    return error;
  };

  const handleChangeInput = (text, event) => {
    setProfileData({
      ...profileData,
      [text]: event.target.value,
    });
  };

  function handleDateChange(newValue) {
    setProfileData({
      ...profileData,
      birth_date: newValue
        ? `${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`
        : "",
    });
  }

  const handleSubmit = () => {
    let error = validateForm(profileData);

    if (error.length == 0) {
      let request = {
        ...profileData,
      };

      dispatch(
        updateUserProfile({
          request: request,
          user_id: profileDetails?.id,
          callback: (data) => {
            if (id == "my") {
              dispatch(setUserDetails(data));
            }
          },
        })
      );
    }
  };

  function handleCountryChange(newValue) {
    setProfileData({
      ...profileData,
      country: newValue,
    });
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "600 !important",
            textTransform: "uppercase !important",
            marginBottom: "10px !important",
            fontSize: "16px !important",
          }}
        >
          {t("myAccountFirstNameLable")}:
        </Typography>
        <TextField
          margin="dense"
          size="small"
          id="first_name"
          value={profileData.first_name}
          onChange={(e) => {
            handleChangeInput("first_name", e);
          }}
          type="text"
          fullWidth
          variant="outlined"
          error={formError.includes("first_name")}
          helperText={
            formError.includes("first_name")
              ? t("myAccountFirstNameValidationLable")
              : ""
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "600 !important",
            textTransform: "uppercase !important",
            marginBottom: "10px !important",
            marginTop: "20px !important",
            fontSize: "16px !important",
          }}
        >
          {t("myAccountLastNameLable")}:
        </Typography>
        <TextField
          margin="dense"
          size="small"
          id="name"
          value={profileData.name}
          onChange={(e) => {
            handleChangeInput("name", e);
          }}
          type="text"
          fullWidth
          variant="outlined"
          error={formError.includes("name")}
          helperText={
            formError.includes("name")
              ? t("myAccountLastNameValidationLable")
              : ""
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "600 !important",
            textTransform: "uppercase !important",
            marginBottom: "10px !important",
            marginTop: "20px !important",
            fontSize: "16px !important",
          }}
        >
          {t("myAccountEmailLable")}:
        </Typography>
        <TextField
          margin="dense"
          size="small"
          id="email"
          value={profileData.email}
          onChange={(e) => {
            handleChangeInput("email", e);
          }}
          type="text"
          fullWidth
          variant="outlined"
          error={formError.includes("email")}
          helperText={t("myAccountEmailNoteLable")}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "600 !important",
            textTransform: "uppercase !important",
            marginBottom: "10px !important",
            marginTop: "20px !important",
            fontSize: "16px !important",
          }}
        >
          {t("myAccountAddressLable")}:
        </Typography>
        <TextField
          margin="dense"
          size="small"
          id="address"
          value={profileData.address}
          onChange={(e) => {
            handleChangeInput("address", e);
          }}
          type="text"
          fullWidth
          variant="outlined"
          error={formError.includes("address")}
          helperText={
            formError.includes("address")
              ? t("myAccountAddressValidationLable")
              : ""
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600 !important",
                textTransform: "uppercase !important",
                marginBottom: "10px !important",
                marginTop: "20px !important",
                fontSize: "16px !important",
              }}
            >
              {t("myAccountPostalCodeLable")}:
            </Typography>
            <TextField
              margin="dense"
              size="small"
              id="postal_code"
              value={profileData.postal_code}
              onChange={(e) => {
                handleChangeInput("postal_code", e);
              }}
              type="text"
              fullWidth
              variant="outlined"
              error={formError.includes("postal_code")}
              helperText={
                formError.includes("postal_code")
                  ? t("myAccountPostalValidationLable")
                  : ""
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600 !important",
                textTransform: "uppercase !important",
                marginBottom: "10px !important",
                marginTop: "20px !important",
                fontSize: "16px !important",
              }}
            >
              {t("myAccountCityLable")}:
            </Typography>
            <TextField
              margin="dense"
              size="small"
              id="city"
              value={profileData.city}
              onChange={(e) => {
                handleChangeInput("city", e);
              }}
              type="text"
              fullWidth
              variant="outlined"
              error={formError.includes("city")}
              helperText={
                formError.includes("city")
                  ? t("myAccountCityValidationLable")
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined" margin="normal">
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            sx={{
              fontWeight: "600 !important",
              textTransform: "uppercase !important",
              marginBottom: "10px !important",
              fontSize: "16px !important",
              color: "#000 !important",
            }}
          >
            {t("myAccountCountryLable")}
          </FormLabel>
          <Autocomplete
            size="small"
            id="country-select-demo"
            options={countries}
            error={formError.includes("country")}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code})
              </Box>
            )}
            value={countryState}
            renderInput={(params) => (
              <TextField
                {...params}
                id="country-text"
                label=""
                error={formError.includes("country")}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "country", // disable autocomplete and autofill
                }}
              />
            )}
            getOptionLabel={(option) => option.label}
            onChange={(e, newValue) => {
              if (newValue) {
                handleCountryChange(newValue.code);
              } else {
                handleCountryChange("");
              }
            }}
            freeSolo
            defaultValue={{
              code: "FR",
              label: "France",
              phone: "33",
              suggested: true,
            }}
            onInputChange={(event, newInputValue, reason) => {
              if (event && reason === "reset") {
                setProfileData({
                  ...profileData,
                  country: "",
                });
              }
            }}
          />
          {formError.includes("country") && (
            <FormHelperText id="component-error-text" error>
              {t("myAccountCountryValidationLable")}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl margin="normal">
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              sx={{
                fontWeight: "600 !important",
                textTransform: "uppercase !important",
                marginBottom: "10px !important",
                fontSize: "16px !important",
                color: "#000 !important",
              }}
            >
              {t("myAccountDOBLable")}
            </FormLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack fullWidth>
                <DesktopDatePicker
                  inputFormat={
                    localStorage.getItem("language") == "en"
                      ? "YYYY-MM-DD"
                      : "DD-MM-YYYY"
                  }
                  value={profileData?.birth_date || ""}
                  onChange={(newValue) => handleDateChange(newValue)}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      error={formError.includes("birth_date")}
                    />
                  )}
                  disableFuture={true}
                  sx={{
                    ".PrivatePickersYear-yearButton": {
                      "& .Mui-selected": {
                        textAlign: "Center",
                      },
                    },
                    "& > fieldset": { border: "1px solid green" },
                  }}
                />
              </Stack>
            </LocalizationProvider>
            {formError.includes("birth_date") && (
              <FormHelperText id="component-error-text" error>
                {t("myAccountDOBValidationLable")}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} sx={{ mt: "16px" }}>
          <FormControl fullWidth>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              sx={{
                fontWeight: "600 !important",
                textTransform: "uppercase !important",
                marginBottom: "10px !important",
                fontSize: "16px !important",
                color: "#000 !important",
              }}
            >
              {t("LanguagePreferd")}
            </FormLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={profileData.language}
              size="small"
              onChange={(e) => {
                handleChangeInput("language", e);
              }}
            >
              <MenuItem value={"EN"}>{t("EnglishLable")}</MenuItem>
              <MenuItem value={"FR"}>{t("FrenchLable")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid xs={12} sx={{ textAlign: "center" }}>
        <Button
          onClick={() => handleSubmit()}
          sx={{
            background: "#592091",
            color: "#fff",
            display: "block",
            marginTop: "10px",
            padding: "8px 20px",
            fontWeight: "600",
            letterSpacing: " 2px",
            border: "1px solid #592091",
            "&:hover": {
              color: "#592091",
            },
          }}
        >
          {t("submitLabel")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default Profile;
