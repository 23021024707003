import * as React from "react";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch } from "../../../../../store/store";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { deleteCommissionTontine } from "../../../../../store/thunk/Payments/CheckOutThunk";
import ConfirmDialogComponent from "../../../../../components/common/ConfirmDialog";

export default function DeleteAlertBBox(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteUpdateKitty = () => {
    dispatch(deleteCommissionTontine(props?.data?.id));
  };

  return (
    <>
      <Tooltip title={t("deleteLable")}>
        <Button
          onClick={handleClickOpen}
          sx={{ textDecoration: "none", color: "#fff", minWidth: "unset" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: " center",
              justifyContent: "center",
              fontWeight: " 600",
              fontSize: "18px",
            }}
          >
            <DeleteIcon sx={{ color: "#ff0000", mr: 1 }} />
          </Box>
        </Button>
      </Tooltip>
      {open && (
        <ConfirmDialogComponent
          confirmSuccessFunc={deleteUpdateKitty}
          confirmCancelFunc={handleClose}
          title={t("confirmationLabel")}
          contentText={t("deleteConfirmLabel")}
          dialogStatus={open}
          confirmOkBGColor="#ff0000"
        />
      )}
    </>
  );
}
