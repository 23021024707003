import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  createLoading: false,
  LinkCheckloading: false,
  error: "",
  success: false,
  CreateTontineSuccess: false,
  myTontine: {},
  SubscribedTontine: {},
  singleTontine: {},
  tontineLink: {},
  ListofInvites: {
    results: [],
  },
  docStatus: {},
  CreateFunctionToggle: false,
  newTontineDetails: {},
  nextStepActive: false,
  userDocuments: [],
  userDocumentsList: {},
  adminTontineList: { results: [] },
  adminTontineLogList: { results: [] },
  adminTontineParticipateLogList: { results: [] },
  adminTontineDocumentLogList: { results: [] },
  adminTontineInviteLogList: { results: [] },
  adminTontineCycleLogList: {},
  cycleOfTontine: {},
  currentCycleDetails: { results: [] },
  TontineParticipantList: {},
  PausedTontineList: {},
  TontinePayoutListing: {},
  adminTontineCycleUserLogList: {},
  adminTontineSubscriptionLogList: {},
  adminTontinePayoutLogList: {},
};

const listofTontineSlice = createSlice({
  name: "listofTontineSlice",
  initialState,
  reducers: {
    setListofMyTontine: (state, action) => ({
      ...state,
      myTontine: action.payload,
    }),
    setTontineLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setListofSubscribedTontine: (state, action) => ({
      ...state,
      SubscribedTontine: action.payload,
    }),
    setSingleMyTontine: (state, action) => ({
      ...state,
      singleTontine: action.payload,
    }),
    setNewMyTontine: (state, action) => ({
      ...state,
      newTontineDetails: action.payload,
    }),
    setTontineLink: (state, action) => ({
      ...state,
      tontineLink: action.payload,
    }),
    setTontineInvites: (state, action) => ({
      ...state,
      ListofInvites: action.payload,
    }),
    setTontineDocStatus: (state, action) => ({
      ...state,
      docStatus: action.payload,
    }),
    setMyTontineLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setMyCreateTontineLoader: (state, action) => ({
      ...state,
      createLoading: action.payload,
    }),
    setMyTontineLinkCheckloading: (state, action) => ({
      ...state,
      LinkCheckloading: action.payload,
    }),
    setMyTontineSuccess: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    setMyTontineCreateSuccess: (state, action) => ({
      ...state,
      CreateTontineSuccess: action.payload,
    }),
    setMyTontineError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    setMyTontineCreateFunction: (state, action) => ({
      ...state,
      CreateFunctionToggle: action.payload,
    }),
    setNextStep: (state, action) => ({
      ...state,
      nextStepActive: action.payload,
    }),
    setUserDocuments: (state, action) => ({
      ...state,
      userDocuments: action.payload,
    }),
    setUserDocumentList: (state, action) => ({
      ...state,
      userDocumentsList: action.payload,
    }),
    setListofAdminTontine: (state, action) => ({
      ...state,
      adminTontineList: action.payload,
    }),
    setAdminTontineLogList: (state, action) => ({
      ...state,
      adminTontineLogList: action.payload,
    }),
    setAdminTontineParticipateLogList: (state, action) => ({
      ...state,
      adminTontineParticipateLogList: action.payload,
    }),
    setAdminTontineDocumentLogList: (state, action) => ({
      ...state,
      adminTontineDocumentLogList: action.payload,
    }),
    setAdminTontineInviteLogList: (state, action) => ({
      ...state,
      adminTontineInviteLogList: action.payload,
    }),
    setadminTontineCycleLogList: (state, action) => ({
      ...state,
      adminTontineCycleLogList: action.payload,
    }),
    setCycleOfTontine: (state, action) => ({
      ...state,
      cycleOfTontine: action.payload,
    }),
    setTontineParticipantList: (state, action) => ({
      ...state,
      TontineParticipantList: action.payload,
    }),
    setCurrentCycleDetails: (state, action) => ({
      ...state,
      currentCycleDetails: action.payload,
    }),
    setPausedTontineList: (state, action) => ({
      ...state,
      PausedTontineList: action.payload,
    }),
    setTontinePayoutListing: (state, action) => ({
      ...state,
      TontinePayoutListing: action.payload,
    }),
    setadminTontineCycleUserLogList: (state, action) => ({
      ...state,
      adminTontineCycleUserLogList: action.payload,
    }),
    setadminTontineSubscriptionLogList: (state, action) => ({
      ...state,
      adminTontineSubscriptionLogList: action.payload,
    }),
    setadminTontinePayoutLogList: (state, action) => ({
      ...state,
      adminTontinePayoutLogList: action.payload,
    }),
  },
});

export const {
  setListofMyTontine,
  setTontineLoader,
  setListofSubscribedTontine,
  setNewMyTontine,
  setMyTontineLoader,
  setMyTontineSuccess,
  setMyTontineError,
  setSingleMyTontine,
  setTontineLink,
  setTontineInvites,
  setTontineDocStatus,
  setMyTontineCreateFunction,
  setMyTontineCreateSuccess,
  setNextStep,
  setUserDocuments,
  setUserDocumentList,
  setListofAdminTontine,
  setAdminTontineLogList,
  setMyTontineLinkCheckloading,
  setAdminTontineParticipateLogList,
  setAdminTontineDocumentLogList,
  setAdminTontineInviteLogList,
  setCycleOfTontine,
  setTontineParticipantList,
  setMyCreateTontineLoader,
  setCurrentCycleDetails,
  setPausedTontineList,
  setTontinePayoutListing,
  setadminTontineCycleLogList,
  setadminTontineCycleUserLogList,
  setadminTontineSubscriptionLogList,
  setadminTontinePayoutLogList,
} = listofTontineSlice.actions;

export default listofTontineSlice.reducer;
