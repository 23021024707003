import React from "react";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import TontineLanding from "./tontinePage/tontineLanding";
import { Box } from "@mui/material";

function TontinePageComponent() {
  return (
    <>
      <Header />
      <Box className="min-height-div" sx={{ backgroundColor: "#fff" }}>
        <TontineLanding />
      </Box>
      <Footer />
    </>
  );
}
export default TontinePageComponent;
