import { Navigate } from "react-router-dom";

function SuperAdminRoute(props) {
  const { component } = props;
  return localStorage.getItem("UserInfo") &&
    localStorage.getItem("is_superuser") == "true" ? (
    component
  ) : (
    <Navigate to="/admin" />
  );
}

export default SuperAdminRoute;
