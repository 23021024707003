import React, { useEffect } from "react";
import ResponsiveDrawer from "../../topbar";
import {
  Box,
  Container,
  Paper,
  Typography,
  Button,
  CssBaseline,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import VerticalTabs from "../../../../../components/tontineSettings/VerticalTabs";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { SingleTontine } from "../../../../../store/thunk/Tontine/MytontineThunk";

function AdminEditTontineContent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const tontineDetails = useAppSelector(
    (state) => state.MyTontine.singleTontine
  );

  useEffect(() => {
    dispatch(SingleTontine(id));
  }, []);

  return (
    <Box className="howItWorkBlock">
      <Container sx={{ p: "16px !important" }}>
        <Paper
          sx={{ p: "10px 10px", mb: 2, display: "flex", flexDirection: "rows" }}
        >
          <img
            src={tontineDetails?.image}
            height="100px"
            width="100px"
            loading="lazy"
            style={{ objectFit: "cover" }}
            alt={t("kittyFieldLable.0")}
          />
          <Box sx={{ m: "4px", display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "17px !important",
                fontWeight: "600 !important",
                padding: "10px !important",
              }}
            >
              {tontineDetails?.name}
            </Typography>
            <Button
              onClick={() => {
                navigate("/admin-tontine-list");
              }}
              sx={{
                color: "#868484",
                fontWeight: "600",
                justifyContent: "left",
              }}
            >
              {"< "}
              {t("back")}
            </Button>
          </Box>
        </Paper>
        <Paper>
          <VerticalTabs />
        </Paper>
      </Container>
    </Box>
  );
}

export default function AdminKittySettingsComponent() {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (localStorage.getItem("is_superuser") == "false") {
      navigate("/admin");
    }
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ResponsiveDrawer pageComponent={<AdminEditTontineContent />} />
    </Box>
  );
}
