import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  success: "",
  list: {},
  settings: {},
  details: {},
  updateForm: "",
  updateTempForm: {},
  like: {},
  kittyLikeData: {},
  contributeList: {},
  contributeAmount: {},
  InvitationList: {},
  SwapLink: {},
  adminKittyLogList: { results: [] },
  adminKittyCategoryLogList: { results: [] },
  adminKittyLikeLogList: { results: [] },
  adminKittyContributeLogList: { results: [] },
  adminKittyCommentLogList: { results: [] },
  adminKittyMessageLogList: { results: [] },
  adminKittySettingLogList: { results: [] },
  adminKittyUpdateLogList: { results: [] },
  adminKittyInviteLogList: { results: [] },
  adminKittyContributeList: { results: [] },
  adminKittyContributePayoutList: { results: [] },
  adminKittyPayoutLogList: { results: [] },
  adminKittyPayoutIntentLogList: { results: [] },
};

const DetailsOfKittySlice = createSlice({
  name: "DetailsOfKittySlice",
  initialState,
  reducers: {
    setDetailsOfKitty: (state, action) => ({
      ...state,
      list: action.payload,
    }),
    setSettingssOfKitty: (state, action) => ({
      ...state,
      settings: action.payload,
    }),
    setDetailsOfKittyLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setDetailsOfKittySuccess: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    setDetailsOfKittyError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    setKittyUpdate: (state, action) => ({
      ...state,
      updateForm: action.payload,
    }),
    setTempKittyUpdate: (state, action) => ({
      ...state,
      updateTempForm: action.payload,
    }),
    setKittyLike: (state, action) => ({
      ...state,
      like: action.payload,
    }),
    setSwapLink: (state, action) => ({
      ...state,
      SwapLink: action.payload,
    }),
    setKittyLikeData: (state, action) => ({
      ...state,
      kittyLikeData: action.payload,
    }),
    setKittyContribute: (state, action) => ({
      ...state,
      contributeList: action.payload,
    }),
    setKittyContributeAmount: (state, action) => ({
      ...state,
      contributeAmount: action.payload,
    }),
    setKittyInvitationList: (state, action) => ({
      ...state,
      InvitationList: action.payload,
    }),
    setAdminKittyLogList: (state, action) => ({
      ...state,
      adminKittyLogList: action.payload,
    }),
    setAdminKittyCategoryLogList: (state, action) => ({
      ...state,
      adminKittyCategoryLogList: action.payload,
    }),
    setAdminKittyLikeLogList: (state, action) => ({
      ...state,
      adminKittyLikeLogList: action.payload,
    }),
    setAdminKittyContributeLogList: (state, action) => ({
      ...state,
      adminKittyContributeLogList: action.payload,
    }),
    setAdminKittyCommentLogList: (state, action) => ({
      ...state,
      adminKittyCommentLogList: action.payload,
    }),
    setAdminKittyMessageLogList: (state, action) => ({
      ...state,
      adminKittyMessageLogList: action.payload,
    }),
    setAdminKittySettingLogList: (state, action) => ({
      ...state,
      adminKittySettingLogList: action.payload,
    }),
    setAdminKittyUpdateLogList: (state, action) => ({
      ...state,
      adminKittyUpdateLogList: action.payload,
    }),
    setAdminKittyInviteLogList: (state, action) => ({
      ...state,
      adminKittyInviteLogList: action.payload,
    }),
    setAdminKittyContributeList: (state, action) => ({
      ...state,
      adminKittyContributeList: action.payload,
    }),
    setAdminKittyContributePayoutList: (state, action) => ({
      ...state,
      adminKittyContributePayoutList: action.payload,
    }),
    setAdminKittyPayoutLogList: (state, action) => ({
      ...state,
      adminKittyPayoutLogList: action.payload,
    }),
    setadminKittyPayoutIntentLogList: (state, action) => ({
      ...state,
      adminKittyPayoutIntentLogList: action.payload,
    }),
  },
});

export const {
  setDetailsOfKitty,
  setSettingssOfKitty,
  setDetailsOfKittyLoader,
  setDetailsOfKittySuccess,
  setDetailsOfKittyError,
  setKittyUpdate,
  setTempKittyUpdate,
  setKittyLike,
  setKittyLikeData,
  setSwapLink,
  setKittyContribute,
  setKittyContributeAmount,
  setKittyInvitationList,
  setAdminKittyLogList,
  setAdminKittyCategoryLogList,
  setAdminKittyLikeLogList,
  setAdminKittyContributeLogList,
  setAdminKittyCommentLogList,
  setAdminKittyMessageLogList,
  setAdminKittySettingLogList,
  setAdminKittyUpdateLogList,
  setAdminKittyInviteLogList,
  setAdminKittyContributeList,
  setAdminKittyContributePayoutList,
  setAdminKittyPayoutLogList,
  setadminKittyPayoutIntentLogList,
} = DetailsOfKittySlice.actions;

export default DetailsOfKittySlice.reducer;
