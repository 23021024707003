import { Box, Button, Container } from "@mui/material";
import { Link } from "react-router-dom";
import "../Footer/Footer.scss";
import * as React from "react";
import Grid from "@mui/material/Grid";
import FacebookIcon from "../../../assets/Images/social-icon/facebook-icon.svg";
import InstagramIcon from "../../../assets/Images/social-icon/instagram-icon.svg";
import LinkedInIcon from "../../../assets/Images/social-icon/linkedin-icon.svg";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function Footer() {
  const { t } = useTranslation();

  const aboutUs = [
    {
      head: t("footer1.0"),
      body: t("footer1", { returnObjects: true }),
      link: "/how-it-works",
    },
  ];
  const ourServices = [
    {
      head: t("footerHead1"),
      body: t("footer2", { returnObjects: true }),
      link: ["/inner-lowest-fees", "/contact-us"],
    },
  ];
  const legal = [
    {
      head: t("footer3.0"),
      body: t("footer3", { returnObjects: true }),
      link: "/inner-service-page",
    },
  ];
  const region = [
    {
      head: t("footer4.0"),
      body: t("footer4", { returnObjects: true }),
      link: "/legal-mentions",
    },
  ];

  const help = [
    {
      head: "Help",
      body: t("FAQhelp", { returnObjects: true }),
      link: "/",
    },
  ];
  const socialMedia = [
    {
      body: [
        <a
          href="https://www.facebook.com/profile.php?id=100092561656535"
          target="_blank"
        >
          <img src={FacebookIcon} alt="FacebookIcon" />
        </a>,
        <a href="https://www.linkedin.com/company/likelemba/" target="_blank">
          <img src={LinkedInIcon} alt="LinkedInIcon" />
        </a>,
        <a href="https://www.instagram.com/likelemba1/" target="_blank">
          <img src={InstagramIcon} alt="InstagramIcon" />
        </a>,
      ],
    },
  ];

  function FormInnerRow(data) {
    return data.map((list) => {
      return (
        <>
          <Box className="footer" sx={{ p: 0 }}>
            <ul className="menu social-menu">
              {list.body?.map((subHead) => {
                return (
                  <li className="social-list">
                    <Link to={subHead[1]}> {subHead[0]}</Link>
                  </li>
                );
              })}
            </ul>
            <ul></ul>
          </Box>
        </>
      );
    });
  }

  function SocialInnerRow(data) {
    return data.map((list) => {
      return (
        <>
          <Box
            className="footer menu social-menu social-list"
            sx={{ padding: { md: "0 10px", xs: "0px" }, display: "flex" }}
          >
            {list.body?.map((subHead) => {
              return <Box> {subHead}</Box>;
            })}
          </Box>
        </>
      );
    });
  }

  return (
    <>
      <Box>
        <Box sx={{ flexGrow: 1 }} className="footer">
          <Container maxWidth="xl" spacing={1}>
            <Grid container item spacing={1} className="upperList">
              <Grid item xs={6} md={2}>
                {FormInnerRow(aboutUs)}
              </Grid>
              <Grid item xs={6} md={2}>
                {FormInnerRow(ourServices)}
              </Grid>
              <Grid item xs={6} md={2}>
                {FormInnerRow(legal)}
              </Grid>
              <Grid item xs={6} md={2}>
                {FormInnerRow(region)}
              </Grid>
              <Grid item xs={6} md={2}>
                {FormInnerRow(help)}
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                sx={{
                  color: "#7d0cee",
                  placeContent: "center",
                  display: { md: "flex", xs: "block" },
                  alignItems: "center",
                }}
                className="icons"
              >
                {SocialInnerRow(socialMedia)}
              </Grid>
            </Grid>
          </Container>
        </Box>
        <div className="footer-copyright">
          <p>
            {`${t("footerHead4CopyText")} © ${moment().format("YYYY")} `}
            <a
              href="http://makav-sd.com/"
              style={{ textDecoration: "underLine", color: "#fff" }}
              rel="noreferrer"
              target="_blank"
            >
              makav-sd.com
            </a>
            {` ${t("footerHead4AllText")}`}
          </p>
        </div>
      </Box>
    </>
  );
}
