import * as React from "react";
import "./Login.scss";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormHelperText, Grid, Typography } from "@mui/material";
import { loginUser, UserFacebookCode } from "../../store/thunk/userThunk";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { ColorRing } from "react-loader-spinner";
import GoogleLoginButton from "./GoogleLogin/GoogleLogin";
import i18Next from "../../i18Next";

export default function SimplePaper() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const { t } = useTranslation();

  React.useEffect(() => {
    i18Next.changeLanguage(
      localStorage.getItem("language") ? localStorage.getItem("language") : "fr"
    );
  }, []);

  React.useEffect(() => {
    if (code) {
      dispatch(
        UserFacebookCode({
          code: code,
          t,
          callback: () => {
            navigate("/sign-up");
          },
          callback2: () => {
            navigate("/admin");
          },
          callback3: () => {
            navigate("/sign-in/");
          },
        })
      );
    }
  }, [code]);

  const [formError, setFormError] = React.useState([]);
  const [formValue, setFormValue] = React.useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);

  const auth = useAppSelector((state) => state.userSlice);

  const handleChange = (name, event) => {
    setFormValue({
      ...formValue,
      [name]: event.target.value,
    });
  };

  var mailformat =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

  const validateForm = (data) => {
    let error = [];

    if (data.email === "") {
      error.push("email");
    }
    if (!data.email.match(mailformat)) {
      error.push("email");
    }
    if (data.password === "") {
      error.push("password");
    }
    setFormError(error);

    return error;
  };

  const handleLogin = () => {
    let error = validateForm(formValue);
    if (error.length == 0) {
      let request = {
        ...formValue,
        social_account: 0,
      };
      dispatch(
        loginUser({
          request,
          t,
          callback: (is_SuperUser) => {
            is_SuperUser ? navigate("/admin-dashboard") : navigate("/admin");
          },
        })
      );
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  async function handleFBLogin(user) {
    window.open(
      `https://www.facebook.com/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&scope=email&display=popup&redirect_uri=${window.location.href}`,
      "_self"
    );
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <>
      <Box
        fullWidth
        sx={{
          background: "rgb(89, 32, 145)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            zIndex: 1,
            background: "#f1efeb",
            padding: { md: 4, xs: 2 },
            margin: "75px 8px",
            mt: { md: "100px", xs: "65px" },
            maxWidth: { xs: "265px", md: "400px" },
            width: "100%",
          }}
          className="LogInForm"
        >
          <Grid sx={{ width: "100%" }}>
            <Grid item>
              <Typography variant="h6" align="left">
                {t("loginPage.0")}
              </Typography>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    // maxWidth: "360px",
                    width: "100%",
                  }}
                >
                  <input
                    type="password"
                    name="fake-password"
                    autoComplete="new-password"
                    tabIndex="-1"
                    style={{ display: "none" }}
                  />
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-email" required>
                      {t("loginPage.2")}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-email"
                      error={formError.includes("email")}
                      value={formValue.email}
                      onChange={(e) => {
                        handleChange("email", e);
                      }}
                      endAdornment={
                        <InputAdornment position="end"></InputAdornment>
                      }
                      label={t("loginPage.2")}
                      onKeyDown={handleKeyDown}
                    />
                    {formError.includes("email") && (
                      <FormHelperText id="component-error-text" error>
                        {t("emailValidation")}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password" required>
                      {t("loginPage.3")}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      error={formError.includes("password")}
                      type={showPassword ? "text" : "password"}
                      value={formValue.password}
                      onChange={(e) => handleChange("password", e)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label={t("loginPage.3")}
                      onKeyDown={handleKeyDown}
                    />
                    {formError.includes("password") && (
                      <FormHelperText id="component-error-text" error>
                        {t("createAccountPasswordValidationLable")}
                      </FormHelperText>
                    )}
                    <Link className="forgetPassword" to="/forget-password">
                      {t("loginPage.1")}
                    </Link>
                  </FormControl>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ padding: 2, maxHeight: "50px" }}
                    onClick={handleLogin}
                    onKeyPress={handleKeypress}
                    disabled={formValue.username && formValue.password === ""}
                  >
                    {auth.loading ? (
                      <ColorRing
                        visible={true}
                        height="50"
                        width="50"
                        ariaLabel="blocks-loading"
                        wrapperClass="blocks-wrapper"
                        colors={[
                          "#e15b64",
                          "#f47e60",
                          "#f8b26a",
                          "#abbd81",
                          "#849b87",
                        ]}
                      />
                    ) : (
                      t("login")
                    )}
                  </Button>
                </Box>
              </Box>
              <Box className="newMember">
                <Box>
                  {t("loginPage.4")}
                  <Link to="/sign-up"> {t("loginPage.5")}</Link>
                </Box>
              </Box>
              <Typography variant="h6" mt="2rem" align="center" sx={{ mb: 1 }}>
                {t("loginPage.6")}
              </Typography>
              <Box
                className="otherSignInoption"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Button
                  onClick={handleFBLogin}
                  variant="contained"
                  sx={{ background: "#4267B2", padding: "6px 24px" }}
                >
                  {t("loginPage.7")}
                </Button>
                <Button>
                  <GoogleLoginButton />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}
