import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./DescriptionContentDialog.scss";

export default function ContentDialog({ closeFunc, dialogContent }) {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog open onClose={closeFunc} fullWidth maxWidth="sm">
        <DialogTitle>{t("kittyUpdateContentLable")}</DialogTitle>
        <DialogContent dividers sx={{ minHeight: "250px" }}>
          <Box
            className="content-text-box"
            dangerouslySetInnerHTML={{ __html: dialogContent }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeFunc}
            sx={{
              fontSize: "12px",
              background: "#fff",
              color: "#592091",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
