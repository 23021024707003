import React from "react";
import { Paper, Box } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function DocumentSection({ title, description,documentFunc }) {
  return (
    <Paper elevation={0} className="document-section" onClick={documentFunc}>
      <Box>
        <Box className="document-list-label">{title}</Box>
        <Box className="document-list-description">{description}</Box>
      </Box>
      <Box sx={{ mr: "15px", mt: "5px" }}>
        <KeyboardArrowRightIcon />
      </Box>
    </Paper>
  );
}

export default DocumentSection;
