import React from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../../../store/reducer/User/UserProfileSlice";
import { useAppSelector } from "../../../../store/store";
import { updateUserProfile } from "../../../../store/thunk/newUserThunk";

function NewsLetter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const profileDetails = useAppSelector((state) => state?.UserProfile?.details);

  React.useEffect(() => {
    setAcceptTerms(profileDetails?.is_notify);
  }, [profileDetails]);

  const handleChange = (event) => {
    setAcceptTerms(event.target.checked);
  };

  const handleSubmit = () => {
    dispatch(
      updateUserProfile({
        request: { is_notify: acceptTerms },
        user_id: profileDetails?.id,
        callback: (response) => {
          dispatch(
            setUserDetails({
              ...profileDetails,
              is_notify: response?.is_notify,
            })
          );
        },
      })
    );
  };

  return (
    <Grid container>
      <Grid xs={12}>
        <Grid
          item
          xs={12}
          sx={{
            display: { md: "flex", xs: "block" },
            justifyContent: " center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptTerms}
                name="confirm_document"
                onChange={handleChange}
              />
            }
            label={
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ color: "inherit" }}
              >
                {t("notificationTextLbl")}
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: { md: "flex", xs: "block" },
            justifyContent: " center",
          }}
        >
          <Button
            onClick={() => handleSubmit()}
            sx={{
              background: "#592091",
              color: "#fff",
              display: "block",
              marginTop: "10px",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
              },
              "&:disabled": {
                color: "#fff",
                background: "#876ba3",
              },
            }}
          >
            {t("submitLabel")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default NewsLetter;
