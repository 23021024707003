import React from "react";
import UserSettings from "../components/User/adminDashboard/UserManagement/UserSettings/index";
import { useNavigate } from "react-router-dom";

function AdminUserManagementList() {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (localStorage.getItem("is_superuser") == "false") {
      navigate("/admin");
    }
  }, []);
  return (
    <>
      <UserSettings />
    </>
  );
}
export default AdminUserManagementList;
