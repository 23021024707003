import { Box, Button, Chip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { GetCurrentCycleDetails } from "../../../../store/thunk/Tontine/MytontineThunk";

function CycleDetails(props) {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const list = useAppSelector((state) => state.MyTontine?.currentCycleDetails);
  const loginUserDetails = useAppSelector(
    (state) => state?.UserProfile?.details
  );

  useEffect(() => {
    dispatch(GetCurrentCycleDetails(id));
  }, []);

  const TempID = list?.results?.length > 0 ? list.results[0]?.tontine.id : null;

  return (
    <>
      <Box
        sx={{
          height: { md: "360px", xs: "360px" },
          overflow: "auto",
          pr: { md: 0, xs: "10px" },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#d5c7e3",
            p: 2,
            pt: "1px",
            m: { md: 2, xs: "16px 0px" },
          }}
        >
          {list?.results?.length > 0 && id == TempID ? (
            list?.results?.map((item) => {
              return (
                <>
                  <Box
                    sx={
                      item?.payment_status === "PAID"
                        ? {
                            margin: { md: "16px 8px", xs: "12px 0px" },
                            borderRadius: "16px",
                            padding: { md: "15px", xs: "15px" },
                            fontFamily: "inherit",
                            color: "#3c763d",
                            backgroundColor: "#dff0d8",
                            border: "2px solid #3c763d",
                          }
                        : item?.payment_status === "OPEN"
                        ? {
                            margin: { md: "16px 8px", xs: "12px 0px" },
                            borderRadius: "16px",
                            padding: { md: "15px", xs: "15px" },
                            fontFamily: "inherit",
                            color: "#a94442",
                            backgroundColor: "#f2dede",
                            border: "2px solid #a94442",
                          }
                        : {
                            margin: { md: "16px 8px", xs: "12px 0px" },
                            borderRadius: "16px",
                            padding: { md: "15px", xs: "15px" },
                            fontFamily: "inherit",
                            color: "#31708f",
                            backgroundColor: "#d9edf7",
                            border: "2px solid #31708f",
                          }
                    }
                  >
                    <Box>
                      <Box
                        sx={{
                          m: "unset",
                          p: "unset",
                          textAlign: "right",
                        }}
                      >
                        <Box
                          sx={{
                            m: "unset",
                            p: "unset",
                            textAlign: { md: "right", xs: "center" },
                          }}
                        >
                          <Chip
                            sx={
                              item?.payment_status === "PAID"
                                ? {
                                    color: "#dff0d8",
                                    backgroundColor: "#3c763d",
                                  }
                                : item?.payment_status === "OPEN"
                                ? {
                                    borderRadius: "16px",
                                    fontFamily: "inherit",
                                    color: "#f8d7da",
                                    backgroundColor: "#a94442",
                                  }
                                : {
                                    color: "#d9edf7",
                                    backgroundColor: "#31708f",
                                  }
                            }
                            label={
                              item?.payment_status === "PAID"
                                ? t("CycleDetailsTab.0")
                                : item.invoice_link !== null &&
                                  item?.payment_status === "OPEN"
                                ? t("CycleDetailsTab.1")
                                : t("CycleDetailsTab.2")
                            }
                            variant="outlined"
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: { md: "row", xs: "column" },
                        }}
                      >
                        <Box
                          className="current-cycle-details-box"
                          sx={{
                            fontSize: "18px",
                            fontWeight: "600",
                            textAlign: "left",
                          }}
                        >
                          <Box>
                            <Typography variant="p">
                              {t("myAccountLastNameLable")}:
                            </Typography>
                            <Typography variant="p" sx={{ pl: 1 }}>
                              {`${item?.user?.first_name} ${item?.user?.name}`}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="p">
                              {item?.payment_status === "OPEN"
                                ? t("CycleDetailsTab.3")
                                : t("CycleDetailsTab.4")}
                            </Typography>
                            <Typography variant="p" sx={{ pl: 1 }}>
                              {item?.payment_date === null
                                ? t("CycleDetailsTab.5")
                                : `${moment(item?.payment_date).format(
                                    localStorage.getItem("language") == "en"
                                      ? "YYYY-MM-DD"
                                      : "DD-MM-YYYY"
                                  )}, ${moment(item?.payment_date).format(
                                    "HH:MM A"
                                  )}`}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space",
                            alignItems: "center",
                          }}
                        >
                          {item?.payment_status !== "NOTPAID" &&
                          item?.user?.id === loginUserDetails?.id ? (
                            <Box>
                              <Button
                                onClick={() => {
                                  if (item.invoice_link !== null) {
                                    window.open(
                                      `${item.invoice_link}`,
                                      "_blank"
                                    );
                                  }
                                }}
                                sx={{
                                  color: "#fff",
                                  border: "1px solid #fff",
                                  borderRadius: "16px",
                                  padding: "0 48px",
                                  backgroundColor: "#592091",
                                  margin: "8px 0 0 0",
                                  "&:hover": {
                                    backgroundColor: "#8392d6",
                                  },
                                }}
                              >
                                {item?.payment_status === "OPEN"
                                  ? t("CycleDetailsTab.6")
                                  : t("CycleDetailsTab.7")}
                              </Button>
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              );
            })
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  placeContent: "center",
                  padding: "4rem 1rem",
                }}
              >
                <Typography variant="h5">
                  {t("TontineStartedValidationLable")}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default CycleDetails;
