import { createSlice } from "@reduxjs/toolkit";

let token = localStorage.getItem("UserInfo");
let isSuperUser = localStorage.getItem("is_superuser");

const initialState = {
  loading: false,
  UserLoader: false,
  error: "",
  success: "",
  token: token || "",
  newPassword: {},
  FacebookData: {},
  ValidationUpdate: {},
  resetEmailLink: {},
  is_superuser: isSuperUser || "",
  adminUserLogList: { result: [] },
  adminUserPasswordLogList: { result: [] },
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUserData: (state, action) => ({
      ...state,
      token: action.payload,
    }),
    setFBUserData: (state, action) => ({
      ...state,
      FacebookData: action.payload,
    }),
    setLoginLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setUserLoader: (state, action) => ({
      ...state,
      UserLoader: action.payload,
    }),
    setSuccess: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    setError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    setSuccessFlag: (state, action) => ({
      ...state,
      success: false,
    }),
    setNewPassword: (state, action) => ({
      ...state,
      newPassword: action.payload,
    }),
    setNewEmailLink: (state, action) => ({
      ...state,
      resetEmailLink: action.payload,
    }),
    ValidationUpdate: (state, action) => ({
      ...state,
      ValidationUpdate: action.payload,
    }),
    SetNewUserCheck: (state, action) => ({
      ...state,
      newUserCheck: action.payload,
    }),
    setAdminadminUserLogList: (state, action) => ({
      ...state,
      adminUserLogList: action.payload,
    }),
    setAdminUserPasswordLogList: (state, action) => ({
      ...state,
      adminUserPasswordLogList: action.payload,
    }),
  },
});

export const {
  setUserData,
  setFBUserData,
  setNewEmailLink,
  SetNewUserCheck,
  setLoginLoader,
  setSuccess,
  setUserLoader,
  setError,
  setSuccessFlag,
  setNewPassword,
  ValidationUpdate,
  setUserDataLocal,
  setAdminadminUserLogList,
  setAdminUserPasswordLogList,
} = userSlice.actions;

export default userSlice.reducer;
