import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  NewUserloading: false,
  error: null,
  success: false,
  newUser: {
    email: "",
    username: "",
    password: "",
    first_name: "",
    name: "",
    city: "",
    bith_date: "",
    address: "",
    postal_code: "",
    country: "",
    confidence_index: "",
  },
  contributeResponse: {},
  documentUploadResponse: {},
  contributePaymentResponse: {},
};

const NewUserSlice = createSlice({
  name: "newUserSlice",
  initialState,
  reducers: {
    setNewUserData: (state, action) => ({
      ...state,
      newUser: action.payload,
      success: true,
    }),
    setUserLoading: (state, action) => ({
      ...state,
      NewUserloading: action.payload,
    }),
    setNewUserError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    setContributeResponse: (state, action) => ({
      ...state,
      contributeResponse: action.payload,
    }),
    setDocumentUploadResponse: (state, action) => ({
      ...state,
      documentUploadResponse: action.payload,
    }),
    setContributePaymentResponse: (state, action) => ({
      ...state,
      contributePaymentResponse: action.payload,
    }),
  },
});

export const {
  setNewUserData,
  setNewUserError,
  setContributeResponse,
  setDocumentUploadResponse,
  setUserLoading,
  setContributePaymentResponse,
} = NewUserSlice.actions;

export default NewUserSlice.reducer;
