import { Button } from "@mui/material";
import React from "react";
import { CheckoutForm } from "../../store/thunk/Payments/CheckOutThunk";
import { useAppDispatch } from "../../store/store";
import { useNavigate } from "react-router-dom";

function CheckOut() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        dispatch(CheckoutForm({ navigate: navigate }));
      }}
    >
      Checkout
    </Button>
  );
}

export default CheckOut;
