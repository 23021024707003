import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setNewUserData,
  setNewUserError,
  setContributeResponse,
  setDocumentUploadResponse,
  setUserLoading,
  setContributePaymentResponse,
} from "../reducer/User/NewUserSlice";
import axios from "axios";
import { setSuccessAlert, setErrorAlert } from "../reducer/AlertSlice";
import {
  setUserDetails,
  setUserDetailsSuccess,
} from "../reducer/User/UserProfileSlice";
import { DeleteData } from "../reducer/User/TokenExpireSlice";
import { TabIndexData } from "../reducer/KittySlice/CreateKittySlice";
import {
  CheckUserDocumentStatus,
  CheckUserDocumentVerifyStatus,
} from "./Tontine/MytontineThunk";
import { ListOfAllUsers } from "./Admin/UserListThunk";
import { CheckoutForm } from "./Payments/CheckOutThunk";
import { UserProfileDetails } from "./UserProfileThunk";
import { setUserLoader } from "../reducer/User/SliceUser";

let guestUserToken = "";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Authorization"] = localStorage.getItem("UserInfo")
  ? `Token ${localStorage.getItem("UserInfo")}`
  : "";

export const signUpNewUser = createAsyncThunk(
  "signUpNewUser",
  async (_request, { dispatch }) => {
    dispatch(setUserLoading(true));
    dispatch(setUserLoader(true));
    axios
      .post("/api/v1/accounts/users/", _request.request, {
        headers: {
          Authorization: localStorage.getItem("UserInfo")
            ? `Token ${localStorage.getItem("UserInfo")}`
            : "",
        },
      })
      .then((response) => {
        dispatch(setNewUserData(response.user));
        dispatch(ListOfAllUsers({ limit: 10, offSet: 0 }));
        dispatch(setUserLoading(false));
        dispatch(setUserLoader(false));
        _request.callback();
      })
      .catch((err) => {
        dispatch(setUserLoading(false));
        dispatch(setUserLoader(false));
        dispatch(setNewUserError(err?.response?.data));
        err?.response?.data?.email &&
          dispatch(setErrorAlert(err?.response?.data?.email[0]));
        err?.response?.data?.birth_date &&
          dispatch(setErrorAlert(err?.response?.data?.birth_date[0]));
        err?.response?.data?.message &&
          dispatch(setErrorAlert(err?.response?.data?.message));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const updateUserProfile = createAsyncThunk(
  "updateUserProfile",
  async (_request, { dispatch }) => {
    dispatch(setUserLoader(true));
    axios
      .patch(`/api/v1/accounts/users/${_request.user_id}/`, _request.request, {
        headers: {
          Authorization: localStorage.getItem("UserInfo")
            ? `Token ${localStorage.getItem("UserInfo")}`
            : "",
        },
      })
      .then((response) => {
        const {
          id,
          username,
          first_name,
          name,
          email,
          avatar,
          birth_date,
          address,
          postal_code,
          city,
          phone_number,
          country,
          is_verified,
          is_superuser,
          social_account,
          is_notify,
        } = response?.data;
        dispatch(ListOfAllUsers({ limit: 10, offSet: 0 }));
        dispatch(setSuccessAlert("Profile updated successfully"));
        dispatch(setUserLoader(false));
        dispatch(UserProfileDetails());
        // key check for notify update request
        if (_request?.callback) {
          _request?.callback({
            id,
            username,
            first_name,
            name,
            email,
            avatar,
            birth_date,
            address,
            postal_code,
            city,
            phone_number,
            country,
            is_verified,
            is_superuser,
            social_account,
            is_notify,
          });
        }
        dispatch(setUserDetails({ request: _request?.callbackRedirect() }));
        if ("is_notify" in _request?.request) {
          dispatch(setSuccessAlert("Preference updated successfully"));
        } else {
          dispatch(setSuccessAlert("Profile updated successfully"));
        }
        dispatch(setUserDetailsSuccess(true));
        dispatch(TabIndexData(2));
      })
      .catch((err) => {
        dispatch(setUserLoader(false));
        dispatch(setErrorAlert(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token." ||
          err?.response?.data?.detail === "Invalid token"
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const updateUserProfileImage = createAsyncThunk(
  "updateUserProfileImage",
  async (_request, { dispatch, getState }) => {
    const formData = new FormData();
    if (_request.request.image && _request.request.image.name) {
      formData.append("avatar", _request.request.image);
    }
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    axios
      .patch(`/api/v1/accounts/users/${_request.user_id}/`, formData, {
        headers: {
          Authorization: localStorage.getItem("UserInfo")
            ? `Token ${localStorage.getItem("UserInfo")}`
            : "",
        },
      })
      .then((response) => {
        dispatch(setSuccessAlert("Profile updated successfully"));
        const { UserProfile } = getState();
        dispatch(
          setUserDetails({
            ...UserProfile?.details,
            avatar: response?.data?.avatar,
          })
        );
        _request.callback();
      })
      .catch((err) => {
        dispatch(setErrorAlert(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const addContributeWithUserData = createAsyncThunk(
  "addContributeUserData",
  async (_request, { dispatch }) => {
    const { user, kitty } = _request;
    const { amount } = _request.request;
    const payment_intent_id = _request.payment_intent_id;
    axios
      .post("/api/v1/kitty/kitty-contribute/", {
        user: user,
        kitty,
        amount,
        payment_intent_id,
      })
      .then((response) => {
        dispatch(setUserLoading(false));
        dispatch(setContributeResponse(response?.data));
        dispatch(setSuccessAlert("Contribution added successfully"));
        _request.callback({ id: response?.data?.id, token: guestUserToken });
      })
      .catch((err) => {
        dispatch(setUserLoading(false));
        dispatch(setContributeResponse(err));
        dispatch(setErrorAlert("Something went wrong"));
        dispatch(setErrorAlert(err?.response?.data?.email[0]));
      });
  }
);

export const ContributeWithUserData = createAsyncThunk(
  "contributeWithUserData",
  async (_request, { dispatch }) => {
    axios
      .get(`/api/v1/kitty/kitty-contribute/${_request}/`)
      .then((response) => {
        dispatch(setUserLoading(false));
        dispatch(setContributeResponse(response?.data));
        dispatch(UserProfileDetails());
      })
      .catch((err) => {
        dispatch(setUserLoading(false));
        dispatch(setContributeResponse(err));
        dispatch(setErrorAlert(err?.response?.data?.email[0]));
      });
  }
);

export const UpdateContributeWithUserData = createAsyncThunk(
  "contributeWithUserData",
  async (_request, { dispatch }) => {
    axios
      .patch(`/api/v1/kitty/kitty-contribute/${_request.id}/`, _request.data, {
        headers: {
          Authorization:
            _request.token !== "null"
              ? `Token ${_request.token}`
              : localStorage.getItem("UserInfo"),
        },
      })
      .then((response) => {
        dispatch(setUserLoading(false));
        dispatch(setContributeResponse(response?.data));
      })
      .catch((err) => {
        dispatch(setUserLoading(false));
        dispatch(setContributeResponse(err));
        dispatch(setErrorAlert(err?.response?.data?.email[0]));
      });
  }
);

export const addContributeUserData = createAsyncThunk(
  "addContributeUserData",
  async (_request, { dispatch }) => {
    const { first_name, name, email, birth_date } = _request.request;
    axios.defaults.headers["Content-Type"] = "application/json";
    axios
      .post("/api/v1/accounts/guest-user/", {
        first_name,
        name,
        email,
        birth_date,
      })
      .then((response) => {
        _request.user = response?.data?.user?.id;
        guestUserToken = response.data?.token;
        const customer = response.data?.user?.stripe_customer_id;
        const newRequest = {
          ..._request,
          token: guestUserToken,
          customer: customer,
        };
        dispatch(CheckoutForm(newRequest));
        localStorage.setItem("guestUserToken", guestUserToken);
        localStorage.setItem("kittyContributeData", JSON.stringify(newRequest));
      })
      .catch((err) => {
        dispatch(setErrorAlert(err?.response?.data?.email[0]));
      });
  }
);

export const checkUserData = createAsyncThunk(
  "checkUserData",
  async (_request, { dispatch }) => {
    const { email } = _request.request;
    axios.defaults.headers["Content-Type"] = "application/json";
    axios
      .post("/api/v1/accounts/check-user-account/", {
        email,
      })
      .then((response) => {
        if (response?.data?.is_user) {
          let requestData = {
            ..._request,
            customer: response.data?.stripe_customer_id,
          };
          requestData.user = response?.data?.user_id;
          dispatch(CheckoutForm(requestData));
          // dispatch(addContributeWithUserData(requestData));
          localStorage.setItem(
            "kittyContributeData",
            JSON.stringify(requestData)
          );
        } else {
          dispatch(addContributeUserData(_request));
        }
      })
      .catch((err) => {
        dispatch(setErrorAlert(err?.response?.data?.email[0]));
      });
  }
);

export const addContributeData = createAsyncThunk(
  "addContributeData",
  async (_request, { dispatch }) => {
    dispatch(setUserLoading(true));
    if (_request.user === "") {
      dispatch(checkUserData(_request));
    } else {
      dispatch(addContributeWithUserData(_request));
    }
  }
);

export const uploadUserDocument = createAsyncThunk(
  "uploadUserDocument",
  async (_request, { dispatch, getState }) => {
    dispatch(setUserLoading(true));
    const formData = new FormData();
    formData.append("user", _request.user);
    /* formData.append("account_no", "123");
    formData.append("debit_auth", "123");
    formData.append("passport_number", "12345678");
    formData.append("payment_info", "Upload"); */

    if (_request.request.image && _request.request.image.length > 0) {
      for (const key of Object.keys(_request.request.image)) {
        formData.append("passport_image", _request.request.image[key]);
      }
    }
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    axios
      .post(`/api/v1/tontine/tontine-document/`, formData)
      .then((response) => {
        dispatch(setDocumentUploadResponse(response));
        dispatch(setUserLoading(false));
        dispatch(CheckUserDocumentStatus());
        dispatch(CheckUserDocumentVerifyStatus());
        dispatch(setSuccessAlert("Document uploaded successfully"));
        _request.callback();
      })
      .catch((err) => {
        dispatch(setErrorAlert(err));
        dispatch(setUserLoading(false));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const DeleteTontineDocument = createAsyncThunk(
  "DeleteTontineDocument",
  async (_request, { dispatch }) => {
    axios
      .delete(`api/v1/tontine/tontine-document/${_request.id}/`)
      .then(() => {})
      .catch((err) => {
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const updateContributeUser = createAsyncThunk(
  "updateContributeUser",
  async (_request, { dispatch, getState }) => {
    axios
      .patch(`api/v1/accounts/users/${_request.id}/`, _request.data, {
        headers: {
          Authorization:
            _request.token !== "null"
              ? `Token ${_request.token}`
              : localStorage.getItem("UserInfo"),
        },
      })
      .then((response) => {
        if (_request.callback) {
          _request.callback();
        }
      })
      .catch((err) => {
        dispatch(setErrorAlert(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const getContributePaymentInfo = createAsyncThunk(
  "getContributePaymentInfo",
  async (_request, { dispatch }) => {
    axios
      .post(`/api/v1/checkout/generate-charge-receipt/`, _request.request)
      .then((response) => {
        dispatch(setUserLoading(false));
        dispatch(setContributePaymentResponse(response?.data));
      })
      .catch((err) => {
        dispatch(setUserLoading(false));
        dispatch(setContributePaymentResponse(err));
      });
  }
);

export const sendContactDetails = createAsyncThunk(
  "sendContactDetails",
  async (_request, { dispatch }) => {
    const formData = new FormData();
    formData.append("name", _request?.request.contact_name);
    formData.append("email", _request?.request.contact_email);
    formData.append("details", _request?.request.description);
    dispatch(setUserLoading(true));
    axios
      .post("api/v1/accounts/contact-email-send/", formData)
      .then((response) => {
        dispatch(setUserLoading(false));
        dispatch(setSuccessAlert(response?.data?.message));
        _request.callback();
      })
      .catch((err) => {
        dispatch(setUserLoading(false));
      });
  }
);
