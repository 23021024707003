import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Paper,
  MenuItem,
  Menu,
} from "@mui/material";
import { useState } from "react";
import BlankProfilePicture from "../../../../assets/Images/no-picture.webp";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  DetailsOfKitty,
  DeleteCommentKitty,
} from "../../../../store/thunk/Kitty/KittyDetailsThunk";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

export default function Messages(id) {
  const kittyId = id.id;
  const dispatch = useAppDispatch();

  const list = useAppSelector((state) => state.DetailsOfKitty.list);
  const [anchorEl, setAnchorEl] = useState(null);

  // const messageNew = messageDate[0];
  const profileDetails = useAppSelector((state) => state?.UserProfile?.details);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const MoreOptions = (del) => {
    const { t } = useTranslation();

    return (
      <Box>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <>
              <Button
                variant="text"
                {...bindTrigger(popupState)}
                sx={{ padding: 0, color: "#000", justifyContent: " end" }}
              >
                {del.id.user === profileDetails?.id || list?.hide_option ? (
                  <MoreHorizIcon />
                ) : null}
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    dispatch(
                      DeleteCommentKitty({ id: del.id.id, kitty: kittyId })
                    );
                    dispatch(DetailsOfKitty(kittyId));
                  }}
                  sx={{ fontSize: "14px" }}
                >
                  {t("deleteLable")}
                </MenuItem>
              </Menu>
            </>
          )}
        </PopupState>
      </Box>
    );
  };

  return (
    <Box className="contributions-block">
      <Container maxWidth="lg" sx={{ p: 0 }}>
        {[...list?.kitty_for_comment].reverse().map((item) => (
          <Paper
            sx={{
              margin: "5px 0px 20px",
              padding: "20px",
              background: "#59209140",
            }}
          >
            <Grid container sx={{ p: 0, alignItems: "center" }}>
              <Grid item md={2} sx={{ p: "0px 20px", mb: 2 }}>
                {" "}
                <img
                  src={item.imgPath || BlankProfilePicture}
                  alt="contribution"
                  loading="lazy"
                  style={{
                    height: "52px",
                    width: "52px",
                    border: "1px solid #ccc",
                    margin: "0 auto",
                    background: "#fff",
                    display: "table",
                    borderRadius: "50%",
                  }}
                />
              </Grid>
              <Grid item md={10}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "13px",
                        fontWeight: "600",
                        color: "#555",
                      }}
                    >
                      {`${item.user.first_name} ${item.user.name}`}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "#666", fontSize: "13px", ml: 1 }}
                    >
                      {moment(item.created_at).format(
                        localStorage.getItem("language") == "en"
                          ? "YYYY-MM-DD hh:mm A"
                          : "DD-MM-YYYY hh:mm A"
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <MoreOptions id={item} />
                  </Box>
                </Box>
                <Typography
                  variant="h6"
                  sx={{ color: "#666", fontSize: "14px", m: "10px 0px" }}
                >
                  {item.comment}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Container>
    </Box>
  );
}
