import * as React from "react";
import Header from "../Header/index";
import Footer from "../Footer/index";
import ActivationLink from "./ActivationLink";

export default function ActivationLinkComponent() {
  return (
    <>
      <Header />
      <ActivationLink />
      <Footer />
    </>
  );
}
