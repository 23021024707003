import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CheckUserPasswordData,
  setNewPasswordData,
} from "../reducer/Password/NewPasswordSlice";
import axios from "axios";
import { setSuccessAlert } from "../reducer/AlertSlice";
import { setNewPassword } from "../reducer/User/SliceUser";
import { DeleteData } from "../reducer/User/TokenExpireSlice";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";

export const newPassword = createAsyncThunk(
  "newPassword",
  async (_request, { dispatch }) => {
    axios
      .post(
        `api/v1/accounts/reset-password-confirm/${_request.request.request.uid}/${_request.request.request.token}/`,
        _request.request.request.password
      )
      .then((response) => {
        dispatch(setNewPasswordData(response));
        _request.request.callback();
        dispatch(setSuccessAlert("Password changed successfully"));
        dispatch(setNewPassword(response));
      })
      .catch((err) => {
        if (
          err?.response?.data?.detail === "The  expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const userTokenCheckPasswordChange = createAsyncThunk(
  "userTokenCheckPasswordChange",
  async (_request, { dispatch }) => {
    axios
      .get(`api/v1/accounts/token-verify/${_request.uid}/${_request.token}/`)
      .then((response) => {
        dispatch(CheckUserPasswordData(response));
      })
      .catch((err) => {
        dispatch(CheckUserPasswordData(err.response.data));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
