import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box, Chip } from "@mui/material";
import { createTheme } from "react-data-table-component";
import moment from "moment";
import DataDialog from "../DescriptionContentDialog";
import DataTable from "../../../../../../components/common/DataTableBase";
import { useAppDispatch, useAppSelector } from "../../../../../../store/store";
import { getListofKittyCommentLog } from "../../../../../../store/thunk/Kitty/KittyDetailsThunk";
import CustomMPagination from "../../../../../common/CustomMPagination";

export default function AdminKittyCommentLogComponent() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = useState(0);
  const [pageCountShow, SetPageCountShow] = useState(0);
  const [recordLimit, SetRecordLimit] = useState(10);
  const [openContentDialog, setOpenContentDialog] = React.useState(false);
  const [contentData, setContentData] = React.useState(null);

  const adminKittyCommentLogData = useAppSelector(
    (state) => state.DetailsOfKitty
  );

  const handleClickOpen = () => {
    setOpenContentDialog(true);
  };
  const handleClose = () => {
    setOpenContentDialog(false);
    setContentData(null);
  };

  const showContentDialog = (content) => {
    setContentData(content);
    handleClickOpen();
  };

  const columns = [
    {
      name: t("kittyCommentNameTitle"),
      minWidth: "200px",
      selector: (row) => row.kitty_name || "-",
    },
    {
      name: t("kittyCommentFieldTitle"),
      minWidth: "200px",
      cell: useCallback(
        (row) => (
          <Chip
            size="small"
            sx={{ width: "180px" }}
            label={t("kittyUpdateSeeDetailTitle")}
            onClick={() => showContentDialog(row.kitty_comment)}
          />
        ),
        []
      ),
    },
    {
      name: t("kittyCommentCreatedDateTitle"),
      selector: (row) =>
        moment(row.kitty_created).format(
          localStorage.getItem("language") == "en" ? "YYYY-MM-DD" : "DD-MM-YYYY"
        ),
    },
    {
      name: t("kittyCommentCreatedByTitle"),
      selector: (row) => row.created_by || "-",
    },
    {
      name: t("actionListLabel"),
      minWidth: "200px",
      selector: (row) => row.action || "-",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    dispatch(
      getListofKittyCommentLog({ limit: recordLimit, offSet: pageCount })
    );
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, recordLimit]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "#ccc",
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "#fff ",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        textAlign: "center",
        fontSize: "17px",
        display: "grid",
        placeContent: "center",
        backgroundColor: "#2a2828",
        color: "#fff",
        maxWidth: "40px",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        padding: "8px",
        fontSize: "14px",
        display: "grid",
        placeContent: "center",
      },
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );

  return (
    <>
      <Box className="kittyTable">
        <Box sx={{ borderRadius: 0, padding: { md: 2, xs: 1 } }}>
          <DataTable
            title={
              <>
                <Box
                  className="tableTitle"
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {t("kittyCommentLogListLabel")}
                </Box>
              </>
            }
            columns={columns}
            data={adminKittyCommentLogData?.adminKittyCommentLogList?.results}
            customStyles={customStyles}
            theme="solarized"
            noDataComponent={<Box sx={{ p: 3 }}>{t("norecordLbl")}</Box>}
            className="rdt_TableHeader"
            progressPending={adminKittyCommentLogData.loading}
          />
          <CustomMPagination
            pageCount={pageCount}
            SetPageCount={SetPageCount}
            recordLimit={recordLimit}
            pageCountShow={pageCountShow}
            SetRecordLimit={SetRecordLimit}
            numRecords={
              adminKittyCommentLogData?.adminKittyCommentLogList?.num_records
            }
            numCount={adminKittyCommentLogData?.adminKittyCommentLogList?.count}
          />
        </Box>
      </Box>
      {openContentDialog && (
        <DataDialog
          openFunc={handleClickOpen}
          closeFunc={handleClose}
          dialogContent={contentData}
        />
      )}
    </>
  );
}
