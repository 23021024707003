import React, { useEffect } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Box,
  FormControl,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  TextField,
  FormLabel,
  Stack,
  FormHelperText,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useTranslation } from "react-i18next";
import OtherImage from "../../../assets/Images/OtherOther.webp";
import { DetailsOfKitty } from "../../../store/thunk/Kitty/KittyDetailsThunk";
import "./contributePayment.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { addContributeData } from "../../../store/thunk/newUserThunk";
import { LoadingButton } from "@mui/lab";
import moment from "moment/moment";
import CheckoutForm from "../../Payments/StripPayments";
var mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

export default function ContributePayment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [formError, setFormError] = React.useState([]);
  const [showContributeThanks, setShowContributeThanks] = React.useState(false);
  const { id } = useParams();
  const kittyDetails = useAppSelector((state) => state.DetailsOfKitty.list);
  const profileDetails = useAppSelector((state) => state?.UserProfile?.details);

  const [contributeData, setContributeData] = React.useState({
    first_name: Object.keys(profileDetails).length
      ? profileDetails?.first_name
      : "",
    name: Object.keys(profileDetails).length ? profileDetails?.name : "",
    email: Object.keys(profileDetails).length ? profileDetails?.email : "",
    birth_date: Object.keys(profileDetails).length
      ? profileDetails?.birth_date
      : "2000-01-01",
    payment_type: "visa",
    amount: "",
  });

  useEffect(() => {
    setContributeData({
      ...contributeData,
      email: profileDetails?.email || "",
      first_name: profileDetails?.first_name || "",
      name: profileDetails?.name || "",
      birth_date: profileDetails?.birth_date || "2000-01-01",
    });
  }, [profileDetails]);

  useEffect(() => {
    dispatch(DetailsOfKitty(id));
  }, []);

  const checkOutData = useAppSelector((state) => state.CheckOut);

  useEffect(() => {
    if (checkOutData?.paymentErrorState) {
      ChangeOpen();
    }
  }, [checkOutData]);

  const handleChangeInput = (text, event) => {
    if (event.target.value > 1000000000 || event.target.value === 0) {
      let temp = formError;
      temp.push("maxValue");
      setFormError([temp]);
      return;
    } else {
      if (text === "amount") {
        // Regex pattern to match up to two decimal places
        const regex = /^(\d*\.?\d{0,2}).*$/;
        const newValue = event.target.value.replace(regex, "$1");

        setContributeData({
          ...contributeData,
          [text]: newValue,
        });
      } else {
        setContributeData({
          ...contributeData,
          [text]: event.target.value,
        });
      }
    }
    setFormError(
      formError.filter(function (e) {
        return e !== text;
      })
    );
  };

  function handleDateChange(newValue) {
    setContributeData({
      ...contributeData,
      birth_date: newValue
        ? `${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`
        : "",
    });

    if (newValue) {
      setFormError(
        formError.filter(function (e) {
          return e !== "birth_date";
        })
      );
    }
  }

  const validateForm = (data) => {
    let error = [];

    if (!data.first_name) {
      error.push("first_name");
    }
    if (!data.name) {
      error.push("name");
    }

    if (!data.email) {
      error.push("email");
    }

    if (!data?.email?.match(mailformat)) {
      error.push("email");
    }

    if (!data.birth_date) {
      error.push("birth_date");
    }

    if (!data.amount) {
      error.push("amount");
    }

    if (!data.payment_type) {
      error.push("payment_type");
    }

    if (data.amount !== "" && data.amount < 1) {
      error.push("amount_value");
    }

    setFormError(error);

    return error;
  };
  const handleSubmit = () => {
    let error = validateForm(contributeData);
    if (error.length == 0) {
      let request = {
        ...contributeData,
      };
      setOpen(true);
      dispatch(
        addContributeData({
          request: request,
          navigate: navigate,
          user: profileDetails?.user?.id || "",
          kitty: id,
          callback: (id) => {
            setShowContributeThanks(true);
            navigate(
              `/after-contribution/${id.id}/${
                id?.token ? id?.token : localStorage.getItem("UserInfo")
              }`
            );
          },
        })
      );
    }
  };

  const updatePaymentData = (event) => {
    setContributeData({
      ...contributeData,
      payment_type: event.target.value,
    });
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const ChangeOpen = () => {
    setOpen(false);
  };

  return (
    <Box className="howItWorkBlock" sx={{ pt: 10, background: "#f1efeb" }}>
      {open && (
        <CheckoutForm
          email={{
            email: contributeData?.email,
            amount: contributeData?.amount,
            ChangeOpen: ChangeOpen,
          }}
        />
      )}
      <Container>
        <Typography variant="h5" className="tontine-title">
          {t("contributePaymentContributeLable")}
        </Typography>
        <Box>
          <Paper
            sx={{
              p: "10px 10px",
              mb: 2,
              display: "flex",
              flexDirection: "rows",
            }}
          >
            <img
              src={kittyDetails ? kittyDetails?.image : OtherImage}
              height="100px"
              width="100px"
              style={{ objectFit: "cover" }}
              alt={"asdasdf"}
            />
            <Box sx={{ m: "4px", display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: "17px",
                  fontWeight: " 600",
                  padding: "10px",
                }}
              >
                {kittyDetails?.name}
              </Typography>
              <Button
                onClick={() => {
                  navigate(`/kittypage/${id}`);
                }}
                sx={{
                  color: "#868484",
                  fontWeight: "600",
                  justifyContent: "left",
                }}
              >
                {"< "}
                {t("back")}
              </Button>
            </Box>
          </Paper>
        </Box>
        <Grid container>
          <Grid container>
            <Grid item md={9} xs={12} sx={{ p: { md: 2, xs: "0" } }}>
              <Paper sx={{ mb: "10px" }}>
                {!showContributeThanks ? (
                  <Box sx={{ p: { xs: 1, md: 2 } }}>
                    <Container maxWidth="xl" sx={{ p: " 0 !important" }}>
                      <Paper
                        sx={{
                          p: 2,
                          width: { md: "95%", xs: "auto" },
                          border: "1px solid #ddd",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              sx={{
                                fontWeight: "600",
                                textTransform: "uppercase",
                                marginBottom: "10px",
                                fontSize: "16px",
                              }}
                            >
                              {t("contributePaymentFirstNameLable")}:
                            </Typography>
                            <TextField
                              margin="dense"
                              size="small"
                              id="first_name"
                              value={contributeData.first_name}
                              onChange={(e) => {
                                handleChangeInput("first_name", e);
                              }}
                              type="text"
                              fullWidth
                              variant="outlined"
                              error={formError.includes("first_name")}
                              helperText={
                                formError.includes("first_name")
                                  ? t(
                                      "contributePaymentFirstNameValidationLable"
                                    )
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              sx={{
                                fontWeight: "600",
                                textTransform: "uppercase",
                                marginBottom: "10px",
                                marginTop: "20px",
                                fontSize: "16px",
                              }}
                            >
                              {t("contributePaymentLastNameLable")}:
                            </Typography>
                            <TextField
                              margin="dense"
                              size="small"
                              id="name"
                              value={contributeData.name}
                              onChange={(e) => {
                                handleChangeInput("name", e);
                              }}
                              type="text"
                              fullWidth
                              variant="outlined"
                              error={formError.includes("name")}
                              helperText={
                                formError.includes("name")
                                  ? t(
                                      "contributePaymentLastNameValidationLable"
                                    )
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              sx={{
                                fontWeight: "600",
                                textTransform: "uppercase",
                                marginBottom: "10px",
                                marginTop: "20px",
                                fontSize: "16px",
                              }}
                            >
                              {t("contributePaymentEmailLable")}:
                            </Typography>
                            <TextField
                              margin="dense"
                              size="small"
                              id="email"
                              value={contributeData.email}
                              onChange={(e) => {
                                handleChangeInput("email", e);
                              }}
                              type="text"
                              fullWidth
                              variant="outlined"
                              error={formError.includes("email")}
                              helperText={
                                formError.includes("email")
                                  ? t("contributePaymentEmailValidationLable")
                                  : ""
                              }
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <FormControl margin="normal">
                              <FormLabel
                                id="demo-controlled-radio-buttons-group"
                                sx={{
                                  fontWeight: "600",
                                  textTransform: "uppercase",
                                  marginBottom: "10px",
                                  fontSize: "16px",
                                  color: "#000",
                                }}
                              >
                                {t("contributePaymentDOBLable")}
                              </FormLabel>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack fullWidth>
                                  <DesktopDatePicker
                                    inputFormat={
                                      localStorage.getItem("language") == "en"
                                        ? "YYYY-MM-DD"
                                        : "DD-MM-YYYY"
                                    }
                                    value={
                                      contributeData?.birth_date || "2000-01-01"
                                    }
                                    onChange={(newValue) =>
                                      handleDateChange(newValue)
                                    }
                                    maxDate={moment().subtract(18, "years")}
                                    minDate={moment().subtract(120, "years")}
                                    renderInput={(params) => (
                                      <TextField
                                        size="small"
                                        {...params}
                                        error={formError.includes("birth_date")}
                                      />
                                    )}
                                    disableFuture={true}
                                    sx={{
                                      ".PrivatePickersYear-yearButton": {
                                        "& .Mui-selected": {
                                          textAlign: "Center",
                                        },
                                      },
                                      "& > fieldset": {
                                        border: "1px solid green",
                                      },
                                    }}
                                  />
                                </Stack>
                              </LocalizationProvider>
                              {formError.includes("birth_date") && (
                                <FormHelperText id="component-error-text" error>
                                  {t("contributePaymentDOBValidationLable")}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              sx={{
                                fontWeight: "600",
                                textTransform: "uppercase",
                                marginBottom: "10px",
                                marginTop: "20px",
                                fontSize: "16px",
                              }}
                            >
                              {t("contributePaymentChooseLable")}:
                            </Typography>
                            <TextField
                              margin="dense"
                              size="small"
                              id="amount"
                              type="number"
                              value={contributeData.amount}
                              placeholder={t("ExampleAmountLable")}
                              onChange={(e) => {
                                handleChangeInput("amount", e);
                              }}
                              onKeyPress={preventMinus}
                              fullWidth
                              defaultValue="10"
                              variant="outlined"
                              error={
                                formError.includes("amount") ||
                                formError.includes("amount_value") ||
                                formError.includes("maxValue")
                              }
                              helperText={
                                formError.includes("amount")
                                  ? t("contributePaymentAmountValidationLable")
                                  : formError.includes("amount_value")
                                  ? t(
                                      "contributePaymentAmountValueValidationLable"
                                    )
                                  : formError.includes("maxValue")
                                  ? "Amount reached to max value"
                                  : ""
                              }
                              step=".01"
                              inputProps={{
                                autoComplete: "off",
                                min: 1,
                                max: 2000000000,
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    €
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <LoadingButton
                              onClick={() => handleSubmit()}
                              sx={{
                                background: "#592091",
                                color: "#fff",
                                display: "block",
                                marginTop: "10px",
                                padding: "8px 20px",
                                fontWeight: "600",
                                letterSpacing: " 2px",
                                border: "1px solid #592091",
                                "&:hover": {
                                  color: "#592091",
                                },
                              }}
                            >
                              {t("contributePaymentContributeLable")}
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Container>
                  </Box>
                ) : null}
                {showContributeThanks ? (
                  <Box sx={{ p: { xs: 1, md: 2 } }}>
                    <Paper
                      sx={{
                        p: 2,
                        width: { md: "95%", xs: "auto" },
                        border: "1px solid #ddd",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: "600",
                              textTransform: "uppercase",
                              marginBottom: "10px",
                              fontSize: "16px",
                            }}
                          >
                            {t("contributeSucessLable")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>
                ) : null}
              </Paper>
            </Grid>
            <Divider />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
