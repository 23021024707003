import { Box, Container, Divider, Typography } from "@mui/material";
import CatchingPokemonIcon from "@mui/icons-material/CatchingPokemon";
import AccordionComponent from "../../Accordion";
import { useTranslation } from "react-i18next";

export default function TransactionsSecurity() {
  const { t } = useTranslation();
  return (
    <Box sx={{ p: { md: "35px 0px 50px", xs: "50px 0px" } }}>
      <Container>
        <Box>
          <Typography
            className="top-subheading"
            variant="h5"
            sx={{ textAlign: "center", fontWeight: "600", mb: 2 }}
          >
            {t("Q&ALable")}
          </Typography>
        </Box>
      </Container>
      <Container maxWidth="md">
        <Box>
          <AccordionComponent
            title={t("FAQorHelp.0")}
            content={t("FAQorHelp.1")}
          />
          <AccordionComponent
            title={t("FAQorHelp.2")}
            content={t("FAQorHelp.3")}
          />
          <AccordionComponent
            title={t("FAQorHelp.4")}
            content={t("FAQorHelp.5")}
          />
          <AccordionComponent
            title={t("FAQorHelp.6")}
            content={t("FAQorHelp.7")}
          />
          <AccordionComponent
            title={t("FAQorHelp.8")}
            content={t("FAQorHelp.9")}
          />
          <AccordionComponent
            title={t("FAQorHelp.10")}
            content={t("FAQorHelp.11")}
          />
          <AccordionComponent
            title={t("FAQorHelp.12")}
            content={t("FAQorHelp.13")}
          />
          <AccordionComponent
            title={t("FAQorHelp.14")}
            content={t("FAQorHelp.15")}
          />
          <AccordionComponent
            title={t("FAQorHelp.16")}
            content={t("FAQorHelp.17")}
          />
          <AccordionComponent
            title={t("FAQorHelp.18")}
            content={t("FAQorHelp.19")}
          />
          <AccordionComponent
            title={t("FAQorHelp.20")}
            content={t("FAQorHelp.21")}
          />
          <AccordionComponent
            title={t("FAQorHelp.22")}
            content={t("FAQorHelp.23")}
          />
        </Box>
      </Container>
      <Box
        sx={{
          width: " 100%",
          color: "#e6e6e6",
          position: "relative",
          margin: "50px 0px",
        }}
      >
        <CatchingPokemonIcon
          className="divider"
          sx={{
            margin: "0px",
            position: "absolute",
            left: "50%",
            top: "-12px",
            background: " white",
            borderRadius: "50%",
          }}
        />
      </Box>
      <Divider />
      <Box sx={{ p: { md: "40px 209px 5px 207px", xs: "10px" } }}>
        <Box
          sx={{
            backgroundColor: "#eeeeee",
            p: { md: "20px", xs: "10px" },
            textAlign: "justify",
          }}
        >
          {t("FAQorHelp.24")}
        </Box>
      </Box>
    </Box>
  );
}
