import { Box, Alert, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import UploadBlogImage from "../../../assets/Images/upload-blog-image.png";
// import CloseIcon from '@mui/icons-material/Close';
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";

const fileType = ["image/png", "image/jpeg", "image/gif"];

const FileUploader = ({ onSelectFile, imagewidth, imageSrc }) => {
  const { t } = useTranslation();
  const [imgSrcFile, setImageSrcFile] = React.useState(UploadBlogImage);
  const [imgBackground, setImgBackground] = React.useState("#592091");
  const [showCloseIcon, setShowCloseIcon] = React.useState(false);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    if (imageSrc) {
      setImgBackground("transparent");
      setImageSrcFile(imageSrc);
    } else {
      setImgBackground("#592091");
      setImageSrcFile(UploadBlogImage);
    }
  }, []);
  const cancelFileUpload = () => {
    setImgBackground("#592091");
    setImageSrcFile(UploadBlogImage);
    setShowCloseIcon(false);
  };
  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    setError("");
    if (!fileType.includes(e.target.files[0].type)) {
      setError(t("imageValidation"));
      return;
    }

    const fileSize = e.target.files[0].size;
    let fileSizeMb = 0;

    if (fileSize > 0) {
      fileSizeMb = (e.target.files[0].size / 1024 / 1024).toFixed(2);
    }

    if (fileSizeMb > 5) {
      setError(t("imageSizeValidation"));
      return;
    }

    if (onSelectFile) {
      onSelectFile(e.target.files[0]);
    }
    setImgBackground("transparent");
    setImageSrcFile(URL.createObjectURL(e.target.files[0]));
    setShowCloseIcon(true);
  };
  return (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: "10px" }}>
          {error}
        </Alert>
      )}
      <Box sx={{ position: "relative" }}>
        <Box component="label" sx={{ cursor: "pointer" }}>
          <input
            type="file"
            accept="image/png,image/jpeg,image/gif"
            hidden
            onChange={handleFileUpload}
            style={{ color: "#fff" }}
          />
          <Box
            sx={{
              background: imgBackground,
              borderRadius: "20px", 
              m: "20px 0px",
              padding: "8px",
              margin: "0 auto",
            }}
          >
            <Tooltip title={t("browseFileLabel")} placement="top">
              <img
                src={imgSrcFile}
                alt="blog preview"
                style={{ width: "100%" }}
              />
            </Tooltip>
          </Box>
        </Box>
        {showCloseIcon && (
          <Box
            onClick={cancelFileUpload}
            sx={{
              position: "absolute",
              right: "-8px",
              cursor: "pointer",
              top: "-8px",
            }}
          >
            <CancelTwoToneIcon
              sx={{
                color: "#fff",
                fontSize: "37px",
                background: "#1976d2",
                borderRadius: "50%",
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
export default FileUploader;
