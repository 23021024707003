import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  success: false,
  newKitty:{}
};

const NewKittyCatSlice = createSlice({
  name: 'newKittyCatSlice',
  initialState,
  reducers: {
    setNewKittyCat: (state, action) => ({
      ...state,
      newKitty: action.payload,
    }),
    setNewKittyCatSuccess: (state, action) => ({
        ...state,
        success: action.payload,
      }),
    setNewKittyCatError: (state, action) => ({
        ...state,
        error: action.payload,
      }),
  }
});

export const { setNewKittyCat, setNewKittyCatSuccess, setNewKittyCatError } = NewKittyCatSlice.actions;

export default NewKittyCatSlice.reducer;