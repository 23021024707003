import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  deleteMyTontine,
  listofUserTontine,
} from "../../../store/thunk/Tontine/MytontineThunk";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateNewTontine from "../../../layout/tontinePage/TontineStepper/PaymentStepper";
import DeleteDialogBox from "../../../components/Kitty/KittyDeleteDialog";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CustomMPagination from "../../../components/common/CustomMPagination";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#592091ab",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function MyTontineTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = React.useState(0);
  const [pageCountShow, SetPageCountShow] = React.useState(1);
  const [recordLimit, SetRecordLimit] = React.useState(10);

  React.useEffect(() => {
    dispatch(
      listofUserTontine({
        limit: recordLimit,
        offset: pageCount,
      })
    );
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [recordLimit, pageCount]);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [deleteId, setdeleteId] = React.useState("");

  const myTontine = useAppSelector((state) => state.MyTontine);
  const handleClickOpenDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  };

  const deleteKittyRequest = () => {
    dispatch(deleteMyTontine(deleteId));
  };

  const detailPage = (id) => {
    navigate(`/tontine/${id}`, {
      state: { backRedirectUrl: "/tontine-admin" },
    });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={myTontine?.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ margin: "10px 0" }}>
        <Paper
          sx={{
            p: 2,
            backgroundColor: "#59209124",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="h5"
              sx={{
                textTransform: "uppercase",
                fontWeight: " 600",
                fontSize: "16px",
              }}
            >
              {t("tontineTab2")}
            </Typography>
          </Box>
          <Box>
            <CreateNewTontine
              data={{
                limit: Number(recordLimit),
                offset: Number(pageCount),
              }}
            />
          </Box>
        </Paper>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                {t("tontineTableColumn.0")}
              </StyledTableCell>
              <StyledTableCell>{t("tontineTableColumn.1")}</StyledTableCell>
              <StyledTableCell align="center">
                {t("tontineTableColumn.2")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("tontineTableColumn.3")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("tontineTableColumn.4")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("tontineTableColumn.5")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("StartDateLable")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("tontineFrequencyFieldLabel")}
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "20px" }}>
                {t("tontineTableColumn.8")}
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "20px" }}>
                {t("tontineTableColumn.9")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myTontine?.myTontine?.results?.length ? (
              myTontine?.myTontine?.results?.map((row) => (
                <StyledTableRow key={row.id} sx={{ cursor: "pointer" }}>
                  <StyledTableCell component="th" scope="row">
                    <img
                      src={row.image}
                      alt="Tontine"
                      height={60}
                      width={85}
                      onClick={() => detailPage(row.id)}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    onClick={() => detailPage(row.id)}
                  >
                    <Box sx={{ width: "160px" }}>{row.name}</Box>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.id)}
                  >
                    {t(row.status)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.id)}
                  >
                    {row.amount}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.id)}
                  >
                    {row.participants}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.id)}
                  >
                    {moment(row?.created_at).format(
                      localStorage.getItem("language") == "en"
                        ? "YYYY-MM-DD"
                        : "DD-MM-YYYY"
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.id)}
                  >
                    {row?.creation_date !== null
                      ? moment(row?.creation_date).format(
                          localStorage.getItem("language") == "en"
                            ? "YYYY-MM-DD"
                            : "DD-MM-YYYY"
                        )
                      : t("NotstartedLable")}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => detailPage(row.id)}
                  >
                    {t(row.frequency)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <>
                      <Tooltip title={t("editLable")}>
                        <Button
                          // disabled={!documentStatus?.docStatus?.is_verified}
                          onClick={() =>
                            navigate("/tontine_settings/0/" + row.id)
                          }
                        >
                          <ModeIcon />
                        </Button>
                      </Tooltip>
                    </>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <>
                      <Tooltip title={t("deleteLable")}>
                        <Button
                          // disabled={!documentStatus?.docStatus?.is_verified}
                          onClick={() => {
                            handleClickOpenDialog();
                            setdeleteId(row.id);
                          }}
                        >
                          <DeleteIcon sx={{ color: "#ff0000" }} />
                        </Button>
                      </Tooltip>
                    </>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow key="1">
                <StyledTableCell
                  scope="row"
                  colSpan={10}
                  sx={{
                    color: "#525453cf",
                    backgroundColor: "#f1efeb",
                    textAlign: "center",
                  }}
                >
                  {t("norecordLbl")}
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ mt: 1 }}>
        <CustomMPagination
          pageCount={pageCount}
          SetPageCount={SetPageCount}
          recordLimit={recordLimit}
          pageCountShow={pageCountShow}
          SetRecordLimit={SetRecordLimit}
          numRecords={myTontine?.myTontine?.num_records}
          numCount={myTontine?.myTontine?.count}
        />
      </Box>
      <DeleteDialogBox
        handleClickOpen={handleClickOpenDialog}
        handleClose={handleCloseDialog}
        deleteUpdateKitty={deleteKittyRequest}
        open={openDeleteDialog}
        data={myTontine}
      />
    </>
  );
}
