import * as React from "react";
import "./CreateNewPassword.scss";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { FormHelperText, Typography } from "@mui/material";
import {
  newPassword,
  userTokenCheckPasswordChange,
} from "../../../../store/thunk/NewPasswordThunk";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import { CheckUserPasswordData } from "../../../../store/reducer/Password/NewPasswordSlice";

export default function SimplePaper() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userCheckData = useAppSelector((state) => state.newPasswordSlice);
  const urlParams = new URLSearchParams(window.location.search);
  const uid = urlParams.get("uid");
  const token = urlParams.get("token");
  const [formError, setFormError] = React.useState([]);
  const passwordFormat =
    /^(?!.* )(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{12,}$/;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  React.useEffect(() => {
    dispatch(userTokenCheckPasswordChange({ uid, token }));
  }, []);

  React.useEffect(() => {
    if (userCheckData.userCheck.message === "Invalid token.") {
      navigate("/");
      dispatch(CheckUserPasswordData({}));
    }
  }, [userCheckData]);

  const [password, setPassword] = React.useState({
    password1: "",
    password2: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleChange = (name, event) => {
    setPassword({
      ...password,
      [name]: event.target.value,
    });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateForm = (data) => {
    let error = [];

    if (data.password1 === "") {
      error.push("password1");
    }

    if (!data.password1.match(passwordFormat)) {
      error.push("passwordFormate");
    }

    if (data.password2 === "") {
      error.push("password2");
    }

    if (
      data.password2 !== data.password1 &&
      data.password1 !== "" &&
      data.password2 !== ""
    ) {
      error.push("not_matched");
    }

    setFormError(error);
    return error;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = () => {
    let error = validateForm(password);
    if (error.length == 0) {
      let request = {
        request: {
          password,
          uid,
          token,
        },
        callback: () => {
          navigate("/sign-in");
        },
      };
      dispatch(newPassword({ request }));
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);
  return (
    <>
      <Box
        fullWidth
        sx={{
          background: "rgb(89, 32, 145)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Paper
          sx={{
            zIndex: 1,
            background: "#f1efeb",
            padding: 4,
            margin: 5,
            mt: "100px",
          }}
          className="NewPasswordForm"
        >
          <div>
            <Typography variant="h5">{t("resetPasswordLable")}</Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                width: { md: "360px", sm: "360px", xs: "240px" },
              }}
            >
              <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-new-password" required>
                  {t("resetPasswordFieldLable")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-new-password"
                  type={showPassword ? "text" : "password"}
                  value={password.password1}
                  error={formError.includes("password1")}
                  onChange={(e) => handleChange("password1", e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("resetPasswordFieldLable")}
                  onKeyDown={handleKeyDown}
                />
                {formError.includes("password1") && (
                  <FormHelperText id="component-error-text" error>
                    {t("resetCreatePasswordValidation")}
                  </FormHelperText>
                )}
                {formError.includes("passwordFormate") &&
                  !formError.includes("password1") && (
                    <FormHelperText
                      id="component-error-text"
                      error
                      sx={{ fontSize: "10px" }}
                    >
                      {t("passwordFormatValidateLabel")}
                    </FormHelperText>
                  )}
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                width: { md: "360px", sm: "360px", xs: "240px" },
              }}
            >
              <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                <InputLabel
                  htmlFor="outlined-adornment-confirm-password"
                  required
                >
                  {t("resetConfirmPasswordFieldLable")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-confirm-password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={password.password2}
                  error={formError.includes("password2")}
                  onChange={(e) => handleChange("password2", e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("resetConfirmPasswordFieldLable")}
                  onKeyDown={handleKeyDown}
                />
                {formError.includes("password2") && (
                  <FormHelperText id="component-error-text" error>
                    {t("resetCreateConfirmPasswordValidation")}
                  </FormHelperText>
                )}

                {formError.includes("not_matched") && (
                  <FormHelperText id="component-error-confirm-text" error>
                    {t("resetCreatePasswordNotValidation")}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>

            <Button
              fullWidth
              variant="contained"
              sx={{ padding: 2 }}
              onClick={handleLogin}
            >
              {t("resetCreatePasswordBtnLable")}
            </Button>
          </div>
        </Paper>
      </Box>
    </>
  );
}
