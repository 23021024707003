import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { ToastContainer, toast } from "react-toastify";
import { emailInviteTontine } from "../../../store/thunk/Tontine/MytontineThunk";

function Invites(id) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [flag, setFlag] = React.useState(0);
  const [emailIds, setEmailIds] = useState({
    emails: [],
    error: null,
  });
  const handleChange = (evt) => {
    setEmail(evt.target.value);
  };
  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      let emailId = email.trim();
      emailId = emailId.split(" ");
      if (emailId && isValid(emailId)) {
        setEmailIds({
          emails: [...emailIds?.emails, ...emailId],
        });
        setEmail("");
      }
    }
  };
  const handleDelete = (toBeRemoved) => {
    let temp = emailIds.emails.filter((email) => email !== toBeRemoved);
    setEmailIds({
      emails: temp,
    });
  };
  function isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }
  function isInList(email) {
    return emailIds.emails.includes(...email);
  }
  function isValid(email) {
    var error = null;
    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }
    if (isInList(email)) {
      error = `${email} has already been added.`;
    }
    if (error) {
      setEmailIds({ ...emailIds, error });
      return false;
    }
    return true;
  }

  const showToastMessage = () => {
    toast.error(t("EmailBoxValidation"), {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <>
      {flag === 1 ? <ToastContainer autoClose={4000} /> : <></>}
      <Grid item xs={12}>
        <Paper sx={{ margin: "10px 6px", border: "1px solid #ddd", p: 2 }}>
          <Typography
            variant="h5"
            sx={{
              textTransform: "uppercase",
              fontWeight: "600",
              mb: "16px",
            }}
          >
            {t("kittySettingInvite.4")}
          </Typography>
          {emailIds?.emails?.map((email) => (
            <Chip
              label={email}
              onDelete={() => handleDelete(email)}
              sx={{ m: "2px" }}
            />
          ))}
          <TextField
            id="outlined-multiline-static"
            multiline
            disabled={emailIds.emails.length < 101 ? false : true}
            fullWidth
            rows={4}
            placeholder={t("kittySettingInvite.7")}
            value={email}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onMouseLeave={handleKeyDown}
            sx={{ mt: "10px" }}
          />
          {emailIds?.error && (
            <Typography
              variant="body2"
              sx={{
                color: "#d11313",
                display: "block",
                marginTop: "10px",
                fontWeight: "600",
              }}
            >
              {emailIds?.error}
            </Typography>
          )}
          <Typography variant="body2" sx={{ mt: 1 }}>
            {t("kittySettingInvite.5")} : {100 - emailIds.emails.length}/100
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                background: "#592091",
                color: "#fff",
                display: "block",
                marginTop: "10px",
                padding: "8px 20px",
                fontWeight: "600",
                letterSpacing: " 2px",
                border: "1px solid #592091",
                "&:hover": {
                  color: "#592091",
                  background: "#fff",
                },
              }}
              disabled={!emailIds.emails}
              onClick={() => {
                if (emailIds?.emails?.length === 0) {
                  showToastMessage();
                  setFlag(1);
                } else {
                  dispatch(
                    emailInviteTontine({
                      request: {
                        emails: emailIds.emails.toString(),
                        link: `${process.env.REACT_APP_REDIRECT_URL}/tontine/participantpage/`,
                        tontine_name: id.id.name,
                        tontine: id.id.id,
                      },
                      callback: () => {
                        if (!window.location.href.includes("settings")) {
                          id.handleFinish();
                        }
                      },
                    })
                  );
                }
                setEmailIds({
                  emails: [],
                  error: null,
                });
              }}
            >
              {t("sendLabel")}
            </Button>
          </Box>
        </Paper>
      </Grid>
    </>
  );
}

export default Invites;
