import * as React from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  Alert,
  Box,
  Collapse,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { frFR } from "@mui/x-date-pickers/locales";
import CloseIcon from "@mui/icons-material/Close";

export default function BasicDatePicker(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const [dates, setDates] = React.useState({
    creation_date: moment().add(1, "days"),
  });
  React.useEffect(() => {
    props?.data?.dateChange({
      ...dates,
      creation_date: dates.creation_date?.$y
        ? `${dates.creation_date?.$y}-${dates.creation_date?.$M + 1}-${
            dates.creation_date?.$D
          }`
        : props?.data?.data?.creation_date,
    });
  }, [dates]);

  React.useEffect(() => {
    setDates({
      ...dates,
      creation_date: moment(props?.data?.data?.creation_date),
    });
  }, []);

  return (
    <>
      {props?.data?.data?.participants >
      props.data?.data?.tontine_participants_id?.length ? (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Collapse in={open}>
            <Alert
              severity="warning"
              onClose={() => {}}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {t("ParticipantWarningLable")}
            </Alert>
          </Collapse>
        </Stack>
      ) : null}
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", width: "360px" }}>
          <FormControl
            fullWidth
            sx={{ m: 1, height: "70px", mb: 2 }}
            variant="outlined"
            error={props?.formError?.includes("creation_date")}
          >
            <LocalizationProvider
              localeText={
                frFR.components.MuiLocalizationProvider.defaultProps.localeText
              }
              dateAdapter={AdapterDayjs}
            >
              <Stack fullWidth>
                <DesktopDatePicker
                  label={t("StartDateLable")}
                  PopperProps={{
                    placement: "auto",
                  }}
                  inputFormat={
                    localStorage.getItem("language") == "en"
                      ? "YYYY-MM-DD"
                      : "DD-MM-YYYY"
                  }
                  value={dates.creation_date || moment().add(1, "days")}
                  disablePast
                  onChange={(newValue) =>
                    setDates(() => {
                      return { ...dates, creation_date: newValue };
                    })
                  }
                  minDate={moment().add(1, "days")}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{
                    ".PrivatePickersYear-yearButton": {
                      "& .Mui-selected": {
                        textAlign: "Center",
                      },
                    },
                  }}
                />
              </Stack>
              {props?.formError?.includes("creation_date") && (
                <FormHelperText id="component-error-text" error>
                  {t("StartDateValidationLable")}
                </FormHelperText>
              )}
            </LocalizationProvider>
          </FormControl>
        </Box>
      </Box>
    </>
  );
}
