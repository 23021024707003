import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import Box from "@mui/material/Box";
import {
  CreateNewTontine,
  getListofAdminTontine,
  listofUserTontine,
} from "../../../store/thunk/Tontine/MytontineThunk";
import RichTextEditor from "react-rte";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import FileUploader from "../../../components/common/imageUpload";
import {
  setMyTontineCreateFunction,
  setNextStep,
} from "../../../store/reducer/Tontine/MyTontineSlice";

export default function CreateTontine(props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [formError, setFormError] = React.useState([]);
  const session = useAppSelector((state) => state.UserProfile);
  const documentStatus = useAppSelector((state) => state.MyTontine);
  const [open, setOpen] = React.useState(false);
  const [tontineData, setTontineData] = React.useState({
    amount: "",
    frequency: "",
    description: "",
    image: "",
    name: "",
    participants: "",
    start_day: null,
  });
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createValueFromString("", "html")
  );

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     props.setSearch(searchTerm);
  //   }, 500);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [searchTerm]);

  useEffect(() => {
    if (documentStatus.CreateFunctionToggle) {
      handleSubmit();
      dispatch(setMyTontineCreateFunction(false));
    }
  }, [documentStatus]);

  const clearData = {
    amount: "",
    frequency: "",
    description: "",
    image: "",
    name: "",
    participants: "",
  };

  const validateForm = (data) => {
    let error = [];

    if (data.name === "") {
      error.push("name");
    }
    if (data.amount === "") {
      error.push("amount");
    }
    if (data.frequency === "") {
      error.push("frequency");
    }
    if (data.description === "") {
      error.push("description");
    }
    if (data.participants === "") {
      error.push("participants");
    }
    if (data.start_day === null) {
      error.push("start_day");
    }
    setFormError(error);

    return error;
  };

  const theme = createTheme({
    TextField: {
      outlined: {
        padding: "10px 14px",
      },
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updatekittyData = (text, event) => {
    if (text === "participants") {
      // const regex = /^(\d*\.?\d{0,0}).*$/;
      const newValue = event.target.value.replace(/\D/g, "");

      setTontineData({
        ...tontineData,
        [text]: newValue,
      });
    } else if (text === "amount") {
      // Regex pattern to match up to two decimal places
      const regex = /^(\d*\.?\d{0,2}).*$/;
      const newValue = event.target.value.replace(regex, "$1");

      setTontineData({
        ...tontineData,
        [text]: newValue,
      });
    } else {
      setTontineData({
        ...tontineData,
        [text]: event.target.value,
      });
    }
  };

  const handleSubmitCallback = () => {
    if (props.callFrom && props.callFrom === "admin") {
      dispatch(getListofAdminTontine({ limit: 10, offSet: 0 }));
    } else {
      dispatch(
        listofUserTontine({
          limit: 10,
          offset: 0,
        })
      );
      dispatch(getListofAdminTontine({ limit: 10, offSet: 0 }));
    }
    setTontineData(clearData);
  };

  const handleSubmit = () => {
    let error = validateForm(tontineData);
    if (error.length == 0) {
      let request = {
        ...tontineData,
        user: session?.details?.id,
        callback: handleSubmitCallback,
      };
      dispatch(CreateNewTontine(request));
      handleClose();
      dispatch(setNextStep(true));
    } else {
      dispatch(setMyTontineCreateFunction(false));
    }
  };

  function onChangeTextEditor(value) {
    setEditorValue(value);
    setTontineData({
      ...tontineData,
      description: value.toString("html"),
    });
  }

  const handleSelectFile = (file) => {
    setTontineData((tontineData) => ({
      ...tontineData,
      image: file,
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <Grid
          container
          sx={{
            justifyContent: "center",
            display: { xs: "block", md: "flex" },
          }}
        >
          <Grid item md={3} xs={12}>
            <Box
              item
              sx={{ padding: "1px 4px", maxWidth: "250px", m: "0 auto" }}
            >
              <FileUploader onSelectFile={handleSelectFile} />
              {formError.includes("image") && (
                <FormHelperText id="component-error-text" error>
                  {t("kittyFieldValidation.0")}
                </FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid item md={6} xs={12} sx={{ p: { md: 0, xs: 2 } }}>
            <Box item sx={{ padding: "1px 4px", minWidth: "250px" }}>
              <TextField
                autoFocus
                size="small"
                margin="dense"
                id="name"
                value={tontineData.name}
                onChange={(e) => {
                  updatekittyData("name", e);
                }}
                label={t("tontineNameFieldLabel")}
                type="text"
                inputProps={{ maxLength: 150 }}
                fullWidth
                variant="outlined"
                error={formError.includes("name")}
                helperText={
                  formError.includes("name") ? t("kittyFieldValidation.1") : ""
                }
              />
            </Box>
            <Box item sx={{ padding: "1px 4px", mt: 1, minWidth: "250px" }}>
              <TextField
                margin="dense"
                size="small"
                value={tontineData.amount}
                onChange={(e) => {
                  updatekittyData("amount", e);
                }}
                id="amount"
                label={`${t("tontineParticipantAmountFieldLabel")}`}
                type="number"
                fullWidth
                variant="outlined"
                error={formError.includes("amount")}
                helperText={
                  formError.includes("amount")
                    ? t("tontineAmountFieldValidation")
                    : ""
                }
              />
            </Box>
            <Box item sx={{ padding: "1px 4px", mt: 1, minWidth: "250px" }}>
              <TextField
                margin="dense"
                size="small"
                value={tontineData.participants}
                onChange={(e) => {
                  updatekittyData("participants", e);
                }}
                id="participants"
                label={t("NumberParticipantsLable")}
                type="number"
                fullWidth
                variant="outlined"
                pattern="[0-9]"
                inputProps={{
                  autoComplete: "off",
                  min: 1,
                  max: 2000000000,
                }}
                error={formError.includes("participants")}
                helperText={
                  formError.includes("participants")
                    ? t("tontineAmountFieldValidation")
                    : ""
                }
              />
            </Box>
            <Box sx={{ display: { md: "flex", xs: "block" } }}>
              <Box item sx={{ padding: "1px 0", mt: 1, minWidth: "250px" }}>
                <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    {t("tontineFrequencyFieldLabel")}
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    error={formError.includes("frequency")}
                    value={tontineData.frequency}
                    label={t("tontineFrequencyFieldLabel")}
                    onChange={(e) => {
                      updatekittyData("frequency", e);
                    }}
                  >
                    <MenuItem value={"WEEKLY"}>{t("WeeklyLable")}</MenuItem>
                    <MenuItem value={"MONTHLY"}>{t("MonthlyLable")}</MenuItem>
                  </Select>
                  {formError.includes("frequency") && (
                    <FormHelperText id="component-error-text" error>
                      {t("PleaseFrequencyLable")}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              {tontineData.frequency !== "" ? (
                <Box item sx={{ padding: "1px 0", mt: 1, minWidth: "250px" }}>
                  <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                    <InputLabel id="demo-select-small-label">
                      {tontineData.frequency === "WEEKLY" ||
                      tontineData.frequency === "BIWEEKLY"
                        ? t("SelectDayDateLable.0")
                        : t("SelectDayDateLable.1")}
                    </InputLabel>
                    {tontineData.frequency === "WEEKLY" ? (
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        error={formError.includes("start_day")}
                        value={tontineData.start_day}
                        label={t("tontineFrequencyFieldLabel")}
                        onChange={(e) => {
                          updatekittyData("start_day", e);
                        }}
                      >
                        <MenuItem value={"0"}>{t("WeekdaysLable.0")}</MenuItem>
                        <MenuItem value={"1"}>{t("WeekdaysLable.1")}</MenuItem>
                        <MenuItem value={"2"}>{t("WeekdaysLable.2")}</MenuItem>
                        <MenuItem value={"3"}>{t("WeekdaysLable.3")}</MenuItem>
                        <MenuItem value={"4"}>{t("WeekdaysLable.4")}</MenuItem>
                        <MenuItem value={"5"}>{t("WeekdaysLable.5")}</MenuItem>
                        <MenuItem value={"6"}>{t("WeekdaysLable.6")}</MenuItem>
                      </Select>
                    ) : (
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        error={formError.includes("start_day")}
                        value={tontineData.start_day}
                        label={t("tontineFrequencyFieldLabel")}
                        onChange={(e) => {
                          updatekittyData("start_day", e);
                        }}
                      >
                        <MenuItem value={1}>01</MenuItem>
                        <MenuItem value={2}>02</MenuItem>
                        <MenuItem value={3}>03</MenuItem>
                        <MenuItem value={4}>04</MenuItem>
                        <MenuItem value={5}>05</MenuItem>

                        <MenuItem value={6}>06</MenuItem>
                        <MenuItem value={7}>07</MenuItem>
                        <MenuItem value={8}>08</MenuItem>
                        <MenuItem value={9}>09</MenuItem>
                        <MenuItem value={10}>10</MenuItem>

                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>

                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>

                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>

                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                      </Select>
                    )}
                    {formError.includes("start_day") && (
                      <FormHelperText id="component-error-text" error>
                        {"Please select start day"}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              ) : null}
            </Box>
          </Grid>
          <Grid item md={9} xs={12} sx={{ p: { md: 0, xs: 2 } }}>
            <Box item sx={{ padding: "1px 4px", mt: 1, minWidth: "250px" }}>
              <RichTextEditor
                onChange={onChangeTextEditor}
                value={editorValue}
                sx={{ width: "100%" }}
                placeholder={t("tontineDescFieldLabel")}
                editorClassName="richtext-editor-input"
              />
              <FormHelperText
                sx={{
                  marginBottom: "10px",
                  marginLeft: "10px",
                  color: "red",
                }}
              >
                {formError.includes("description")
                  ? t("tontineDescriptinFieldValidation")
                  : ""}
              </FormHelperText>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
