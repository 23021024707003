import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Typography,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { createTheme } from "react-data-table-component";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import moment from "moment";
import DataTable from "../../../../../../components/common/DataTableBase";
import { useAppDispatch, useAppSelector } from "../../../../../../store/store";
import { getListofKittySettingLog } from "../../../../../../store/thunk/Kitty/KittyDetailsThunk";

export default function AdminKittySettingLogComponent() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = useState(0);
  const [pageCountShow, SetPageCountShow] = useState(0);
  const [recordLimit, SetRecordLimit] = useState(10);
  const adminKittySettingLogData = useAppSelector(
    (state) => state.DetailsOfKitty
  );

  const columns = [
    {
      name: t("kittyMessageNameTitle"),
      selector: (row) => row.kitty_name,
    },
    {
      name: t("kittyMessageFieldTitle"),
      selector: (row) => row.kitty_message,
    },
    {
      name: t("kittyMessageCreatedDateTitle"),
      selector: (row) =>
        moment(row.kitty_created).format(
          localStorage.getItem("language") == "en" ? "YYYY-MM-DD" : "DD-MM-YYYY"
        ),
    },
    {
      name: t("kittyMessageCreatedByTitle"),
      selector: (row) => row.created_by || "-",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    dispatch(
      getListofKittySettingLog({ limit: recordLimit, offSet: pageCount })
    );
    SetPageCountShow((pageCount + recordLimit) / recordLimit);
  }, [pageCount, recordLimit]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "#ccc",
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "#fff ",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        textAlign: "center",
        fontSize: "17px",
        display: "grid",
        placeContent: "center",
        backgroundColor: "#2a2828",
        color: "#fff",
        maxWidth: "40px",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        padding: "8px",
        fontSize: "14px",
        display: "grid",
        placeContent: "center",
      },
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );

  return (
    <>
      <Box className="kittyTable">
        <Box sx={{ borderRadius: 0, padding: { md: 2, xs: 1 } }}>
          <DataTable
            title={
              <>
                <Box
                  className="tableTitle"
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {t("kittySettingListLabel")}
                </Box>
              </>
            }
            columns={columns}
            data={adminKittySettingLogData?.adminKittySettingLogList?.results}
            customStyles={customStyles}
            theme="solarized"
            noDataComponent={<Box sx={{ p: 3 }}>{t("norecordLbl")}</Box>}
            className="rdt_TableHeader"
            progressPending={adminKittySettingLogData.loading}
          />
          <Box
            className="pagination"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="p">{t("TotalLable")}</Typography>
              {adminKittySettingLogData?.adminKittySettingLogList?.count}
            </Box>
            <Box>
              <Typography
                variant="p"
                sx={{ padding: "0 8px", fontSize: "16px", fontWeight: "500" }}
              >
                {t("RowsPageLable")}
              </Typography>
              <FormControl sx={{ padding: "0 8px" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  sx={{
                    lineHeight: "unset",
                  }}
                  value={recordLimit}
                  onChange={(event) => {
                    SetRecordLimit(event.target.value);
                    SetPageCount(0);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={10000}>{t("allLable")}</MenuItem>
                </Select>
              </FormControl>
              <Tooltip title={t("FirstPageLable")}>
                <Button
                  onClick={() => SetPageCount(0)}
                  disabled={pageCount < 1 ? true : false}
                  sx={{ margin: "0 8px" }}
                >
                  <KeyboardDoubleArrowLeftIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t("previousLabel")}>
                <Button
                  onClick={() =>
                    SetPageCount(
                      pageCount < recordLimit
                        ? pageCount + recordLimit
                        : pageCount - recordLimit
                    )
                  }
                  disabled={pageCount < 1 ? true : false}
                  sx={{ margin: "0 8px" }}
                >
                  <ArrowBackIosNewIcon />
                </Button>
              </Tooltip>
              <Typography variant="h7" padding={2.5}>
                {Math.ceil(pageCountShow)}
              </Typography>
              <Tooltip title={t("nextLabel")}>
                <Button
                  onClick={() => SetPageCount(pageCount + recordLimit)}
                  disabled={
                    adminKittySettingLogData?.adminKittySettingLogList?.count <=
                    pageCount + recordLimit
                      ? true
                      : adminKittySettingLogData?.adminKittySettingLogList
                          ?.count <= recordLimit
                      ? true
                      : false
                  }
                  sx={{ margin: "0 8px" }}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </Tooltip>
              <Tooltip title={t("LastPageLable")}>
                <Button
                  onClick={() =>
                    SetPageCount(
                      adminKittySettingLogData?.adminKittySettingLogList
                        ?.count - recordLimit
                    )
                  }
                  disabled={
                    adminKittySettingLogData?.adminKittySettingLogList?.count <=
                    pageCount + recordLimit
                      ? true
                      : adminKittySettingLogData?.adminKittySettingLogList
                          ?.count <= recordLimit
                      ? true
                      : false
                  }
                  sx={{ margin: "0 8px" }}
                >
                  <KeyboardDoubleArrowRightIcon />
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
