import React, { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import { Box } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { listofKittyCat } from "../../../../store/thunk/KittyCategory/listofKittyCatThunk";
import {} from "../../../../store/thunk/Kitty/listofKittyThunk";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  MenuListProps: {
    disablePadding: true,
  },
};

export default function CategoriesBlock(props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const data = useAppSelector((state) => state.listofKittyCatSlice);
  const { allkitty } = useParams();
  const [category, setCategory] = React.useState([]);
  const [categoryDisplay, setCategoryDisplay] = React.useState([]);

  useEffect(() => {
    let CatId = category.filter((item) => {
      return item !== undefined;
    });
    if (allkitty != "allkitty")
      window.history.pushState(
        {},
        "",
        `${window.location.origin}/money-pots-innerPage/${CatId}`
      );
  }, [category]);

  useEffect(() => {
    if (allkitty != "allkitty") {
      setCategory([allkitty]);
      let temp = data?.list?.results?.filter((item) => item.id == allkitty);
      setCategoryDisplay(temp ? [temp[0]?.category_name] : []);
    } else {
      props.setCategory("");
    }
  }, [data, allkitty]);

  useEffect(() => {
    dispatch(listofKittyCat({}));
  }, []);

  useEffect(() => {
    let CatId = category.filter((item) => {
      return item !== undefined;
    });

    if (CatId.length != 0) {
      props.setCategory(CatId);
    } else {
      props.setCategory("");
    }
  }, [categoryDisplay]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategoryDisplay(value);
    setCategory(
      data?.list?.results?.map((item) => {
        if (value.includes(item?.category_name)) {
          return item.id;
        } else {
          return undefined;
        }
      })
    );
  };

  return (
    <Box sx={{ p: { md: 2, xs: 1 } }}>
      <Box>
        <FormControl
          sx={{ width: { md: "auto", xs: "100%", background: "#fff" } }}
        >
          <InputLabel id="demo-simple-select-label">
            {t("kittyFieldLable.3")}
          </InputLabel>
          <Select
            IconComponent={KeyboardArrowDownIcon}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            label={t("kittyFieldLable.3")}
            value={categoryDisplay}
            onChange={handleChange}
            MenuProps={MenuProps}
            sx={{
              backgroundColor: "#f1efeb",
              color: "#000 !important",
              fill: "rgba(255, 255, 255, 0.04)",
              width: { md: "219px", xs: "100%" },
              ".MuiSvgIcon-root ": {
                fill: "#000 !important",
              },
            }}
          >
            <MenuItem
              key={""}
              value={t("navigationMenu.0")}
              className="category-filter-dropdown-item"
            >
              <ListItemText primary={t("navigationMenu.0")} />
            </MenuItem>
            {data.list.results?.map((data) => {
              return (
                <MenuItem
                  key={data?.id}
                  value={data?.category_name}
                  className="category-filter-dropdown-item"
                >
                  <ListItemText primary={t('category.'+data?.category_name).replace('category.','')} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
