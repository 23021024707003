import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  success: false,
  details: {},
  ListofUsers: {},
  tempDetails: {},
  listOfBlockedUsers: { results: [] },
  MyCardDetails: {},
};

const UserProfile = createSlice({
  name: "UserProfileDetails",
  initialState,
  reducers: {
    setUserDetails: (state, action) => ({
      ...state,
      details: action.payload,
    }),
    setUserTempDetails: (state, action) => ({
      ...state,
      tempDetails: action.payload,
    }),
    setUserList: (state, action) => ({
      ...state,
      ListofUsers: action.payload,
    }),
    setUserDetailsLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setUserDetailsSuccess: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    setUserDetailsError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
    setListOfBlockedUsers: (state, action) => ({
      ...state,
      listOfBlockedUsers: action.payload,
    }),
    setListOfSavedCards: (state, action) => ({
      ...state,
      MyCardDetails: action.payload,
    }),
  },
});

export const {
  setUserDetails,
  setUserDetailsLoader,
  setUserDetailsSuccess,
  setUserDetailsError,
  setUserList,
  setUserTempDetails,
  setListOfBlockedUsers,
  setListOfSavedCards,
} = UserProfile.actions;

export default UserProfile.reducer;
