import * as React from "react";
import Header from "../../Header/index";
import Footer from "../../Footer/index";
import ForgetPassword from "./ForgetPassword";

export default function Admin() {

    return (
        <>
            <Header />
            <ForgetPassword/>
            <Footer />
        </>
    );
}