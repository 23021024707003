import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  success: "",
  contribution: {},
};

const contributeKittySlice = createSlice({
  name: "contributeKittySlice",
  initialState,
  reducers: {
    setContributeKitty: (state, action) => ({
      ...state,
      like: action.payload,
    }),
    setContributeLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setContributeSuccess: (state, action) => ({
      ...state,
      success: action.payload,
    }),
    setContributeError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
  },
});

export const {
  setContributeKitty,
  setContributeLoader,
  setContributeSuccess,
  setContributeError,
} = contributeKittySlice.actions;

export default contributeKittySlice.reducer;
