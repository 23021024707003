import { Grid, Paper, Box } from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Image1 from "../../assets/Images/emergency.jpg";
import Image2 from "../../assets/Images/business.jpg";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function CardBlock() {
  const { t } = useTranslation();
  const items = [
    {
      name: t("myKitty.2"),
      imgPath: Image1,
      title: t("myKitty.3"),
      route: "/kitty-cat-listing",
    },
    {
      name: t("myKitty.4"),
      imgPath: Image2,
      title: t("myKitty.5"),
      route: "/kitty-listing",
    },
  ];

  return (
    <Grid
      container
      sx={{
        flexwrap: " nowrap",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {items.map((item) => (
        <Grid
          item
          md={3}
          sx={{ p: 1, width: "100%", mb: { md: "0px", xs: 2 } }}
        >
          <Paper sx={{ background: "#e6e6e6", position: "relative" }}>
            <LazyLoadImage
              alt={""}
              effect="blur"
              src={item?.imgPath}
              width={"100%"}
            />
            <Box className="description">{item.name}</Box>
            <Link to={item.route}>
              <Button
                size="small"
                sx={{
                  background: " #91bcff",
                  color: "#1c1c1c",
                  "&:hover": {
                    background: " #91bcff",
                    color: "#1c1c1c",
                  },
                }}
              >
                {item.title}{" "}
              </Button>
            </Link>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
