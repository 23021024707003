import * as React from "react";
// import "./ActivationLink.scss";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  SingleTontine,
  participantReplyTontine,
  participantTontine,
} from "../../../store/thunk/Tontine/MytontineThunk";
// import Signin from "../../../components/SignIn/SignInPopup";
import { KittySwapLinkCheck } from "../../../store/thunk/Kitty/KittyDetailsThunk";

const Signin = React.lazy(() =>
  import("../../../components/SignIn/SignInPopup")
);

export default function ActivationLink() {
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const uid = urlParams.get("uid");
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = React.useState(false);
  const tontine = useAppSelector((state) => state.MyTontine);
  const userData = useAppSelector((state) => state.UserProfile);
  let flag = React.useRef(true);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(SingleTontine(id));
  }, []);

  React.useEffect(() => {
    dispatch(SingleTontine(id));
    dispatch(
      KittySwapLinkCheck({
        email: userData?.details?.email,
        uid,
        tontine: id,
      })
    );
  }, [userData]);

  const handleReject = () => {
    let request = {
      id: Number(tontine.tontineLink.id),
      status: "REJECTED",
      is_verify: true,
      navigate: navigate,
    };
    dispatch(participantReplyTontine(request));
    flag.current = false;
  };

  const handleRequest = () => {
    setDisableBtn(true);
    let request = {
      navigate: navigate,
      tontine: Number(id),
      // status: "accepted",
      // is_verify: true,
      user: userData?.details?.id,
      callback: () => {
        setTimeout(() => {
          setDisableBtn(false);
        }, 1000);
      },
    };
    let status = {
      id: Number(tontine.tontineLink.id),
      status: "ACCEPTED",
      is_verify: true,
    };
    dispatch(participantTontine(request));
    dispatch(participantReplyTontine(status));
  };

  return (
    <>
      {localStorage.getItem("UserInfo") ? (
        tontine.tontineLink.link ? (
          window.location.pathname.includes("accept") ? (
            <Box
              fullWidth
              sx={{
                background: "rgb(89, 32, 145)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                sx={{
                  zIndex: 1,
                  background: "#f1efeb",
                  padding: 4,
                  margin: 5,
                  mt: "100px",
                  width: "400px",
                }}
                className="ForgetPasswordForm"
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      width={"100%"}
                      sx={{
                        textAlign: "center",
                        marginBottom: "20px",
                        fontWeight: "600",
                      }}
                    >
                      {`You are invited to join ${tontine.singleTontine.name} tontine`}
                    </Typography>
                    <Box sx={{ display: "grid", placeContent: "center" }}>
                      <img
                        src={tontine.singleTontine.image}
                        alt="Tontine"
                        loading="lazy"
                        height={150}
                        style={{ maxWidth: "250px" }}
                      />
                    </Box>
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "center",
                        fontSize: "16px",
                        m: "10px 0px",
                      }}
                    >
                      {`Thank you. Owner of ${tontine.singleTontine.name} have sent invite email to join tontine & contribute. Please click the Join button to continue tontine & fill further details.`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{
                          mt: 1,
                          width: "100%",
                          height: "50px",
                        }}
                        onClick={handleRequest}
                        disabled={disableBtn}
                      >
                        Join
                      </Button>
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{
                          mt: 1,
                          width: "100%",
                          height: "50px",
                        }}
                        onClick={() => navigate("/admin")}
                        disabled={disableBtn}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          ) : (
            <>
              <Box
                fullWidth
                sx={{
                  background: "rgb(89, 32, 145)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Paper
                  sx={{
                    zIndex: 1,
                    background: "#f1efeb",
                    padding: 4,
                    margin: 5,
                    mt: "100px",
                    width: "400px",
                  }}
                  className="ForgetPasswordForm"
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        align="left"
                        width={"100%"}
                        sx={{
                          textAlign: "center",
                          marginBottom: "20px",
                          fontWeight: "600",
                        }}
                      >
                        {`You reject the invited to join ${tontine.singleTontine.name} tontine`}
                      </Typography>
                      <Box sx={{ display: "grid", placeContent: "center" }}>
                        <img
                          src={tontine.singleTontine.image}
                          alt="Tontine"
                          height={150}
                          style={{ maxWidth: "250px" }}
                        />
                      </Box>
                      <Box>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{
                            mt: 1,
                            width: "100%",
                            height: "50px",
                          }}
                          onClick={handleReject}
                          disabled={disableBtn}
                        >
                          Confirm
                        </Button>
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                          fontSize: "16px",
                          m: "10px 0px",
                        }}
                      >
                        {`Thank you for your response we will inform owner of ${tontine.singleTontine.name}.`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </>
          )
        ) : (
          <Box
            sx={{
              pt: "5rem",
              pb: "1rem",
              display: "grid",
              placeContent: "center",
            }}
          >
            <Paper
              sx={{
                width: "40rem",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#f1efeb",
              }}
            >
              <Typography
                variant="h3"
                sx={{ textAlign: "center", padding: "2rem 1rem" }}
              >
                You are not invited for this tontine to participate
              </Typography>
            </Paper>
          </Box>
        )
      ) : (
        <React.Suspense
          fallback={
            <div sx={{ minHeight: "540px", background: "#f1efeb" }}></div>
          }
        >
          <Signin />
        </React.Suspense>
      )}
    </>
  );
}
