import React from "react";
import { useDispatch } from "react-redux";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import {
  fewUpdateKitty,
  updateKittySetting,
} from "../../../store/thunk/Kitty/UpdateKittyListThunk";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../../store/store";
import moment from "moment";

function Visibility(id) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const list = useAppSelector((state) => state.DetailsOfKitty.list);
  const Settings = useAppSelector((state) => state.DetailsOfKitty.settings);
  const [formError, setFormError] = React.useState([]);
  const [newdata, setNewData] = React.useState({});
  const [newSettingData, setSettingData] = React.useState({});

  React.useEffect(() => {
    setNewData({
      name: list.name,
      beneficiary_name: list.beneficiary_name,
      id: list.id,
      visibility: list.visibility,
      status: list.status,
    });
    setSettingData(Settings);
  }, [list, Settings]);

  const validateForm = (data) => {
    let error = [];

    if (data.name === "") {
      error.push("name");
    }
    setFormError(error);

    return error;
  };

  const updateData = (text, event) => {
    setNewData({
      ...newdata,
      [text]: event.target.value,
    });
  };

  function handleDateChange(newValue) {
    setSettingData({
      ...newSettingData,
      end_date: `${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`,
    });
  }

  const updateVisibilityData = (event) => {
    setNewData({
      ...newdata,
      visibility: event.target.value,
    });
  };

  const updateStatusData = (event) => {
    setNewData({
      ...newdata,
      status: event.target.value,
      closed_date: moment().format("YYYY-MM-DD"),
    });
  };

  const handleSubmit = () => {
    let error = validateForm(newdata);
    if (error.length === 0) {
      let req = {
        end_date: newSettingData.end_date,
        id: newSettingData.id,
      };
      dispatch(fewUpdateKitty(newdata));
      dispatch(updateKittySetting(req));
      // handleClose();
    }
  };

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "600 !important",
            textTransform: "uppercase !important",
            marginBottom: "10px !important",
            fontSize: "16px !important",
          }}
        >
          {t("kittySettingVisibility.0")}:
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          size="small"
          id="name"
          value={newdata.name}
          onChange={(e) => {
            updateData("name", e);
          }}
          type="text"
          fullWidth
          variant="outlined"
          error={formError.includes("name")}
          helperText={
            formError.includes("name")
              ? t("kittySettingVisibilityValidation.0")
              : ""
          }
        />
      </Grid>
      <Grid xs={12}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "600 !important",
            textTransform: "uppercase !important",
            marginBottom: "10px !important",
            marginTop: "20px !important",
            fontSize: "16px !important",
          }}
        >
          {t("kittySettingVisibility.1")}:
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          size="small"
          id="name"
          value={newdata.beneficiary_name}
          onChange={(e) => {
            updateData("beneficiary_name", e);
          }}
          type="text"
          fullWidth
          variant="outlined"
          // error={formError.includes("name")}
          // helperText={
          //   formError.includes("name")
          //     ? "Please enter name of kitty"
          //     : ""
          // }
        />
      </Grid>
      <Grid xs={12}>
        <FormControl margin="normal">
          <FormLabel
            id="demo-controlled-radio-buttons-group1"
            sx={{
              fontWeight: "600 !important",
              textTransform: "uppercase !important",
              marginBottom: "10px !important",
              fontSize: "16px !important",
              color: "#000 !important",
            }}
          >
            {t("kittySettingVisibility.2")}:
          </FormLabel>
          <RadioGroup
            name="controlled-radio-buttons-group1"
            value={newdata?.visibility}
            sx={{ display: "inline-block" }}
            onChange={(e) => {
              updateVisibilityData(e);
            }}
          >
            <FormControlLabel
              value="PRIVATE"
              control={<Radio checked={newdata?.visibility === "PRIVATE"} />}
              label={t("kittySettingVisibility.5")}
            />
            <FormControlLabel
              value="PUBLIC"
              control={<Radio checked={newdata?.visibility === "PUBLIC"} />}
              label={t("kittySettingVisibility.4")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {list?.kitty_for_payout?.length > 0 ? null : (
        <Grid xs={12}>
          <FormControl margin="normal">
            <FormLabel
              id="demo-controlled-radio-buttons-group1"
              sx={{
                fontWeight: "600 !important",
                textTransform: "uppercase !important",
                marginBottom: "10px !important",
                fontSize: "16px !important",
                color: "#000 !important",
              }}
            >
              {t("kittySettingVisibility.6")}:
            </FormLabel>
            <RadioGroup
              name="controlled-radio-buttons-group1"
              value={newdata?.status}
              sx={{ display: "inline-block" }}
              onChange={(e) => {
                updateStatusData(e);
              }}
            >
              <FormControlLabel
                value="OPEN"
                control={<Radio checked={newdata?.status === "OPEN"} />}
                label={t("kittySettingVisibility.7")}
              />
              <FormControlLabel
                value="CLOSED"
                control={<Radio checked={newdata?.status === "CLOSED"} />}
                label={t("kittySettingVisibility.8")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      <Grid xs={12}>
        <FormControl margin="normal">
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            sx={{
              fontWeight: "600 !important",
              textTransform: "uppercase !important",
              marginBottom: "10px !important",
              fontSize: "16px !important",
              color: "#000 !important",
            }}
          >
            {t("kittySettingVisibility.3")}
          </FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            // error={formError.includes("birth_date")}
          >
            <Stack fullWidth>
              <DesktopDatePicker
                inputFormat={
                  localStorage.getItem("language") == "en"
                    ? "YYYY-MM-DD"
                    : "DD-MM-YYYY"
                }
                value={newSettingData?.end_date || new Date()}
                onChange={(newValue) => handleDateChange(newValue)}
                renderInput={(params) => <TextField {...params} />}
                disablePast
                sx={{
                  ".PrivatePickersYear-yearButton": {
                    "& .Mui-selected": {
                      textAlign: "Center",
                    },
                  },
                }}
              />
            </Stack>
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid xs={12} sx={{ textAlign: "center" }}>
        <Button
          onClick={() => handleSubmit()}
          sx={{
            background: "#592091",
            color: "#fff",
            display: "block",
            marginTop: "10px",
            padding: "8px 20px",
            fontWeight: "600",
            letterSpacing: " 2px",
            border: "1px solid #592091",
            "&:hover": {
              color: "#592091",
            },
          }}
        >
          {t("submitLabel")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default Visibility;
