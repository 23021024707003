import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

import ContactImage from "../../../assets/Images/contactUs.jpg";
import { sendContactDetails } from "../../../store/thunk/newUserThunk";
import { useAppDispatch } from "../../../store/store";

const InitContactData = {
  contact_email: "",
  contact_name: "",
  description: "",
};

function ContactUs() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [formError, setFormError] = React.useState([]);
  const [formData, setFormData] = React.useState(InitContactData);

  var mailformat =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

  const validateForm = (data) => {
    let error = [];

    if (data.contact_name.trim() === "" || !data.contact_name) {
      error.push("contact_name");
    }

    if (data.contact_email === "" || !data.contact_email) {
      error.push("contact_email");
    }
    if (!data?.contact_email?.match(mailformat) || !data.contact_email) {
      error.push("contact_email_invalid");
    }

    if (data.description.trim() === "" || !data.description) {
      error.push("description");
    }

    setFormError(error);

    return error;
  };
  const handleSubmitData = () => {
    let error = validateForm(formData);
    if (error.length == 0) {
      let request = {
        ...formData,
      };

      dispatch(
        sendContactDetails({
          request: request,
          callback: () => {
            setFormData(InitContactData);
          },
        })
      );
    }
  };

  const handleContactDetailChange = (name, event) => {
    setFormData({
      ...formData,
      [name]: event.target.value,
    });

    const index = formError.indexOf(name);
    if (index !== -1) {
      formError.splice(index, 1);
    }

    if (name == "contact_email") {
      const index = formError.indexOf("contact_email_invalid");
      if (index !== -1) {
        formError.splice(index, 1);
      }
    }
  };

  return (
    <Box sx={{ backgroundColor: "#592091" }}>
      <Box sx={{ padding: { md: 10, xs: 2 }, paddingBottom: 1 }}>
        <Paper>
          <Grid container>
            <Grid xs={12} md={6}>
              <img
                src={ContactImage}
                alt="Contact us"
                loading="lazy"
                style={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Box sx={{ p: { md: 3, xs: "0 20px" } }}>
                <Typography
                  variant="h4"
                  sx={{
                    textTransform: "uppercase",
                    color: "#592091",
                    fontSize: { md: "34px", xs: "25px " },
                    fontWeight: "600",
                    mb: 1,
                    textAlign: "center",
                  }}
                >
                  {t("ContactUsPage.0")}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "capitalize",
                    color: "#000",
                    fontWeight: "600",
                    fontSize: { md: "20px", xs: "14px " },
                    textAlign: "center",
                  }}
                >
                  {t("ContactUsPage.1")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: { md: "0 20px", xs: 0 },
                }}
              >
                <Box
                  sx={{
                    padding: { md: "18px 4px", xs: " 5px 4px" },
                    m: 1,
                    display: "inline-block",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    label={t("userNameLabel")}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      handleContactDetailChange("contact_name", e);
                    }}
                    value={formData.contact_name}
                    error={formError.includes("contact_name")}
                    helperText={
                      formError.includes("contact_name")
                        ? t("fieldRequiredLbl")
                        : ""
                    }
                  />
                </Box>
                <Box
                  sx={{
                    padding: { md: "18px 4px", xs: " 5px 4px" },
                    m: 1,
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    label={t("createEmailLable")}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      handleContactDetailChange("contact_email", e);
                    }}
                    value={formData.contact_email}
                    error={
                      formError.includes("contact_email") ||
                      formError.includes("contact_email_invalid")
                    }
                    helperText={
                      formError.includes("contact_email") ||
                      formError.includes("contact_email_invalid")
                        ? formError.includes("contact_email")
                          ? t("fieldRequiredLbl")
                          : t("emailValidation")
                        : ""
                    }
                  />
                </Box>
                <Box
                  sx={{
                    padding: { md: "18px 4px", xs: " 5px 4px" },
                    m: 1,
                  }}
                >
                  <TextField
                    id="outlined-multiline-static"
                    label={t("ContactUsPage.2")}
                    multiline
                    rows={4}
                    placeholder={t("ContactUsPage.3")}
                    fullWidth
                    onChange={(e) => {
                      handleContactDetailChange("description", e);
                    }}
                    value={formData.description}
                    error={formError.includes("description")}
                    helperText={
                      formError.includes("description")
                        ? t("fieldRequiredLbl")
                        : ""
                    }
                  />
                </Box>
                <Box
                  sx={{
                    padding: { md: "18px 4px", xs: " 5px 4px" },
                    m: 1,
                    textAlign: "center",
                  }}
                >
                  <Button
                    sx={{
                      margin: "0 8px",
                      backgroundColor: "#592091",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#4b2373",
                        color: "#fff",
                      },
                      padding: "10px 38px",
                    }}
                    onClick={handleSubmitData}
                  >
                    {t("sendLabel")}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
}

export default ContactUs;
