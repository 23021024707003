/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../../../common/DataTableBase";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { getListOfBlockedUsers } from "../../../../../store/thunk/Admin/UserListThunk";
import { Button, Box, Avatar, Chip } from "@mui/material";
import AdminUnblockAction from "./AdminUnblockAction";
import { createTheme } from "react-data-table-component";
import SearchBar from "../../../../common/SearchBar";
import CustomMPagination from "../../../../common/CustomMPagination";

if (!localStorage.getItem("SuperAdminUserBlockListPageCount")) {
  localStorage.setItem("SuperAdminUserBlockListPageCount", 0);
  localStorage.setItem("SuperAdminUserBlockListRecordLimit", 10);
  localStorage.setItem("SuperAdminUserBlockListSearch", "");
}

function AdminBlockUsersComponent() {
  const dispatch = useAppDispatch();
  const [pageCount, SetPageCount] = useState(
    localStorage.getItem("SuperAdminUserBlockListPageCount")
  );
  const [recordLimit, SetRecordLimit] = useState(
    localStorage.getItem("SuperAdminUserBlockListRecordLimit")
  );
  const [pageCountShow, SetPageCountShow] = useState(
    (Number(pageCount) + Number(recordLimit)) / Number(recordLimit)
  );
  const { t } = useTranslation();
  const blockedUserListState = useAppSelector((state) => state.UserProfile);
  const [search, setSearch] = useState("");

  useEffect(() => {
    localStorage.setItem("SuperAdminUserBlockListPageCount", Number(pageCount));
    localStorage.setItem(
      "SuperAdminUserBlockListRecordLimit",
      Number(recordLimit)
    );
    localStorage.setItem("SuperAdminUserBlockListSearch", search);
  }, [pageCount, recordLimit, search, localStorage.getItem("language")]);

  const notIsGuest = (row) => {
    return row.is_superuser ? (
      <img
        width={50}
        height={50}
        style={{ borderRadius: "100%", border: "solid 3px #ffd700" }}
        src={row.avatar}
        alt=""
      />
    ) : (
      <img
        width={50}
        height={50}
        style={{ borderRadius: "50%" }}
        src={row.avatar}
        alt=""
      />
    );
  };

  const getSuperLabel = (row) => {
    return row.is_superuser ? "Super User" : "Verified User";
  };

  const columns = [
    {
      name: t("tontineTableColumn.0"),
      selector: useCallback(
        (row) =>
          row.is_guest ? (
            <Chip
              sx={{ borderRadius: 1 }}
              avatar={<Avatar alt="Natacha" src={row.avatar} />}
              label={t("guestUserLabel")}
              variant="outlined"
            />
          ) : (
            notIsGuest(row)
          ),
        []
      ),
      width: "9rem",
    },
    {
      name: t("tontineNameFieldLabel"),
      selector: (row) => `${row.first_name || ""} ${row.name || ""}`,
    },
    {
      name: t("myAccountEmailLable"),
      selector: (row) => row.email,
      width: "16rem",
    },
    {
      name: t("cityLabel"),
      selector: (row) => row.city,
    },
    {
      name: t("myAccountCountryLable"),
      selector: (row) => row.country,
    },
    {
      name: t("userTypeLabel"),
      selector: (row) => (row.is_guest ? "Guest User" : getSuperLabel(row)),
    },
    {
      name: t("kittyCatListingTableLable.5"),
      width: "6rem",
      cell: useCallback(
        (row) => (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "rows",
              width: "1rem",
            }}
          >
            <Box
              style={{
                width: "2rem",
                display: "flex",
                margin: "10px",
                lineHeight: 0,
                minWidth: "64px",
                padding: 0,
              }}
            >
              <Button>
                <AdminUnblockAction
                  data={row}
                  limit={Number(recordLimit)}
                  offSet={Number(pageCount)}
                  from="admin"
                />
              </Button>
            </Box>
          </Box>
        ),
        []
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    dispatch(
      getListOfBlockedUsers({
        limit: Number(recordLimit),
        offSet: Number(pageCount),
        search,
      })
    );
    SetPageCountShow(
      (Number(pageCount) + Number(recordLimit)) / Number(recordLimit)
    );
  }, [pageCount, recordLimit, search]);

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     dispatch(
  //       getListOfBlockedUsers({
  //         limit: recordLimit,
  //         offSet: 0,
  //         search,
  //         callback: () => {
  //           if (search !== "") {
  //             SetPageCount(0);
  //           }
  //         },
  //       })
  //     );
  //   }, 500);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [search]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "#ccc",
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "#fff ",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        textAlign: "center",
        fontSize: "17px",
        display: "grid",
        placeContent: "center",
        backgroundColor: "#2a2828",
        color: "#fff",
        maxWidth: "40px",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        padding: "8px",
        fontSize: "14px",
        display: "grid",
        placeContent: "center",
      },
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );

  const LastPageCount = () => {
    return Math.round(
      (blockedUserListState?.listOfBlockedUsers?.count / Number(recordLimit) -
        Math.floor(
          blockedUserListState?.listOfBlockedUsers?.count / Number(recordLimit)
        )) *
        Number(recordLimit)
    );
  };

  return (
    <Box className="kittyTable">
      <Box sx={{ padding: { md: 2, xs: 1 } }}>
        <DataTable
          title={
            <>
              <Box
                className="tableTitle"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                {t("kittyBlockedUserListLabel")}
              </Box>
            </>
          }
          columns={columns}
          data={blockedUserListState.listOfBlockedUsers.results}
          customStyles={customStyles}
          theme="solarized"
          progressPending={blockedUserListState.loading}
          actions={
            <Box
              sx={{
                marginBottom: { lg: "0", xs: "10px" },
                mr: { md: "10px", xs: "0px" },
                mt: { md: "0", xs: "10px" },
              }}
            >
              <SearchBar searchVal={search} setSearchFunc={setSearch} />
            </Box>
          }
          noDataComponent={<Box sx={{ p: 3 }}>{t("norecordLbl")}</Box>}
          className="rdt_TableHeader"
        />
      </Box>
      <CustomMPagination
        pageCount={pageCount}
        SetPageCount={SetPageCount}
        recordLimit={recordLimit}
        pageCountShow={pageCountShow}
        SetRecordLimit={SetRecordLimit}
        numRecords={blockedUserListState?.listOfBlockedUsers?.num_records}
        numCount={blockedUserListState?.listOfBlockedUsers?.count}
      />
    </Box>
  );
}

export default AdminBlockUsersComponent;
