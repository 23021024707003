import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function LegalImprint() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        pt: "5rem",
        backgroundColor: "#f1efeb",
        width: "100%",
        pb: "0 !important",
      }}
    >
      <Container>
        <Divider sx={{ whiteSpace: { md: "nowrap", xs: "normal" } }}>
          <Typography
            className="top-subheading"
            variant="h3"
            sx={{
              fontWeight: "700",
              fontSize: { md: "30px", xs: "25px" },
              m: "10px 0px",
            }}
          >
            {t("TermsAndConditions.0")}
          </Typography>
        </Divider>
        <Box>
          <Box sx={{ m: "20px 0", textAlign: { md: "justify", xs: "left" } }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { md: "18px", xs: "14px" },
                    fontWeight: "600",
                    display: "block",
                    p: { md: 1, xs: "16px 8px" },
                    color: "#592091",
                  }}
                >
                  {t("TermsAndConditions.1")}
                </Typography>
                <Box sx={{ p: { md: 2, xs: 1 } }}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: { md: "18px", xs: "15px" } }}
                  >
                    {t("TermsAndConditions.2")}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0" }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    display: "block",
                    p: 2,
                    color: "#592091",
                  }}
                >
                  {t("TermsAndConditions.3")}
                </Typography>
                <Divider sx={{ margin: "0 14px" }} />
                <Box sx={{ p: 2 }}>
                  <Box sx={{ ml: 3, fontSize: "18px" }}>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.4")}
                    </Box>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.5")}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0" }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    display: "block",
                    p: 2,
                    color: "#592091",
                  }}
                >
                  {t("TermsAndConditions.6")}
                </Typography>
                <Divider sx={{ margin: "0 14px" }} />
                <Box sx={{ p: 2 }}>
                  <Box sx={{ ml: 3, fontSize: "18px" }}>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.7")}
                    </Box>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.8")}
                    </Box>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.9")}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0" }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    display: "block",
                    p: 2,
                    color: "#592091",
                  }}
                >
                  {t("TermsAndConditions.10")}
                </Typography>
                <Divider sx={{ margin: "0 14px" }} />
                <Box sx={{ p: 2 }}>
                  <Box sx={{ ml: 3, fontSize: "18px" }}>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.11")}
                    </Box>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.12")}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0" }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    display: "block",
                    p: 2,
                    color: "#592091",
                  }}
                >
                  {t("TermsAndConditions.13")}
                </Typography>
                <Divider sx={{ margin: "0 14px" }} />
                <Box sx={{ p: 2 }}>
                  <Box sx={{ ml: 3, fontSize: "18px" }}>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.14")}
                    </Box>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.15")}
                    </Box>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.16")}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0" }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    display: "block",
                    p: 2,
                    color: "#592091",
                  }}
                >
                  {t("TermsAndConditions.17")}
                </Typography>
                <Divider sx={{ margin: "0 14px" }} />
                <Box sx={{ p: 2 }}>
                  <Box sx={{ ml: 3, fontSize: "18px" }}>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.18")}
                    </Box>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.19")}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0" }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    display: "block",
                    p: 2,
                    color: "#592091",
                  }}
                >
                  {t("TermsAndConditions.20")}
                </Typography>
                <Divider sx={{ margin: "0 14px" }} />
                <Box sx={{ p: 2 }}>
                  <Box sx={{ ml: 3, fontSize: "18px" }}>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.21")}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0" }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    display: "block",
                    p: 2,
                    color: "#592091",
                  }}
                >
                  {t("TermsAndConditions.22")}
                </Typography>
                <Divider sx={{ margin: "0 14px" }} />
                <Box sx={{ p: 2 }}>
                  <Box sx={{ ml: 3, fontSize: "18px" }}>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.23")}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0" }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    display: "block",
                    p: 2,
                    color: "#592091",
                  }}
                >
                  {t("TermsAndConditions.24")}
                </Typography>
                <Divider sx={{ margin: "0 14px" }} />
                <Box sx={{ p: 2 }}>
                  <Box sx={{ ml: 3, fontSize: "18px" }}>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.25")}
                    </Box>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.26")}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ m: "20px 0" }}>
            <Paper>
              <Box sx={{ backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "22px",
                    fontWeight: "600",
                    display: "block",
                    p: 2,
                    color: "#592091",
                  }}
                >
                  {t("TermsAndConditions.27")}
                </Typography>
                <Divider sx={{ margin: "0 14px" }} />
                <Box sx={{ p: 2 }}>
                  <Box sx={{ ml: 3, fontSize: "18px" }}>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.28")}
                    </Box>
                  </Box>
                  <Box sx={{ ml: 3, fontSize: "18px" }}>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.29")}
                    </Box>
                  </Box>
                  <Box sx={{ ml: 3, fontSize: "18px" }}>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.30")}
                    </Box>
                  </Box>
                  <Box sx={{ ml: 3, fontSize: "18px" }}>
                    <Box sx={{ textAlign: "justify" }}>
                      {t("TermsAndConditions.31")}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box
            sx={{
              textAlign: { md: "justify", xs: "left" },
              pb: 1,
            }}
          >
            <Paper>
              <Box sx={{ p: { md: 2, xs: 1 }, backgroundColor: "#dac0f4" }}>
                <Typography
                  variant="p"
                  sx={{ fontSize: { md: "18px", xs: "15px" } }}
                >
                  {t("TermsAndConditions.32")}
                </Typography>
              </Box>
              <Typography
                variant="p"
                sx={{
                  fontSize: { md: "18px", xs: "14px" },
                  fontWeight: "600",
                  display: "block",
                  p: { md: 1, xs: "16px 8px" },
                  color: "#592091",
                  backgroundColor: "#dac0f4",
                }}
              >
                {t("TermsAndConditions.33")} <br />
                {t("TermsAndConditions.34")}
              </Typography>
            </Paper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default LegalImprint;
