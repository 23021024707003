import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setDeleteKittyCat,
  setKittyCatDeleteError,
} from "../../reducer/KittyCategorySlice/DeleteKittyCatListSlice";
import axios from "axios";
import { listofKittyCat } from "./listofKittyCatThunk";
import { setSuccessAlert, setErrorAlert } from "../../reducer/AlertSlice";
import { DeleteData } from "../../reducer/User/TokenExpireSlice";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Authorization"] = localStorage.getItem("UserInfo")
  ? `Token ${localStorage.getItem("UserInfo")}`
  : "";

export const deleteKittyCat = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    let kittyId = _request;
    if (_request?.kitty_id) {
      kittyId = _request?.kitty_id;
    }
    axios
      .delete(`api/v1/kitty/kitty-category/${kittyId}/`)
      .then((response) => {
        dispatch(setDeleteKittyCat(response.data));
        if (_request.deleteCallback) {
          _request.deleteCallback();
        } else {
          dispatch(listofKittyCat({ limit: 10, offSet: 0 }));
        }
        dispatch(setSuccessAlert("Deleted successfully"));
      })
      .catch((err) => {
        dispatch(setKittyCatDeleteError(err));
        dispatch(setErrorAlert(err?.response?.data?.message));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
