import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function CustomMPagination({
  pageCount,
  SetPageCount,
  recordLimit,
  pageCountShow,
  SetRecordLimit,
  numRecords,
  numCount,
}) {
  const { t } = useTranslation();

  const LastPageCount = () => {
    return Math.round(
      (numCount / Number(recordLimit) -
        Math.floor(numCount / Number(recordLimit))) *
        Number(recordLimit)
    );
  };

  return (
    <Box
      className="pagination"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { md: "row", xs: "column" },
        alignItems: "center",
        pb: "25px",
      }}
    >
      <Box
        className="showing-num-entries"
        sx={{
          mb: { md: "0", xs: "10px" },
          ml: { md: "15px", xs: "0" },
        }}
      >
        <Typography variant="p">{`${t("ShowingLable")} ${
          numRecords ? Number(pageCount) + 1 : 0
        } ${t("ToLable")} ${(numRecords || 0) + Number(pageCount)} ${t(
          "OfLable"
        )} ${numCount || 0} ${t("EntriesLable")}`}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
        }}
      >
        <Box
          sx={{
            mb: {
              md: "0",
              xs: "10px",
              alignItems: "center",
              display: "flex",
            },
          }}
        >
          <Typography
            variant="p"
            sx={{
              padding: "0 8px",
              fontSize: "16px",
              fontWeight: "500",
              justifyContent: "center",
            }}
          >
            {t("RowsPageLable")}
          </Typography>
          <FormControl sx={{ padding: "0 8px" }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              sx={{
                lineHeight: "unset",
              }}
              value={Number(recordLimit)}
              onChange={(event) => {
                SetRecordLimit(event.target.value);
                SetPageCount(0);
              }}
              classes={{
                select: {
                  selectRoot: {
                    margin: "10px", // Update the margin here as per your requirement
                  },
                },
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={10000}>{t("allLable")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ alignItems: "center", display: "flex" }}>
          <Tooltip title={t("FirstPageLable")}>
            <Button
              onClick={() => SetPageCount(0)}
              disabled={Number(pageCount) < 1 ? true : false}
              sx={{ margin: "0 8px" }}
            >
              <KeyboardDoubleArrowLeftIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t("previousLabel")}>
            <Button
              onClick={() =>
                SetPageCount(
                  Number(pageCount) < Number(recordLimit)
                    ? Number(pageCount) + Number(recordLimit)
                    : Number(pageCount) - Number(recordLimit)
                )
              }
              disabled={Number(pageCount) < 1 ? true : false}
              sx={{ margin: "0 8px" }}
            >
              <KeyboardArrowLeft />
            </Button>
          </Tooltip>
          <Typography variant="h7" padding={1}>
            {Math.ceil(pageCountShow)}
          </Typography>
          <Tooltip title={t("nextLabel")}>
            <Button
              onClick={() =>
                SetPageCount(Number(pageCount) + Number(recordLimit))
              }
              disabled={
                numCount <= Number(pageCount) + Number(recordLimit) ||
                numCount <= Number(recordLimit)
                  ? true
                  : false
              }
              sx={{ margin: "0 8px" }}
            >
              <KeyboardArrowRight />
            </Button>
          </Tooltip>
          <Tooltip title={t("LastPageLable")}>
            <Button
              onClick={() => SetPageCount(numCount - LastPageCount())}
              disabled={
                numCount <= Number(pageCount) + Number(recordLimit) ||
                numCount <= Number(recordLimit)
                  ? true
                  : false
              }
              sx={{ margin: "0 8px" }}
            >
              <KeyboardDoubleArrowRightIcon />
            </Button>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
}

export default CustomMPagination;
