import { Box } from "@mui/material";
import SubmoneypotsImage from "../../../../assets/Images/submoneypotsImage.png";
import TransactionsSecurity from "./TransactionsSecurity";
import DataProtectionBlock from "./DataProtectionBlock";
import CardVerifiedBlock from "./CardVerifiedBlock";

export default function InnerServicePage() {
  return (
    <Box>
      <TransactionsSecurity />
      <DataProtectionBlock />
      <CardVerifiedBlock />
      <Box sx={{ background: "#f1efeb" }}>
        {/* <Container>
                    <Grid container sx={{
                        paddingTop: { md: "3.75rem", xs: "20px" },
                        paddingBottom: { md: "3.75rem", xs: "20px" },
                        display: { md: "flex", xs: "block" }
                    }}>
                        <Grid item md={4} sx={{ textAlign: " center", p: { md: "0 20px", xs: "20px" } }}>
                            <SettingsIcon sx={{
                                background: "#592091",
                                color: "#fff",
                                padding: "20px",
                                borderRadius: "50%",
                                marginBottom: "10px",
                                fontSize: "45px"
                            }} />
                            <Typography variant="h6" sx={{
                                background: "linear-gradient(to left, #592091, #4b0082, #91bcff, #1064e7, #808080, #4b0082, #0161f7)",
                                WebkitBackgroundClip: "text",
                                color: " transparent"
                            }}> How it works</Typography>
                            <Typography variant="body" sx={{ color: "#555", fontSize: "1.25rem" }}> Find out more information about our service</Typography>
                        </Grid>
                        <Grid item md={4} sx={{ textAlign: " center", p: { md: "0 20px", xs: "20px" } }}>
                            <LockIcon sx={{
                                background: "#592091",
                                color: "#fff",
                                padding: "20px",
                                borderRadius: "50%",
                                marginBottom: "10px",
                                fontSize: "45px"
                            }} />
                            <Typography variant="h6" sx={{
                                background: "linear-gradient(to left, #592091, #4b0082, #91bcff, #1064e7, #808080, #4b0082, #0161f7)",
                                WebkitBackgroundClip: "text",
                                color: " transparent"
                            }}> Security</Typography>
                            <Typography variant="body" sx={{ color: "#555", fontSize: "1.25rem" }}>Find out how we protect your transactions</Typography>
                        </Grid>
                        <Grid item md={4} sx={{ textAlign: " center", p: { md: "0 20px", xs: "20px" } }}>
                            <SettingsIcon sx={{
                                background: "#592091",
                                color: "#fff",
                                padding: "20px",
                                borderRadius: "50%",
                                marginBottom: "10px",
                                fontSize: "45px"
                            }} />
                            <Typography variant="h6" sx={{
                                background: "linear-gradient(to left, #592091, #4b0082, #91bcff, #1064e7, #808080, #4b0082, #0161f7)",
                                WebkitBackgroundClip: "text",
                                color: " transparent"
                            }}> Help Center</Typography>
                            <Typography variant="body" sx={{ color: "#555", fontSize: "1.25rem" }}>Get answers to frequently asked questions</Typography>
                        </Grid>
                    </Grid>
                </Container> */}
      </Box>
      <Box
        className="SubmoneypotsImage"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={SubmoneypotsImage} alt="SubmoneypotsImage" />
      </Box>
    </Box>
  );
}
