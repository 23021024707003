import { Navigate } from "react-router-dom";
import { useAppSelector } from "./store/store";

function PrivateRoute(props) {
  const { component } = props;
  const userAuthData = useAppSelector((state) => state.userSlice);
  const userCheck = useAppSelector((state) => state.UserProfile);

  return userAuthData?.token && userCheck?.details?.first_name !== null ? (
    component
  ) : userAuthData?.token && userCheck?.details?.first_name === null ? (
    <Navigate to="/sign-up" />
  ) : (
    <Navigate to="/sign-in" />
  );
}

export default PrivateRoute;
