import { Box, Input, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setNewKittyCreate } from "../../store/reducer/KittySlice/CreateKittySlice";

export default function MoneyPotsFormGroup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [kittyName, setKittyName] = useState("");
  const [formError, setFormError] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateForm = (data) => {
    let error = [];

    if (kittyName === "") {
      error.push("kittyName");
    }
    setFormError(error);

    return error;
  };

  const handleSubmit = () => {
    let error = validateForm(kittyName);
    if (error.length == 0) {
      dispatch(setNewKittyCreate({ kittyName, id: allkitty }));
      navigate("/kittycreation");
    }
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
      // navigate("/kittycreation");
    }
  };

  const setKittyData = (e) => {
    setKittyName(e.target.value);
    const index = formError.indexOf("kittyName");
    if (index !== -1) {
      formError.splice(index, 1);
    }
  };

  const { allkitty } = useParams();
  return (
    <>
      <FormGroup
        className="form-group-slider"
        row
        sx={{
          justifyContent: "center",
          border: "none",
          maxWidth: " 400px",
          flexWrap: "nowrap",
          margin: "0 auto",
          position: " absolute",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
        }}
      >
        <Input
          className="create-pot"
          style={{
            border: "none",
            background: " white",
            borderRadius: "5px",
            padding: "0 10px",
            height: { md: "50px", xs: "40px" },
            width: "100%",
            margin: "15px 0",
          }}
          onChange={setKittyData}
          value={kittyName}
          onKeyDown={handleKeypress}
        />
        <Link to={kittyName ? "/kittycreation" : ""}>
          <Button
            sx={{
              color: "#fff",
              background: "#91bcff",
              ml: "5px",
              p: "0 30px",
              height: { md: "50px", xs: "40px" },
              margin: "15px 4px",
              "&:hover": {
                background: "#303030",
              },
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            {t("createButtonLbl")}
          </Button>
        </Link>
      </FormGroup>
      <Box
        className="form-group-slide-error"
        sx={{
          justifyContent: "center",
          border: "none",
          maxWidth: " 400px",
          flexWrap: "nowrap",
          margin: "0 auto",
          position: " absolute",
          left: "51%",
          transform: "translate(-50%, -50%)",
          width: "80%",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: "#f1efeb",
            fontWeight: "750",
            fontSize: "13px",
            fontFamily: "cursive",
          }}
        >
          {formError.includes("kittyName") ? (
            <>{t("fieldRequiredLbl")}</>
          ) : null}
        </Typography>
      </Box>
    </>
  );
}
