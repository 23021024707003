import * as React from "react";
import PaymentStepper from "./PaymentStepper";

export default function TontineSettings() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PaymentStepper />
    </>
  );
}
