import { createAsyncThunk } from "@reduxjs/toolkit";
import { setUpdatedKitty } from "../../reducer/KittySlice/UpdateKittyListSlice";
import axios from "axios";
import { setSuccessAlert, setErrorAlert } from "../../reducer/AlertSlice";
import { DetailsOfKitty } from "./KittyDetailsThunk";
import { DeleteData } from "../../reducer/User/TokenExpireSlice";
import { listofUserKitty } from "./listofKittyThunk";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Authorization"] = localStorage.getItem("UserInfo")
  ? `Token ${localStorage.getItem("UserInfo")}`
  : "";

export const updateKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    let form_data = new FormData();
    form_data.append("name", _request.name);
    form_data.append("object_of_kitty", _request.object_of_kitty);
    form_data.append("amount", _request.amount);
    form_data.append("id", _request.id);
    form_data.append("status", _request.status);
    // form_data.append('category', _request.category);
    if (_request.image) {
      form_data.append("image", _request.image || "");
    }

    axios.defaults.headers["Content-Type"] = "multipart/form-data";

    axios
      .patch(`api/v1/kitty/kitty/${_request.id}/`, form_data)
      .then((response) => {
        dispatch(setUpdatedKitty(response));
        dispatch(setSuccessAlert("Kitty updated successfully"));
        dispatch(listofUserKitty({ limit: 10, offSet: 0 }));
      })
      .catch((err) => {
        dispatch(setErrorAlert("something wrong try again"));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const fewUpdateKitty = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    let form_data = new FormData();
    if (_request.name) {
      form_data.append("name", _request.name);
    }
    if (_request.beneficiary_name) {
      form_data.append("beneficiary_name", _request.beneficiary_name);
    }
    if (_request.object_of_kitty) {
      form_data.append("object_of_kitty", _request.object_of_kitty);
    }
    if (_request.status) {
      form_data.append("status", _request.status);
    }
    if (_request.visibility) {
      form_data.append("visibility", _request.visibility);
    }
    if (_request.suggested_amount) {
      form_data.append("suggested_amount", _request.suggested_amount);
    }
    if (_request.amount) {
      form_data.append("amount", _request.amount);
    }
    if (_request.image) {
      form_data.append("image", _request.image || "");
    }
    if (_request.is_beneficiary) {
      form_data.append("is_beneficiary", _request.is_beneficiary || "");
    }
    if (_request.closed_date && _request.status) {
      form_data.append("closed_date", _request.closed_date || "");
    }
    axios.defaults.headers["Content-Type"] = "multipart/form-data";

    axios
      .patch(`api/v1/kitty/kitty/${_request.id}/`, form_data)
      .then((response) => {
        dispatch(setUpdatedKitty(response));
        dispatch(setSuccessAlert("Kitty updated successfully"));
        dispatch(DetailsOfKitty(response.data.id));
      })
      .catch((err) => {
        dispatch(setErrorAlert("something wrong try again"));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);

export const updateKittySetting = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    let form_data = new FormData();
    if (_request.end_date) {
      form_data.append("end_date", _request.end_date);
    }
    if (_request.hasOwnProperty("hide_target_total_amount")) {
      form_data.append(
        "hide_target_total_amount",
        _request.hide_target_total_amount
      );
    }
    if (_request.hasOwnProperty("hide_contribution_amount")) {
      form_data.append(
        "hide_contribution_amount",
        _request.hide_contribution_amount
      );
    }
    if (_request.hasOwnProperty("hide_all_contributors_info")) {
      form_data.append(
        "hide_all_contributors_info",
        _request.hide_all_contributors_info
      );
    }
    if (_request.hasOwnProperty("notify_is_contribution")) {
      form_data.append(
        "notify_is_contribution",
        _request.notify_is_contribution
      );
    }
    if (_request.hasOwnProperty("notify_all_contribution")) {
      form_data.append(
        "notify_all_contribution",
        _request.notify_all_contribution
      );
    }
    axios
      .patch(`api/v1/kitty/kitty-settings/${_request.id}/`, form_data)
      .then((response) => {
        if (_request.hasOwnProperty("notify_all_contribution"))
          dispatch(setSuccessAlert("Kitty updated successfully"));
        dispatch(DetailsOfKitty(response.data.kitty));
      })
      .catch((err) => {
        dispatch(setErrorAlert("something wrong try again"));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token."
        ) {
          dispatch(DeleteData());
        }
      });
  }
);
