import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Tooltip } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { createTheme } from "react-data-table-component";
import DataTable from "../../../../common/DataTableBase";
import InsertNewKitty from "../../../../../components/Kitty/KittyInsertNew";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { listofKittyForAdmin } from "../../../../../store/thunk/Kitty/listofKittyThunk";
import { listofKittyCat } from "../../../../../store/thunk/KittyCategory/listofKittyCatThunk";
import DeleteAlertBBox from "./AdminKittyDelete";
import CustomMPagination from "../../../../common/CustomMPagination";

if (!localStorage.getItem("SuperAdminKittyPageCount")) {
  localStorage.setItem("SuperAdminKittyPageCount", 0);
  localStorage.setItem("SuperAdminKittyRecordLimit", 10);
  localStorage.setItem("SuperAdminKittySearch", "");
  localStorage.setItem(
    "SuperAdminKittyCategory",
    localStorage.getItem("language") === "en" ? "All" : "All"
  );
  localStorage.setItem(
    "SuperAdminKittyStatus",
    localStorage.getItem("language") === "en" ? "All" : "All"
  );
}

export default function AdminKittyComponent() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [firstReload, setFirstReload] = useState(false); //To check first reload
  const [pageCount, SetPageCount] = useState(
    localStorage.getItem("SuperAdminKittyPageCount")
  );
  const [recordLimit, SetRecordLimit] = useState(
    localStorage.getItem("SuperAdminKittyRecordLimit")
  );
  const [pageCountShow, SetPageCountShow] = useState(
    (Number(pageCount) + Number(recordLimit)) / Number(recordLimit)
  );
  const data = useAppSelector((state) => state.listofKittySlice);
  const [search, setSearch] = useState(
    localStorage.getItem("SuperAdminKittySearch")
  );
  const [category, setCategory] = useState(
    localStorage.getItem("SuperAdminKittyCategory")
  );
  const [status, setStatus] = useState(
    localStorage.getItem("SuperAdminKittyStatus")
  );

  //to store filter & search key words in loacal storage, then even after page render we will them again
  useEffect(() => {
    localStorage.setItem("SuperAdminKittyPageCount", Number(pageCount));
    localStorage.setItem("SuperAdminKittyRecordLimit", Number(recordLimit));
    localStorage.setItem("SuperAdminKittySearch", search);
    localStorage.setItem("SuperAdminKittyCategory", category);
    localStorage.setItem("SuperAdminKittyStatus", status);
  }, [
    pageCount,
    recordLimit,
    search,
    category,
    status,
    localStorage.getItem("language"),
  ]);

  const columns = [
    {
      name: t("kittyListingTableLable.0"),
      selector: useCallback(
        (row) => (
          <img
            width={50}
            height={50}
            src={row.image}
            loading="lazy"
            style={{ border: "1px #5254535c solid", cursor: "pointer" }}
            alt=""
            onClick={() => navigate(`/kittypage/${row.id}/`)}
          />
        ),
        []
      ),
    },
    {
      name: t("kittyListingTableLable.1"),
      selector: (row) => row.name,
    },
    {
      name: t("kittyListingTableLable.2"),
      width: "10rem",
      cell: useCallback((row) => <Box>{row.category.category_name}</Box>, []),
    },
    {
      name: t("kittyListingTableLable.3"),
      width: "5rem",
      selector: (row) => row.status,
    },
    {
      name: t("kittyListingTableLable.5"),
      selector: (row) => row.amount,
      // sortable: true,
    },
    {
      name: "Creator",
      selector: (row) => `${row.user.first_name} ${row.user.name}`,
    },
    {
      name: t("kittyListingTableLable.6"),
      selector: (row) => row.created_at.slice(0, 10),
    },
    {
      name: t("kittyListingTableLable.9"),
      selector: (row) => row.closed_date,
    },
    {
      name: t("kittyListingTableLable.8"),
      width: "8rem",
      cell: useCallback(
        (row) => (
          <>
            <Box sx={{ display: "flex" }}>
              <Button sx={{ minWidth: "unset" }}>
                <Link to={`/admin-kitty-settings/0/${row.id}`}>
                  <Button sx={{ fontSize: "12px", color: "#592091" }}>
                    <Tooltip title={t("spendKittyOptionSettingsLable")}>
                      <SettingsOutlinedIcon
                        sx={{ fontSize: "24px", color: "#592091" }}
                      />
                    </Tooltip>
                  </Button>
                </Link>
              </Button>
              <DeleteAlertBBox
                data={row}
                SetPageCount={SetPageCount}
                recordLimit={recordLimit}
                search={search}
                category={category}
                status={status}
                pageCount={pageCount}
              />
            </Box>
          </>
        ),
        []
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount, recordLimit]);

  useEffect(() => {
    // Call fresh data from start when search or change category or change status filter
    if (firstReload) {
      SetPageCount(0);

      dispatch(
        listofKittyForAdmin({
          limit: Number(recordLimit),
          offSet: 0,
          search,
          category,
          status,
        })
      );

      SetPageCountShow(1);
    }
  }, [search, category, status]);

  useEffect(() => {
    if (Number(pageCount) >= data.list.count) {
      SetPageCount(Number(pageCount) - Number(recordLimit));
    }
    dispatch(
      listofKittyForAdmin({
        limit: Number(recordLimit),
        offSet: Number(pageCount),
        search,
        category,
        status,
      })
    );
    SetPageCountShow(
      (Number(pageCount) + Number(recordLimit)) / Number(recordLimit)
    );
  }, [pageCount, recordLimit]);

  useEffect(() => {
    dispatch(listofKittyCat({}));
    setFirstReload(true);
  }, []);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "#ccc",
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "#fff ",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        textAlign: "center",
        fontSize: "17px",
        display: "grid",
        placeContent: "center",
        backgroundColor: "#2a2828",
        color: "#fff",
        maxWidth: "40px",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        padding: "8px",
        fontSize: "14px",
        display: "grid",
        placeContent: "center",
      },
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );

  return (
    <>
      <Box className="kittyTable">
        <Box sx={{ borderRadius: 0, padding: { md: 2, xs: 1 } }}>
          <DataTable
            title={
              <>
                <Box
                  className="tableTitle"
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {t("kittyListLabel")}
                </Box>
              </>
            }
            columns={columns}
            data={data.list.results}
            customStyles={customStyles}
            theme="solarized"
            actions={
              <InsertNewKitty
                search={search}
                setSearch={setSearch}
                SetPageCount={SetPageCount}
                category={category}
                setCategory={setCategory}
                status={status}
                setStatus={setStatus}
                limit={Number(recordLimit)}
                offSet={Number(pageCount)}
              />
            }
            noDataComponent={<Box sx={{ p: 3 }}>{t("norecordLbl")}</Box>}
            className="rdt_TableHeader"
            progressPending={data.loading}
          />
          <CustomMPagination
            pageCount={pageCount}
            SetPageCount={SetPageCount}
            recordLimit={recordLimit}
            pageCountShow={pageCountShow}
            SetRecordLimit={SetRecordLimit}
            numRecords={data?.list?.num_records}
            numCount={data?.list?.count}
          />
        </Box>
      </Box>
    </>
  );
}
