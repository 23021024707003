import * as React from "react";
import {
  Paper,
  Button,
  List,
  ListItem,
  Checkbox,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { updateKittySetting } from "../../../store/thunk/Kitty/UpdateKittyListThunk";



export default function Notifications() {
  const settings = useAppSelector((state) => state.DetailsOfKitty.settings);
  const dispatch = useAppDispatch()
  const [checked1, setChecked1] = React.useState(settings?.notify_is_contribution)
  const [checked2, setChecked2] = React.useState(settings?.notify_all_contribution)
  const { t } = useTranslation();


  const handleSubmit = () => {
    let check = {
      id: settings?.id,
      notify_is_contribution: checked1,
      notify_all_contribution: checked2,
    }
    dispatch(updateKittySetting(check))
  }

  return (
    <>
      <Paper sx={{ marginTop: "10px", border: "1px solid #ddd" }}>
        <List
          dense
          sx={{ width: "100%", bgcolor: "background.paper" }}
        >
          <ListItem
            secondaryAction={
              <Checkbox checked={checked1} onChange={() => setChecked1(!checked1)} edge="end" inputProps={"aria-labelledby"} />
            }
            disablePadding
          >
            <ListItemButton onClick={() => setChecked1(!checked1)}>
              <ListItemText sx={{ fontWeight: "600" }}>
              {t("kittySettingNotification.0")}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem
            secondaryAction={
              <Checkbox checked={checked2} onChange={() => setChecked2(!checked2)} edge="end" inputProps={"aria-labelledby"} />
            }
            disablePadding
          >
            <ListItemButton onClick={() => setChecked2(!checked2)}>
              <ListItemText sx={{ fontWeight: "600" }}>{t("kittySettingNotification.1")}</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Paper>

      <Button
        sx={{
          background: "#592091",
          color: "#fff",
          display: "block",
          marginTop: "10px",
          padding: "8px 20px",
          fontWeight: "600",
          letterSpacing: " 2px",
          border: "1px solid #592091",
          "&:hover": {
            color: "#592091",
            background: "#fff"
          }
        }}
        variant="contained"
        onClick={() => {
          handleSubmit();
        }}
      >
        {t("saveLabel")}
      </Button>

    </>
  );
}
