import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import { Divider, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  setOpen,
  handleChange,
  imgError,
  setImgError,
}) {
  const { t } = useTranslation();

  React.useEffect(() => {
    setImgError("");
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        PaperProps={{ sx: { m: { md: "30px", xs: "10px" } } }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            background: " #592091",
            color: "#fff",
          }}
        >
          {"Image"}
          <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#ffff",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {imgError && (
              <Alert severity="error" sx={{ mb: "10px" }}>
                {imgError}
              </Alert>
            )}
            <ul style={{ margin: "0px", padding: "0px 10px" }}>
              <li style={{ listStyle: "decimal" }}>
                {t("settingImagePopupContent1")}
              </li>
              <li style={{ listStyle: "decimal" }}>
                {t("settingImagePopupContent2")}
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              mr: 1,
              borderColor: "#592091",
              color: "#592091",
              "&:hover": {
                borderColor: "#592091",
              },
            }}
            variant="outlined"
          >
            {t("cancelLable")}
          </Button>
          <Button
            variant="contained"
            component="label"
            sx={{
              background: "#592091",
              "&:hover": {
                background: "#592091",
              },
            }}
          >
            {t("chooseLabel")}
            <input
              accept="image/png,image/jpeg,image/gif"
              type="file"
              style={{ fontSize: "12px" }}
              hidden
              onChange={handleChange}
            />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
