import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Tooltip from "@mui/material/Tooltip";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { useAppDispatch } from "../../store/store";
import { updateKittyCat } from "../../store/thunk/KittyCategory/UpdateKittyCatListThunk";
import FileUploader from "../common/imageUpload";
import { Box, Grid, FormHelperText } from "@mui/material";

export default function KittyUpdate(props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [newdata, setNewData] = React.useState({});
  const [formError, setFormError] = React.useState([]);

  const getData = () => {
    setNewData({
      id: props.data.id,
      description: props.data.description,
      category_name: props.data.category_name,
      image: props.data.image,
    });
  };

  const validateForm = (data) => {
    let error = [];

    if (data.category_name === "") {
      error.push("category_name");
    }
    if (data.description === "") {
      error.push("description");
    }

    // if (data.image === "") {
    //   error.push("image");
    // }
    setFormError(error);

    return error;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.refreshSearch();
  };

  const updateData = (text, event) => {
    setNewData({
      ...newdata,
      [text]: event.target.value,
    });

    const index = formError.indexOf(text);
    if (index !== -1) {
      formError.splice(index, 1);
    }
  };

  const handleSubmit = () => {
    let error = validateForm(newdata);
    if (error.length == 0) {
      dispatch(updateKittyCat(newdata));
      handleClose();
    }
  };

  const handleSelectFile = (file) => {
    setNewData((newdata) => ({
      ...newdata,
      image: file,
    }));
  };

  return (
    <div>
      <Button
        sx={{ textDecoration: "none", color: "#fff" }}
        onClick={() => {
          handleClickOpen();
          getData();
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: " center",
            justifyContent: "center",
            fontWeight: " 600",
            fontSize: "18px",
          }}
        >
          <EditIcon
            sx={{
              color: props.from && props.from === "admin" ? "#592091" : "#fff",
              mr: 1,
            }}
          />
          {props.from && props.from === "admin" ? "" : "Edit"}
        </Box>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { m: { md: "30px", xs: "10px" } } }}
      >
        <DialogTitle
          sx={{
            textTransform: "uppercase",
            borderBottom: "1px solid #ddd",
            background:
              "linear-gradient(to left, #592091, #4b0082, #91bcff, #1064e7, #808080, #4b0082, #0161f7)",
            WebkitBackgroundClip: "text",
          }}
        >
          {t("kittyCatFieldLable.0")}
        </DialogTitle>
        <DialogContent sx={{ padding: { md: "0 24px", xs: "0 10px" } }}>
          <DialogContentText
            sx={{ fontSize: "20px", fontWeight: "600", m: "10px 0" }}
          >
            {t("kittyCatFieldLable.5")}
          </DialogContentText>
          <Grid container>
            <Grid item sx={{ padding: "1px 4px", mt: 1 }} xs={12} md={6}>
              <FileUploader
                onSelectFile={handleSelectFile}
                imageSrc={newdata?.image}
              />
              {formError.includes("image") && (
                <FormHelperText id="component-error-text" error>
                  {t("kittyCatFieldValidation.0")}
                </FormHelperText>
              )}
            </Grid>
            <Grid
              item
              sx={{ padding: { md: "1px 4px 1px 20px", xs: 0 } }}
              xs={12}
              md={6}
            >
              <Box sx={{ padding: "1px 4px" }}>
                <TextField
                  autoFocus
                  margin="dense"
                  size="small"
                  id="name"
                  value={newdata.category_name}
                  onChange={(e) => {
                    updateData("category_name", e);
                  }}
                  label={t("kittyCatFieldLable.2")}
                  type="text"
                  sx={{ display: "flex", margin: "15px 2px" }}
                  variant="outlined"
                  error={formError.includes("category_name")}
                  helperText={
                    formError.includes("category_name")
                      ? t("kittyCatFieldValidation.1")
                      : ""
                  }
                />
              </Box>
              <Box sx={{ padding: "1px 4px" }}>
                <TextField
                  margin="dense"
                  size="small"
                  value={newdata.description}
                  onChange={(e) => {
                    updateData("description", e);
                  }}
                  id="object_of_kitty"
                  label={t("kittyCatFieldLable.3")}
                  type="text"
                  sx={{ display: "flex", margin: "15px 2px" }}
                  variant="outlined"
                  error={formError.includes("description")}
                  helperText={
                    formError.includes("description")
                      ? t("kittyCatFieldValidation.2")
                      : ""
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            p: { md: "20px", xs: "10px" },
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => {
              handleClose();
              setNewData(props.data);
            }}
            sx={{
              fontSize: "12px",
              background: "#fff",
              color: "#592091",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
          >
            {t("cancelLable")}
          </Button>
          <Button
            sx={{
              background: "#592091",
              color: "#fff",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={() => {
              handleSubmit();
            }}
          >
            {t("updateLable")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
