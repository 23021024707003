import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setUpdatedKittyCat,
  setKittyCatUpdateError,
} from "../../reducer/KittyCategorySlice/UpdateKittyCatListSlice";
import axios from "axios";
import { setSuccessAlert, setErrorAlert } from "../../reducer/AlertSlice";
import { DeleteData } from "../../reducer/User/TokenExpireSlice";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "multipart/form-data";
axios.defaults.headers["Authorization"] = localStorage.getItem("UserInfo")
  ? `Token ${localStorage.getItem("UserInfo")}`
  : "";

export const updateKittyCat = createAsyncThunk(
  "checkAuth",
  async (_request, { dispatch }) => {
    let form_data = new FormData();
    form_data.append("category_name", _request.category_name);
    form_data.append("description", _request.description);
    if (_request.image && _request.image.name) {
      form_data.append("image", _request.image);
    }
    axios.defaults.headers["Content-Type"] = "multipart/form-data";
    axios
      .patch(`api/v1/kitty/kitty-category/${_request.id}/`, form_data)
      .then((response) => {
        dispatch(setUpdatedKittyCat(response.data));
        dispatch(setSuccessAlert("Updated successfully"));
      })
      .catch((err) => {
        dispatch(setKittyCatUpdateError(err));
        if (
          err?.response?.data?.detail === "The Token is expired" ||
          err?.response?.data?.detail === "Invalid token." ||
          err?.response?.data?.detail === "Invalid token"
        ) {
          dispatch(DeleteData());
        } else {
          dispatch(setErrorAlert("something wrong try again"));
        }
      });
  }
);
