import * as React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ListofKittyCat from "./KittyCatTable";

export default function Admin() {
    return (
        <>
            <Header />
            <ListofKittyCat/>
            <Footer />
        </>
    );
}
