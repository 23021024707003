import React from "react";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import InnerLowestFees from "./homePage/lowestFees/innerLowestFees";
import LowestFeesMainBlock from "./homePage/lowestFees/innerLowestFees/LowestFeesMainBlock";


function InnerLowestFeesComponent() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <Header />
            <LowestFeesMainBlock />
            <InnerLowestFees />
            <Footer />
        </>
    );
}
export default InnerLowestFeesComponent;
