import React from "react";
import Footer from "../../../components/common/Footer";
import Header from "../../../components/common/Header";
import ParticipantPage from "./ParticipantPage";
import { Box } from "@mui/material";

function index() {
  return (
    <>
      <Header />
      <Box sx={{ background: "#592091" }} className="min-height-div">
        <ParticipantPage />
      </Box>
      <Footer />
    </>
  );
}

export default index;
