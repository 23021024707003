/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import "./KittyTable.scss";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { listofKittyCat } from "../../store/thunk/KittyCategory/listofKittyCatThunk";
import { Button, Box, Typography } from "@mui/material";
import DeleteAlertBBox from "./KittyCatDelete";
import UpdateKittyCat from "./KittyCatUpdate";
import { Grid, Paper } from "@mui/material";
import KittyCatUpdate from "./KittyCatInsertNew";
import { createTheme } from "react-data-table-component";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

function MyComponent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const recordLimit = 10;
  const [pageCount, SetPageCount] = useState(0);
  const { t } = useTranslation();
  const data = useAppSelector((state) => state.listofKittyCatSlice);
  const profileDetails = useAppSelector((state) => state?.UserProfile?.details);
  const [search, setSearch] = useState("");

  const columns = [
    {
      name: t("kittyCatListingTableLable.0"),
      selector: (row) => <img width={50} height={50} src={row.image} alt="" />,
      // width: "4rem",
    },
    {
      name: t("kittyCatListingTableLable.1"),
      selector: (row) => row.category_name,
    },
    {
      name: t("kittyCatListingTableLable.2"),
      selector: (row) => row.description,
    },
    {
      name: t("kittyCatListingTableLable.3"),
      selector: (row) => row.created_at.slice(0, 10),
    },
    {
      name: t("kittyCatListingTableLable.4"),
      selector: (row) => row.updated_at.slice(0, 10),
    },
    {
      name: t("kittyCatListingTableLable.5"),
      width: "6rem",
      cell: (row) => (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "rows",
            width: "1rem",
          }}
        >
          <Box style={{ width: "2rem", display: { md: "flex", xs: "block" } }}>
            <Button>
              <UpdateKittyCat data={row} SetPageCount={SetPageCount} />
            </Button>
          </Box>
          <Box
            sx={{
              width: "2rem",
              display: "flex",
              margin: "10px",
              lineHeight: 0,
              minWidth: "64px",
              padding: 0,
            }}
          >
            <Button>
              <DeleteAlertBBox data={row} />
            </Button>
          </Box>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageCount]);

  useEffect(() => {
    // dispatch(listofKittyCat());
    dispatch(listofKittyCat({ limit: recordLimit, offSet: pageCount }));
  }, [pageCount]);

  useEffect(() => {
    dispatch(listofKittyCat({ limit: recordLimit, offSet: 0, search }));
  }, [search]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "#ccc",
        "&:not(:last-of-type)": {
          borderBottomWidth: 0,
        },
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "#fff ",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        textAlign: "center",
        fontSize: "17px",
        display: "grid",
        placeContent: "center",
        backgroundColor: "#2a2828",
        color: "#fff",
        maxWidth: "40px",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        padding: "8px",
        fontSize: "14px",
        display: "grid",
        placeContent: "center",
      },
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#525453cf",
        secondary: "#2aa198",
      },
      background: {
        default: "#f1efeb",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#073642",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
      stripedStyle: {
        default: "",
      },
    },
    "dark"
  );

  const redirectPage = () => {
    if (profileDetails?.is_superuser) {
      navigate("/my-kitty");
    } else {
      navigate("/admin");
    }
  };

  return (
    <Box
      className="kittyTable"
      sx={{
        padding: { md: "5rem 2rem !important", xs: "4rem 10px !important" },
      }}
    >
      <Box sx={{ xs: 1, md: 2 }}>
        <Grid container>
          <Paper
            sx={{
              width: "100%",
              padding: { md: 2, xs: 1 },
              margin: { md: "16px 16px 0px 16px", xs: "10px 0" },
              background: "rgba(89, 32, 145, 0.1411764706)",
              display: { md: "flex", xs: "block" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: { md: "block", xs: " flex" },
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                className="tableTitle"
                sx={{
                  textTransform: "uppercase",
                  marginTop: { md: "10px", xs: 0 },
                }}
              >
                {t("kittyCatListinSection.0")}
              </Box>
              <Box>
                <Button
                  onClick={redirectPage}
                  sx={{
                    color: "#868484",
                    fontWeight: "600",
                    justifyContent: "left",
                  }}
                >
                  {"< "}
                  {t("backLabel")}
                </Button>
              </Box>
            </Box>
            <Box sx={{ mt: 0 }}>
              <KittyCatUpdate
                search={search}
                setSearch={setSearch}
                SetPageCount={SetPageCount}
              />
            </Box>
          </Paper>
          {data?.list?.results?.map((item) => (
            <Grid
              item
              md={12}
              sx={{ flex: "0 0 100%", maxWidth: "100%" }}
              key={item.id}
            >
              <Paper
                sx={{
                  p: { xs: 1, md: 2 },
                  border: "1px solid #ddd",
                  margin: { md: "16px 16px 0px 16px", xs: "10px 0" },
                }}
              >
                <Grid
                  container
                  sx={{
                    justifyContent: "space-between",
                    display: { md: "flex", xs: "block" },
                  }}
                >
                  <Grid
                    item
                    md={3}
                    sx={{
                      paddingRight: { md: "20px", xs: "0" },
                      borderRight: { md: "1px solid #ddd", xs: "0px" },
                    }}
                  >
                    <img
                      src={item.image}
                      alt="TontineImage"
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "cover",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={7}
                    sx={{ p: { xs: 0, md: "0 16px" }, textAlign: "left" }}
                  >
                    <Box
                      sx={{
                        display: { xs: "block", md: "flex" },
                        justifyContent: "space-between",
                        alignContent: "center",
                        m: { md: 0, xs: "10px 0" },
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: "600",
                          color: "#555",
                          mb: 1,
                          fontSize: { md: "18px", xs: "16px" },
                        }}
                      >
                        {item.category_name}
                      </Typography>
                    </Box>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#555",
                        mb: 1,
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                    >
                      Description :{" "}
                      <span style={{ fontSize: "15px", fontWeight: "100" }}>
                        {item.description}
                      </span>
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#555",
                        mb: 1,
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                    >
                      {t("kittyCatListingTableLable.3")} :{" "}
                      <span style={{ fontSize: "15px", fontWeight: "100" }}>
                        {item.created_at.slice(0, 10)}{" "}
                      </span>
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#555",
                        mb: 1,
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                    >
                      {t("kittyCatListingTableLable.4")} :{" "}
                      <span style={{ fontSize: "15px", fontWeight: "100" }}>
                        {item.updated_at.slice(0, 10)}{" "}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    sx={{
                      paddingLeft: { md: "20px", xs: "0px" },
                      borderLeft: { md: "1px solid #ddd", xs: 0 },
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: "center",
                        background: "#808080",
                        marginBottom: "10px",
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "3px",
                        color: "#fff",
                      }}
                    >
                      <UpdateKittyCat data={item} SetPageCount={SetPageCount} />
                    </Box>
                    <Box
                      sx={{
                        textAlign: "center",
                        background: "#f5564a",
                        marginBottom: "10px",
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "3px",
                        color: "#fff",
                      }}
                    >
                      <DeleteAlertBBox data={item} />
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
          {!data?.list?.results?.length ? (
            <Grid item md={12}>
              <Paper
                sx={{
                  p: 2,
                  border: "1px solid #ddd",
                  margin: "16px 16px 0px 16px",
                  color: "#525453cf",
                  backgroundColor: "#f1efeb",
                  textAlign: "center",
                }}
              >
                <Grid container>
                  <Grid item md={12}>
                    {t("norecordLbl")}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ) : null}
        </Grid>
      </Box>
      <Box className="pagination">
        <Tooltip title={t("previousLabel")}>
          <Button
            onClick={() => SetPageCount(pageCount - 10)}
            disabled={pageCount < 1 ? true : false}
          >
            <ArrowBackIosNewIcon />
          </Button>
        </Tooltip>
        <Typography variant="h7" padding={2.5}>
          {pageCount / 10 + 1}
        </Typography>
        <Tooltip title={t("nextLabel")}>
          <Button
            onClick={() => SetPageCount(pageCount + 10)}
            disabled={data.list.count <= pageCount + 10 ? true : false}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default MyComponent;
