import * as React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

function SearchBar({ searchVal, setSearchFunc, change }) {
  const { t } = useTranslation();

  return (
    <TextField
      sx={{ width: "100%", background: "#fff", borderRadius: "4px" }}
      size="small"
      label={t("SearchLable")}
      value={searchVal}
      onChange={(e) => {
        setSearchFunc(e.target.value);
        change.setPage();
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment sx={{ p: 0 }}>
            <IconButton>
              {searchVal ? (
                <Tooltip title={t("Clear")}>
                  <ClearIcon
                    onClick={(e) => {
                      setSearchFunc("");
                    }}
                  />
                </Tooltip>
              ) : (
                <SearchIcon />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchBar;
