import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Box } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/store";
import { deleteKittyCat } from "../../store/thunk/KittyCategory/DeleteKittyCatThunk";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteAlertBBox(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteUpdateKitty = () => {
    dispatch(deleteKittyCat(props.data.id));
  };

  return (
    <div>
      <Button
        sx={{ textDecoration: "none", color: "#fff" }}
        onClick={handleClickOpen}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: " center",
            justifyContent: "center",
            fontWeight: " 600",
            fontSize: "18px",
          }}
        >
          <DeleteIcon sx={{ color: "#fff ", mr: 1 }} />
          Delete
        </Box>
      </Button>

      <Dialog
        sx={{ padding: { md: "0 24px", xs: "0 10px" } }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            fontWeight: "600",
            background: "#592091",
            color: "#fff",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >{`${t("deleteActionLable.0")} "${props.data.category_name}" ${t(
          "deleteActionLable.1"
        )}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("deleteActionLable.2")} {props.data.category_name}{" "}
            {t("deleteActionLable.1")}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontSize: "12px",
              background: "#fff",
              color: "#592091",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: " 2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={handleClose}
          >
            {t("cancelLable")}
          </Button>
          <Button
            sx={{
              background: "#592091",
              color: "#fff",
              display: "block",
              padding: "8px 20px",
              fontWeight: "600",
              letterSpacing: "2px",
              border: "1px solid #592091",
              "&:hover": {
                color: "#592091",
                background: "#fff",
              },
            }}
            onClick={() => {
              handleClose();
              deleteUpdateKitty();
            }}
          >
            {t("deleteLable")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
