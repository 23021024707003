import React from "react";
import ComingSoon from "./comingSoon";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";

function ComingSoonComponent() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.URL]);
  return (
    <>
      <Header />
      <ComingSoon />
      <Footer />
    </>
  );
}
export default ComingSoonComponent;
