import { Box, Button, FormHelperText } from "@mui/material";
import React, { useEffect, useState } from "react";
import RichTextEditor from "react-rte";
import { useTranslation } from "react-i18next";
import {
  editUpdateofKitty,
  UpdateofKitty,
} from "../../../store/thunk/Kitty/KittyDetailsThunk";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { setKittyUpdate } from "../../../store/reducer/KittySlice/KittyDetailsSlice";
import { fewUpdateKitty } from "../../../store/thunk/Kitty/UpdateKittyListThunk";
// import "./styles.css";

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_ALIGNMENT_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_ALIGNMENT_BUTTONS: [
    { label: "Align Left", style: "ALIGN_LEFT" },
    { label: "Align Center", style: "ALIGN_CENTER" },
    { label: "Align Right", style: "ALIGN_RIGHT" },
    { label: "Align Justify", style: "ALIGN_JUSTIFY" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export default function Update(props) {
  const { showCancel } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [formError, setFormError] = useState([]);
  const [kittyData, setKittyData] = useState("");

  const tempdata = useAppSelector(
    (state) => state.DetailsOfKitty.updateTempForm
  );

  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createValueFromString("", "html")
  );
  /**
   * Handle editor's change.
   * @param {import('react-rte').EditorValue} value Value from the editor.
   */
  function onChange(value) {
    setEditorValue(value);
    setFormError([]);
  }

  function closeEditor() {
    props.closeFunc(false);
  }
  useEffect(() => {
    if (tempdata?.edit)
      setEditorValue(
        RichTextEditor.createValueFromString(tempdata?.content, "html")
      );
    if (tempdata?.objKitty)
      setEditorValue(
        RichTextEditor.createValueFromString(tempdata?.content, "html")
      );
  }, [tempdata]);

  const validateForm = (data) => {
    let error = [];
    if (data.content === "" || data.content === "<p><br></p>") {
      error.push("content");
    }
    setFormError(error);
    return error;
  };

  const addNewUpdates = () => {
    if (!tempdata?.edit) {
      let error = validateForm({ content: editorValue.toString("html") });

      if (error.length == 0) {
        dispatch(
          UpdateofKitty({
            content: editorValue.toString("html"),
            ...props?.ids?.id,
          })
        );
      }
    }
  };

  const updateKittyData = () => {
    let error = validateForm({ content: editorValue.toString("html") });

    if (error.length == 0) {
      dispatch(
        editUpdateofKitty({
          data: {
            content: editorValue.toString("html"),
            ...props.ids.id,
          },
          id: tempdata.id,
        })
      );
      setEditorValue(RichTextEditor.createValueFromString("", "html"));
    }
  };

  return (
    <div className="Update">
      <RichTextEditor
        onChange={onChange}
        value={editorValue}
        sx={{ width: "100%" }}
        editorClassName="richtext-editor-input"
      />
      <FormHelperText
        sx={{
          marginBottom: "10px",
          marginLeft: "10px",
          color: "red",
        }}
      >
        {formError.includes("content") ? t("kittyFieldValidation.3") : ""}
      </FormHelperText>
      <Box sx={{ display: "flex", alignItems: "ceter", mt: "15px" }}>
        <>
          {tempdata.edit ? (
            <>
              <Button
                sx={{
                  margin: "0 8px",
                  backgroundColor: "#592091",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#4b2373",
                    color: "#fff",
                  },
                }}
                onClick={updateKittyData}
              >
                {t("updateLable")}
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setEditorValue(
                    RichTextEditor.createValueFromString("", "html")
                  );
                  closeEditor();
                }}
              >
                {t("cancelLable")}
              </Button>
            </>
          ) : tempdata?.objKitty ? (
            <>
              <Button
                sx={{
                  background: "#592091",
                  color: "#fff",
                  padding: { md: "8px 20px", xs: "8px 10px" },
                  fontWeight: "600",
                  letterSpacing: "1px",
                  ml: 2,
                  mr: 2,
                  border: "1px solid #592091",
                  "&:hover": {
                    color: "#592091",
                    background: "#fff",
                  },
                }}
                onClick={() => {
                  if (tempdata?.objKitty) {
                    dispatch(
                      fewUpdateKitty({
                        object_of_kitty: editorValue.toString("html"),
                        id: tempdata?.id,
                      })
                    );
                  }
                  setEditorValue(
                    RichTextEditor.createValueFromString("", "html")
                  );
                }}
              >
                {t("saveDescriptionLabel")}
              </Button>
              {showCancel && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEditorValue(
                      RichTextEditor.createValueFromString("", "html")
                    );
                    closeEditor();
                  }}
                >
                  {t("cancelLable")}
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                sx={{
                  margin: "0 8px",
                  backgroundColor: "#592091",
                  color: "#fff",
                  p: "8px 20px",
                  "&:hover": {
                    backgroundColor: "#4b2373",
                    color: "#fff",
                  },
                }}
                onClick={addNewUpdates}
              >
                {t("addLabel")}
              </Button>
              {showCancel && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEditorValue(
                      RichTextEditor.createValueFromString("", "html")
                    );
                    closeEditor();
                  }}
                >
                  {t("cancelLable")}
                </Button>
              )}
            </>
          )}
        </>
      </Box>
    </div>
  );
}
