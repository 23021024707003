import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function InnerServiceMainBlock() {
    const { t } = useTranslation();
    return (
        <Box className="inner-service-main-block" sx={{ pt: "64px" }}>
            <Container>
                <Box sx={{
                    background: "#ffffffc7",
                    position: " absolute",
                    top: "18%",
                    padding: "20px",
                    left: "2%",
                    right: "1%",
                    maxWidth: "390px",
                    '@media (max-width: 767px)': {
                        top: "60%",
                        left: "50%",
                        transform: " translate(-50%, -50%)",
                        width: "100%",
                        maxWidth: "260px",

                    },
                }}>
                    <Typography
                        variant="h3"
                        sx={{
                            fontSize: "2.25rem",
                            lineHeight: "2.475rem",
                            fontWeight: " 600",
                            color: "#555",
                            '@media (max-width: 767px)': {
                                fontSize: "20px",
                                lineHeight: "22px",
                            },
                        }}
                    >

                        {t("servicePageTitleContent.0")}

                    </Typography>
                    <Typography
                        variant="body"
                        sx={{
                            fontWeight: " 400",
                            fontSize: "1.25rem",
                            lineHeight: " 32px",
                            color: "#592091",
                            display: " block",
                            marginTop: " 10px",
                            '@media (max-width: 767px)': {
                                marginTop: "0px",
                                fontSize: "15px",
                                lineHeight: "22px",
                            },
                        }}
                    >
                        {t("servicePageTitleContent.1")}

                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}
