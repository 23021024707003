import { Box, Container, Typography } from "@mui/material";
import SecurityImage from "../../../../assets/Images/banking.png";
import CatchingPokemonIcon from "@mui/icons-material/CatchingPokemon";
import AccordionComponent from "../../../../components/common/Accordion";
import { useTranslation } from "react-i18next";

export default function DataProtectionBlock() {
  const { t } = useTranslation();
  return (
    <Box sx={{ p: "0px 0px 50px" }}>
      <Container>
        <Box>
          <Typography
            className="top-subheading"
            variant="h5"
            sx={{ textAlign: "center", fontWeight: "600", mb: 2 }}
          >
            {t("servicePageHeading.1")}
          </Typography>
          <img
            src={SecurityImage}
            loading="lazy"
            alt=""
            style={{ margin: "0 auto 40px", display: "table" }}
          />
        </Box>
      </Container>
      <Container maxWidth="md">
        <Box>
          <AccordionComponent
            title={t("servicePageQuestion.3")}
            content={t("servicePageAnswer.3")}
          />
          <AccordionComponent
            title={t("servicePageQuestion.4")}
            content={t("servicePageAnswer.4")}
          />
          <AccordionComponent
            title={t("servicePageQuestion.5")}
            content={t("servicePageAnswer.5")}
          />
        </Box>
      </Container>
      <Box
        sx={{
          width: " 100%",
          color: "#e6e6e6",
          position: "relative",
          margin: "50px 0px",
        }}
      >
        <CatchingPokemonIcon
          className="divider"
          sx={{
            margin: "0px",
            position: "absolute",
            left: "50%",
            top: "-12px",
            background: " white",
            borderRadius: "50%",
          }}
        />
      </Box>
    </Box>
  );
}
