import * as React from "react";
import {
  Grid,
  Paper,
  Button,
  Box,
  Typography,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Checkbox,
  ListItemButton,
  ListItemText,
  FormHelperText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  fewUpdateKitty,
  updateKittySetting,
} from "../../../store/thunk/Kitty/UpdateKittyListThunk";

export default function Amount() {
  const { t } = useTranslation();
  const settings = useAppSelector((state) => state.DetailsOfKitty.settings);
  const list = useAppSelector((state) => state.DetailsOfKitty.list);
  const dispatch = useAppDispatch();
  const [newdata, setNewData] = React.useState({
    suggested_amount: list.suggested_amount,
    amount: list.amount,
  });
  const [checked1, setChecked1] = React.useState(
    settings?.hide_target_total_amount
  );
  const [checked2, setChecked2] = React.useState(
    settings?.hide_contribution_amount
  );
  const [checked3, setChecked3] = React.useState(
    settings?.hide_all_contributors_info
  );

  const [formError, setFormError] = React.useState([]);

  const validateForm = (data) => {
    let error = [];

    if (data.suggested_amount !== "" && data.suggested_amount <= 0) {
      error.push("suggested_amount");
    }

    if (data.amount !== "" && data.amount <= 0) {
      error.push("amount");
    }

    setFormError(error);

    return error;
  };

  const updateData = (text, event) => {
    setNewData({
      ...newdata,
      [text]: event.target.value,
    });

    const index = formError.indexOf(text);
    if (index !== -1) {
      formError.splice(index, 1);
    }
  };

  const handleSubmit = () => {
      let req = {
        id: list?.id,
        suggested_amount: newdata.suggested_amount,
        amount: newdata.amount,
      };
      let check = {
        id: settings?.id,
        hide_target_total_amount: checked1,
        hide_contribution_amount: checked2,
        hide_all_contributors_info: checked3,
      };
      dispatch(updateKittySetting(check));
      dispatch(fewUpdateKitty(req));
  };

  return (
    <>
      <Paper
        sx={{
          p: 2,
          width: { md: "95%", xs: "auto" },
          backgroundColor: "#f2f2f2",
          margin: "0 auto",
        }}
      >
        <Grid container>
          <Grid item xs={6} sx={{ p: 1 }}>
            <Typography
              variant="body2"
              sx={{ display: "table", mb: "10px", fontWeight: "600" }}
            >
              {t("kittySettingAmount.0")}:
            </Typography>
            <OutlinedInput
              style={{ width: "100%" }}
              placeholder="350"
              sx={{ background: "#fff" }}
              autoFocus
              value={newdata.amount}
              onChange={(e) => {
                updateData("amount", e);
              }}
              margin="dense"
              id="name"
              type="text"
              variant="outlined"
              endAdornment={<InputAdornment position="end">€</InputAdornment>}
              error={formError.includes("amount")}
            />
            {formError.includes("amount") && (
              <FormHelperText id="component-error-text" error>
                {t("kittyFieldValidation.4")}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} sx={{ p: 1 }}>
            <Typography
              variant="body2"
              sx={{ display: "table", mb: "10px", fontWeight: "600" }}
            >
              {" "}
              {t("kittySettingAmount.1")}:
            </Typography>
            <OutlinedInput
              placeholder="30"
              sx={{ background: "#fff", width: "100%" }}
              value={newdata.suggested_amount}
              onChange={(e) => {
                updateData("suggested_amount", e);
              }}
              margin="dense"
              id="suggested_amount"
              type="text"
              variant="outlined"
              endAdornment={<InputAdornment position="end">€</InputAdornment>}
              error={formError.includes("suggested_amount")}
            />
            {formError.includes("suggested_amount") && (
              <FormHelperText id="component-error-text" error>
                {t("kittyFieldValidation.4")}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ marginTop: "10px" }}>
        <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
          <ListItem
            secondaryAction={
              <Checkbox
                checked={checked1}
                onChange={() => setChecked1(!checked1)}
                edge="end"
                inputProps={"aria-labelledby"}
              />
            }
            disablePadding
          >
            <ListItemButton onClick={() => setChecked1(!checked1)}>
              <ListItemText>{t("kittySettingAmount.2")}</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem
            secondaryAction={
              <Checkbox
                checked={checked2}
                onChange={() => setChecked2(!checked2)}
                edge="end"
                inputProps={"aria-labelledby"}
              />
            }
            disablePadding
          >
            <ListItemButton onClick={() => setChecked2(!checked2)}>
              <ListItemText>{t("kittySettingAmount.3")}</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem
            secondaryAction={
              <Checkbox
                checked={checked3}
                onChange={() => setChecked3(!checked3)}
                edge="end"
                inputProps={"aria-labelledby"}
              />
            }
            disablePadding
          >
            <ListItemButton onClick={() => setChecked3(!checked3)}>
              <ListItemText>{t("kittySettingAmount.4")}</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>

      <Button
        sx={{
          background: "#592091",
          color: "#fff",
          display: "block",
          marginTop: "10px",
          padding: "8px 20px",
          fontWeight: "600",
          letterSpacing: " 2px",
          border: "1px solid #592091",
          "&:hover": {
            color: "#592091",
            background: "#fff",
          },
        }}
        variant="contained"
        onClick={() => {
          handleSubmit();
        }}
      >
        {t("saveLabel")}
      </Button>
    </>
  );
}
