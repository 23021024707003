import * as React from "react";
import { Box } from "@mui/material";
import KittyStepper from "./CreatekittyTabs/KittyStepper";
// import VerticalTabs from "./VerticalTabs";

export default function KittyCategoryCard(props) {
  return (
    <Box>
      <Box
        sx={{
          padding: { md: "10px", xs: 0 },
          display: "flex",
          flexDirection: "rows",
          flex: "1fr 1fr",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <KittyStepper />
        {/* <VerticalTabs data={props} /> */}
      </Box>
    </Box>
  );
}
